<template>
    <div>
      <div class="tax master-table box mt-4">
        <div class="row">
          <div class="col-md-12 offset-md-11">
            <button
              type="submit"
              PAYMENT_DESCRIPTION=""
              id=""
              class="custom-btn add-btn"
              name=""
              data-bs-toggle="modal"
              data-bs-target="#master_tax_modal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
            </button>
          </div>
        </div>
  
        <table class="table table-bordered mt-3">
          <thead>
            <tr>
              <th scope="col">Sl. No.</th>
              <th scope="col">Payment Code</th>
              <th scope="col">Payment Description</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(tax_info, index) in code_info_details"
              :key="tax_info.PAYMENT_CODE_SYS_ID"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ tax_info.PAYMENT_CODE }}</td>
              <td>{{ tax_info.PAYMENT_DESCRIPTION }}</td>
              <td class="table-btn">
                <button
                  type="button"
                  class="custom-btn p-0"
                  @click="showModal(tax_info, '#master_tax_edit_modal')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit"
                  >
                    <path
                      d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                    ></path>
                    <path
                      d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                    ></path>
                  </svg>
                </button>
                <button
                  type="button"
                  class="custom-btn p-0"
                  @click="showModal(tax_info, '#master_tax_delete_modal')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trash-2"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- create modal-->
  
      <div
        class="modal modal-right fade"
        id="master_tax_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" style="width: 30%">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Payment Code</h5>
              <button
                type="button"
                class="btn-normal modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <ValidationObserver ref="taxfor" v-slot="{ invalid }">
                    <ValidationProvider v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Payment Code</label>
                        <input
                          type="text"
                          name="Payment Code"
                          placeholder="Enter Payment Code"
                          v-model="create_code.PAYMENT_CODE"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null && create_code.PAYMENT_CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
  
                  
                    <ValidationProvider v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Payment Description</label>
                        <input
                          type="text"
                          name="Payment Description"
                          placeholder="Enter Payment Description"
                          v-model="create_code.PAYMENT_DESCRIPTION"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null && create_code.PAYMENT_DESCRIPTION != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
  
                    <div class="modal-footer-2">
                      <div class="row mt-4">
                        <div class="col-md-5">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            :disabled="invalid"
                            @click="createCode"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            @click="emptyfu"
                            data-bs-dismiss="modal"
                            class="custom-btn custom-danger-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- edit modal-->
  
      <div
        class="modal modal-right fade"
        id="master_tax_edit_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" style="width: 30%">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Edit Payment Code</h5>
              <button
                type="button"
                class="btn-normal modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <ValidationObserver ref="taxeditform" v-slot="{ invalid }">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Payment Code</label>
                        <input
                          type="text"
                          name="Payment Code"
                          placeholder="Enter Payment Code"
                          v-model="selected_row.PAYMENT_CODE"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null && create_code.PAYMENT_CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
  
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Payment Description</label>
                        <input
                          type="text"
                          name="Payment Description"
                          placeholder=""
                          v-model="selected_row.PAYMENT_DESCRIPTION"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null && create_code.PAYMENT_DESCRIPTION != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <div class="modal-footer-2">
                      <div class="row mt-4">
                        <div class="col-md-5">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            :disabled="invalid"
                            @click="updateCode"
                          >
                            Update
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            class="custom-btn custom-danger-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!--- delete modal -->
  
      <div class="modal" id="master_tax_delete_modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Delete</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete ?</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger" @click="deleteCode">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import apiEndPoints from "../../endpoints.js";
  import { Global } from "@/global.js";
  
  export default {
    data() {
      return {
        code_info_details: [],
        create_code: {
          ITEM: "ADD",
          RECORD_SYS_ID: "0",
          PAYMENT_CODE: "",
          CREATED_BY: "",
          PAYMENT_DESCRIPTION: "",
        },
        selected_row: {},
      };
    },
  
    mounted() {
      this.getPaymentCode();
    },
  
    methods: {
      emptyfu() {
        this.create_code.PAYMENT_CODE = "";
        this.create_code.PAYMENT_DESCRIPTION = "";
      },
  
      getPaymentCode() {
        this.axios
          .get(apiEndPoints.get_view_payment_code, {
            params: {
              ITEM: "VIEW_ALL",
              RECORD_SYS_ID: 0,
            },
          })
          .then((response) => {
          
            if (response.data.status == "true") {
              this.code_info_details = response.data.response;
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              this.code_info_details = [];
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
      },
  
      createCode() {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        this.create_code["CREATED_BY"] = user_details.UserId; 
        this.axios
          .post(apiEndPoints.add_update_payment_code, this.create_code)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              $("#master_tax_modal").modal("hide");
              this.getPaymentCode();
              this.emptyfu();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
      },
  
      showModal(row, id) {
        this.selected_row = row;
        $(id).modal("show");
      },
  
      updateCode() {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let update_payload = {
          ITEM: "UPDATE",
          RECORD_SYS_ID: this.selected_row.PAYMENT_CODE_SYS_ID,
          PAYMENT_CODE: this.selected_row.PAYMENT_CODE,
          CREATED_BY: user_details.UserId,
          PAYMENT_DESCRIPTION: this.selected_row.PAYMENT_DESCRIPTION,
        };
        this.$refs.taxeditform.validate().then((success) => {
          if (!success) {
            return;
          }
  
          this.axios
            .post(apiEndPoints.add_update_payment_code, update_payload)
            .then((response) => {
              //console.log(response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(true, "success", response.data.response);
                $("#master_tax_edit_modal").modal("hide");
                this.getPaymentCode();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .catch((err) => {
              //console.log(err);
            });
        });
      },
  
      deleteCode() {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let delete_payload = {
          ITEM: "DELETE",
          RECORD_SYS_ID: this.selected_row.PAYMENT_CODE_SYS_ID,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.add_update_payment_code, delete_payload)
          .then((response) => {
            $("#master_tax_delete_modal").modal("hide");
            this.getPaymentCode();
          })
      },
    },
  };
  </script>
  