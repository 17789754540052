<template>
  <div>
    <div v-if="loading">
      <!-- <div class="loading"></div> -->
      <div class="preloder loading_main_background" id="upload_loader">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>
    <div class="floating-heading">
      <h4><span>View Insurance Details</span></h4>

      <div class="floating-search-btn-container">
        <button
          id="srch_btn"
          data-bs-toggle="modal"
          data-bs-target="#left_modal_sm"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>
      <div class="page-content">
        <div class="page-data">
          <div class="row mb-3">
            <div class="col-md-12 mb-1">
              <label class="frm-label-2" style="color: #a36626"
                ><i class="fa-solid fa-filter"></i> Filter</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="floating-add-btn-container animate__animated animate__lightSpeedInRight"
    >
      <button
        id="add_btn"
        data-bs-toggle="modal"
        data-bs-target="#_modal"
        data-bs-dismiss="modal"
        @click="onLogIn"
        v-if="menuPermissions.FULL_ACCESS || menuPermissions.CREATE_ACCESS"
      >
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="floating-search-btn-container">
        <button
          id="srch_btn"
          data-bs-toggle="modal"
          data-bs-target="#left_modal_sm"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>

      <div class="page-content">
        <div class="page-data">
          <div class="row mb-3">
            <div class="col-md-2" v-if="statusvalue">
              <label class="frm-label-2" style="font-size: 15px"
                >Insurance Type:
                <span class="filter-lbl">{{ statusvalue }}</span></label
              >
            </div>
            <div class="col-md-3" v-if="numbervalue">
              <label class="frm-label-2" style="font-size: 15px">
                Vehicle Number:
                <span class="filter-lbl">{{ numbervalue }}</span></label
              >
            </div>
          </div>
          <div class="data-table admin-master-table">
            <b-table
              :bordered="true"
              :items="insurance_details"
              :fields="fields"
              @row-clicked="Editinsurance"
            >
              <template #cell(index)="data">
                {{ data.index + 1 }}
                <div
                  v-if="
                    data.index + 1 == insurance_details.length &&
                    insurense_page_no > 1
                  "
                >
                  <span v-b-visible.once="loadInsurenceDetails"></span>
                </div>
              </template>
              <template v-slot:cell(ACTIONS)="row">
                <button
                  type="button"
                  class="custom-btn p-0"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_frm"
                  @click="Delete(row.item)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trash-2"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </button>

                &nbsp;
                <button class="custom-btn p-0" @click="row.toggleDetails">
                  <svg
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="css-i6dzq1"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                  </svg>
                </button>
                <button
                  class="custom-btn p-0"
                  @click="RedirectToaddinsurance(row.item)"
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="css-i6dzq1"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                  </svg>
                </button>
              </template>
              <template #row-details="row">
                <b-card>
                  <div class="d-flex justify-content-center">
                    <div style="padding-right: 10px">
                      <button
                        type="button"
                        class="custom-btn custom-secondary-btn-4"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop-1"
                        @click="getDebitNOteDetails(row.item)"
                      >
                        DEBIT NOTE
                      </button>
                    </div>

                    <div stCFRE32yle="padding-right: 10px;">
                      <button
                        type="button"
                        class="custom-btn custom-secondary-btn-4"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop-2"
                        @click="getCreditNoteDetails(row.item)"
                      >
                        CREDIT NOTE
                      </button>
                    </div>

                    <div style="padding-right: 10px">
                      <button
                        type="button"
                        class="custom-btn custom-secondary-btn-4"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop-5"
                      >
                        INVOICE
                      </button>
                    </div>
                    <div class="table-under-table-dlt">
                      <button
                        type="button"
                        class="custom-btn custom-danger-btn"
                      >
                        <i class="fa-solid fa-trash-can"></i>
                      </button>
                    </div>
                  </div>
                </b-card>
              </template>
            </b-table>
            <!-- <table class="table table-bordered table-hover">
              <thead>
                <tr style="background: #ebd8c3">
                  <th>SL. No.</th>
                  <th scope="col">Vehicle Name</th>
                  <th scope="col">Registration No.</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Insurance Type</th>
                  <th scope="col">Workshop Name</th>
                  <th scope="col">Company Name</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody
                v-for="(vehicle_detail, index) in insurance_details"
                :key="index"
              >
                <tr>
                  <td
                    @click="Editinsurance(vehicle_detail)"
                    data-bs-toggle="modal"
                    data-bs-target="#rokerupdate_modal"
                  >
                    {{ index + 1 }}
                    <div
                      v-if="
                        index + 1 == insurance_details.length &&
                        insurense_page_no > 1
                      "
                    >
                      <span v-b-visible.once="loadInsurenceDetails"></span>
                    </div>
                  </td>
                  <td
                    @click="Editinsurance(vehicle_detail)"
                    data-bs-toggle="modal"
                    data-bs-target="#rokerupdate_modal"
                  >
                    {{ vehicle_detail.VEHICLE_MAKE }}
                    {{ vehicle_detail.VEHICLE_MODEL }}
                  </td>
                  <td
                    @click="Editinsurance(vehicle_detail)"
                    data-bs-toggle="modal"
                    data-bs-target="#rokerupdate_modal"
                  >
                    {{ vehicle_detail.VEHICLE_REG_NO }}
                  </td>
                  <td
                    @click="Editinsurance(vehicle_detail)"
                    data-bs-toggle="modal"
                    data-bs-target="#rokerupdate_modal"
                  >
                    {{ vehicle_detail.INSURANCE_START_DATE }}
                  </td>
                  <td
                    @click="Editinsurance(vehicle_detail)"
                    data-bs-toggle="modal"
                    data-bs-target="#rokerupdate_modal"
                  >
                    {{ vehicle_detail.INSURANCE_END_DATE }}
                  </td>
                  <td
                    @click="Editinsurance(vehicle_detail)"
                    data-bs-toggle="modal"
                    data-bs-target="#rokerupdate_modal"
                  >
                    {{ vehicle_detail.INSURANCE_TYPE }}
                  </td>
                  <td
                    @click="Editinsurance(vehicle_detail)"
                    data-bs-toggle="modal"
                    data-bs-target="#rokerupdate_modal"
                  >
                    {{ vehicle_detail.WORKSHOP_NAME }}
                  </td>
                  <td
                    @click="Editinsurance(vehicle_detail)"
                    data-bs-toggle="modal"
                    data-bs-target="#rokerupdate_modal"
                  >
                    {{ vehicle_detail.COMPANY_NAME }}
                  </td>

                  <td class="table-btn" colspan="2">
                    <button
                      type="button"
                      class="custom-btn p-0"
                      @click="Editinsurance(vehicle_detail)"
                      data-bs-toggle="modal"
                      data-bs-target="#rokerupdate_modal"
                    ></button>
                    <button
                      type="button"
                      class="custom-btn p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_frm"
                      @click="Delete(vehicle_detail)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-trash-2"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path
                          d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                        ></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </button>
                    <button
                      class="custom-btn p-0"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#flush-collapseOne-2'+index"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="css-i6dzq1"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                      </svg>
                    </button>
                  </td>
                </tr> -->

            <!-- table under table  -->
            <!-- <tr class="table-under-table">
                  <td colspan="12" class="p-0" style="padding: 0 !important">
                    <div
                      :id="'#flush-collapseOne-2'+index"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body p-2 mt-1">
                        <form class="d-flex">
                          <button
                            type="button"
                            class="custom-btn custom-secondary-btn-4"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop-1"
                            @click="getDebitNOteDetails(vehicle_detail)"
                          >
                            DEBIT NOTE
                          </button>
                          <button
                            type="button"
                            class="custom-btn custom-secondary-btn-4"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop-2"
                            @click="getCreditNoteDetails(vehicle_detail)"
                          >
                            CREDIT NOTE
                          </button>
                          <button
                            type="button"
                            class="custom-btn custom-secondary-btn-4"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop-5"
                          >
                            INVOICE
                          </button>
                          <div class="table-under-table-dlt">
                            <button
                              type="button"
                              class="custom-btn custom-danger-btn"
                            >
                              <i class="fa-solid fa-trash-can"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table> -->
          </div>
        </div>
      </div>
    </div>

    <!--pdf -->
    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-2">
                <h5 class="modal-title" id="staticBackdropLabel">DEBIT NOTE</h5>
              </div>
              <div class="col-md-10">
                <button
                  class="downld animate__animated animate__bounceIn"
                  @click="generateReport"
                >
                  <i class="fa-solid fa-cloud-arrow-down"></i> Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <div class="row">
              <!-- <div class="col-md-12 offset-md-10">
                        
                     </div> -->
            </div>
            <div class="content">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-2">
                            <p>
                              1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="heading">
                      <p>DEBIT NOTE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6">
                  <div class="content-header-2">
                    <ul>
                      <li><h5>To.</h5></li>
                      <li>
                        <h5>{{ this.pdf_details.FIRST_NAME }}</h5>
                      </li>
                      <li>
                        <p>{{ this.pdf_details.ADDRESS_LINE_1 }}</p>
                      </li>
                      <li>
                        <p>{{ this.pdf_details.ADDRESS_LINE_2 }}</p>
                      </li>
                      <li>
                        <p>
                          {{ this.pdf_details.COUNTRY_NAME }}
                          {{ this.pdf_details.PIN_CODE }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 mt-2" style="text-align: right">
                  <div class="content-header-2">
                    <!-- <h5>Debit Note</h5> -->
                    <ul>
                      <li>
                        <p>
                          CO. REGN NO:
                          <span
                            >{{ this.pdf_details.CO_REGISTRATION_NO }}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Debit Note No.:
                          <span>{{ this.pdf_details.DEBIT_NOTE_NO }} </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Date:
                          <span>{{ this.pdf_details.DEBIT_NOTE_DATE }}</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="content-body">
                    <ul>
                      <li><h5>Policy Details:</h5></li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Vehicle No.</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_details.VEHICLE_NUMBER }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Make & Model</p></div>
                          <div class="col-md-3">
                            <span
                              >: {{ this.pdf_details.VEHICLE_MAKE }} &
                              {{ this.pdf_details.VEHICLE_MODEL }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Period of Insurance</p></div>
                          <div class="col-md-3">
                            <span
                              >: {{ this.pdf_details.INSURANCE_START_DATE }} to
                              {{ this.pdf_details.INSURANCE_END_DATE }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Class</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_details.CLASS }}</span>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Coverage</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_details.COVERAGE }} </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Insurer</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_details.INSURER }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Policy No.</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_details.POLICY_NO }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>NCD</p></div>
                          <div class="col-md-3">
                            <span
                              >:
                              {{ this.pdf_details.NO_CLAIM_DISC_VALUE }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Remarks</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_details.NCD_REMARKS }} </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Premium</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_details.PREMIUM }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>GST</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_details.GST }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Premium Discount</p></div>
                          <div class="col-md-3">
                            <span
                              >: {{ this.pdf_details.PREMIUM_DISCOUNT }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li class="total-amnt">
                        <div class="row">
                          <div class="col-md-3"><p>Premium Payable</p></div>
                          <div class="col-md-3">
                            <span
                              >: {{ this.pdf_details.PREMIUM_PAYABLE }}</span
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul class="mt-4">
                      <li><h5>Note:</h5></li>
                      <li>
                        1) Subject to Premium Warranty as per your policy.
                      </li>
                      <li>
                        2) Please pay the premium with in the Premium Warranty
                        to ensure the continuity of your insurance cover.
                      </li>
                      <li>
                        3) This is a Debit Note and this GST amount is
                        non-claimable as Input-Tax.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <!-- content footer  -->
            <div class="content-footer">
                              <div class="row">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="debitnote"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdf"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="heading">
                  <p>DEBIT NOTE</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <div class="content-header-2">
                <ul>
                  <li><h5>To.</h5></li>
                  <li>
                    <h5>{{ this.pdf_details.FIRST_NAME }}</h5>
                  </li>
                  <li>
                    <p>{{ this.pdf_details.ADDRESS_LINE_1 }}</p>
                  </li>
                  <li>
                    <p>{{ this.pdf_details.ADDRESS_LINE_2 }}</p>
                  </li>
                  <li>
                    <p>
                      {{ this.pdf_details.COUNTRY_NAME }}
                      {{ this.pdf_details.PIN_CODE }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 mt-2" style="text-align: right">
              <div class="content-header-2">
                <!-- <h5>Debit Note</h5> -->
                <ul>
                  <li>
                    <p>
                      UEN/NRIC:
                      <span>{{ this.pdf_details.CO_REGISTRATION_NO }} </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Debit Note No.:
                      <span>{{ this.pdf_details.DEBIT_NOTE_NO }} </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Date:
                      <span>{{ this.pdf_details.DEBIT_NOTE_DATE }}</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-12">
              <div class="content-body">
                <ul>
                  <li><h5>Policy Details:</h5></li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Vehicle No.</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.VEHICLE_NUMBER }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Make & Model</p></div>
                      <div class="col-md-3">
                        <span
                          >: {{ this.pdf_details.VEHICLE_MAKE }} &
                          {{ this.pdf_details.VEHICLE_MODEL }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Period of Insurance</p></div>
                      <div class="col-md-3">
                        <span
                          >: {{ this.pdf_details.INSURANCE_START_DATE }} to
                          {{ this.pdf_details.INSURANCE_END_DATE }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Class</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.CLASS }}</span>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Coverage</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.COVERAGE }} </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Insurer</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.INSURER }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Policy No.</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.POLICY_NO }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>NCD</p></div>
                      <div class="col-md-3">
                        <span
                          >: {{ this.pdf_details.NO_CLAIM_DISC_VALUE }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Remarks</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.NCD_REMARKS }} </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Premium</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.PREMIUM }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>GST</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.GST }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Premium Discount</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.PREMIUM_DISCOUNT }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="total-amnt">
                    <div class="row">
                      <div class="col-md-3"><p>Premium Payable</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.PREMIUM_PAYABLE }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul class="mt-4">
                  <li><h5>Note:</h5></li>
                  <li>1) Subject to Premium Warranty as per your policy.</li>
                  <li>
                    2) Please pay the premium with in the Premium Warranty to
                    ensure the continuity of your insurance cover.
                  </li>
                  <li>
                    3) This is a Debit Note and this GST amount is non-claimable
                    as Input-Tax.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-12">
              <!-- content footer  -->
            <div class="content-footer mt-3">
                              <div class="row">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!--pdf for credtt-->
    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-2">
                <h5
                  class="modal-title"
                  id="staticBackdropLabel"
                  style="width: 150px"
                >
                  CREDIT NOTE
                </h5>
              </div>
              <div class="col-md-10">
                <button
                  class="downld animate__animated animate__bounceIn"
                  @click="generateReportcredit"
                >
                  <i class="fa-solid fa-cloud-arrow-down"></i> Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <!-- <div class="close-btn-div">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> -->
            <div class="row">
              <!-- <div class="col-md-12 offset-md-10">
                        
                     </div> -->
            </div>

            <div class="content">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-2">
                            <p>
                              1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="heading">
                      <p>CREDIT NOTE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 offset-md-7 mt-2">
                  <div class="content-header-2">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Invoice No.</p></div>
                          <div class="col-md-3">
                            <span>: TI/2021-11/1303</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Date</p></div>
                          <div class="col-md-3"><span>: 9/11/2021</span></div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Agreement No:</p></div>
                          <div class="col-md-3"><span>: SA23554</span></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="content-header-2">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>To</p></div>
                          <div class="col-md-8">
                            :<span> WHEELERS AVENUE</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4"><p>Address</p></div>
                          <div class="col-md-8">
                            :<span>
                              258D COMPASSVALE ROAD #07-595 COMPASSVALE GARDEN
                              <span class="ms-1">SINGAPORE 544258 </span></span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <p>Vehicle Registration No.</p>
                          </div>
                          <div class="col-md-8">
                            :<span>{{ this.pdf_detail.VEHICLE_NUMBER }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4"><p>Engine Number</p></div>
                          <div class="col-md-8">:<span> HR16189457D</span></div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4"><p>Chassis Number</p></div>
                          <div class="col-md-8">
                            :<span> JN1YAAM20Z0002323</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="content-body-2">
                    <table class="table table-borderless">
                      <thead
                        style="
                          border-top: 2px solid #212121;
                          border-bottom: 2px solid #212121;
                        "
                      >
                        <tr>
                          <th scope="col">Qty</th>
                          <th scope="col">Description of Goods</th>
                          <th scope="col">Unit Price</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>
                            {{
                              this.pdf_detail.MAKE_NAME +
                              "/" +
                              this.pdf_detail.MODEL_NAME
                            }}
                            <span class="ms-1">NV200</span>
                          </td>
                          <td>$ <span class="ms-1">6,000.00</span></td>
                          <td>$ <span class="ms-1">6,000.00</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="content-footer" style="border: 1px solid #212121">
                    <table class="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <!-- <th scope="col">Qty</th> -->
                          <td></td>
                          <td style="border-left: 1px solid #212121">
                            Amount SGD$
                          </td>
                          <td style="border-left: 1px solid #212121">
                            $ <span class="ms-1">6,000.00</span>
                          </td>
                        </tr>

                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            Terms of Payment: <span class="MS-2">CASH</span>
                          </td>
                          <td style="border-left: 1px solid #212121">
                            $ <span class="ms-1">7% GST</span>
                          </td>
                          <td style="border-left: 1px solid #212121"></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            All cheques issued must be made in the favour of Lek
                            Auto Pte Ltd
                          </td>
                          <td style="border-left: 1px solid #212121">
                            $ <span class="ms-1">Total SGD $</span>
                          </td>
                          <td style="border-left: 1px solid #212121">
                            $ <span class="ms-1">6,000.00</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="creditnote"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li><img src="../../assets/images/logo-txt.png" /></li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div
                  class="heading"
                  style="
                    border: 1px solid #000;
                    padding: 10px;
                    text-align: center;
                  "
                >
                  <p>CREDIT NOTE</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 offset-md-7 mt-2">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Invoice No.</p></div>
                      <div class="col-md-3"><span>: TI/2021-11/1303</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Date</p></div>
                      <div class="col-md-3"><span>: 9/11/2021</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Agreement No:</p></div>
                      <div class="col-md-3"><span>: SA23554</span></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-12" style="margin-bottom: 1rem">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>To</p></div>
                      <div class="col-md-8">:<span> WHEELERS AVENUE</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4"><p>Address</p></div>
                      <div class="col-md-8">
                        :<span>
                          258D COMPASSVALE ROAD #07-595 COMPASSVALE GARDEN
                          <span class="ms-1">SINGAPORE 544258 </span></span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4">
                        <p>Vehicle Registration No.</p>
                      </div>
                      <div class="col-md-8">
                        :<span> {{ this.pdf_detail.VEHICLE_NUMBER }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4"><p>Engine Number</p></div>
                      <div class="col-md-8">:<span> HR16189457D</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4"><p>Chassis Number</p></div>
                      <div class="col-md-8">
                        :<span> JN1YAAM20Z0002323</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-12">
              <div class="content-body-2">
                <table class="table table-borderless">
                  <thead
                    style="
                      border-top: 2px solid #212121;
                      border-bottom: 2px solid #212121;
                    "
                  >
                    <tr>
                      <th scope="col">Qty</th>
                      <th scope="col">Description of Goods</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>
                        {{
                          this.pdf_detail.MAKE_NAME +
                          "/" +
                          this.pdf_detail.MODEL_NAME
                        }}
                        <span class="ms-1">NV200</span>
                      </td>
                      <td>$ <span class="ms-1">6,000.00</span></td>
                      <td>$ <span class="ms-1">6,000.00</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <div class="content-footer" style="border: 1px solid #212121">
                <table class="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <!-- <th scope="col">Qty</th> -->
                      <td></td>
                      <td style="border-left: 1px solid #212121">
                        Amount SGD$
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1">6,000.00</span>
                      </td>
                    </tr>

                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>Terms of Payment: <span class="MS-2">CASH</span></td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1">7% GST</span>
                      </td>
                      <td style="border-left: 1px solid #212121"></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        All cheques issued must be made in the favour of Lek
                        Auto Pte Ltd
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1">Total SGD $</span>
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1">6,000.00</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <!-- edit modal -->
    <div
      class="modal modal-right fade"
      id="rokerupdate_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 90% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update Insurance Details
            </h5>
            <h5 class="modal-title" style="margin-left: 20px">
              {{ vehicletype }}
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div></div>
          <div class="modal-body">
            <div class="row admin-edt-frm mt-2">
              <!-- left sec  -->
              <div class="col-md-6 left-side-frm">
                <div class="row">
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Type<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="updatevehicle_details.INSURANCE_TYPE"
                          @change="showhide"
                          class="form-control"
                        >
                          <option value="">Select Insurance Type</option>
                          <option value="Extention">Extention</option>
                              <option value="Renewal(T)">Renewal(T)</option>
                              <option value="External(R)">External(R)</option>
                              <option value="External">External</option>
                              <option value="Trade Sales">Trade Sales</option>
                              <option value="Rental">Rental</option>
                              <option value="Temp CI">Temp CI</option>
                              <option value="Lek Auto INS" id="typeselection">Lek Auto INS</option>
                              <option value="Cancellation">Cancellation</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Vehicle No.<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.VEHICLE_REG_NO"
                          class="form-control"
                          placeholder="Enter Vehicle No."
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Vehicle Status
                          <span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.VEHICLE_STATUS"
                          class="form-control"
                          placeholder="Enter Vehicle Status"
                        />
                        <!-- <select
                          v-model="updatevehicle_details.VEHICLE_STATUS"
                          @change="vehicletypelebels()"
                          class="form-control"
                        >
                          <option value="">Select</option>

                          <option value="New Vehicle">New Vehicle</option>
                          <option value="Pre Owned">Old Vehicle</option>
                        </select> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Customer ID No.<span style="color: red"
                            >*</span
                          ></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.ID_NUMBER"
                          @keyup="hideRegNo"
                          class="form-control"
                          placeholder="Enter ID No."
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Customer Type<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.CUSTOMER_TYPE"
                          @keyup="hideRegNo"
                          class="form-control"
                          placeholder="Enter ID No."
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Customer Name<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.CUSTOMER_NAME"
                          @keyup="hideRegNo"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Customer Address<span style="color: red"
                            >*</span
                          ></label
                        >
                      </div>
                      <div class="col-md-8">
                        <textarea
                          style="height: 4em"
                          id="Customer Address"
                          name="Customer Address"
                          class="form-control"
                          v-model="updatevehicle_details.CUSTOMER_ADDRESS"
                          disabled
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-show="hidereg" class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >CO. Registration No.<span style="color: red"
                            >*</span
                          ></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.CO_REGISTRATION_NO"
                          class="form-control"
                          placeholder="Enter Reg No."
                        />
                      </div>
                    </div>
                  </div> -->

                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Debit Note No.<span style="color: red"
                            >*</span
                          ></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.DEBIT_NOTE_NO"
                          class="form-control"
                          placeholder="Enter Debit Note No."
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Policy No.<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.POLICY_NO"
                          class="form-control"
                          placeholder="Enter Policy No."
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Coverage <span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="updatevehicle_details.COVERAGE"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option>COMPREHENSIVE</option>
                          <option>TPFT</option>
                          <option>TPO</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <h5 class="line-heading"><span>NCD Transfer</span></h5>

                  <ValidationObserver ref="bankfor">
                    <div class="col-md-12 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Previous Insurer
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="NCD_DETAILSUPDATE.PREVIOUS_INSURER"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  NCD_DETAILSUPDATE.PREVIOUS_INSURER != null,
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <!-- <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">NCD% Entitlement  <span style="color: red">*</span></label>
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="NCD_DETAILSUPDATE.NCD_PERCENTAGE"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div> -->
                    <div class="col-md-12 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >NCD% Entitlement<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="number"
                              v-model="NCD_DETAILSUPDATE.NCD_PERCENTAGE"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  NCD_DETAILSUPDATE.NCD_PERCENTAGE != null,
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <!-- <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Cancellation/Expiry Date   <span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="NCD_DETAILSUPDATE.NCD_EXPIRY_DATE"
                          type="number"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div> -->
                    <div class="col-md-12 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Cancellation/Expiry Date
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="date"
                              v-model="NCD_DETAILSUPDATE.NCD_EXPIRY_DATE"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  NCD_DETAILSUPDATE.NCD_EXPIRY_DATE != null,
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <!-- <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">NCD Transfer From   <span style="color: red">*</span></label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="NCD_DETAILSUPDATE.NCD_TRANSFER_FROM"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div> -->
                    <div class="col-md-12 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >NCD Transfer From
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="NCD_DETAILSUPDATE.NCD_TRANSFER_FROM"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  NCD_DETAILSUPDATE.NCD_TRANSFER_FROM != null,
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12 mt-2">
                      <div
                        class="accordion accordion-flush"
                        id="accordionExample"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label
                              class="accordion-header frm-label-2"
                              id="headingTwo"
                            >
                              Remarks
                            </label>
                          </div>
                          <div class="col-md-8">
                            <div class="accordion-item">
                              <input
                                type="tex"
                                v-model="NCD_DETAILSUPDATE.NCD_REMARKS"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-md-3">
                        <button
                          type="button"
                          @click="updateNCDDETAILS"
                          class="custom-btn custom-success-btn"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </ValidationObserver>
                  <div class="modal-body">
                    <ValidationObserver ref="bankform3">
                      <div>
                        <div class="row mt-4">
                          <h5 class="line-heading"><span>Payable</span></h5>

                          <!-- <div class="col-md-4">
                                  <label class="frm-label-2">Company
                                    <span style="color: red">*</span></label>
                                </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      name="Company"
                                      v-model.trim="
                                        Payabledetails.PAYABLE_COMPANY
                                      "
                                      class="form-control"
                                      placeholder=""
                                    
                                    />
                                  </div>
                                  -->
                          <div
                            class="col-md-12 mt-3"
                            v-if="
                              updatevehicle_details.BILL_TO != 'Direct Payment'
                            "
                          >
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >Company
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <!-- <input type="text"
                                                               v-model="Payabledetails.PAYABLE_COMPANY"
                                                              
                                                               class="form-control" 
                                                               :class="{
                                                               'is-invalid': errors[0] != null,
                                                               'is-valid':
                                                               errors[0] == null &&    Payabledetails.PAYABLE_COMPANY!= null,
                                                            }"> -->
                                  <select
                                    name="Company"
                                    v-model="Payabledetails.PAYABLE_COMPANY"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': errors[0] != null,
                                      'is-valid':
                                        errors[0] == null &&
                                        Payabledetails.PAYABLE_COMPANY != null,
                                    }"
                                  >
                                    <option :value="null">select</option>
                                    <option
                                      v-for="insurance_company in insurance_provider_details"
                                      :value="
                                        insurance_company.INSURANCE_PROVIDER_SYS_ID
                                      "
                                      :key="
                                        insurance_company.INSURANCE_PROVIDER_SYS_ID
                                      "
                                    >
                                      {{ insurance_company.INSURANCE_PROVIDER }}
                                    </option>
                                    <!-- <option
                                      v-for="insurance_company in insurance_company_details"
                                      :value="
                                        insurance_company.INSURANCE_COMPANY_SYS_ID
                                      "
                                      :key="
                                        insurance_company.INSURANCE_COMPANY_SYS_ID
                                      "
                                    >
                                      {{ insurance_company.INSURANCE_COMPANY }}
                                    </option> -->
                                  </select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </div>
                              </div>
                            </ValidationProvider>
                          </div>
                          <!-- <div class="col-md-4 mt-2">
                          <label class="frm-label-2"
                                    >Salesman
                                    <span style="color: red">*</span></label
                                  >
                         </div>
                                  
                                  <div class="col-md-8 mt-2">
                                    <select
                                      v-model="Payabledetails.SALESMAN"
                                      class="form-control"
                                      name="Salesmans"
                                   
                                    >
                                      <option :value="null">Select</option>
                                      <option
                                        v-for="broker in brokerdetails"
                                        :value="broker.USER_SYS_ID"
                                        :key="broker.USER_SYS_ID"
                                      >
                                        {{ broker.PERSON_NAME }}
                                      </option>
                                    </select>
                                  </div> -->
                          <div class="col-md-12 mt-3">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >Salesman<span style="color: red"
                                      >*</span
                                    ></label
                                  >
                                </div>
                                <div class="col-md-8 mt-2">
                                  <select
                                    v-model="Payabledetails.SALESMAN"
                                    name="Salesman"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': errors[0] != null,
                                      'is-valid':
                                        errors[0] == '' &&
                                        Payabledetails.SALESMAN != null,
                                    }"
                                  >
                                    <option :value="null">Select</option>
                                    <option
                                      v-for="broker in brokerdetails"
                                      :value="broker.PERSON_NAME"
                                      :key="broker.USER_SYS_ID"
                                    >
                                      {{ broker.PERSON_NAME }}
                                    </option>
                                  </select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </div>
                              </div>
                            </ValidationProvider>
                          </div>

                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2">Sale Type</label>
                              </div>
                              <!-- <div class="col-md-8">
                                <div class="row">
                                  <div class="col-md-6">
                                    <select
                                      v-model="
                                        Payabledetails.SALES_TYPE_PERCENTAGE
                                      "
                                      class="form-control"
                                    >
                                      <option>Select</option>
                                      <option>5</option>
                                      <option>7</option>
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    <input
                                      v-model="Payabledetails.SALES_TYPE_AMOUNT"
                                      type="number"
                                      class="form-control"
                                    />
                                  </div>
                                  
                                </div>
                                <div
                                    class="col-md-8 ps-0"
                                    style="text-align: righr"
                                  >
                                    <label class="frm-label-2-2" style="text-align: left"
                                      >(Please Fill in if Tradesales)</label
                                    >
                                  </div>
                              </div> -->

                              <div class="col-md-8">
                                <select
                                  name="insurance type"
                                  v-model="updatevehicle_details.INSURANCE_TYPE"
                                  disabled
                                  class="form-control"
                                >
                                  <option>select</option>
                                  <option value="">
                                    Select Insurance Type
                                  </option>
                                  <option value="Extention">Extention</option>
                                  <option value="Renewal(T)">Renewal(T)</option>
                                  <option value="External(R)">
                                    External(R)
                                  </option>
                                  <option value="External">External</option>
                                  <option value="Trade Sales">
                                    Trade Sales
                                  </option>
                                  <option value="Rental">Rental</option>
                                  <option value="Temp CI">Temp CI</option>
                                  <option value="Lek Auto INS">
                                    Lek Auto INS
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2"
                                    >Premium
                                    <span style="color: red">*</span></label
                                  >
                              </div>
                                  
                                  <div class="col-md-8">
                                    <input
                                      type="number"
                                      name="Premium"
                                      v-model.trim="
                                        Payabledetails.PAYABLE_PREMIUM
                                      "
                                      class="form-control"
                                      placeholder=""
                                    
                                    />
                                  </div>
                                
                            </div>
                          </div> -->
                          <div
                            class="col-md-12 mt-3"
                            v-if="
                              updatevehicle_details.BILL_TO != 'Direct Payment'
                            "
                          >
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >Premium
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    v-model="Payabledetails.PAYABLE_PREMIUM"
                                    disabled
                                    class="form-control"
                                    :class="{
                                      'is-invalid': errors[0] != null,
                                      'is-valid':
                                        errors[0] == null &&
                                        Payabledetails.PAYABLE_PREMIUM != null,
                                    }"
                                  />
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </div>
                              </div>
                            </ValidationProvider>
                          </div>

                          <!-- / -->
                          <div class="col-md-12 mt-2">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >Salesman Commission($)
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        Payabledetails.SALES_COMMISSION_AMOUNT
                                      "
                                      class="form-control"
                                      :class="{
                                        'is-invalid': errors[0] != null,
                                        'is-valid':
                                          errors[0] == null &&
                                          Payabledetails.SALES_COMMISSION_AMOUNT !=
                                            null,
                                      }"
                                    />
                                  </div>

                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </div>
                              </div>
                            </ValidationProvider>
                          </div>

                          <div
                            class="col-md-12 mt-2"
                            v-if="
                              updatevehicle_details.BILL_TO != 'Direct Payment'
                            "
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >TAX <span style="color: red">*</span></label
                                >
                              </div>
                              <div class="col-md-8">
                                <div class="row">
                                  <div class="col-md-12">
                                    <input
                                      disabled
                                      v-model="
                                        Payabledetails.PAYABLE_TAX_AMOUNT
                                      "
                                      type="number"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-12 mt-2"
                            v-if="
                              updatevehicle_details.BILL_TO != 'Direct Payment'
                            "
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label">Premium Payable</label>
                              </div>
                              <div class="col-md-8">
                                <input
                                  disabled
                                  v-model="
                                    Payabledetails.TOTAL_PAYABLE_PREMIUM_PERCENTAGE
                                  "
                                  type="number"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-md-3">
                            <button
                              type="button"
                              @click="updatePayabledetails"
                              class="custom-btn custom-success-btn"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-2">
                            <button
                              type="button"
                              class="custom-btn custom-danger-btn"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </ValidationObserver>
                  </div>
                  <ValidationObserver ref="bankform2">
                    <div class="col-md-12">
                      <div class="row mt-4">
                        <h5 class="line-heading">
                          <span>Payment <span style="color: red">*</span></span>
                        </h5>

                        <!-- <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2">Entry Date  <span style="color: red">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="Paymentsdetails.PAYMENT_ENTRY_DATE"
                              type="date"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div> -->
                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >Entry Date
                                  <span style="color: red">*</span></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="date"
                                  v-model="Paymentsdetails.PAYMENT_ENTRY_DATE"
                                  name="Entry Date"
                                  class="form-control"
                                  :class="{
                                    'is-invalid': errors[0] != null,
                                    'is-valid':
                                      errors[0] == null &&
                                      Paymentsdetails.PAYMENT_ENTRY_DATE !=
                                        null,
                                  }"
                                />
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >AP.<span style="color: red">*</span></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  disabled
                                  type="text"
                                  v-model="Paymentsdetails.PAYMENT_AR"
                                  name="PAYMENT AR"
                                  class="form-control"
                                  :class="{
                                    'is-invalid': errors[0] != null,
                                    'is-valid':
                                      errors[0] == null &&
                                      Paymentsdetails.PAYMENT_AR != null,
                                  }"
                                />
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >Payment voucher no.<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="Paymentsdetails.PAYMENT_VOUCHER_OC"
                                  name="Payment voucher "
                                  class="form-control"
                                  :class="{
                                    'is-invalid': errors[0] != null,
                                    'is-valid':
                                      errors[0] == null &&
                                      Paymentsdetails.PAYMENT_VOUCHER_OC !=
                                        null,
                                  }"
                                />
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >D/N <span style="color: red">*</span></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  disabled
                                  type="text"
                                  v-model="Paymentsdetails.D_N"
                                  class="form-control"
                                  :class="{
                                    'is-invalid': errors[0] != null,
                                    'is-valid':
                                      errors[0] == null &&
                                      Paymentsdetails.D_N != null,
                                  }"
                                />
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-12 mt-3">
                          <!-- <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          > -->
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label">C/N</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="Paymentsdetails.C_N"
                                class="form-control"
                              />
                              <!-- <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div> -->
                            </div>
                          </div>
                          <!-- </ValidationProvider> -->
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12" style="margin-top: 54px">
                      <div class="row">
                        <div class="col-md-3">
                          <button
                            type="button"
                            @click="updatePaymentdetails"
                            class="custom-btn custom-success-btn"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-1">
                          <button
                            type="button"
                            class="custom-btn custom-danger-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </div>
              <!-- right sec  -->
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Make<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <select
                          name="Make"
                          v-model="updatevehicle_details.VEHICLE_MAKE"
                          @change="getmakeName"
                          class="form-control"
                        >
                          <option value="">Select Manufracturer</option>
                          <option
                            v-for="makenm in Manufracturename"
                            :value="makenm.VEHICLE_MAKE"
                            :key="makenm.VEHICLE_MAKE_SYS_ID"
                          >
                            {{ makenm.VEHICLE_MAKE }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Model<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <!-- <select
                          name="Model"
                          v-model="updatevehicle_details.VEHICLE_MODEL_SYS_ID"
                          @change="getmodelshortName"
                          class="form-control"
                        >
                          <option value="">Select Model</option>
                          <option
                            v-for="model in Modeltype"
                            :value="model.VEHICLE_MODEL_SYS_ID"
                            :key="model.VEHICLE_MODEL_SYS_ID"
                          >
                            {{ model.VEHICLE_MODEL }}
                          </option>
                        </select> -->
                        <input
                          type="text"
                          v-model="updatevehicle_details.VEHICLE_MODEL"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Engine No<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.ENGINE_NO"
                          class="form-control"
                          placeholder="Enter Engine"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Chasis No<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.CHASIS_NO"
                          class="form-control"
                          placeholder="Enter Chasis"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Bill To<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="updatevehicle_details.BILL_TO"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option value="Direct Payment">Direct Payment</option>
                          <option value=" Bill to Customer">
                            Bill to Customer
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Orig Date<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <!-- <date-picker 
                          style="width:100%" 
                          v-model="updatevehicle_details.ORIGINAL_REGISTRATION_DATE" 
                          format="DD-MM-YYYY" 
                          title-format="DD-MM-YYYY" 
                          valueType="format"
                        >
                        </date-picker> -->
                        <input
                          type="date"
                          v-model="
                            updatevehicle_details.ORIGINAL_REGISTRATION_DATE
                          "
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Year<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          maxlength="4"
                          v-model="updatevehicle_details.MANUFACTURE_YEAR"
                          class="form-control"
                          placeholder="Enter Year"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Capacity<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.CAPACITY"
                          class="form-control"
                          placeholder="Enter Capacity"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Laden<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.LADEN"
                          class="form-control"
                          placeholder="Enter Laden"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Unladen<span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.UNLADEN"
                          class="form-control"
                          placeholder="Enter Unladen"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Seat No. <span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="updatevehicle_details.PAX"
                          class="form-control"
                          placeholder="Enter Seat No."
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row mt-5 mb-4">
                    <div class="col-md-3">
                      <button
                        type="button"
                        @click="updateGeneralvehicledetails"
                        class="custom-btn custom-success-btn"
                      >
                        Save
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button
                        type="button"
                        class="custom-btn custom-danger-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                  <hr />
                  <ValidationObserver ref="bankform5">
                    <div class="col-md-12">
                      <div class="divider-4"></div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <!-- <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2">Dealer  <span style="color: red">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="Premiumdetails.DEALER_NAME"
                              type="text"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div> -->
                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >Insurance Provider
                                  <span style="color: red">*</span></label
                                >
                              </div>
                              <div class="col-md-8">
                                <select
                                  v-model="Premiumdetails.DEALER_NAME"
                                  class="form-control"
                                  @change="
                                    getInsurenseProvider(
                                      Premiumdetails.DEALER_NAME
                                    )
                                  "
                                >
                                  <option :value="null">Select</option>
                                  <option
                                    v-for="insurance_company in insurance_provider_details"
                                    :value="
                                      insurance_company.INSURANCE_PROVIDER_SYS_ID
                                    "
                                    :key="
                                      insurance_company.INSURANCE_PROVIDER_SYS_ID
                                    "
                                  >
                                    {{ insurance_company.INSURANCE_PROVIDER }}
                                  </option>
                                </select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div
                          class="col-md-12 mt-3"
                          v-if="Premiumdetails.DEALER_NAME"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label">Person Name</label>
                            </div>
                            <div class="col-md-8">
                              {{ Premiumdetails.PERSON_NAME }}
                            </div>
                          </div>
                        </div>

                        <div
                          class="col-md-12 mt-3"
                          v-if="Premiumdetails.DEALER_NAME"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label"
                                >Contact No
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              {{ Premiumdetails.CONTACT_NO }}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >Insur Company.
                                  <span style="color: red">*</span></label
                                >
                              </div>
                              <div class="col-md-8">
                                <select
                                  v-model="
                                    Premiumdetails.INSURANCE_COMPANY_SYS_ID
                                  "
                                  @change="getInsuranceCompanyName"
                                  class="form-control"
                                  :class="{
                                    'is-invalid': errors[0] != null,
                                    'is-valid':
                                      errors[0] == null &&
                                      Premiumdetails.INSURANCE_COMPANY_SYS_ID !=
                                        '',
                                  }"
                                >
                                  <option :value="null">Select</option>
                                  <option
                                    v-for="insurance_company in insurance_company_details"
                                    :value="
                                      insurance_company.INSURANCE_COMPANY_SYS_ID
                                    "
                                    :key="
                                      insurance_company.INSURANCE_COMPANY_SYS_ID
                                    "
                                  >
                                    {{ insurance_company.INSURANCE_COMPANY }}
                                  </option>
                                </select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <!-- <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2">To Agent  <span style="color: red">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="Premiumdetails.TO_AGENT"
                              type="text"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div> -->
                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >To Agent
                                  <span style="color: red">*</span></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="Premiumdetails.TO_AGENT"
                                  class="form-control"
                                  :class="{
                                    'is-invalid': errors[0] != null,
                                    'is-valid':
                                      errors[0] == null &&
                                      Premiumdetails.TO_AGENT != null,
                                  }"
                                />
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <!-- <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2">Periods  <span style="color: red">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-5">
                                <input
                                  type="date"
                                  v-model="Premiumdetails.INSURANCE_START_DATE"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                              <div class="col-md-2">
                                <label class="frm-label-2 mt-1">To  <span style="color: red">*</span></label>
                              </div>
                              <div class="col-md-5">
                                <input
                                  type="date"
                                  v-model="Premiumdetails.INSURANCE_END_DATE"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                        <div class="col-md-12 mt-2">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >Periods
                                  <span style="color: red">*</span></label
                                >
                              </div>

                              <div class="col-md-8">
                                <div class="row">
                                  <div class="col-md-5">
                                    <input
                                      type="date"
                                      v-model="
                                        Premiumdetails.INSURANCE_START_DATE
                                      "
                                      class="form-control"
                                      :class="{
                                        'is-invalid': errors[0] != null,
                                        'is-valid':
                                          errors[0] == null &&
                                          Premiumdetails.INSURANCE_START_DATE !=
                                            null,
                                      }"
                                    />

                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <label class="frm-label-2 mt-1"
                                      >To
                                      <span style="color: red">*</span></label
                                    >
                                  </div>
                                  <div class="col-md-5">
                                    <input
                                      type="date"
                                      v-model="
                                        Premiumdetails.INSURANCE_END_DATE
                                      "
                                      class="form-control"
                                      placeholder=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-12">
                    <div class="divider-4"></div>
                  </div> -->
                    <div class="col-md-12">
                      <div class="row">
                        <!-- <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2">Sum Insured  <span style="color: red">*</span></label>
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="Premiumdetails.SUM_INSURED"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div> -->
                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >Sum Insured
                                  <span style="color: red">*</span></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="Premiumdetails.SUM_INSURED"
                                  class="form-control"
                                  :class="{
                                    'is-invalid': errors[0] != null,
                                    'is-valid':
                                      errors[0] == null &&
                                      Premiumdetails.SUM_INSURED != null,
                                  }"
                                />
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >Basic Premium<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="Premiumdetails.BASE_PREMIUM"
                                  @keyup="premium_calcu"
                                  class="form-control"
                                  :class="{
                                    'is-invalid': errors[0] != null,
                                    'is-valid':
                                      errors[0] == null &&
                                      Premiumdetails.BASE_PREMIUM != null,
                                  }"
                                />
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >No Claim Disc
                                <span style="color: red">*</span></label
                              ><br />
                              <div class="d-flex">
                                <div class="d-block mb-1">
                                  <input
                                    type="radio"
                                    v-model="Premiumdetails.NO_CLAIM_DISC"
                                    value="Y"
                                    :checked="
                                      Premiumdetails.NO_CLAIM_DISC == 'Y'
                                    "
                                  /><label class="frm-label-2 radio-lbls ms-1"
                                    >Yes</label
                                  >
                                </div>
                                <div
                                  class="d-block mb-1"
                                  style="margin-left: 10px"
                                >
                                  <input
                                    type="radio"
                                    v-model="Premiumdetails.NO_CLAIM_DISC"
                                    value="N"
                                    :checked="
                                      Premiumdetails.NO_CLAIM_DISC == 'N'
                                    "
                                  /><label class="frm-label-2 radio-lbls ms-1"
                                    >No</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-8"
                              v-if="Premiumdetails.NO_CLAIM_DISC == 'Y'"
                            >
                              <div class="row">
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      v-model="
                                        Premiumdetails.NO_CLAIM_DISC_PERCENTAGE
                                      "
                                      class="form-control"
                                      @keyup="saferdi_calcu(), premium_calcu()"
                                      placeholder=""
                                    />
                                    <span
                                      class="input-group-text"
                                      id="basic-addon7"
                                      >%</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <input
                                      disabled
                                      type="text"
                                      v-model="
                                        Premiumdetails.NO_CLAIM_DISC_VALUE
                                      "
                                      class="form-control"
                                      aria-label="Amount (to the nearest dollar)"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Safer Disc
                                <span style="color: red">*</span></label
                              ><br />
                              <div class="d-flex">
                                <div class="d-block mb-1">
                                  <input
                                    type="radio"
                                    name="safer-disc"
                                    class="radio safeys"
                                    v-model="Premiumdetails.SAFER_DISC"
                                    value="Y"
                                    id="safer-yes"
                                  /><label
                                    class="frm-label-2 radio-lbls ms-1"
                                    for="safer-yes"
                                    >Yes</label
                                  >
                                </div>
                                <div
                                  class="d-block mb-1"
                                  style="margin-left: 10px"
                                >
                                  <input
                                    type="radio"
                                    name="safer-disc"
                                    class="radio safeno"
                                    v-model="Premiumdetails.SAFER_DISC"
                                    value="N"
                                    id="safer-no"
                                  /><label
                                    class="frm-label-2 radio-lbls ms-1"
                                    for="safer-no"
                                    >No</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-8"
                              v-if="Premiumdetails.SAFER_DISC == 'Y'"
                            >
                              <div class="row">
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      v-model="
                                        Premiumdetails.SAFER_DISC_PERCENTAGE
                                      "
                                      @keyup="
                                        saferdisc_calcu(), premium_calcu()
                                      "
                                      class="form-control"
                                      placeholder=""
                                    />
                                    <span
                                      class="input-group-text"
                                      id="basic-addon2"
                                      >%</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <input
                                      disabled
                                      type="text"
                                      v-model="Premiumdetails.SAFER_DISC_VALUE"
                                      class="form-control"
                                      aria-label="Amount (to the nearest dollar)"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Loyalty Disc
                                <span style="color: red">*</span></label
                              ><br />
                              <div class="d-flex">
                                <div class="d-block mb-1">
                                  <input
                                    type="radio"
                                    v-model="Premiumdetails.LOYALITY_DISC"
                                    value="Y"
                                    name="safer-disc2"
                                    class="radio loyalys"
                                    id="safer-yes2"
                                  /><label
                                    class="frm-label-2 radio-lbls ms-1"
                                    for="safer-yes"
                                    >Yes</label
                                  >
                                </div>
                                <div
                                  class="d-block mb-1"
                                  style="margin-left: 10px"
                                >
                                  <input
                                    type="radio"
                                    name="safer-disc2"
                                    value="N"
                                    v-model="Premiumdetails.LOYALITY_DISC"
                                    class="radio loyalno"
                                    id="safer-no2"
                                  /><label
                                    class="frm-label-2 radio-lbls ms-1"
                                    for="safer-no"
                                    >No</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-8"
                              v-if="Premiumdetails.LOYALITY_DISC == 'Y'"
                            >
                              <div class="row">
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      v-model="
                                        Premiumdetails.LOYALITY_DISC_PERCENTAGE
                                      "
                                      @keyup="
                                        loyaltydisc_calcu(), premium_calcu()
                                      "
                                      class="form-control"
                                      placeholder=""
                                    />
                                    <span
                                      class="input-group-text"
                                      id="basic-addon2"
                                      >%</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <input
                                      type="text"
                                      disabled
                                      class="form-control"
                                      v-model="
                                        Premiumdetails.LOYALITY_DISC_VALUE
                                      "
                                      aria-label="Amount (to the nearest dollar)"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Fleet Disc
                                <span style="color: red">*</span></label
                              ><br />
                              <div class="d-flex">
                                <div class="d-block mb-1">
                                  <input
                                    type="radio"
                                    name="safer-disc3"
                                    v-model="Premiumdetails.FLEET_DISC"
                                    value="Y"
                                    class="radio fleetys"
                                    id="safer-yes3"
                                  /><label
                                    class="frm-label-2 radio-lbls ms-1"
                                    for="safer-yes"
                                    >Yes</label
                                  >
                                </div>
                                <div
                                  class="d-block mb-1"
                                  style="margin-left: 10px"
                                >
                                  <input
                                    type="radio"
                                    name="safer-disc3"
                                    v-model="Premiumdetails.FLEET_DISC"
                                    value="N"
                                    class="radio fleetno"
                                    id="safer-no3"
                                  /><label
                                    class="frm-label-2 radio-lbls ms-1"
                                    for="safer-no"
                                    >No</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-8"
                              v-if="Premiumdetails.FLEET_DISC == 'Y'"
                            >
                              <div class="row">
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      v-model="
                                        Premiumdetails.FLEET_DISC_PERCENTAGE
                                      "
                                      @keyup="
                                        fleetdisc_calcu(), premium_calcu()
                                      "
                                      class="form-control"
                                      placeholder=""
                                    />
                                    <span
                                      class="input-group-text"
                                      id="basic-addon2"
                                      >%</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <input
                                      type="text"
                                      disabled
                                      v-model="Premiumdetails.FLEET_DISC_VALUE"
                                      class="form-control"
                                      aria-label="Amount (to the nearest dollar)"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Direct Disc
                                <span style="color: red">*</span></label
                              ><br />
                              <div class="d-flex">
                                <div class="d-block mb-1">
                                  <input
                                    type="radio"
                                    name="safer-disc4"
                                    v-model="Premiumdetails.DIRECT_DISC"
                                    value="Y"
                                    class="radio yes4"
                                    id="safer-yes4"
                                  /><label
                                    class="frm-label-2 radio-lbls ms-1"
                                    for="safer-yes"
                                    >Yes</label
                                  >
                                </div>
                                <div
                                  class="d-block mb-1"
                                  style="margin-left: 10px"
                                >
                                  <input
                                    type="radio"
                                    v-model="Premiumdetails.DIRECT_DISC"
                                    value="N"
                                    name="safer-disc4"
                                    class="radio no4"
                                    id="safer-no4"
                                  /><label
                                    class="frm-label-2 radio-lbls ms-1"
                                    for="safer-no"
                                    >No</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-8"
                              v-if="Premiumdetails.DIRECT_DISC == 'Y'"
                            >
                              <div class="row">
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      v-model="
                                        Premiumdetails.DIRECT_DISC_PERCENTAGE
                                      "
                                      @keyup="
                                        directdisc_calcu(), premium_calcu()
                                      "
                                      class="form-control"
                                      placeholder=""
                                    />
                                    <span
                                      class="input-group-text"
                                      id="basic-addon2"
                                      >%</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                  <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <input
                                      type="text"
                                      disabled
                                      class="form-control"
                                      v-model="Premiumdetails.DIRECT_DISC_VALUE"
                                      aria-label="Amount (to the nearest dollar)"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Premium
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                disabled
                                type="text"
                                v-model="Payabledetails.PAYABLE_PREMIUM"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >TAX <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <div class="row">
                                <div class="col-md-6">
                                  <select
                                    v-model="Premiumdetails.TAX_PERCENTAGE"
                                    @change="getgstr"
                                    class="form-control"
                                  >
                                    <option :value="null">Select</option>
                                    <option
                                      :value="tax_details.TAX_NAME"
                                      v-for="(
                                        tax_details, index
                                      ) in tax_info_details"
                                      :key="index"
                                    >
                                      {{ tax_details.TAX_NAME }}-{{
                                        tax_details.TAX_PERCENTAGE
                                      }}
                                      %
                                    </option>
                                    <!-- <option value="EXE-0%"> EXE-0%</option> -->
                                  </select>
                                </div>
                                <div class="col-md-6">
                                  <input
                                    disabled
                                    type="text"
                                    v-model="Premiumdetails.TAX_AMOUNT"
                                    class="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Gross Premium
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                disabled
                                type="text"
                                v-model="Premiumdetails.FINAL_PREMIUM"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-md-3">
                            <button
                              type="button"
                              @click="updatePremiumdetails"
                              class="custom-btn custom-success-btn"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-2">
                            <button
                              type="button"
                              class="custom-btn custom-danger-btn"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>

                  <ValidationObserver ref="taxeditform">
                    <div class="col-md-12">
                      <div class="row mt-4">
                        <h5 class="line-heading">
                          <span>Dealer Commission </span>
                        </h5>

                        <div class="col-md-12 mt-3">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2"
                                  >Dealer Commission
                                  <span style="color: red">*</span></label
                                ><br />
                              </div>
                              <div class="col-md-8">
                                <div class="row">
                                  <div class="col-md-6 mt-2">
                                    <div class="input-group">
                                      <input
                                        type="text"
                                        v-model="
                                          Receivabledetails.DEALER_COMMISSION_PERCENTAGE
                                        "
                                        class="form-control"
                                        @keyup="commisonamount"
                                        placeholder=""
                                      />
                                      <span
                                        class="input-group-text"
                                        id="basic-addon7"
                                        >%</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-md-6 mt-2">
                                    <div class="input-group">
                                      <span class="input-group-text">$</span>
                                      <input
                                        type="text"
                                        v-model="
                                          Receivabledetails.DEALER_COMMISSION_AMOUNT
                                        "
                                        class="form-control"
                                        aria-label="Amount (to the nearest dollar)"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6 mt-3"></div>
                      </div>
                    </div>

                    <div class="row mt-5">
                      <div class="col-md-3">
                        <button
                          type="button"
                          @click="updateReceivabledetails"
                          class="custom-btn custom-success-btn"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </div>

              <ValidationObserver ref="bankform6">
                <div class="col-md-12">
                  <h5 class="line-heading"><span>Driver Section</span></h5>
                  <div class="divider-4"></div>
                </div>

                <div class="col-md-12">
                  <b-button variant="light" @click="AddExtraDriver()">
                    <strong>Add Excess</strong>&nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-plus-circle"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="16"></line>
                      <line x1="8" y1="12" x2="16" y2="12"></line>
                    </svg>
                    <!-- <i data-feather="plus-circle"></i> -->
                  </b-button>
                </div>

                <div
                  class="col-md-12 mt-3"
                  v-for="(driver, driver_index) in driver_info"
                  :key="`driver-${driver_index}`"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="frm-label-2"
                            >Excess (Named Driver)
                          </label>
                        </div>
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col-md-6">
                              <input
                                v-model="driver.EXCESS_DRIVER_NAME"
                                placeholder="Excess Driver Name"
                                type="text"
                                class="form-control"
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                v-model="driver.EXCESS_DRIVER_AMOUNT"
                                type="number"
                                placeholder="Driver Amount"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Excess (Unnamed Driver) <span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-md-6">
                            <input
                              v-model="
                                Excessdriverdetails.EXCESS_UNNAMED_DRIVER_AMOUNT
                              "
                              type="text"
                              class="form-control"
                                placeholder="Enter Unamed Driver"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                    <div class="col-md-6">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Excess (Unnamed Driver)
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="number"
                              v-model="driver.EXCESS_UNNAMED_DRIVER_AMOUNT"
                              placeholder="Enter Unamed Driver"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  driver.EXCESS_UNNAMED_DRIVER_AMOUNT != null,
                              }"
                            />
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <!-- <div class="col-md-6 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2"
                          >Excess (Work Permit Hold) <span style="color: red">*</span></label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="Excessdriverdetails.EXCESS_WORK_PERMIT_HOLD"
                          type="number"
                          class="form-control"
                          placeholder="Enter Work Permit Hold"
                        />
                      </div>
                    </div>
                  </div> -->
                    <!-- <div class="col-md-6 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Excess (Young Driver) <span style="color: red">*</span></label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="number"
                          v-model="Excessdriverdetails.EXCESS_YOUNG_DRIVER"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div> -->
                    <div class="col-md-6">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Excess (Young Driver)
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="number"
                              v-model="driver.EXCESS_YOUNG_DRIVER"
                              placeholder="Enter Young Driver"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  driver.EXCESS_YOUNG_DRIVER != null,
                              }"
                            />
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <!-- <div class="col-md-6 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Excess (Windscreen)</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="number"
                          v-model="Excessdriverdetails.EXCESS_WINDSCREEN"
                          class="form-control"
                          placeholder="Enter Windscreen"
                        />
                      </div>
                    </div>
                  </div> -->
                    <div class="col-md-6">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Excess (Windscreen)
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="number"
                              v-model="driver.EXCESS_WINDSCREEN"
                              placeholder="Enter Excess Windscreen"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  driver.EXCESS_WINDSCREEN != null,
                              }"
                            />
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <!-- <div class="col-md-6 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Workshop Scheme</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="Excessdriverdetails.WORKSHOP_SCHEME"
                          class="form-control"
                        >
                          <option :value="null">Select</option>
                          <option value="Distributor’s Workshop">Distributor’s Workshop</option>
                           <option value="Any Workshop">Any Workshop</option>
                            <option value="Authorised Workshop">Authorised Workshop</option>
                        </select>
                      </div>
                    </div>
                  </div> -->

                    <div class="col-md-6 mt-2">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Workshop Scheme
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="driver.WORKSHOP_SCHEME"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  driver.WORKSHOP_SCHEME != '',
                              }"
                            >
                              <option :value="null">Select</option>
                              <option value="Distributor’s Workshop">
                                Distributor’s Workshop
                              </option>
                              <option value="Any Workshop">Any Workshop</option>
                              <option value="Authorised Workshop">
                                Authorised Workshop
                              </option>
                            </select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <!-- <div class="col-md-6 mt-2">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Extra Benefit</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="Excessdriverdetails.EXTRA_BENEFIT"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div> -->
                    <div class="col-md-6 mt-2">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label"
                              >Extra Benefit
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="number"
                              v-model="driver.EXTRA_BENEFIT"
                              placeholder="Enter enter benefit"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  driver.EXTRA_BENEFIT != null,
                              }"
                            />
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="row">
                      <div class="col-md-1 mt-4 me-4">
                        <button
                          @click="AddDriver(driver_index, driver.RECORD_SYS_ID)"
                          type="button"
                          class="custom-btn custom-success-btn"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-1 mt-4">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>

                    <!-- add driver tab -->
                    <ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button
                          v-show="show"
                          class="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          View Driver
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          v-show="show"
                          class="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Add Driver
                        </button>
                      </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      ></div>
                      <div
                        class="tab-pane fade"
                        id="profile"
                        v-show="show"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div class="col-md-12 mt-3">
                          <div class="row">
                            <div class="col-md-4 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2" v-show="show"
                                    >Driver Name</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="Driverdetails.DRIVER_NAME"
                                    v-show="show"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2" v-show="show"
                                    >Occupation</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="Driverdetails.OCCUPATION"
                                    v-show="show"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2" v-show="show"
                                    >Driving Pass Dt</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="Driverdetails.DRIVING_PASS_DATE"
                                    v-show="show"
                                    type="date"
                                    class="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2" v-show="show"
                                    >Relationship</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="Driverdetails.RELATIONSHIP"
                                    v-show="show"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2" v-show="show"
                                    >I/C Number</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="Driverdetails.I_C_NUMBER"
                                    v-show="show"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2" v-show="show"
                                    >DOB</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="Driverdetails.DOB"
                                    type="date"
                                    v-show="show"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <!-- <div class="col-md-2 mt-4">
                                                <button type="button" class="custom-btn custom-secondary-btn-4 aliasadd"><i class="fa-solid fa-user-plus"></i>&nbsp; Add Driver</button>
                                             </div> -->
                          <div class="col-md-1 mt-4 me-4">
                            <button
                              type="button"
                              v-show="show"
                              @click="updateDriverdetails"
                              class="custom-btn custom-success-btn"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-1 mt-4">
                            <button
                              type="button"
                              v-show="show"
                              class="custom-btn custom-danger-btn"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- add driver tab end -->

                    <div class="col-md-12 mb-4" id="add_item">
                      <table class="table table-bordered mt-3">
                        <thead>
                          <tr v-show="show">
                            <th scope="col">Sl No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">OCCUPATION</th>
                            <th scope="col">RELATIONSHIP</th>
                            <th scope="col">I_C_NUMBER</th>
                            <th scope="col">DRIVING_PASS_DATE</th>
                            <th scope="col">DOB</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(Getdriverdetail, index) in Getdriverdetails"
                            :key="Getdriverdetail.DRIVER_SYS_ID"
                            v-show="show"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ Getdriverdetail.DRIVER_NAME }}</td>
                            <td>{{ Getdriverdetail.OCCUPATION }}</td>
                            <td>{{ Getdriverdetail.RELATIONSHIP }}</td>
                            <td>{{ Getdriverdetail.I_C_NUMBER }}</td>
                            <td>{{ Getdriverdetail.DRIVING_PASS_DATE }}</td>
                            <td>{{ Getdriverdetail.DOB }}</td>
                            <td class="table-btn">
                              <button
                                type="button"
                                class="custom-btn p-0"
                                @click="Editdriverdetails(Getdriverdetail)"
                                v-if="menuPermissions.UPDATE_ACCESS"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-edit"
                                >
                                  <path
                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                  ></path>
                                  <path
                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                type="button"
                                class="custom-btn p-0"
                                @click="Deletedriver(Getdriverdetail)"
                                v-if="menuPermissions.DELETE_ACCESS"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                  ></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ValidationObserver>

              <!-- <section10->Insurance Receiveable> -->

              <h5 class="line-heading"><span>Insurance Receiveable</span></h5>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        class="custom-btn add-btn"
                        name=""
                        data-bs-toggle="collapse"
                        data-bs-target="#buyer-colapse"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        <strong>Add Payment</strong>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-plus-circle"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="16"></line>
                          <line x1="8" y1="12" x2="16" y2="12"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div
                    id="buyer-colapse"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body p-2 mt-2">
                      <form>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >AR Payment</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="
                                        addupdatebuyerapdata.PAYMENT_DESC
                                      "
                                      type="text"
                                      class="form-control mt-2"
                                      placeholder=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="
                                  addupdatebuyerapdata.PAYMENT_DESC == 'Others'
                                "
                                class="col-md-12 mt-2"
                              >
                                <div class="row">
                                  <div class="col-md-4"></div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="
                                        addupdatebuyerapdata.PAYMENT_DESC_OTHER
                                      "
                                      type="text"
                                      class="form-control mt-2"
                                      placeholder=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Bank</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <select
                                      v-model="addupdatebuyerapdata.BANK_SYS_ID"
                                      @change="getbankName_buyer"
                                      class="form-control"
                                    >
                                      <option value="">Select</option>
                                      <option
                                        v-for="item in Banklist"
                                        :value="item.BANK_SYS_ID"
                                        :key="item.BANK_SYS_ID"
                                      >
                                        {{ item.BANK_NAME }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Cheque Number</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="addupdatebuyerapdata.CHEQUE_NO"
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter Cheque No."
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >To/From Who</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="
                                        addupdatebuyerapdata.TO_FROM_WHOM
                                      "
                                      type="text"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >AR Balance($)</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      v-model="
                                        updat_user.REDUCED_RECEIVABLE_AMOUNT
                                      "
                                      class="form-control"
                                      placeholder="Enter AR Balance"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <!-- <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >P Settlement Remark</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        v-model="
                                          addupdatebuyerapdata.SETTLEMENT_REMARKS
                                        "
                                        class="form-control"
                                        placeholder="Enter P Settlement Remark"
                                      />
                                    </div>
                                  </div> -->
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Amount($)</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      v-model="addupdatebuyerapdata.AMOUNT"
                                      @keyup="calculation()"
                                      class="form-control"
                                      placeholder="Enter Amount"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Payment Mode</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <select
                                      v-model="
                                        addupdatebuyerapdata.PAYMENT_MODE
                                      "
                                      @change="show"
                                      class="form-control"
                                    >
                                      <option value="">Select</option>
                                      <option value="GIRO">GIRO</option>
                                      <option value="TT">TT</option>
                                      <option value="Cheque">Cheque</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Payment Date</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      v-model="addupdatebuyerapdata.CHEQUE_DATE"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Add Remarks</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <textarea
                                      v-model="addupdatebuyerapdata.REMARKS"
                                      class="custom-textarea form-control"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="row mt-4 mb-4">
                              <div class="col-md-1">
                                <button
                                  @click="
                                    AddUpdatebuyerapdetails(), getemptydata()
                                  "
                                  type="button"
                                  class="custom-btn custom-success-btn"
                                >
                                  Save
                                </button>
                              </div>
                              <div class="col-md-1">
                                <button
                                  type="button"
                                  class="custom-btn custom-danger-btn"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class="master-table">
                <table class="table table-bordered mt-2">
                  <thead>
                    <tr>
                      <th>SL. No.</th>
                      <th scope="col">AR Receipt</th>
                      <th scope="col">Amount($)</th>
                      <th scope="col">Bank</th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Cheque No.</th>
                      <th scope="col">Cheque Date</th>
                      <th scope="col">Reduced Balance($)</th>
                      <th scope="col">Total Balance</th>
                      <th scope="col">Remarks</th>
                      <th scope="col">Sell To</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(buyerapdetail, index) in buyerapdetails"
                    :key="buyerapdetail.RECORD_SYS_ID"
                  >
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td>{{ buyerapdetail.PAYMENT_DESC }}</td>
                      <td>{{ buyerapdetail.AMOUNT }}</td>
                      <td>{{ buyerapdetail.BANK_NAME }}</td>
                      <td>{{ buyerapdetail.PAYMENT_MODE }}</td>
                      <td>{{ buyerapdetail.CHEQUE_NO }}</td>
                      <td>{{ buyerapdetail.CHEQUE_DATE }}</td>
                      <td>{{ buyerapdetail.REDUCED_RECEIVABLE_AMOUNT }}</td>
                      <td>{{ buyerapdetail.TOTAL_RECEIVABLE_AMOUNT }}</td>
                      <td>{{ buyerapdetail.SETTLEMENT_REMARKS }}</td>
                      <td>{{ buyerapdetail.REMARKS }}</td>
                      <td>{{ buyerapdetail.TO_FROM_WHOM }}</td>
                      <td class="table-btn">
                        <button
                          type="button"
                          class="custom-btn p-0"
                          data-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          @click="modalshow(buyerapdetail, '#rokerupdate_moda')"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-edit"
                          >
                            <path
                              d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                            ></path>
                            <path
                              d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                            ></path>
                          </svg>
                        </button>

                        <!-- <button
                            type="button"
                            @click="editbuyerApdetails(buyerapdetail)"
                            class="custom-btn p-0"
                            
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-edit"
                            >
                              <path
                                d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                              ></path>
                              <path
                                d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                              ></path>
                            </svg>
                          </button>    -->
                        <button type="button" class="custom-btn p-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-trash-2"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                              d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                            ></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- <div class="modal-footer-2">
                          <div class="row">
                             <div class="col-md-5">
                                <button type="button"
                                @click="UpdateInsurance"
                                class="custom-btn custom-success-btn">Save</button>
                             </div>
                             <div class="col-md-1">
                                <button type="button" data-bs-dismiss="modal"
                                 class="custom-btn custom-danger-btn">Cancel</button>
                             </div>
                          </div>
                       </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- search modal -->
    <div
      class="modal modal-left fade"
      id="left_modal_sm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="left_modal_sm"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search By</h5>
          </div>
          <div class="modal-body search-panel">
            <div class="row">
              <div class="col-md-12">
                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="all"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">All Combinations</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="status"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Insurance Type</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="number"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Vehicle Number</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="make"
                    class="rad-input"
                    v-model="search_option"
                  />
                </label>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'status'"
              >
                <label class="frm-label">Status</label>
                <select class="form-control" v-model="INSURANCE_TYPE">
                  <option value="">Select Insurance Type</option>
                  <option value="Extention">Extention</option>
                  <option value="Renewal(T)">Renewal(T)</option>
                  <option value="External(R)">External(R)</option>
                  <option value="External">External</option>
                  <option value="Trade Sales">Trade Sales</option>
                  <option value="Rental">Rental</option>
                  <option value="Temp CI">Temp CI</option>
                  <option value="Lek Auto INS">Lek Auto INS</option>
                </select>
              </div>
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'number'"
              >
                <label class="frm-label">Vehicle Number</label>
                <input
                  type="text"
                  v-model="VEHICLENUMBER"
                  class="form-control"
                  placeholder="Enter Vehicle No."
                />
              </div>

              <div class="col-md-12 mt-2" v-if="search_option">
                <div class="btn-conainer">
                  <button
                    type="button"
                    @click="getinsurancesearch()"
                    id="srch-btn"
                    class="custom-btn custom-success-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { viewinsurance } from "../insurance/viewinsurance";
export default viewinsurance;
</script>
<style scoped>
.loading_main_background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(42, 42, 43, 0.45);
  z-index: 9999;
  /* display: none; */
}
/* .preloder {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgb(0, 155, 254);
    z-index: 9999;
} */

.preloder img {
  width: auto;
  margin: 12% 38%;
  border-radius: 50%;
  opacity: 0.6;
}
.loading_main_background img {
  width: 5% !important;
  margin: 20% 50% !important;
  opacity: 1 !important;
  position: absolute;
}
img {
  vertical-align: middle;
  border-style: none;
}
li.nav-item {
  width: 10em !important;
  text-align: center !important;
}
</style>
