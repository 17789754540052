<template>
  <div>
    <div class="floating-heading">
      <h4><b>Insurance Report</b></h4>
    </div>
    <div class="col-md-12 mt-2 text-success">
      <div class="form-outline text-primary">
        <span class="inline">
          <div class="row mt-4">
            <div class="col-md-1 mt-2">
              <label class="form-label" for="form1" display="inline"
                >Start Date</label
              >
            </div>
            <div class="col-md-3">
              <input
                style="height: 3em"
                type="date"
                id="form1"
                placeholder="dd/mm/yyyy"
                class="form-control form-icon-trailing"
                v-model="INSURANCE_START_DATE"
              />
            </div>
            <div class="col-md-1 mt-2">
               <label class="form-label" for="form1">End Date</label>
            </div>
            <div class="col-md-3">
              <input
                style="height: 3em"
                type="date"
                id="form1"
                placeholder="dd/mm/yyyy"
                class="form-control form-icon-trailing"
                v-model="INSURANCE_END_DATE"
              />
            </div>
            <div class="col-md-2">
              <button
                type="submit"
                style="font-size: 0.9rem !important"
                class="btn btn-primary"
                @click="getPriceDetails"
              >
                Search
              </button>
            </div>
            <div class="col-md-2 p-0">
              <button
                style="margin-left: 5em"
                type="button"
                class="custom-btn custom-excel-btn"
                @click="exportExcel()"
              >
                <i class="fa-solid fa-file-excel"></i> Excel
              </button>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div class="col-md-12" style="width:1060px">
      <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data">
          <div class="data-table master-table">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>SERIAL NUMBER</th>
                  <th>VEHICLE PLATE</th>
                  <th>MAKE & MODEL</th>
                  <th>TODAY</th>
                  <th>GROSS PREMIUM 0% NCD</th>
                  <th>TOTAL DISCOUNTS</th>
                  <th>7% GST</th>
                  <th>DEALER COMM</th>
                  <th>OUTSIDE SALES COMM</th>
                  <th>PAID DATE</th>
                  <th>INSURANCE TYPE</th>
                  <th>INSURANCE COMPANY</th>
                  <th>INSURANCE PROVIDER</th>
                  <th>COVER DATE-START DATE</th>
                  <th>COVER DATE-END DATE</th>
                  <th>SALESMAN CODE</th>
                  <th>UEN/ROC NO.</th>
                  <th>NUMBER OF DAY TO RENEW INSURANCE</th>
                </tr>
              </thead>
              <tbody
                v-for="user_detail in system_role_details"
                :key="user_detail.RECORD_SYS_ID"
              >
                <tr style="text-align: center">
                  <td>{{ user_detail.SL_NO }}</td>
                  <td>{{ user_detail.VEHICLE_PLATE }}</td>
                  <td>
                    {{ user_detail.MAKE_NAME }}-{{ user_detail.MODEL_NAME }}
                  </td>
                  <td>{{ user_detail.TODAY }}</td>
                  <td>{{ user_detail.GROSS_PREMIUM_0_PERCENT_NCD }}</td>
                  <td>{{ user_detail.TOTAL_DISCOUNTS }}</td>
                  <td>{{ user_detail.GST_PERCENT_7 }}</td>
                  <td>{{ user_detail.DEALER_COMMISSION }}</td>
                  <td>{{ user_detail.OUTSIDE_SALES_COMMISSION }}</td>
                  <td>{{ user_detail.PAID_DATE }}</td>
                  <td>{{ user_detail.INSURANCE_TYPE }}</td>
                  <td>{{ user_detail.INSURANCE_COMPANY }}</td>
                  <td>{{ user_detail.INSURANCE_PROVIDER }}</td>
                  <td>{{ user_detail.INSURANCE_START_DATE }}</td>
                  <td>{{ user_detail.INSURANCE_END_DATE }}</td>
                  <td>{{ user_detail.SELL_CODE }}</td>
                  <td>{{ user_detail.ID_NUMBER }}</td>
                  <td>{{ user_detail.NO_OF_DAYS_TO_RENEW_INSURANCE }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  </div>
</template>
<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
import moment from "moment";
export default {
  data() {
    return {
      system_role_details: [],
      INSURANCE_START_DATE: "",
      INSURANCE_END_DATE: "",
      USER_SYS_ID: "",
      user_details: [],
    };
  },

  computed: {
    system_role_groups() {
      const gr = [];
      this.system_role_details.forEach((g) => {
        if (!gr.includes(g.BODY_TYPE)) gr.push(g.BODY_TYPE);
      });
      return gr;
    },
  },

  methods: {
    getGroupDetails(group) {
      return this.system_role_details.filter((role) => role.BODY_TYPE == group);
    },

    generateRandomColor() {
      let maxVal = 0xffffff;
      let randomNumber = Math.random() * maxVal;
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`;
    },
    getGroupStyles() {
      let color = this.generateRandomColor();
      return {
        "background-color": color,
        padding: "5px 6px !important",
        "border-width": "3px",
        "border-color": "#fff",
      };
    },

    getPriceDetails() {
      this.INSURANCE_START_DATE = this.formatDate(this.INSURANCE_START_DATE);
      this.INSURANCE_END_DATE = this.formatDate(this.INSURANCE_END_DATE);
      this.axios
        .get(apiEndPoints.insurance_details, {
          params: {
            ITEM: "SPECIFIC",
            INSURANCE_START_DATE: this.INSURANCE_START_DATE,
            INSURANCE_END_DATE: this.INSURANCE_END_DATE,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.system_role_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.system_role_details = [];
          }
        })
        .catch((err) => {
         // console.log(err);
        });
    },
    exportExcel() {
      const tHeader = [
        "SL. No.",
        "VEHICLE_PLATE",
        "MAKE & MODEL",
        "TODAY",
        "GROSS PREMIUM 0% NCD",
        "TOTAL DISCOUNTS",
        "7% GST",
        "DEALER COMM",
        "OUTSIDE SALES COMM",
        "PAID DATE",
        "INSURANCE TYPE",
        "INSURANCE COMPANY",
        "COVER DATE-START DATE",
        "COVER DATE-END DATE",
        "SALESMAN CODE",
        "UEN/ROC NO",
        "NUMBER OF DAY TO RENEW INSURANCE",
      ];

      const filterVal = [
        "SL_NO",
        "VEHICLE_PLATE",
        "MAKE_NAME",
        "TODAY",
        "GROSS_PREMIUM_0_PERCENT_NCD",
        "TOTAL_DISCOUNTS",
        "GST_PERCENT_7",
        "DEALER_COMMISSION",
        "OUTSIDE_SALES_COMMISSION",
        "PAID_DATE",
        "INSURANCE_TYPE",
        "INSURANCE_COMPANY",
        "INSURANCE_START_DATE",
        "INSURANCE_END_DATE",
        "SELL_CODE",
        "ID_NUMBER",
        "NO_OF_DAYS_TO_RENEW_INSURANCE",
      ];
      let system_role_groups = [];
      this.system_role_groups.forEach((role) => {
        this.getGroupDetails(role).forEach((item, index) => {
          if (index > 0) {
            item.BODY_TYPE = "";
          }
          system_role_groups.push(item);
        });
      });
      const data = this.formatJson(filterVal, system_role_groups);
      this.generateXlsx(tHeader, data, "Insurance Report");
    },
  },
};
</script>
