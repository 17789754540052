//const base_url = 'http://65.0.180.154:9999/api';//dev
//const base_url = 'http://dev-lekautoapi.devxportal.com/api';//dev-devxportal
//const base_url = 'https://uat-lekautoapi.devxportal.com/api';//uat-devxportal
//const base_url = 'http://65.0.180.154:9998/api';//uat
const base_url = process.env.VUE_APP_API_URL;
const endpoints = {
    login: base_url + "/Authentication/api-login",
    get_user_role_privilege_info: base_url + "/WebApi/api-get-view-user-role-privilege-info",
    tax_info: base_url + "/WebApi/api-get-view-tax-info",
    cost_type_info: base_url + "/WebApi/api-get-view-cost-type-info",
    insurance_provider_details: base_url + "/WebApi/api-get-view-insurance-provider-name-info",
    insurance_type_details: base_url + "/WebApi/api-get-view-insurance-type-name-info",
    insurance_company_api: base_url + "/WebApi/api-get-view-insurance-company-info",
    bank_name_api: base_url + "/WebApi/api-get-view-bank-name-info",
    vechile_manufracturer_api: base_url + "/WebApi/api-get-view-vehicle-manufacture-name-info",
    vechile_body_type_api: base_url + "/WebApi/api-get-view-vehicle-body-type-info",
    vechile_model_type_api: base_url + "/WebApi/api-get-view-vehicle-model-info",
    vechile_color_api: base_url + "/WebApi/api-get-view-vehicle-color-name-info",
    vechile_location_api: base_url + "/WebApi/api-get-view-vehicle-location-info",
    system_role_api: base_url + "/WebApi/api-get-view-system-role-info",
    create_update_tax_api: base_url + "/WebApi/api-post-add-update-tax",
    create_update_cost_type_api: base_url + "/WebApi/api-post-add-update-cost-type",
    create_update_insurance_provider: base_url + "/WebApi/api-post-add-update-insurance-provider-name",
    create_update_insurance_type: base_url + "/WebApi/api-post-add-update-insurance-type-name",
    create_update_insurance_company: base_url + "/WebApi/api-post-add-update-insurance-company",
    create_update_bank_details: base_url + "/WebApi/api-post-add-update-bank-name",
    create_update_vehicle_maker: base_url + "/WebApi/api-post-add-update-vehicle-manufacture-name",
    create_update_vechile_body_type: base_url + "/WebApi/api-post-add-update-vehicle-body-type",
    create_update_vechile_model: base_url + "/WebApi/api-post-add-update-vehicle-model",
    create_update_vechile_color: base_url + "/WebApi/api-post-add-update-vehicle-color-name",
    create_update_vechile_location: base_url + "/WebApi/api-post-add-update-vehicle-location",
    create_update_system_role: base_url + "/WebApi/api-post-add-update-system-role",
    create_update_broker: base_url + "/WebApi/api-post-add-update-user",
    view_user: base_url + "/WebApi/api-get-view-user-info",
    create_update_customer: base_url + "/WebApi/api-post-add-update-customer-info",
    view_customer: base_url + "/WebApi/api-get-view-customer-info",
    create_update_employee: base_url + "/WebApi/api-post-add-update-user",
    view_employee: base_url + "/WebApi/api-get-view-user-info",
    create_update_module:base_url + "/WebApi/api-post-add-update-module",
    view_module: base_url + "/WebApi/api-get-view-module-info",
    view_country:base_url + "/WebApi/api-get-view-country-name-info",
    create_update_country:base_url + "/WebApi/api-post-add-update-country-name",
    view_sub_module:base_url + "/WebApi/api-get-view-sub-module-info",
    create_update_sub_module: base_url + "/WebApi/api-post-add-update-sub-module",
    view_master: base_url + "/WebApi/api-get-view-master-name-info",
    create_update_vendor: base_url + "/WebApi/api-post-add-update-vendor-info",
    view_vendor: base_url + "/WebApi/api-get-view-vendor-info",
    view_address_details: base_url + "/WebApi/api-get-view-address-info",
    view_vehicle_manufacture_name: base_url + "/WebApi/api-get-view-vehicle-manufacture-name-info",
    view_vehicle_color_name: base_url + "/WebApi/api-get-view-vehicle-color-name-info",
    view_vehicle_body_type: base_url + "/WebApi/api-get-view-vehicle-body-type-info",
    view_vehicle_model_type: base_url + "/WebApi/api-get-view-vehicle-model-info",
    view_vehicle_location: base_url + "/WebApi/api-get-view-vehicle-location-info",
    add_vehicle_details: base_url + "/WebApi/api-post-add-update-vehicle-details",
    view_vehicle_details: base_url + "/WebApi/api-get-view-vehicle-details-info",
    view_debit_note_info: base_url + "/WebApi/api-get-view-debit-note-info",
    view_credit_note_info: base_url + "/WebApi/api-get-view-credit-note-info",
    view_sales_note_info:base_url+ "/WebApi/api-get-view-sales-receipt-info",
    view_tax_note_info:base_url+  "/WebApi/api-get-view-tax-invoice-info",
    change_password: base_url + "/authentication/change-password",
    view_vehicle_details_for_edit: base_url + "/WebApi/api-get-view-edit-vehicle-info",
    vehicle_details_info_based_on_vehicle_number: base_url + "/WebApi/api-get-view-vehicle-no-based-vehicle-details-info",
    add_insurance_details: base_url + "/WebApi/api-post-add-update-insurance-details",
    Add_Quotation: base_url +"/WebApi/api-post-add-update-quotation-details",
    view_vehicle_number:base_url +"/WebApi/api-get-view-vehicle-no-based-vehicle-details-info",
    view_quotation:base_url +"/WebApi/api-get-view-quotation-details-info",
    add_update_record_section:base_url +"/WebApi/api-post-add-update-record-section-details",
    view_insurance_details: base_url + "/WebApi/api-get-view-insurance-details-info",
    add_update_seller_details:base_url +"/WebApi/api-post-add-update-seller-details",
    view_seller_details:base_url +"/WebApi/api-get-view-seller-details-info",
    add_workshop:  base_url +"/WebApi/api-post-add-update-workshop-quotation-details",
    add_update_ap_payment_details:  base_url +"/WebApi/api-post-add-update-ap-payment-details",
    view_ap_payment_details:base_url +"/WebApi/api-get-view-ap-payment-details-info",
    view_buyer_details:base_url +"/WebApi/api-get-view-buyer-details-info",
    add_update_buyer_details:  base_url +"/WebApi/api-post-add-update-buyer-details",
    view_ets_details:base_url +"/WebApi/api-get-view-ets-transaction-details-info",
    add_update_ets_details:  base_url +"/WebApi/api-post-add-update-ets-transaction-details",
   
      price_list: base_url +"/WebApi/api-get-view-price-list-details-info",
    add_update_driver_details: base_url + "/WebApi/api-post-add-update-driver",
    view_driver_details: base_url + "/WebApi/api-get-view-vendor-info",
    finace_company: base_url +"/WebApi/api-get-view-finance-company-info",
    add_update_finance_company: base_url +"/WebApi/api-post-add-update-finance-company",
add_update_vehicle_cost: base_url +"/WebApi/api-post-add-update-vehicle-cost",
cost_purchase:base_url +"/WebApi/api-get-view-cost-centre-purchase-info",
cost_sales:base_url +"/WebApi/api-get-view-cost-centre-sales-info",
// vehicle_cost:base_url +"/WebApi/api-get-view-vehicle-cost-info",    
    verify_email: base_url + "/WebApi/api-post-user-verification",
    otp_send: base_url + "/WebApi/api-get-email-config-details-info",
    otp_verify_save_password: base_url + "/authentication/change-password",
vehicle_cost:base_url +"/WebApi/api-get-view-cost-centre-vehicle-cost-info",
purchase_settlement: base_url +"/WebApi/api-get-view-purchase-settlement-info",
vehic_cost:base_url +"/WebApi/api-get-view-vehicle-cost-info",
    temporary_token: base_url + "/Authentication/get-temporary-token",
    view_role_privilege_info: base_url + "/WebApi/api-get-view-role-privilege-info",
    post_add_update_role_privileges: base_url + "/WebApi/api-post-add-update-role-privileges",
    ets_price:base_url +"/WebApi/api-get-view-ets-price-list-details-info"  ,
    add_loan:base_url +"/WebApi/api-post-add-update-vehicle-loan-details" ,
    view_loan:base_url +"/WebApi/api-get-view-vehicle-loan-details-info" ,
    add_trade:base_url +"/WebApi/api-post-add-update-vehicle-trade-in-details" ,
    view_trade:base_url +"/WebApi/api-get-view-vehicle-trade-in-details-info" ,
    view_commission:base_url+"/WebApi/api-get-view-commission-summary",
    view_commission_summarydetails:base_url+"/WebApi/api-get-view-commission-summary-details",
    update_asking_price: base_url + "/WebApi/api-get-view-asking-price-details-info",
    add_update_menu_structure: base_url + "/WebApi/api-post-add-update-menu-structure",
    add_update_driver_details: base_url + "/WebApi/api-post-add-update-driver",
    view_driver_details: base_url + "/WebApi/api-get-view-driver-info",
    update_commison: base_url  +"/WebApi/api-post-process-vehicle-commission",
    senior_broker:base_url  +"/WebApi/api-get-view-broker-name-info",
    otp_verification:base_url  +"/mailmanagement/api-mail-trigger",
    my_commission_summarydetails:base_url+"/WebApi/api-get-view-my-commission-summary-details",
    broker_detailes:base_url+"/WebApi/api-get-view-broker-p-l-share-info",
    mail_trigger:base_url+"/WebApi/api-mail-trigger",
    user_profile_info: base_url+"/WebApi/api-get-view-user-profile-info/",
    user_block_delete: base_url+"/WebApi/api-post-add-update-user",
    
    view_bookingreport:base_url +"/WebApi/api-get-view-booking-report-info",
    e_trasnfer_in:base_url +"/WebApi/api-get-view-e-transfer-in-report-info",
    insurance_details:base_url +"/WebApi/api-get-view-insurance-report-info",
    view_pendingreport: base_url +"/WebApi/api-get-view-pending-handover-vehicle-report-info",
    e_trasnfer_out: base_url +"/WebApi/api-get-view-e-transfer-out-report-info",
    item_description : base_url +"/WebApi/api-get-view-invoice-item-list",
    Add_tax: base_url +"/WebApi/api-post-add-update-tax-invoice",
    Tax_detailes:base_url +"/WebApi/api-get-view-tax-invoice-details-info",
    pl_statment:base_url +"/WebApi/api-get-view-pl-statement-info",
    add_update_item_description: base_url+"/WebApi/api-post-add-update-invoice-item-list",
    //pl_report:base_url+"/WebApi/api-get-view-p-l-admin-fee-loan-report-info",
    pnl_report: base_url + "/WebApi/api-get-view-loan-pnl-report-info",
    view_credit_note_information:base_url + "/WebApi/api-get-view-credit-note-details-info",
    view_car:base_url + "/WebApi/api-get-view-vehicle-insurance-info",
    add_insurance_info: base_url + "/WebApi/api-post-add-update-insurance-details",
    view_insurance_information:base_url + "/WebApi/api-get-view-vehicle-insurance-info",
    offical_recipt_pdf:base_url + "/WebApi/api-get-view-official-receipt-info",
    payment_voucher:base_url + "/WebApi/api-get-view-payment-voucher-info",
    finace_company_tax:base_url + "/WebApi/api-get-view-finance-company-tax-invoice-info",
    create_floorstock:base_url + "/WebApi/api-post-add-update-vehicle-floor-stock-interest",
    view_floorstock:base_url + "/WebApi/api-get-view-vehicle-floor-stock-interest",
    create_other_payment:base_url + "/WebApi/api-post-add-update-other-fee",
    view_otherpayment:base_url + "/WebApi/api-get-view-other-fee-info",
    get_taxcommissioninvoice:base_url + "/WebApi/api-get-view-tax-commission-invoice-info",
    vehicle_document_add:base_url + "/WebApi/api-post-add-update-vehicle-document-no",
   insurance_search:base_url + "/WebApi/api-get-view-insurance-search-info",
   vehicle_commison:base_url + "/WebApi/api-get-view-vehicle-commission-summary-details",
   view_specific_vehicle_trade:base_url + "/WebApi/api-get-view-specific-vehicle-trade-in-info",
   file_upload:  base_url +"/WebApi/api-post-file-upload",
   file_download:  base_url +"/WebApi/api-post-download-file",
   calculate_payment_receivable:  base_url +"/WebApi/api-get-view-calculate-payment-receivable",
   add_update_finance_company_bank: base_url +"/WebApi/api-post-add-update-finance-company-bank",
   view_finance_company_bank: base_url +"/WebApi/api-get-view-finance-company-bank-info",
   freeze_invoice:base_url +"/WebApi/api-post-add-update-vehicle-save-statement-details",
   commison_view:base_url +"/WebApi/api-get-view-commission-summary-details",
   get_view_payment_code: base_url +"/WebApi/api-get-view-payment-code",
   add_update_payment_code: base_url +"/WebApi/api-post-add-update-payment-code",
   add_commsion: base_url +"/WebApi/api-post-update-commission-details",

}

export default endpoints;
  