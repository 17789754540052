<template>
  <div>
    <div class="floating-heading">
      <h4><b>Vehicle Commission</b></h4>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="row mb-3">
          <div class="col-md-2">
            <label class="frm-label-2"
              >Broker Name
            </label>
            <select
              v-model="USER_SYS_ID"
              class="form-control"
              name="Incharge By"
              @change="getBrokername"
            >
              <option value="">Select</option>
              <option
                v-for="broker in brokerDetailsArray"
                :value="broker.USER_SYS_ID"
                :key="broker.USER_SYS_ID"
              >
                {{ broker.PERSON_NAME }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <label class="frm-label-2">
              Start Date <span style="color: red">*</span></label
            >
            <input
              type="date"
              class="form-control"
              v-model="DELIVERY_OUT_START_DATE"
              placeholder="Enter  Start Date"
            />
          </div>
          <div class="col-md-2">
            <label class="frm-label-2">
              End Date <span style="color: red">*</span></label
            >
            <input
              type="date"
              class="form-control"
              v-model="DELIVERY_OUT_END_DATE"
              placeholder="Enter  end Date"
            />
          </div>

          <div class="col-md-4">
            <div class="">
              <label class="frm-label-2">Vehicle Number</label>
              <b-input-group>
                <b-form-input
                  id="VehicleNo"
                  name="VehicleNo"
                  class="form-control"
                  placeholder="Enter Vehicle No"
                  v-model="VEHICLE_NUMBER"
                  @keyup="onKeyUpVehicleInfo()"
                  @change="getvehiclesysid()"
                  list="datalistOptionsVehicles"
                ></b-form-input>
                <datalist id="datalistOptionsVehicles">
                  <option
                    v-for="(vehicle_details, index) in all_vehicle_details"
                    :key="index"
                    :value="vehicle_details.VEHICLE_NUMBER"
                  ></option>
                </datalist>
              </b-input-group>
            </div>
          </div>
          <div class="col-md-2">
            <button
              style="
                margin-top: 18px;
                font-size: 13px;
                color: #fff;
                background-color: #754115;
              "
              type="button"
              @click="searchvechic"
              class="custom-btn btn btn-success"
            >
              <i class="fa fa-search" aria-hidden="true"></i> Search
            </button>
          </div>
        </div>
        <button
          v-if="selectedvechicledetailes.length > 0"
          class="btn btn-success"
          @click="UpdateModalShow('#pay_invoice_update_modal')"
        >
          Pay Now
        </button>

        <div class="page-data">
          <div class="data-table admin-master-table mt-4">
            <table class="table table-bordered" v-show="truefalse">
              <thead>
                <tr style="background: #ebd8c3">
                  <th></th>
                  <!-- <th></th> -->
                  <th scope="col">SL. No.</th>
                  <th scope="col">Commission Head</th>
                  <th scope="col">Handling</th>
                  <th scope="col">Amount ($)</th>
                  <th scope="col">Vehicle Number</th>
                  <th scope="col">Commission To</th>
                  <th scope="col">Payment Code</th>
                  <th scope="col">Payment Description</th>
                  <th scope="col">Payment To</th>
                  <th scope="col">Payment Date</th>
                  <th scope="col">Bank Name</th>
                  <th scope="col">Payment Amount</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody
                v-for="(user_detail, index) in vehicle_details"
                :key="user_detail.RECORD_SYS_ID"
              >
                <tr>
                  <td>
                    <b-form-checkbox
                      v-model="selectedvechicledetailes"
                      :value="user_detail"
                      :disabled="user_detail.STATUS == 'Paid'"
                    >
                    </b-form-checkbox>
                  </td>
                  <td>{{ index + 1 }}</td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.COMMISSION_HEAD }}
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.HANDLING }}
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.COMMISSION_AMOUNT }}
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.VEHICLE_NUMBER }}
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.COMMISSION_TO }}
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.PAYMENT_CODE }}
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.PAYMENT_DESC }}
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.PAYMENT_TO }}
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.PAYMENT_DATE }}
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.BANK_NAME }}
                  </td>
                  <td>
                    <div v-if="user_detail.STATUS == 'Paid'" @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                      {{ user_detail.PAYMENT_AMOUNT }}
                    </div>
                    <div v-else>
                       <div v-if="selectedvechicledetailes.length>0">
                        <div v-for="(item,sv_index) in selectedvechicledetailes" :key="sv_index">
                          <div class="text-nowrap" style="display: flex;" v-if="item.COMMISSION_BRKP_SYS_ID == user_detail.COMMISSION_BRKP_SYS_ID">
                              <input  class="form-control" type="number" placeholder="Enter payment amount" @change="onchangePaymentAmount($event,sv_index,user_detail)">
                            </div>
                        </div>
                       </div>
                    </div>
                  </td>
                  <td @click="editDetails(user_detail, '#pay_invoice_update_modal_new')">
                    {{ user_detail.STATUS }}
                  </td>
                </tr>
                <tr class="table-under-table">
                  <td colspan="12" class="p-0" style="padding: 0 !important">
                    <div
                      id="flush-collapseOne-1"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body p-2 mt-1">
                        <table class="table table-bordered">
                          <thead>
                            <tr style="background: #d4d4d4">
                              <th>SL. No.</th>
                              <th scope="col">commission head</th>
                              <th scope="col">handling</th>
                              <th scope="col">amount ($)</th>
                              <th scope="col">commission to</th>
                              <th scope="col">timestamp</th>
                            </tr>
                          </thead>
                          <tbody
                            v-for="(user_de, INDEX) in user_deta"
                            :key="user_de.RECORD_SYS_ID"
                          >
                            <tr>
                              <td>{{ INDEX + 1 }}</td>

                              <td>{{ user_de.COMMISSION_HEAD }}</td>
                              <td>{{ user_de.HANDLING }}</td>
                              <td>{{ user_de.COMMISSION_AMOUNT }}</td>
                              <td>{{ user_de.COMMISSION_TO }}</td>
                              <td>{{ user_de.CREATED_DATE }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="modal modal-right fade"
          id="pay_invoice_update_modal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="width: 55% !important">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Pay Commission</h5>
              </div>
              <div class="col-md-12 accordion-body p-3">
                <div class="row">
                  <!--Update Cost Cenetr Left Side Form-->
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Payment Code</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          class="form-control roleddl"
                          v-model="PAYMENT_CODE"
                          @change="paymentdesc"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="item in paymentcode"
                            :value="item.PAYMENT_CODE"
                            :key="item.PAYMENT_CODE_SYS_ID"
                          >
                            {{ item.PAYMENT_DESCRIPTION }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2"> Payment To</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="this.BROKER_NAME"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="row mt-2">
                    <div class="col-md-4 pr-0">
                      <label class="frm-label-2">Payment Amount </label>
                    </div>
                    <div class="col-md-8">
                      <label class="frm-label-2">: <b>S$ {{total_payment_amount}}</b> </label>
                      <!-- <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="PAYMENT_AMOUNT"
                      /> -->
                    </div>
                  </div>
                  </div>

                  <!--Update Cost Cenetr Right Side Form-->
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Payment Date </label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          v-model="PAYMENT_DATE"
                        />
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2">Bank Name</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          class="form-control roleddl"
                          v-model="BANK_SYS_ID"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="item in Banklist"
                            :value="item.BANK_NAME"
                            :key="item.BANK_SYS_ID"
                          >
                            {{ item.BANK_NAME }}
                          </option>
                        </select>
                      </div>
                    </div>

                    
                  </div>
                  
                </div>
                <div class="row mt-4 mb-4">
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="custom-btn custom-success-btn"
                      @click="commisoninvoice"
                    >
                      Pay
                    </button>
                  </div>
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="custom-btn custom-danger-btn"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal modal-right fade"
          id="pay_invoice_update_modal_new"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="width: 55% !important">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Pay Commison</h5>
              </div>
              <div class="col-md-12 accordion-body p-3">
                <div class="row">
                  <!--Update Cost Cenetr Left Side Form-->
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Payment Code</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          class="form-control roleddl"
                          v-model="selected_row.PAYMENT_CODE"
                          @change="paymentdescuPDATE"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="item in paymentcode"
                            :value="item.PAYMENT_CODE"
                            :key="item.PAYMENT_CODE_SYS_ID"
                          >
                            {{ item.PAYMENT_DESCRIPTION }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2"> Payment To</label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="selected_row.PAYMENT_TO"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <!--Update Cost Cenetr Right Side Form-->
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2">Payment Date </label>
                      </div>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          v-model="selected_row.PAYMENT_DATE"
                        />
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2">Bank Name</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          class="form-control roleddl"
                          v-model="selected_row.BANK_NAME"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="item in Banklist"
                            :value="item.BANK_NAME"
                            :key="item.BANK_SYS_ID"
                          >
                            {{ item.BANK_NAME }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-2 pr-0">
                      <label class="frm-label-2">Payment Amount </label>
                    </div>
                    <div class="col-md-6 ml-1" style="margin-left: 3px">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="selected_row.PAYMENT_AMOUNT"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-4 mb-4">
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="custom-btn custom-success-btn"
                      @click="updatecommisoninvoice"
                    >
                      Pay
                    </button>
                  </div>
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="custom-btn custom-danger-btn"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal"
          id="pay_invoice_payment_modal_new"
          v-if="selectedvechicledetailes"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="width: 55% !important">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Pay Commison</h5>
              </div>
              <div class="col-md-12 accordion-body p-3">
                <div class="row">
                  <div class="row mt-2">
                    <div class="col-md-2 pr-0">
                      <label class="frm-label-2">Payment Amount </label>
                    </div>
                    <div class="col-md-6 ml-1" style="margin-left: 3px">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="Payment_mode"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-4 mb-4">
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="custom-btn custom-success-btn"
                      @click="updatecommisoninvoice"
                    >
                      Pay
                    </button>
                  </div>
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="custom-btn custom-danger-btn"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal id="modal-1" size="sm" title="Add Payment Amount" hide-footer>
          <div class="row mt-2">
            <div class="col-md-2 pr-0">
              <label class="frm-label-2">Payment Amount </label>
            </div>
            <div class="col-md-6 ml-1" style="margin-left: 3px">
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model="Payment_mode"
              />
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
export default {
  data() {
    return {
      //for data property decalire
      total_payment_amount: null,
      VEHICLE_NUMBER: "",
      BROKER_NAME: "",

      truefalse: false,
      // selectedvechicledetailes:false,
      Banklist: [],
      paymentcode: [],
      trueg: false,
      truea: false,
      trueb: false,
      truec: false,
      create_system: {},
      PAYMENT_DATE: "",
      Payment_mode: "",
      PAYMENT_AMOUNT: "",
      PAYMENT_CODE: "",
      vehicle_details: {},
      comssionsysid: [],
      VEHICLE_SYS_ID: "",
      user_details: {},
      BANK_SYS_ID: "",
      create_system_role: {},
      showPayNow: false,
      USER_SYS_ID: "",
      user_de: [],
      VEHICLE_ID: "",
      selectedvechicledetailes: [],
      selected_row: {},
      user_deta: {},
      DELIVERY_OUT_END_DATE: "",
      DELIVERY_OUT_START_DATE: "",
      brokerDetailsArray: [],
      all_vehicle_details: [],
    };
  },
  mounted() {
    this.getExternalBrokerDetailsList();
    this.getVehicledetails();

    this.getBanklist();
    this.getPaymentCode();
  },

  methods: {
    // searchvechicle() {
    //   this.axios
    //     .get(apiEndPoints.view_vehicle_number, {
    //       params: {
    //         ITEM: "SPECIFIC",

    //         VEHICLE_NUMBER: this.VEHICLE_NUMBER,
    //       },
    //     })
    //     .then((response) => {

    //       if (response.data.status == "true") {

    //         this.vehicle_details = response.data.response[0];
    //       } else if (
    //         response.data.status == false ||
    //         response.data.status == "false"
    //       ) {
    //         this.truefalse = false;
    //         this.vehicle_details = [];
    //         Global.showErrorAlert(true, "error", response.data.response);
    //       }
    //     })
    //     .catch((err) => {
    //       this.truefalse = false;

    //     });
    // },

    // for search the vehicle commison
    searchvechic() {
      //this.DELIVERY_OUT_START_DATE = this.formatDate(this.DELIVERY_OUT_START_DATE);
      //this.DELIVERY_OUT_END_DATE = this.formatDate(this.DELIVERY_OUT_END_DATE);
      this.axios.get(apiEndPoints.vehicle_commison, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_SYS_ID: this.VEHICLE_SYS_ID,
            USER_SYS_ID: this.USER_SYS_ID,
            START_DATE: this.DELIVERY_OUT_START_DATE ? this.formatDate(this.DELIVERY_OUT_START_DATE) : "",
            END_DATE: this.DELIVERY_OUT_END_DATE ? this.formatDate(this.DELIVERY_OUT_END_DATE) : "",
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.truefalse = true;
            this.trueg = true;
            this.truea = true;
            this.trueb = true;
            this.truec = true;
            this.vehicle_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.vehicle_details = [];
          }
        })
        .catch((err) => {});
    },

    getBanklist() {
      this.axios
        .get(apiEndPoints.bank_name_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.Banklist = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.Banklist = [];
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    getPaymentCode() {
      this.axios
        .get(apiEndPoints.get_view_payment_code, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.paymentcode = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.paymentcode = [];
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    },
    // payment desc get

    paymentdesc() {
      this.PAYMENT_DESC = this.paymentcode.find(
        (o) => o.PAYMENT_CODE === this.PAYMENT_CODE
      ).PAYMENT_DESCRIPTION;
    },
    getBrokername() {
      this.BROKER_NAME = this.brokerDetailsArray.find(
        (o) => o.USER_SYS_ID === this.USER_SYS_ID
      ).PERSON_NAME;
      console.log(this.BROKER_NAME);
    },
    paymentdescuPDATE() {
      this.PAYMENT_DESC = this.paymentcode.find(
        (o) => o.PAYMENT_CODE === this.selected_row.PAYMENT_CODE
      ).PAYMENT_DESCRIPTION;
      console.log(this.PAYMENT_DESC);
    },
    editDetails(row, id) {
      console.log(row);
      this.selected_row = row;
      ////console.log("rt", this.update_user);

      if (this.selected_row.PAYMENT_DATE) {
        $(id).modal("show");
      }
    },

    UpdateModalShow(row, id) {
      $("#pay_invoice_update_modal").modal("show");
      console.log(this.selectedvechicledetailes);
      this.comssionsysid = this.selectedvechicledetailes;
      let total_payment_amount = 0;
        this.selectedvechicledetailes.forEach((sub_item) => {
          total_payment_amount += parseFloat(sub_item.PAYMENT_AMOUNT)
        });
      this.total_payment_amount = total_payment_amount.toFixed(2);
      this.updat_user = [];
      this.updat_user = row;
      this.payinvoice = row;
      if (id == "#update_other_payment_modal") {
        let TO_FIXED_TAX_VALUE = row.TAX_VALUE.toFixed(2);
        let RATE_WITHOUT_TAX =
          parseFloat(row.UNIT_PRICE) - parseFloat(TO_FIXED_TAX_VALUE);
        this.updat_user["RATE_WITHOUT_TAX"] = RATE_WITHOUT_TAX.toFixed(2);
        this.updat_user["TAX_VALUE"] = TO_FIXED_TAX_VALUE;
        if (
          row.DESCRIPTION != "Admin Fee" &&
          row.DESCRIPTION != "HP Facilities Fee" &&
          row.DESCRIPTION != "Transfer Fee" &&
          row.DESCRIPTION != "Road Tax"
        ) {
          this.updat_user["DESCRIPTION_OTHER"] = row.DESCRIPTION;
          this.updat_user["DESCRIPTION"] = "Others Fee";
        }
      } else if (id == "#manage_credit_note_update_modal") {
        this.updat_user["PAYMENT_TERMS"] =
          this.ManageCreditNoteDetailsArray.PAYMENT_TERMS;
        this.updat_user["CUSTOMER_SYS_ID"] =
          this.ManageCreditNoteDetailsArray.CUSTOMER_SYS_ID;
        this.updat_user["CUSTOMER_ADDRESS"] =
          this.ManageCreditNoteDetailsArray.CUSTOMER_ADDRESS;
      } else if (id == "#pay_invoice_update_modal") {
        this.payinvoice["PAYMENT_CODE"] = this.payinvoice.PAYMENT_CODE;
        this.payinvoice["PAYMENT_DATE"] = this.payinvoice.PAYMENT_DATE;
        this.payinvoice["BANK_SYS_ID"] = this.payinvoice.BANK_SYS_ID || "";
        this.payinvoice["PAYMENT_TO"] = this.payinvoice.PAYMENT_TO || "";
        this.updat_user["CUSTOMER_SYS_ID"] = this.ManageCreditNoteDetailsArray.CUSTOMER_SYS_ID;
        this.updat_user["CUSTOMER_ADDRESS"] = this.ManageCreditNoteDetailsArray.CUSTOMER_ADDRESS;
        

      } else if (id == "#cost_center_update_modal") {
        this.VehicleCostCenterForm["TO_FROM"] = row.TO_FROM;
        if (row.TO_FROM == "Vendor") {
          let vendorObj = this.AllVendordetails.find(
            (items) => items.VENDOR_SYS_ID == row.CUSTOMER_SYS_ID
          );
          if (vendorObj) {
            this.COST_CENTER_VENDOR_INFO = vendorObj.VENDOR_NAME;
            this.VehicleCostCenterForm.CUSTOMER_SYS_ID =
              vendorObj.VENDOR_SYS_ID;
            this.DisplayCostCenterVendorName = vendorObj.VENDOR_NAME;
            this.DisplayCostCenterVendorCode = vendorObj.VENDOR_CODE;
            this.DisplayCostCenterVendorAddress =
              vendorObj.ADDRESS_LINE_1 +
              "," +
              vendorObj.COUNTRY_NAME +
              "-" +
              vendorObj.PIN_CODE;
          }
        } else if (row.TO_FROM == "Customer") {
          let cusObj = this.AllCustomerArray.find(
            (items) => items.CUSTOMER_SYS_ID == row.CUSTOMER_SYS_ID
          );
          if (cusObj) {
            this.COST_CENTER_CUSTOMER_INFO = cusObj.CUSTOMER_NAME;
            this.VehicleCostCenterForm.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
            this.DisplayCostCenterCustomerType = cusObj.CUSTOMER_TYPE;
            this.DisplayCostCenterCustomerIdNumber = cusObj.ID_NUMBER;
            this.DisplayCostCenterCustomerName = cusObj.CUSTOMER_NAME;
            let address =
              cusObj.ADDRESS_LINE_1 +
              " , " +
              cusObj.COUNTRY_NAME +
              "-" +
              cusObj.PIN_CODE;
            this.DisplayCostCenterCustomerAddress = address;
          }
        }
      }
      $(id).modal("show");
    },

    // vehicle number againest vehicle-sys_id return
    togglePayNow(user_detail) {
      if (user_detail.showTable == "true") {
        console.log("iopy");
      }
    },
    getvehiclesysid() {
      this.VEHICLE_SYS_ID = this.all_vehicle_details.find(
        (o) => o.VEHICLE_NUMBER === this.VEHICLE_NUMBER
      ).RECORD_SYS_ID;
    },
    // for view the commison

    viewcommison() {
      this.axios
        .get(apiEndPoints.view_commission_summarydetails, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.vehicle_details.VEHICLE_SYS_ID,
          },
        })
        .then((response) => {
          this.user_deta = response.data.response;
        })
        .catch((err) => {});
    },

    commisoninvoice() {
      let inputarray = [];
      this.comssionsysid.forEach((sub_item) => {
        inputarray.push({
          COMMISSION_BRKP_SYS_ID: sub_item.COMMISSION_BRKP_SYS_ID,
          ITEM: "UPDATE",
          PAYMENT_CODE: this.PAYMENT_CODE,
          PAYMENT_DATE: this.PAYMENT_DATE,
          PAYMENT_AMOUNT: sub_item.PAYMENT_AMOUNT,
          PAYMENT_DESC: this.PAYMENT_DESC,
          PAYMENT_TO: this.BROKER_NAME,
          BANK_NAME: this.BANK_SYS_ID,
        });
      });
      this.axios.post(apiEndPoints.add_commsion, inputarray).then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#pay_invoice_update_modal").modal("hide");
            this.viewcommison();
            this.searchvechic();
            //this.emptyfu();
            this.total_payment_amount = null;
            this.selectedvechicledetailes = [];
          } 
          else if (response.data.status == false || response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        });
    },
    updatecommisoninvoice() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.axios
        .post(apiEndPoints.add_commsion, {
          COMMISSION_BRKP_SYS_ID: this.selected_row.COMMISSION_BRKP_SYS_ID,

          ITEM: "UPDATE",
          PAYMENT_CODE: this.selected_row.PAYMENT_CODE,
          PAYMENT_AMOUNT: this.selected_row.PAYMENT_AMOUNT,
          PAYMENT_DESC: this.PAYMENT_DESC,
          PAYMENT_DATE: this.selected_row.PAYMENT_DATE,
          PAYMENT_TO: this.selected_row.PAYMENT_TO,
          BANK_NAME: this.selected_row.BANK_NAME,
        })
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#pay_invoice_update_modal_new").modal("hide");

            this.viewcommison();
            this.emptyfu();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {});
    },

    //vehicle detailes show in auto key up
    onKeyUpVehicleInfo() {
      if (this.VEHICLE_NUMBER == "") {
        this.VEHICLE_SYS_ID = null;
      }
    },
    // on change vehicle data show
    onChnageVehicleInfo() {
      let is_existed = this.all_vehicle_details.find(
        (items) => items.VEHICLE_NUMBER == this.vehicle_number
      );

      if (is_existed) {
        this.particular_vehicle_details = is_existed;
      }
    },
    //     modalopen(){
    //       console.log("call")
    //  $(id).modal("show");
    //     },
    // for vehicle detailes show
    getVehicledetails() {
      this.axios
        .get(apiEndPoints.view_vehicle_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            VEHICLE_STATUS: "",
            VEHICLE_NUMBER: "",
            VEHICLE_MAKE: "",
            VEHICLE_MODEL: "",
            PAGE_SIZE: 1000,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.all_vehicle_details = response.data.response;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // for broker list
    getExternalBrokerDetailsList() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            ITEM: "SPECIFIC",
            USER_TYPE: "External",
            PAGE_SIZE: 1000,
            PAGE_NO: 100,
          },
        })
        .then((response) => {
          this.brokerDetailsArray = response.data.response;
        })
        .catch((err) => {});
    },

    // for adding the data
    purchase() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.create_system_role["ITEM"] = "ADMIN_FEE_PURCHASE";
      this.create_system_role["RECORD_SYS_ID"] =
        this.vehicle_details.VEHICLE_SYS_ID;

      this.axios
        .post(apiEndPoints.update_commison, this.create_system_role)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#master_system_role_modal").modal("hide");

            this.viewcommison();
            this.emptyfu();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {});
    },
    sal() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.create_system["ITEM"] = "SELL_COMMISSION";
      this.create_system["RECORD_SYS_ID"] = this.vehicle_details.VEHICLE_SYS_ID;

      this.axios
        .post(apiEndPoints.update_commison, this.create_system)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#master_system_role_modal").modal("hide");

            this.viewcommison();
            this.emptyfu();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {});
    },

    sales() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.create_system["ITEM"] = "ADMIN_FEE_SALES";
      this.create_system["RECORD_SYS_ID"] = this.vehicle_details.VEHICLE_SYS_ID;

      this.axios
        .post(apiEndPoints.update_commison, this.create_system)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#master_system_role_modal").modal("hide");

            this.viewcommison();
            this.emptyfu();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {});
    },

    openAddPaymentModal(user_detail) {
      console.log(user_detail);
      this.$bvModal.show("modal-1");
    },

    onchangePaymentAmount(event,index,obj_detail){
      if(event.target.value){
        let commision_amount = parseFloat(obj_detail.COMMISSION_AMOUNT);
        let event_value = parseFloat(event.target.value);
        if(event_value > commision_amount){
         Global.showErrorAlert(true, "error", "Payment amount can not be greater than commission amount!");
         event.target.value = null;
         return false;
        }
        this.selectedvechicledetailes[index].PAYMENT_AMOUNT = event.target.value;
      }

    },

    
  },
};
</script>
