<template>
  <div>
    <div v-if="loading_landing_page">
      <div class="preloder loading_main_background" id="upload_loader">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>

    <div class="floating-heading">
      <h4><b>Admin Master</b></h4>
    </div>

    <!-- Admin Master Table Start Here  -->
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data">
          <div class="row mb-3">
            <div
              class="col-md-12 mb-1"
              style="display: flex; justify-content: space-between"
            >
              <label class="frm-label-2" style="color: #a36626"
                ><i class="fa-solid fa-filter"></i> Filter</label
              >
              <div>
                <button
                  type="button"
                  class="btn btn-primary"
                  style="margin-right: 10px"
                  id="add_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#_modal"
                  data-bs-dismiss="modal"
                  @click="onLogIn"
                  v-if="
                    menuPermissions.FULL_ACCESS || menuPermissions.CREATE_ACCESS
                  "
                >
                  Add
                </button>
                <button id="srch_btn" type="button" class="btn btn-success">
                  Search
                </button>
              </div>
            </div>
            <div class="col-md-2" v-if="statusvalue">
              <label class="frm-label-2" style="font-size: 15px"
                >Status:
                <span class="filter-lbl">{{ statusvalue }}</span></label
              >
            </div>
            <div class="col-md-3" v-if="numbervalue">
              <label class="frm-label-2" style="font-size: 15px">
                Vehicle Number:
                <span class="filter-lbl">{{ numbervalue }}</span></label
              >
            </div>
            <div class="col-md-2" v-if="makevalue">
              <label class="frm-label-2" style="font-size: 15px">
                Make: <span class="filter-lbl">{{ makevalue }}</span></label
              >
            </div>
            <div class="col-md-2" v-if="modelvalue">
              <label class="frm-label-2" style="font-size: 15px">
                Model: <span class="filter-lbl">{{ modelvalue }}</span></label
              >
            </div>
          </div>
          <div class="table table-responsive">
            <div class="data-table master-table">
              <b-table
                class="table table-bordered table-hover"
                striped
                hover
                :items="vehicledetails"
                :fields="vehicledetailsfields"
                @row-clicked="clickVehicleDetailsRow"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                  <div
                    v-if="
                      data.index + 1 == vehicledetails.length &&
                      vehicledetails_page_no > 1
                    "
                  >
                    <span v-b-visible.once="loadVehicleDetails"></span>
                  </div>
                </template>
                <template v-slot:cell(NAME_AND_MODEL)="data">
                  {{ data.item.MAKE_NAME }}-{{ data.item.MODEL_NAME }}
                </template>
                <template v-slot:cell(Action)="row">
                  <div class="text-nowrap">
                    <button
                      class="custom-btn p-0"
                      @click="deleteVehicleDetails(row.item)"
                    >
                      <i
                        class="fa-solid fa-trash-can btn btn-secondary fa-2x"
                      ></i>
                    </button>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Admin Master Table End Here  -->

    <!-- Search Left Modal Start Here  -->
    <div
      class="modal modal-left fade"
      id="left_modal_sm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="left_modal_sm"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search By</h5>
          </div>
          <div class="modal-body search-panel">
            <div class="row">
              <div class="col-md-12">
                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="all"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">All Combinations</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="status"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Vehicle Status</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="number"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Vehicle Number</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="make"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Make</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="model"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Model</div>
                </label>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'status'"
              >
                <label class="frm-label">Status</label>
                <select class="form-control" v-model="STATUS">
                  <option value="">Select</option>
                  <option value="Pre Order">Pre Order</option>
                        <option value="New Vehicle">New Vehicle</option>
                        <option value="Local">Local</option>
                        <option value="ETS">ETS</option>
                        <option value="Scrap">Scrap</option>
                        <option value="Trade-in">Trade-in</option>
                </select>
              </div>
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'number'"
              >
                <label class="frm-label">Vehicle Number</label>
                <input
                  type="text"
                  v-model="VEHICLENUMBER"
                  class="form-control"
                  placeholder="Enter Vehicle No."
                />
              </div>
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'make'"
              >
                <label class="frm-label"
                  >Make<span style="color: red">*</span></label
                >
                <select class="form-control" v-model="MAKENAME">
                  <option value="">Select Make</option>
                  <option
                    v-for="makenm in Manufracturename"
                    :value="makenm.VEHICLE_MAKE"
                    :key="makenm.VEHICLE_MAKE_SYS_ID"
                  >
                    {{ makenm.VEHICLE_MAKE }}
                  </option>
                </select>
              </div>
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'model'"
              >
                <label class="frm-label">Model</label>
                <select class="form-control" v-model="MODELNAME">
                  <option value="">Select Model</option>
                  <option
                    v-for="model in Modeltype"
                    :value="model.VEHICLE_MODEL"
                    :key="model.VEHICLE_MODEL_SYS_ID"
                  >
                    {{ model.VEHICLE_MODEL }}
                  </option>
                </select>
              </div>
              <div class="col-md-12 mt-2" v-if="search_option">
                <div class="btn-conainer">
                  <button
                    type="button"
                    @click="getvehicledetails()"
                    id="srch-btn"
                    class="custom-btn custom-success-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search Left Modal End Here  -->

    <!-- Credit Note Modal Start Here  -->
    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-2">
                <h5
                  class="modal-title"
                  id="staticBackdropLabel"
                  style="width: 150px"
                >
                  CREDIT NOTE
                </h5>
              </div>
              <div class="col-md-10">
                <button
                  class="downld animate__animated animate__bounceIn"
                  @click="generateReportcredit"
                >
                  <i class="fa-solid fa-cloud-arrow-down"></i> Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <!-- <div class="close-btn-div">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> -->
            <div class="row">
              <!-- <div class="col-md-12 offset-md-10">

                     </div> -->
            </div>

            <div class="content">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-2">
                            <p>
                              1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="heading">
                      <p>TAX CREDIT NOTE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 offset-md-7 mt-2">
                  <div class="content-header-2">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Credit Note No.</p></div>
                          <div class="col-md-3">
                            <span
                              >:{{ this.DynamicproductFlagArr.CREDIT_NOTE_NO }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Date</p></div>

                          <div class="col-md-3">
                            <span
                              >:{{
                                this.DynamicproductFlagArr.CREDIT_NOTE_DATE
                              }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Agreement No:</p></div>
                          <div class="col-md-3">
                            <span
                              >:
                              {{
                                this.DynamicproductFlagArr
                                  .SALES_AGREEMENT_NUMBER
                              }}</span
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="content-header-2">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>To</p></div>
                          <div class="col-md-8">
                            :<span>
                              {{
                                this.DynamicproductFlagArr.CUSTOMER_NAME
                              }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4"><p>Address</p></div>
                          <div class="col-md-8">
                            :

                            <span class="ms-1">
                              {{ this.DynamicproductFlagArr.CUSTOMER_ADDRESS }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <p>Vehicle Registration No.</p>
                          </div>
                          <div class="col-md-8">
                            :<span>
                              {{ this.DynamicproductFlagArr.VEHICLE_NUMBER }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <p>Engine No../Motor No.</p>
                          </div>
                          <div class="col-md-8">
                            :<span>
                              {{ this.DynamicproductFlagArr.ENGINE_NO }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4"><p>Chassis Number</p></div>
                          <div class="col-md-8">
                            :<span>
                              {{ this.DynamicproductFlagArr.CHASIS_NO }}
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="content-body-2">
                    <table class="table table-borderless">
                      <thead
                        style="
                          border-top: 2px solid #212121;
                          border-bottom: 2px solid #212121;
                        "
                      >
                        <tr>
                          <th scope="col">Qty</th>
                          <th scope="col">Description of Goods</th>
                          <th scope="col">Unit Price</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(
                          user_deta, index
                        ) in DynamicproductFlagArr.CREDIT_NOTE_DETAILS"
                        :key="index"
                      >
                        <tr>
                          <td>{{ user_deta.QTY }}</td>
                          <td>{{ user_deta.DESCRIPTION }}</td>
                          <td>$ {{ user_deta.UNIT_PRICE }}</td>
                          <td>$ {{ user_deta.AMOUNT }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="content-footer" style="border: 1px solid #212121">
                    <table class="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <!-- <th scope="col">Qty</th> -->
                          <td></td>
                          <td style="border-left: 1px solid #212121">
                            Amount SGD$
                          </td>
                          <td style="border-left: 1px solid #212121">
                            ${{
                              this.DynamicproductFlagArr.V_UNIT_TOTAL_AMOUNT
                            }}
                            <span class="ms-1"></span>
                          </td>
                        </tr>

                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            Terms of Payment:
                            {{ this.DynamicproductFlagArr.PAYMENT_TERMS }}
                            <span class="MS-2"> </span>
                          </td>
                          <td style="border-left: 1px solid #212121">
                            Tax Amount $ <span class="ms-1"> </span>
                          </td>
                          <td style="border-left: 1px solid #212121">
                            ${{ this.DynamicproductFlagArr.V_TAX_TOTAL_AMOUNT }}
                          </td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            All cheques issued must be made in the favour of Lek
                            Auto Pte Ltd
                          </td>
                          <td style="border-left: 1px solid #212121">
                            <span class="ms-1">Total SGD $ </span>
                          </td>
                          <td style="border-left: 1px solid #212121">
                            $
                            {{
                              this.DynamicproductFlagArr.V_GRAND_TOTAL_AMOUNT
                            }}
                            <span class="ms-1"></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="creditnote"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="heading">
                  <p>TAX CREDIT NOTE</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 offset-md-7 mt-2">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Credit Note No.</p></div>
                      <div class="col-md-3">
                        <span
                          >:{{ this.DynamicproductFlagArr.CREDIT_NOTE_NO }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Date</p></div>

                      <div class="col-md-3">
                        <span
                          >:
                          {{
                            this.DynamicproductFlagArr.CREDIT_NOTE_DATE
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Agreement No:</p></div>
                      <div class="col-md-3">
                        <span
                          >:{{
                            this.DynamicproductFlagArr.SALES_AGREEMENT_NUMBER
                          }}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-12">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>To</p></div>
                      <div class="col-md-8">
                        :<span>
                          {{
                            this.DynamicproductFlagArr.ISSUE_TO_CUSTOMER_TYPE
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4"><p>Address</p></div>
                      <div class="col-md-8">
                        :

                        <span class="ms-1"
                          >{{ this.DynamicproductFlagArr.CUSTOMER_ADDRESS }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4">
                        <p>Vehicle Registration No.</p>
                      </div>
                      <div class="col-md-8">
                        :<span
                          >{{ this.DynamicproductFlagArr.VEHICLE_NUMBER }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4"><p>Engine No./Motor No.</p></div>
                      <div class="col-md-8">
                        :<span
                          >{{ this.DynamicproductFlagArr.ENGINE_NO }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4"><p>Chassis Number</p></div>
                      <div class="col-md-8">
                        :<span
                          >{{ this.DynamicproductFlagArr.CHASIS_NO }}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-12">
              <div class="content-body-2">
                <table class="table table-borderless">
                  <thead
                    style="
                      border-top: 2px solid #212121;
                      border-bottom: 2px solid #212121;
                    "
                  >
                    <tr>
                      <th scope="col">sl.no</th>
                      <th scope="col">Qty</th>
                      <th scope="col">Description of Goods</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(
                      user_deta, index
                    ) in DynamicproductFlagArr.CREDIT_NOTE_DETAILS"
                    :key="index"
                  >
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td>{{ user_deta.QTY }}</td>
                      <td>{{ user_deta.DESCRIPTION }}</td>
                      <td>{{ user_deta.UNIT_PRICE }}</td>
                      <td>{{ user_deta.AMOUNT }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <div class="content-footer" style="border: 1px solid #212121">
                <table class="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <!-- <th scope="col">Qty</th> -->
                      <td></td>
                      <td style="border-left: 1px solid #212121">
                        Amount SGD$
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1"></span>
                      </td>
                    </tr>

                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        Terms of Payment:
                        {{ this.DynamicproductFlagArr.PAYMENT_TERMS
                        }}<span class="MS-2"> </span>
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1"> </span>
                      </td>
                      <td style="border-left: 1px solid #212121"></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        All cheques issued must be made in the favour of Lek
                        Auto Pte Ltd
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1">Total SGD $</span>
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <!-- Credit Note Modal End Here  -->

    <!-- Tax Invoice Modal Start Here  -->
    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-4"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" v-show="canShowTaxNote">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-2">
                <h5
                  class="modal-title"
                  id="staticBackdropLabel"
                  style="width: 150px"
                >
                  TAX INVOICE
                </h5>
              </div>
              <div class="col-md-10">
                <button
                  class="downld animate__animated animate__bounceIn"
                  @click="generateReportTax"
                >
                  <i class="fa-solid fa-cloud-arrow-down"></i> Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <!-- <div class="close-btn-div">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> -->
            <div class="row">
              <!-- <div class="col-md-12 offset-md-10">

                     </div> -->
            </div>

            <div class="content">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-2">
                            <p>
                              1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-0">
                    <div class="heading">
                      <p>TAX INVOICE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <!-- header  -->
                <div class="col-md-8">
                  <div class="content-header-2">
                    <ul>
                      <li><p>Bill To,</p></li>
                      <li>
                        <p>{{ this.InvoiceDetailsWithTax.FIRST_NAME }}</p>
                      </li>
                      <li>
                        <p>
                          {{ this.InvoiceDetailsWithTax.ADDRESS_LINE_1 }}
                          {{ this.InvoiceDetailsWithTax.ADDRESS_LINE_2 }}
                        </p>
                      </li>
                      <li>
                        <p>
                          {{ this.InvoiceDetailsWithTax.COUNTRY_NAME }}
                          {{ this.InvoiceDetailsWithTax.PIN_CODE }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="content-header-2">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-5"><p>Invoice No.</p></div>
                          <div class="col-md-7">
                            <span>{{
                              this.InvoiceDetailsWithTax.INVOICE_NO
                            }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-5"><p>Date</p></div>
                          <div class="col-md-7">
                            <span>{{
                              this.InvoiceDetailsWithTax.TAX_INVOICE_DATE
                            }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-5"><p>VSA No.</p></div>
                          <div class="col-md-7">
                            <span>{{ this.InvoiceDetailsWithTax.VSA_NO }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- after heading sec  -->
                <div class="col-md-12">
                  <div
                    class="content-body-2 p-1"
                    style="border: 1px solid #212121"
                  >
                    <Div class="row">
                      <div class="col-md-7">
                        <div class="content-header-3">
                          <ul>
                            <li>
                              <div class="row">
                                <div class="col-md-6">
                                  <p>Vehicle Registration No.</p>
                                </div>
                                <div class="col-md-6">
                                  <span
                                    >:
                                    {{
                                      this.InvoiceDetailsWithTax.VEHICLE_NUMBER
                                    }}</span
                                  >
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-6"><p>Vehicle Make</p></div>
                                <div class="col-md-6">
                                  <span
                                    >:
                                    {{
                                      this.InvoiceDetailsWithTax.MAKE_NAME
                                    }}</span
                                  >
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-6"><p>VehicleModel</p></div>
                                <div class="col-md-6">
                                  <span
                                    >:
                                    {{
                                      this.InvoiceDetailsWithTax.MODEL_NAME
                                    }}</span
                                  >
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-6">
                                  <p>Original Registration Date</p>
                                </div>
                                <div class="col-md-6">
                                  <span
                                    >:
                                    {{
                                      this.InvoiceDetailsWithTax
                                        .REGISTRATION_DATE
                                    }}</span
                                  >
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="content-header-3">
                          <ul>
                            <li>
                              <div class="row">
                                <div class="col-md-5">
                                  <p>Engine No./Motor No.</p>
                                </div>
                                <div class="col-md-7">
                                  <span
                                    >:{{
                                      this.InvoiceDetailsWithTax.ENGINE_NO
                                    }}</span
                                  >
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-5"><p>Chassis No.</p></div>
                                <div class="col-md-7">
                                  <span
                                    >:{{
                                      this.InvoiceDetailsWithTax.CHASIS_NO
                                    }}</span
                                  >
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-5"><p>Sales Person</p></div>
                                <div class="col-md-7">
                                  <span
                                    >:
                                    {{
                                      this.InvoiceDetailsWithTax.SALESMAN
                                    }}</span
                                  >
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="content-footer" style="border: 1px solid #212121">
                    <table class="table table-borderless mb-0 bordered-table">
                      <thead style="border-bottom: 1px solid #212121">
                        <tr></tr>
                        <tr>
                          <th scope="col">Item Description</th>
                          <th scope="col" class="border-td">Rate</th>
                          <th scope="col" class="border-td">
                            Quantity <span class="ms-1"></span>
                          </th>
                          <th scope="col" class="border-td">
                            Grossary Amount($)
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="user_detail in ManageTaxInvoiceDetails"
                        :key="user_detail.RECORD_SYS_ID"
                      >
                        <tr>
                          <td>{{ user_detail.DESCRIPTION }}</td>
                          <td class="border-td">
                            {{ user_detail.UNIT_PRICE }}
                          </td>
                          <td class="border-td">
                            {{ user_detail.DISCOUNT_VALUE }}
                          </td>
                          <td class="border-td">
                            {{ user_detail.TOTAL_PRICE }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td">
                            <span class="ms-1">{{
                              this.InvoiceDetailsWithTax.ADVANCE_PAYMENT
                            }}</span>
                          </td>

                          <td class="border-td"><span class="ms-1"></span></td>
                        </tr>

                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td class="border-td-2"></td>
                          <td class="border-td border-td-2"></td>
                          <td class="border-td border-td-2"></td>
                          <td class="border-td border-td-2"></td>

                          <td class="border-td-2"></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>Payment Details:</td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>Sales Deposit</td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td">
                            <span class="ms-1">{{
                              this.InvoiceDetailsWithTax.SALES_DEPOSIT
                            }}</span>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            Less: Trade In
                            <span class="ms-3">{{
                              this.InvoiceDetailsWithTax.TRADE_IN_AMOUNT
                            }}</span>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1">-</span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>Less: PAYMENT PREVIOUSLY MADE</td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td">
                            $
                            <span class="ms-1">{{
                              this.InvoiceDetailsWithTax.PREVIOUSLY_PAYMENT
                            }}</span>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-6">
                                <p></p>
                              </div>
                              <div class="col-md-6 p-0">
                                <span></span>
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td">
                            <span class="ms-1"></span>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Finance Compnay:</p>
                              </div>
                              <div class="col-md-7">
                                :
                                <span class="ms-1">{{
                                  this.InvoiceDetailsWithTax.FINANCE_COMPANY
                                }}</span>
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Loan Amount</p>
                              </div>
                              <div class="col-md-7">
                                <span class="ms-1"
                                  >: $
                                  {{
                                    this.InvoiceDetailsWithTax.LOAN_AMOUNT
                                  }}</span
                                >
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Loan Tenure</p>
                              </div>
                              <div class="col-md-7">
                                <span class="ms-1"
                                  >:
                                  {{
                                    this.InvoiceDetailsWithTax.LOAN_TENURE
                                  }}</span
                                >
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Interest Rate</p>
                              </div>
                              <div class="col-md-7">
                                :
                                <span class="ms-1"
                                  >{{
                                    this.InvoiceDetailsWithTax.INTEREST_RATE
                                  }}
                                  %</span
                                >
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class=""><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td class="border-td-2">Amount Due From Customer</td>
                          <td class="border-td border-td-2">
                            <span class="ms-1"></span>
                          </td>
                          <td class="border-td border-td-2">
                            <span class="ms-1">-</span>
                          </td>
                          <td class="border-td border-td-2">
                            <span class="ms-1"></span>
                          </td>
                          <td class="border-td-2"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- content footer  -->
                <div class="content-footer">
                  <p class="mt-2">
                    *GST is computed at 7% of 50% of the taxable supply
                  </p>
                  <div class="row">
                    <div class="col-md-9">
                      <ul>
                        <li><h5>Mode of Payment</h5></li>
                        <li>
                          <div class="row">
                            <div class="col-md-3"><p>Cheque</p></div>
                            <div class="col-md-9 p-0">
                              <span>
                                : All Cheque must be in favour of
                                <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-3"><p>Bank Transfer</p></div>
                            <div class="col-md-9 p-0">
                              <span>: OCBC671885168001</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-3"><p>Paynow</p></div>
                            <div class="col-md-9 p-0">
                              <span>: EnterUEN201929524C</span>
                            </div>
                          </div>
                        </li>
                        <li class="mt-4">
                          <p>Memo:</p>
                          <span
                            >This is a computer generated document,no signature
                            is required.</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-3">
                      <ul>
                        <li>
                          <img
                            src="../../assets/images/QR.png"
                            class="img-fluid mt-2"
                            alt="qr code"
                          />
                        </li>
                        <li><p class="scan-msg">SCAN TO PAY</p></li>
                        <li>
                          <p
                            class="me-4"
                            style="color: #000; text-align: center"
                          >
                            Scan QR Code for Payment
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Tax Invoice Modal End Here  -->

    <!-- Payment Voucher HTML For PDF Start Here  -->
    <vue-html2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="PAYMENT VOUCHER NOTE"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdfPaymentVoucher"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header" style="margin-left: 4em">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-7 p-0">
                    <ul style="margin-left: 2em">
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2" style="width: 40em">
                        <p style="font-size: 15px">
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li>
                        <p style="font-size: 15px">
                          ROC No./ GST Reg No:. 201929524C
                        </p>
                      </li>
                      <li>
                        <p style="font-size: 15px">
                          Tel: 62395191 Fax: 62395190
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div
              class="col-md-12"
              style="text-align: center; font-weight: bold"
            >
              <h5 style="font-weight: bold">PAYMENT VOUCHER</h5>
            </div>
          </div>
          <div class="row mt-2" style="border-top: 2px solid #212121">
            <div class="col-md-7">
              <p>
                <span style="font-weight: 560">{{
                  pdfPaymentVoucherDetails.CUSTOMER_NAME
                }}</span>
              </p>
            </div>
            <div class="col-md-1 ml-2 float-right">
              <p style="font-weight: 560">
                <span>NO</span>
              </p>
            </div>
            <div class="col-md-4 ml-2">
              <p style="font-weight: 560">
                <span style="">: {{ pdfPaymentVoucherDetails.PV_NO }}</span>
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-7">
              <p>
                <span style="font-weight: 560">{{
                  pdfPaymentVoucherDetails.ADDRESS_LINE_1
                }}</span>
              </p>
            </div>
            <div class="col-md-1 ml-2 float-right">
              <p style="font-weight: 560">
                <span>DATE</span>
              </p>
            </div>
            <div class="col-md-4 ml-2">
              <p style="font-weight: 560">
                <span style="">: {{ pdfPaymentVoucherDetails.PV_DATE }}</span>
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <p>
                <span style="font-weight: 560"
                  >{{ pdfPaymentVoucherDetails.COUNTRY_NAME }}-{{
                    pdfPaymentVoucherDetails.PIN_CODE
                  }}</span
                >
              </p>
            </div>
          </div>

          <div
            class="row mt-3"
            style="
              border-top: 2px solid #212121;
              border-bottom: 2px solid #212121;
            "
          >
            <div class="col-md-12">
              <p>
                <span style="font-weight: 560"
                  >BEING PAYMENT FOR THE FOLLOWING DOCUMENTS</span
                >
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-1">
              <p>
                <span style="font-weight: 560; border-bottom: 2px solid #212121"
                  >#</span
                >
              </p>
            </div>
            <div class="col-md-2">
              <p>
                <span style="font-weight: 560; border-bottom: 2px solid #212121"
                  >INV-NO</span
                >
              </p>
            </div>
            <div class="col-md-3">
              <p>
                <span style="font-weight: 560; border-bottom: 2px solid #212121"
                  >COST TYPE</span
                >
              </p>
            </div>
            <div class="col-md-4">
              <p>
                <span style="font-weight: 560; border-bottom: 2px solid #212121"
                  >DESCRIPTION</span
                >
              </p>
            </div>
            <div class="col-md-2">
              <p>
                <span style="font-weight: 560; border-bottom: 2px solid #212121"
                  >AMOUNT</span
                >
              </p>
            </div>
          </div>
          <div
            class="row mt-2"
            v-for="(dd, index) in pdfPaymentVoucherDetails.DESCRIPTION"
            :key="index"
          >
            <div class="col-md-1">
              <p>
                <span style="font-weight: 520">{{ 1 + index }}</span>
              </p>
            </div>
            <div class="col-md-2">
              <p>
                <span style="font-weight: 520">{{
                  pdfPaymentVoucherDetails.INVOICE_NO
                }}</span>
              </p>
            </div>
            <div class="col-md-3">
              <p>
                <span style="font-weight: 520">{{ dd.COST_TYPE }}</span>
              </p>
            </div>
            <div class="col-md-4">
              <p>
                <span style="font-weight: 520">{{
                  dd.DETAIL_DESCRIPTION
                }}</span>
              </p>
            </div>
            <div class="col-md-2">
              <p>
                <span style="font-weight: 520"
                  >$ <span style="margin-left: 5px">{{ dd.AMOUNT }}</span></span
                >
              </p>
            </div>
          </div>
          <div class="row mt-4" style="border-top: 2px solid #212121">
            <div class="col-md-8">
              <p>
                <span style="font-weight: 560"
                  >PAYMENT MODE<span style="margin-left: 10px"
                    >:&nbsp;{{ pdfPaymentVoucherDetails.PAYMENT_MODE }}</span
                  ></span
                >
              </p>
            </div>
            <div class="col-md-2">
              <p>
                <span style="font-weight: 560; margin-left: 10px"
                  >Sub Total:</span
                >
              </p>
            </div>
            <div class="col-md-2">
              <p style="font-weight: 560">
                <span
                  >$
                  <span style="margin-left: 5px">{{
                    pdfPaymentVoucherDetails.TOTAL_AMOUNT_BEF_GST
                  }}</span></span
                >
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <p>
                <span style="font-weight: 560; margin-left: 10px">GST</span>
              </p>
            </div>
            <div class="col-md-2">
              <p style="font-weight: 560">
                <span
                  >$
                  <span style="margin-left: 5px">{{
                    pdfPaymentVoucherDetails.GST_AMOUNT
                  }}</span></span
                >
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-8"></div>
            <div
              class="col-md-2"
              style="
                border-top: 2px solid #212121;
                border-bottom: 2px solid #212121;
                border-left: 2px solid #212121;
              "
            >
              <p>
                <span style="font-weight: 560; margin-left: 10px">TOTAL</span>
              </p>
            </div>
            <div
              class="col-md-2"
              style="
                border-top: 2px solid #212121;
                border-bottom: 2px solid #212121;
                border-right: 2px solid #212121;
              "
            >
              <p style="font-weight: 560">
                <span
                  >$
                  <span style="margin-left: 5px">{{
                    pdfPaymentVoucherDetails.TOTAL_AMOUNT
                  }}</span></span
                >
              </p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-1"></div>
            <div class="col-md-3" style="text-align: center">
              <span style="font-weight: 560">Prepared By</span>
              <div class="row">
                <div
                  class="col-md-12"
                  style="border: 2px solid #212121; height: 4em"
                ></div>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3" style="text-align: center">
              <span style="font-weight: 560">Approved By</span>
              <div class="row">
                <div
                  class="col-md-12"
                  style="border: 2px solid #212121; height: 4em"
                ></div>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3" style="text-align: center">
              <span style="font-weight: 560">Received By</span>
              <div class="row">
                <div
                  class="col-md-12"
                  style="border: 2px solid #212121; height: 4em"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <!-- Payment Voucher HTML For PDF End Here  -->

    <!-- Update Admin Master Modal Start Here  -->
    <b-modal
      id="admin_edit_modal"
      class="admin_edit_modal"
      v-model="admin_edit_modal_show"
      size="xl"
      hide-footer
    >
      <template #modal-header>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Admin Master</h5>
          <h5 class="modal-title" style="margin-left: 20px">
            {{ vehicletype }}
          </h5>

          <button
            type="button"
            class="btn-normal modal-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </button>
        </div>
      </template>

      <div class="container-fluid d-flex">
        <div v-if="loading">
          <div class="preloder loading_main_background" id="upload_loader">
            <img src="../../assets/images/loader.gif" />
          </div>
        </div>
        <div class="col-md-2 col-sm-12 mt-4" style="position: fixed">
          <aside>
            <div class="side-navbar animate__animated animate__slideInLeft">
              <div class="navbar-links shortcut-nav">
                <ul>
                  <i
                    class="fa-solid fa-sync fa-spin mx-5"
                    v-if="loading"
                    style="color: #a25b5b"
                  ></i>
                  <div class="col-md-2">
                    <button
                      class="custom-btn custom-success-btn"
                      @click="clickVehicleDetailsRow(current_vehicle_detail)"
                    >
                      Refresh
                    </button>
                  </div>
                  <li class="active my-2" @click="scrollTo('stock-entry')">
                    <span class="nav-itm">Stock Record</span>
                  </li>

                  <li class="active my-2" @click="scrollTo('seller-details')">
                    <span class="nav-itm">Seller Details</span>
                  </li>

                  <li class="active my-2" @click="scrollTo('buyer-details')">
                    <span class="nav-itm">Buyer Details / ETS Transaction</span>
                  </li>

                  <li class="dropdown my-1">
                    <a href="#">
                      <li class="dropbtn">
                        <span class="nav-itm">Sales Receipt</span>
                        <i
                          class="fa-solid fa-angle-right icon-2"
                          style="right: 70px"
                        ></i>
                      </li>
                    </a>
                    <div
                      class="dropdown-content mt-2"
                      style="
                        min-width: 200px;
                        max-width: 160px;
                        padding: 0.5rem;
                      "
                    >
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('insurance-info', 'sales-receipt')"
                      >
                        <span class="nav-itm">Insurance Info</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('trade-info', 'sales-receipt')"
                      >
                        <span class="nav-itm">Trade Info</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('other-payment', 'sales-receipt')"
                      >
                        <span class="nav-itm">Others Payable</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('sales-receipt-tab', 'sales-receipt')"
                      >
                        <span class="nav-itm">Sales Receipt</span>
                      </li>
                    </div>
                  </li>

                  <li class="dropdown my-1">
                    <a href="#">
                      <li class="dropbtn">
                        <span class="nav-itm">Loan Info</span>
                        <i
                          class="fa-solid fa-angle-right icon-2"
                          style="right: 70px"
                        ></i>
                      </li>
                    </a>
                    <div
                      class="dropdown-content mt-2"
                      style="
                        min-width: 200px;
                        max-width: 160px;
                        padding: 0.5rem;
                      "
                    >
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('loan-tab-info', 'loan-info')"
                      >
                        <span class="nav-itm">Loan Info</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="
                          scrollTo('finance-company-tax-invoice', 'loan-info')
                        "
                      >
                        <span class="nav-itm">Finance Company Tax Invoice</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="
                          scrollTo('finance-company-invoice', 'loan-info')
                        "
                      >
                        <span class="nav-itm">Finance Company Invoice</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('official-receipt', 'loan-info')"
                      >
                        <span class="nav-itm">Official Receipt</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="
                          scrollTo('loan-commission-invoice', 'loan-info')
                        "
                      >
                        <span class="nav-itm">Loan Commission Invoice</span>
                      </li>
                    </div>
                  </li>

                  <li
                    class="active my-2"
                    @click="scrollTo('manage-tax-invoice', 'tax-invoice')"
                  >
                    <span class="nav-itm">Tax invoice</span>
                  </li>

                  <li
                    class="active my-2"
                    @click="scrollTo('manage-credit-note', 'credit-note')"
                  >
                    <span class="nav-itm">Credit note</span>
                  </li>

                  <li class="active my-2" @click="scrollTo('cost-center')">
                    <span class="nav-itm">Cost Centre</span>
                  </li>
                  <li
                    class="active my-2"
                    @click="getPlStatmentDetails(), getInvoiceDetailsWithTax()"
                    style="color: #754115; font-weight: bold"
                  >
                    <span class="nav-itm">
                      PL Statement
                      <!-- <i
                        class="fa-solid fa-cloud-arrow-down"
                        style="color: blue"
                      ></i> -->
                    </span>
                  </li>
                  <li
                    class="active my-2"
                    @click="calculateCommision"
                    style="color: #754115; font-weight: bold"
                  >
                    <span class="nav-itm">Calculate Commission</span>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
        </div>
        <div
          class="col-md-10 col-sm-12 main-cont animate__animated animate__slideInRight"
          style="margin-left: 220px"
        >
          <div class="container-fluid">
            <div class="modal-body admin-edt-frm">
              <div class="row mt-3">
                <!-- Header Section Start-->
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Vehicle Status
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.VEHICLE_STATUS"
                                @change="onchangeVehicleStatus"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="Pre Order">Pre Order</option>
                                <option value="New Vehicle">New Vehicle</option>
                                <option value="Local">Local</option>
                                <option value="ETS">ETS</option>
                                <option value="Scrap">Scrap</option>
                                <option value="Trade-in">Trade-in</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Vehicle No.
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                :disabled="
                                  addupdatebuyerdata.STATUS == 'Handover'
                                "
                                v-model="addmasterdata.VEHICLE_NUMBER"
                                type="text"
                                class="form-control"
                                placeholder="Enter Vehicle No."
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Temporary Vehicle No.</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.TEMPORARY_VEHICLE_NUMBER"
                                type="text"
                                class="form-control"
                                placeholder="Enter Temporary Vehicle No."
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">New Vehicle No.</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="
                                  addmasterdata.ADDITIONAL_VEHICLE_NUMBER
                                "
                                type="text"
                                class="form-control"
                                placeholder="Enter New Vehicle No."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Entry Date <span style="color: red"></span
                              ></label>
                            </div>
                            <div class="col-md-8">
                              <label>{{ addmasterdata.ENTRY_DATE }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Created By <span style="color: red"></span
                              ></label>
                            </div>
                            <div class="col-md-8">
                              <label>{{ addmasterdata.CREATED_NAME }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="frm-label-2"
                                >Last Modified By:
                                <span
                                  ><label>{{
                                    addmasterdata.MODIFIED_NAME
                                  }}</label></span
                                ></label
                              >
                            </div>
                            <div class="col-md-6">
                              <label class="frm-label-2"
                                >Not Show To Pricelist:
                                <input
                                  @change="onChangePriceListStatus"
                                  type="checkbox"
                                  v-model="addmasterdata.PRICE_LIST_STATUS"
                              /></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Header Section End-->

                <!-- Stock Entry Section Start -->
                <div class="col-md-12 mt-2" id="stock_entry" ref="stock-entry">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="line-heading"><span>Stock Entry</span></h5>
                    </div>
                    <!-- Stock Entry Left Side Form Section -->
                    <div class="col-md-6 left-side-frm">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Make <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                class="form-control"
                                id="make"
                                v-model="addmasterdata.MAKE_SYS_ID"
                                @change="getmakeName"
                              >
                                <option value="">Select Manufracture</option>
                                <option
                                  v-for="makenm in Manufracturename"
                                  :value="makenm.VEHICLE_MAKE_SYS_ID"
                                  :key="makenm.VEHICLE_MAKE_SYS_ID"
                                >
                                  {{ makenm.VEHICLE_MAKE }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Model <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="addmasterdata.MODEL_NAME"
                                name="Model"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Model Short Name</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="addmasterdata.MODEL_SHORT_NAME"
                                name="Model"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Body Type
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                name="Body Type"
                                v-model="addmasterdata.BODY_TYPE_SYS_ID"
                                class="form-control"
                                @change="getbodyName"
                              >
                                <option value="">Select Body Type</option>
                                <option
                                  v-for="body in Bodytype"
                                  :value="body.VEHICLE_BODY_TYPE_SYS_ID"
                                  :key="body.VEHICLE_BODY_TYPE_SYS_ID"
                                >
                                  {{ body.VEHICLE_BODY_TYPE }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Propellant
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                class="form-control"
                                v-model="addmasterdata.PROPELLANT"
                              >
                                <option value="">Select</option>
                                <option value="Petrol">Petrol</option>
                                <option value="Diesel">Diesel</option>
                                <option value="Natural Gas">Natural Gas</option>
                                <option value="Electric">Electric</option>
                                <option value="Petrol-Electric">
                                  Petrol-Electric
                                </option>
                                <option value="Diesel-Electric (Plug-In)">
                                  Diesel-Electric (Plug-In)
                                </option>
                                <option value="Petrol-Electric (Plug-In)">
                                  Petrol-Electric (Plug-In)
                                </option>
                                <option value="Diesel-CNG">
                                  Diesel-CNG (Plug-In)
                                </option>
                                <option value="Diesel- Electric">
                                  Diesel- Electric
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Chasis No. </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.CHASIS_NO"
                                type="text"
                                class="form-control"
                                placeholder="Enter Chasis No."
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Engine No./Motor No.
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.ENGINE_NO"
                                type="text"
                                class="form-control"
                                placeholder="Enter Engine No."
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2"
                                    >Laden
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    @keyup="onKeyUpCalculateTon"
                                    v-model="addmasterdata.LADEN"
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2"
                                    >Unladen
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="addmasterdata.UNLADEN"
                                    @keyup="onKeyUpCalculateTon"
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2"> Ton </label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="addmasterdata.ENGINE_TON"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2">
                                    Engine Cap
                                  </label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="addmasterdata.ENGINE_CAP"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2"
                                    >PAX
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="addmasterdata.PAX"
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Year Of Manufacture
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.MANUFACTURE_YEAR"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Original Registration Date</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="
                                  addmasterdata.ORIGINAL_REGISTRATION_DATE
                                "
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Colour</label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.COLOR_SYS_ID"
                                class="form-control"
                                @change="getcolorName"
                              >
                                <option value="">Select Colour</option>
                                <option
                                  v-for="colournm in Coloursname"
                                  :value="colournm.VEHICLE_COLOR_SYS_ID"
                                  :key="colournm.VEHICLE_COLOR_SYS_ID"
                                >
                                  {{ colournm.VEHICLE_COLOR }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Number Of TRF</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.TSF_NUMBER"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Location</label>
                            </div>
                            <div class="col-md-8">
                              <select
                                name="Location"
                                v-model="addmasterdata.LOCATION"
                                class="form-control"
                              >
                                <option value="">Select location</option>
                                <option
                                  v-for="Vehiclelocation in Vehiclelocations"
                                  :value="Vehiclelocation.VEHICLE_LOCATION"
                                  :key="Vehiclelocation.VEHICLE_LOCATION_SYS_ID"
                                >
                                  {{ Vehiclelocation.VEHICLE_LOCATION }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Incharge By</label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="
                                  addmasterdata.INCHARGE_BY_BROKER_SYS_ID
                                "
                                class="form-control"
                                name="Incharge By"
                              >
                                <option :value="null">Select Incharge</option>
                                <option
                                  v-for="broker in brokerDetailsArray"
                                  :value="broker.USER_SYS_ID"
                                  :key="broker.USER_SYS_ID"
                                >
                                  {{ broker.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Asking Price($)</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                type="number"
                                v-model="addmasterdata.ASKING_PRICE"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Accessory</label>
                            </div>
                            <div class="col-md-8">
                              <textarea
                                type="text"
                                v-model="addmasterdata.ACCESSORY"
                                class="form-control"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12" style="margin-top: 25px">
                          <div class="row">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                type="button"
                                @click="updateVehicleStockRecordLeftFormDetails"
                                class="custom-btn custom-success-btn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Stock Entry Right Side Form Section -->
                    <div class="col-md-6" id="Record-Section">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Transfer Fee($)</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                type="number"
                                v-model="addmasterdata.TRANSFER_FEE"
                                class="form-control"
                                placeholder="Enter Transfer Fee"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Road Tax Type</label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.ROAD_TAX_TYPE"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="6 Months">6 Months</option>
                                <option value="12 Months">12 Months</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Road Tax($) </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                type="number"
                                v-model="addmasterdata.ROAD_TAX"
                                class="form-control"
                                placeholder="Enter Road Tax"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Road Tax Expiry Date</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.ROAD_TAX_EXPIRY_DATE"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Inspection Expiry Date
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.INSPECTION_EXPIRY_DATE"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Layup Exp Date</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.LAYUP_EXP_DATE"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Layup amount</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                type="number"
                                class="form-control"
                                v-model="addmasterdata.LAYUP_AMOUNT"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >COE (Log Card)($)
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.COE_LOG_GUARD"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >COE(ACC)/ DPQP($)</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.COE_ACC_DPQP"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >COE Expiry Date
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.COE_EXPIRY_DATE"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">COE Number </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.COE_NUMBER"
                                type="text"
                                class="form-control"
                                placeholder="Enter COE Number"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">COE To Pay </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.COE_TO_PAY"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="YES">5 year</option>
                                <option value="NO">10 year</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Open Market Value($)</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.OPEN_MARKET_VALUE"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Parf Amount($) </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.PARF_AMOUNT"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Registration Fee ($)<span
                                  style="color: red"
                                  v-if="vehicletype == 'New Vehicle'"
                                  >*</span
                                ></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.REGISTRATION_FEE"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">ARF Amount($) </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.ARF_AMOUNT"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >CEVS Surcharge($)
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.CEVS_SURCHARGE"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">CEVS Rebate($) </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.CEVS_REBATE"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">ETS Paper From </label>
                            </div>
                            <div class="col-md-8">
                              <b-input-group>
                                <b-form-input
                                  :disabled="etsfromdisable"
                                  id="SEARCH_VEHICLE_INFO"
                                  @change="onChnageETSPaperFrom"
                                  v-model="addmasterdata.ETS_PAPER_FROM"
                                  list="SearchAllVehiclelistOptionsForETSPaperFrom"
                                ></b-form-input>
                              </b-input-group>
                              <datalist
                                id="SearchAllVehiclelistOptionsForETSPaperFrom"
                              >
                                <option
                                  v-for="vehicle_info in ets_vehicle_list_info"
                                  :value="vehicle_info.VEHICLE_NUMBER"
                                  :key="vehicle_info.RECORD_SYS_ID"
                                  :id="vehicle_info.RECORD_SYS_ID"
                                ></option>
                              </datalist>
                            </div>
                            <!-- <div class="col-md-8">
                              <select
                                v-model="addmasterdata.ETS_PAPER_FROM"
                                :disabled="etsfromdisable"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="option 1">option 1</option>
                                <option value="option 2">option 2</option>
                              </select>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Use TCOE </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.USE_TCOE"
                                class="form-control"
                              >
                                <option>Select</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-4">
                          <div class="row">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                type="button"
                                @click="
                                  updateVehicleStockRecordRightFormDetails
                                "
                                class="custom-btn custom-success-btn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Stock Entry Section End -->

                <!-- Seller Details Section Start -->
                <div
                  class="col-md-12 mt-2"
                  id="seller_det"
                  ref="seller-details"
                >
                  <div class="col-md-12">
                    <h5 class="line-heading"><span>Seller Details</span></h5>
                  </div>
                  <b-tabs>
                    <!-- Seller Details Tab -->
                    <b-tab active title="Seller Details">
                      <!-- Seller Upper Section Form -->
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right">
                                  Seller Type <span style="color: red">*</span>
                                </label>
                              </div>
                              <div class="col-md-8">
                                <select
                                  v-model="addupdatesellerdata.CUSTOMER_TYPE"
                                  @change="onChnageSellerTypeFun"
                                  class="form-control"
                                >
                                  <option value="">Select</option>
                                  <option value="Vendor">Vendor</option>
                                  <option value="Customer">Customer</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="addupdatesellerdata.CUSTOMER_TYPE == 'Vendor'"
                            class="col-md-12 mt-2 mb-2"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Info</label
                                >
                              </div>
                              <div class="col-md-8">
                                <b-input-group>
                                  <b-form-input
                                    id="SELLER_VENDOR_INFO"
                                    @change="onChnageSellerVendorInfo"
                                    @keyup="onKeyUpSellerVendorInfo"
                                    v-model="SELLER_VENDOR_INFO"
                                    placeholder="Enter vendor"
                                    list="sellervendorlistOptions"
                                  ></b-form-input>
                                  <b-input-group-append>
                                    <b-button
                                      variant="info"
                                      data-bs-toggle="modal"
                                      data-bs-target="#vendor_modal"
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                                <datalist id="sellervendorlistOptions">
                                  <option
                                    v-for="vendor_info in AllSellerVendorDetailsArray"
                                    :value="vendor_info.VENDOR_NAME"
                                    :key="vendor_info.VENDOR_SYS_ID"
                                    :id="vendor_info.VENDOR_SYS_ID"
                                  ></option>
                                </datalist>
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerVendorCode != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Code</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerVendorCode"
                                  id="VendorCode"
                                  name="VendorCode"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerVendorName != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Name</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerVendorName"
                                  id="VendorName"
                                  name="VendorName"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerVendorAddress != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Address</label
                                >
                              </div>
                              <div class="col-md-8">
                                <textarea
                                  v-model="DisplaySellerVendorAddress"
                                  style="height: 6em"
                                  id="Seller Vendor Address"
                                  name="Seller Vendor Address"
                                  class="form-control"
                                  disabled
                                >
                                </textarea>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="
                              addupdatesellerdata.CUSTOMER_TYPE == 'Customer'
                            "
                            class="col-md-12 mt-2 mb-2"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Info</label
                                >
                              </div>
                              <div class="col-md-8">
                                <b-input-group>
                                  <b-form-input
                                    id="SELLER_CUSTOMER_INFO"
                                    @change="onChnageSellerCustomerInfo"
                                    @keyup="onKeyUpSellerCustomerInfo"
                                    v-model="SELLER_CUSTOMER_INFO"
                                    placeholder="Enter customer"
                                    list="sellervendorlistOptions"
                                  ></b-form-input>
                                  <b-input-group-append>
                                    <b-button
                                      variant="info"
                                      data-bs-toggle="modal"
                                      data-bs-target="#employee_modal"
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                                <datalist id="sellervendorlistOptions">
                                  <option
                                    v-for="customer_info in AllSellerCustomerDetailsArray"
                                    :value="customer_info.CUSTOMER_NAME"
                                    :key="customer_info.ID_NUMBER"
                                  ></option>
                                </datalist>
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerCustomerType != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Type</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerCustomerType"
                                  id="Seller Customer Type"
                                  name="Seller Customer Type"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="
                                DisplaySellerCustomerType == 'individual' ||
                                DisplaySellerCustomerType == 'INDIVIDUAL'
                              "
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >NRIC Number</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerCustomerIdNumber"
                                  id="Seller NRIC Number"
                                  name="Seller NRIC Number"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="
                                DisplaySellerCustomerType == 'Company' ||
                                DisplaySellerCustomerType == 'COMPANY'
                              "
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >UEN Number</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerCustomerIdNumber"
                                  id="Seller UEN Number"
                                  name="Seller UEN Number"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerCustomerName != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Name</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerCustomerName"
                                  id="VendorName"
                                  name="VendorName"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerCustomerAddress != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Address</label
                                >
                              </div>
                              <div class="col-md-8">
                                <textarea
                                  v-model="DisplaySellerCustomerAddress"
                                  style="height: 4em"
                                  id="Seller Customer Address"
                                  name="Seller Customer Address"
                                  class="form-control"
                                  disabled
                                >
                                </textarea>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right">
                                  Purchase Date
                                </label>
                              </div>
                              <div class="col-md-8">
                                <input
                                  v-model="addupdatesellerdata.PURCHASE_DATE"
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-2 align-right">
                            <input
                              type="checkbox"
                              @change="clickSellerFormNoDeveryDate"
                              v-model="addupdatesellerdata.NO_DELIVERY_DATE"
                            />
                            <label class="frm-label-2 ms-2"
                              >No Delivery Date</label
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Agreement Number
                                </label>
                              </div>
                              <div class="col-md-8">
                                <input
                                  v-model="addupdatesellerdata.AGREEMENT_NUMBER"
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Agreement No."
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Estimate Delivery In Date
                                </label>
                              </div>
                              <div class="col-md-8">
                                <input
                                  v-model="
                                    addupdatesellerdata.ESTIMATE_DELIVERY_IN_DATE
                                  "
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Admin fees($)
                                </label>
                              </div>
                              <div class="col-md-8">
                                <input
                                  v-model="addupdatesellerdata.ADMIN_FEE"
                                  type="number"
                                  class="form-control"
                                  placeholder="Enter Admin Fees"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Delivery In Date & Time</label
                                >
                              </div>
                              <div class="col-md-4 align-left">
                                <input
                                  :disabled="isDisabled"
                                  v-model="addupdatesellerdata.DELIVERY_IN_DATE"
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                              <div class="col-md-4 align-left">
                                <input
                                  :disabled="isDisabled"
                                  v-model="addupdatesellerdata.DELIVERY_IN_TIME"
                                  type="time"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-4">
                          <div class="row">
                            <div class="col-md-2">
                              <input
                                type="checkbox"
                                v-model="addupdatesellerdata.EXCLUDE_COE"
                                class="align-right"
                                style="margin-top: 2px; margin-left: 5px"
                              />
                              <label class="frm-label-2 align-left">
                                Exclude COE($)
                              </label>
                            </div>
                            <div class="col-md-4">
                              <input
                                type="checkbox"
                                v-model="
                                  addupdatesellerdata.EXCLUDE_REGISTRATION_FEE_AND_ARF
                                "
                                class="align-right"
                                style="margin-top: 2px; margin-left: 5px"
                              />
                              <label class="frm-label-2 align-left">
                                Exclude Registration Fee And ARF($)
                              </label>
                            </div>
                            <div class="col-md-2">
                              <input
                                type="checkbox"
                                v-model="addupdatesellerdata.EXCLUDE_ROAD_TAX"
                                class="align-right"
                                style="margin-top: 2px; margin-left: 5px"
                              />
                              <label class="frm-label-2 align-left">
                                Exclude Road Tax
                              </label>
                            </div>
                            <div class="col-md-4">
                              <input
                                type="checkbox"
                                @change="onchangePurchasePriceIsZero()"
                                v-model="
                                  addupdatesellerdata.PURCHASE_PRICE_IS_ZERO
                                "
                                class="align-right"
                                style="margin-top: 2px; margin-left: 5px"
                              />
                              <label class="frm-label-2 align-left">
                                Tick If The Purchase Price Is Zero
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 mt-4">
                          <div class="row">
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                price amount(with gst amount)<span
                                  style="color: red"
                                  >*</span
                                >
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                type="text"
                                :disabled="isDisablePriceAmount"
                                v-model="addupdatesellerdata.PURCHASE_PRICE"
                                class="form-control"
                                placeholder=""
                                @keyup="onkeyupPriceAmount"
                              />
                            </div>

                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                Purchase Price($)(without gst amount)<span
                                  style="color: red"
                                  >*</span
                                >
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                type="text"
                                disabled
                                v-model="addupdatesellerdata.PURCHASE_PRICE_SD"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                Purchase Outside Commission($)
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                v-model="
                                  addupdatesellerdata.PURCHASE_OUTSIDE_COMMISSION
                                "
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                Total Purchase Price($)<span style="color: red"
                                  >*</span
                                >
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                disabled
                                v-model="
                                  addupdatesellerdata.TOTAL_PURCHASE_PRICE
                                "
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mt-4">
                          <div class="row">
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                GST<span style="color: red">*</span>
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <select
                                id="SELLER_DETAILS"
                                v-model="addupdatesellerdata.TAX_SYS_ID"
                                class="form-control"
                                @change="globalCalculateGST('SELLER_DETAILS')"
                              >
                                <option value="">Select</option>
                                <option
                                  v-for="tax_info in tax_info_detai"
                                  :value="tax_info.TAX_SYS_ID"
                                  :key="tax_info.TAX_SYS_ID"
                                >
                                  {{ tax_info.TAX_NAME }}-{{
                                    tax_info.TAX_PERCENTAGE
                                  }}
                                  %
                                </option>
                              </select>
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                GST Amount Before Adjustment($)
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                v-model="
                                  addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT
                                "
                                disabled
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                GST Decimal Adjustment(+1/-1)
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                v-model="
                                  addupdatesellerdata.GST_DECIMAL_ADJUSTMENT
                                "
                                @keyup="
                                  globalDecimalAdjusment('SELLER_DETAILS')
                                "
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                GST Amount($)<span style="color: red">*</span>
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                disabled
                                v-model="addupdatesellerdata.GST_AMOUNT"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right"
                                >Add Remarks</label
                              >
                            </div>
                            <div class="col-md-8 mt-2">
                              <div class="accordion-item">
                                <textarea
                                  v-model="addupdatesellerdata.NOTE"
                                  class="custom-textarea form-control"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                type="button"
                                @click="addUpdateSellerDetails"
                                class="custom-btn custom-success-btn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="divider-2"></div>
                        </div>

                        <!-- Seller AP Payment Add Section -->
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-3">
                              <button
                                class="custom-btn add-btn"
                                name=""
                                data-bs-toggle="collapse"
                                data-bs-target="#seller-colapse"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                <strong>Add Payment</strong>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-plus-circle"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="12" y1="8" x2="12" y2="16"></line>
                                  <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                              </button>
                            </div>
                            <div class="col-md-2">
                              <button
                                @click="recalculateReceivablePayment('BUY')"
                                style="width: 120px"
                                type="button"
                                class="custom-btn custom-success-btn"
                              >
                                Recalculate
                              </button>
                            </div>
                            <!-- <div class="col-md-7">
                                <div class="row">
                                  <div class="col-md-12">
                                    <p style="font-weight: bold">
                                      Note<span style="color: red"> *</span>&nbsp; Click
                                      on recalculate button if any changes in these
                                      fields
                                    </p>
                                  </div>
                                  <div class="col-md-12" style="font-weight: bold">
                                    <p style="margin-left: 3.4em">
                                      Selling Price,Transfer Fee,Road Tax,Insurance
                                      Premium, Advance Payment,HP Fee,Other Fee,Admin
                                      Fee
                                    </p>
                                  </div>
                                </div>
                              </div> -->
                          </div>
                          <div class="row mt-3">
                            <div class="col-md-6">
                              <span style="font-weight: 580"
                                >Total Payable Amount:&nbsp;S$
                                <span style="font-weight: bold">{{
                                  SELLER_AP_PURCHASE_AMOUNT
                                }}</span></span
                              >
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: 580"
                                >Balance:&nbsp;S$
                                <span style="font-weight: bold">{{
                                  SELLER_AP_REDUCED_PURCHASE_AMOUNT
                                }}</span></span
                              >
                            </div>
                          </div>
                          <div
                            id="seller-colapse"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body p-2 mt-2">
                              <form>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="row">
                                      <div class="col-md-12 mt-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label
                                              class="frm-label-2 align-right"
                                            >
                                              AP Payment
                                              <span style="color: red">*</span>
                                            </label>
                                          </div>
                                          <div class="col-md-8">
                                            <select
                                              v-model="
                                                addupdatesellerapdata.PAYMENT_DESC
                                              "
                                              class="form-control"
                                            >
                                              <option value="">Select</option>
                                              <option
                                                value="Finance Settlement"
                                              >
                                                Finance Settlement
                                              </option>
                                              <option value="Balance Payment">
                                                Balance Payment
                                              </option>
                                              <option value="Full Payment">
                                                Full Payment
                                              </option>
                                              <option value="Deposit">
                                                Deposit
                                              </option>
                                              <option value="Contra">
                                                Contra
                                              </option>
                                              <option value="Others">
                                                Others
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        v-if="
                                          addupdatesellerapdata.PAYMENT_DESC ==
                                          'Others'
                                        "
                                        class="col-md-12 mt-2"
                                      >
                                        <div class="row">
                                          <div class="col-md-4"></div>
                                          <div class="col-md-8">
                                            <input
                                              v-model="
                                                addupdatesellerapdata.PAYMENT_DESC_OTHER
                                              "
                                              type="text"
                                              class="form-control mt-2"
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <!-- <div
                                            v-if="
                                              addupdatesellerapdata.PAYMENT_DESC ==
                                              'Finance Settlement'
                                            "
                                            class="col-md-12 mt-2"
                                          >
                                            <div class="row">
                                              <div class="col-md-4">
                                                <label class="frm-label-2 align-right">
                                                  Serial Number
                                                </label>
                                              </div>
                                              <div class="col-md-8">
                                                <input
                                                  v-model="
                                                    addupdatesellerapdata.PURCHASE_SETTLEMENT_NO
                                                  "
                                                  type="text"
                                                  class="form-control mt-2"
                                                  placeholder=""
                                                />
                                              </div>
                                            </div>
                                          </div> -->
                                      <div class="col-md-12 mt-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label
                                              class="frm-label-2 align-right"
                                            >
                                              Bank
                                            </label>
                                          </div>
                                          <div class="col-md-8">
                                            <select
                                              v-model="
                                                addupdatesellerapdata.BANK_SYS_ID
                                              "
                                              @change="getBankNameForAPPayment"
                                              class="form-control"
                                            >
                                              <option value="">Select</option>
                                              <option
                                                v-for="item in Banklist"
                                                :value="item.BANK_SYS_ID"
                                                :key="item.BANK_SYS_ID"
                                              >
                                                {{ item.BANK_NAME }}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="col-md-12 mt-2"
                                        v-if="
                                          addupdatesellerapdata.PAYMENT_MODE ==
                                          'Cheque'
                                        "
                                      >
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label
                                              class="frm-label-2 align-right"
                                            >
                                              Cheque Number
                                            </label>
                                          </div>
                                          <div class="col-md-8">
                                            <input
                                              v-model="
                                                addupdatesellerapdata.CHEQUE_NO
                                              "
                                              type="text"
                                              class="form-control"
                                              placeholder="Enter Cheque No."
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-12 mt-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label
                                              class="frm-label-2 align-right"
                                            >
                                              To/From Who
                                            </label>
                                          </div>
                                          <div
                                            v-if="
                                              addupdatesellerapdata.PAYMENT_DESC !=
                                              'Finance Settlement'
                                            "
                                            class="col-md-8"
                                          >
                                            <input
                                              v-model="
                                                addupdatesellerapdata.TO_FROM_WHOM
                                              "
                                              type="text"
                                              class="form-control"
                                            />
                                          </div>
                                          <div
                                            v-if="
                                              addupdatesellerapdata.PAYMENT_DESC ==
                                              'Finance Settlement'
                                            "
                                            class="col-md-8"
                                          >
                                            <select
                                              class="form-control roleddl"
                                              v-model="
                                                addupdatesellerapdata.TO_FROM_WHOM
                                              "
                                            >
                                              <option value="">Select</option>
                                              <option
                                                v-for="item in finance_company_bank_details"
                                                :value="
                                                  item.FINANCE_COMPANY_BANK_NAME
                                                "
                                                :key="
                                                  item.FINANCE_COMPANY_BANK_SYS_ID
                                                "
                                              >
                                                {{
                                                  item.FINANCE_COMPANY_BANK_NAME
                                                }}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-12 mt-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label
                                              class="frm-label-2 align-right"
                                            >
                                              AP Balance($)
                                            </label>
                                          </div>
                                          <div class="col-md-8">
                                            <input
                                              type="text"
                                              disabled
                                              v-model="
                                                SELLER_AP_REDUCED_RECEIVABLE_AMOUNT
                                              "
                                              class="form-control"
                                              placeholder="Enter AP Balance"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                      <div class="col-md-12 mt-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label
                                              class="frm-label-2 align-right"
                                            >
                                              Amount($)<span style="color: red"
                                                >*</span
                                              >
                                            </label>
                                          </div>
                                          <div class="col-md-8">
                                            <input
                                              v-model="
                                                addupdatesellerapdata.AMOUNT
                                              "
                                              @keyup="
                                                addAPAmountCalculationForSeller()
                                              "
                                              type="text"
                                              class="form-control"
                                              placeholder="Enter Amount"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-12 mt-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label
                                              class="frm-label-2 align-right"
                                            >
                                              Payment Mode
                                              <span style="color: red">*</span>
                                            </label>
                                          </div>
                                          <div class="col-md-8">
                                            <select
                                              v-model="
                                                addupdatesellerapdata.PAYMENT_MODE
                                              "
                                              class="form-control"
                                            >
                                              <option value="">Select</option>
                                              <option value="GIRO">GIRO</option>
                                              <option value="TT">TT</option>
                                              <option value="Cheque">
                                                Cheque
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-12 mt-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label
                                              class="frm-label-2 align-right"
                                            >
                                              Payment Date
                                            </label>
                                          </div>
                                          <div class="col-md-8">
                                            <input
                                              v-model="
                                                addupdatesellerapdata.CHEQUE_DATE
                                              "
                                              type="text"
                                              class="form-control"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-12 mt-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <label
                                              class="frm-label-2 align-right"
                                            >
                                              Remarks
                                            </label>
                                          </div>
                                          <div class="col-md-8">
                                            <textarea
                                              v-model="
                                                addupdatesellerapdata.REMARKS
                                              "
                                              class="custom-textarea form-control"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="row mt-4">
                                      <div class="col-md-4">
                                        <button
                                          style="width: 120px"
                                          @click="addSellerAPPaymentDetails()"
                                          type="button"
                                          class="custom-btn custom-success-btn"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Seller AP Payment Table Section -->
                      <div class="master-table">
                        <b-table
                          striped
                          hover
                          :items="sellerapdetails"
                          :fields="SellerAPFields"
                          class="table table-bordered mt-3"
                        >
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>
                          <template v-slot:cell(Action)="row">
                            <div class="text-nowrap table-btn">
                              <button
                                type="button"
                                class="custom-btn p-0"
                                data-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                @click="
                                  UpdateModalShow(
                                    row.item,
                                    '#sellerAPPaymentUpdateModal'
                                  )
                                "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-edit"
                                >
                                  <path
                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                  ></path>
                                  <path
                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                type="button"
                                class="custom-btn p-0"
                                @click="deleteSellerAPPaymentDetails(row.item)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                  ></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                            </div>
                          </template>
                        </b-table>
                      </div>

                      <!-- Seller Below Section Form-->
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Asking Price ($)
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdatesellerdata.ASKING_PRICE"
                                type="text"
                                class="form-control"
                                placeholder="Enter Asking Price"
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Alternate Price($)
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                disabled
                                v-model="addupdatesellerdata.ALTERNATE_PRICE_SD"
                                type="number"
                                class="form-control"
                                placeholder="Alternate Price"
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Salesman Code
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="
                                  addupdatesellerdata.SALES_PERSON_SYS_ID
                                "
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option
                                  v-for="broker in brokerdetails"
                                  :value="broker.USER_SYS_ID"
                                  :key="broker.USER_SYS_ID"
                                >
                                  {{ broker.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <!-- <div class="row mt-2">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right">
                                        Trade In By
                                      </label>
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="addupdatesellerdata.TRADE_IN_BY"
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                </div> -->
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Broker 1
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatesellerdata.BROKER_1"
                                class="form-control"
                              >
                                <option value="">Select Broker 1</option>
                                <option
                                  v-for="broker in brokerdetails"
                                  :value="broker.USER_SYS_ID"
                                  :key="broker.USER_SYS_ID"
                                >
                                  {{ broker.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Broker 2
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatesellerdata.BROKER_2"
                                class="form-control"
                              >
                                <option value="">Select Broker 2</option>
                                <option
                                  v-for="broker in brokerdetails"
                                  :value="broker.USER_SYS_ID"
                                  :key="broker.USER_SYS_ID"
                                >
                                  {{ broker.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Com.Rtn.($)
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdatesellerdata.COM_RTN_1"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Commission Date
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdatesellerdata.RETURN_DATE_2"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <br />
                          <br />
                          <br />

                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Broker Deal
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                @change="onChangeBrokerDeal"
                                v-model="addupdatesellerdata.BROKER_DEAL"
                                type="checkbox"
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Transaction Type
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatesellerdata.TRANSACTION_TYPE"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="Local">Local</option>
                                <option value="Ets">Ets</option>
                                <option value="New Vehicle">New Vehicle</option>
                                <option value="Scrap">Scrap</option>
                              </select>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                E-Transfer By
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatesellerdata.E_TRANSFER_BY"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option
                                  v-for="InternalUser in InternalUserList"
                                  :value="InternalUser.USER_SYS_ID"
                                  :key="InternalUser.USER_SYS_ID"
                                >
                                  {{ InternalUser.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                E-Transfer In Date
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdatesellerdata.E_TRANSFER_IN_DATE"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Log Card (Transfer In)
                              </label>
                            </div>
                            <div class="col-md-6">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="customLogCardInFile"
                                accept="application/pdf"
                                ref="logCardInFile"
                                @change="handleLogCardInFileObject()"
                              />
                            </div>
                            <div
                              class="col-md-2"
                              v-if="isDisabledLogCardIn == true"
                            >
                              <button
                                style="margin-left: 27px"
                                type="button"
                                class="custom-btn me-1 p-0"
                                v-b-modal.log_card_preview_modal
                                @click="
                                  previewLogCardPdfFile(
                                    'SELLER_LOG_CARD_TRANSFER_IN'
                                  )
                                "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-eye"
                                >
                                  <path
                                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                  ></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4"></div>
                            <div class="col-md-6">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="customLogCardIn2File"
                                accept="application/pdf"
                                ref="logCardIn2File"
                                @change="handleLogCardIn2FileObject()"
                              />
                            </div>
                            <div
                              class="col-md-2"
                              v-if="isDisabledLogCardIn2 == true"
                            >
                              <button
                                style="margin-left: 27px"
                                type="button"
                                class="custom-btn me-1 p-0"
                                v-b-modal.log_card_preview_modal
                                @click="
                                  previewLogCardPdfFile(
                                    'SELLER_LOG_CARD_TRANSFER_IN_2'
                                  )
                                "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-eye"
                                >
                                  <path
                                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                  ></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-4">
                          <button
                            style="width: 120px"
                            type="button"
                            @click="addUpdateSellerDetails"
                            class="custom-btn custom-success-btn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                   
                    
                    </b-tab>

                    <!-- Purchase Settlement Tab -->
                    <b-tab
                      title="Purchase Settlement"
                      @click="getPurchasesNoteDetails"
                    >
                      <div
                        class="col-md-12"
                        style="text-align: right"
                        ref="purchase-settlement"
                      >
                        <button
                          class="downld animate__animated animate__bounceIn"
                          @click="generateReportPurchase"
                        >
                          <i
                            class="fa-solid fa-cloud-arrow-down"
                            style="color: blue"
                          ></i>
                          Download
                        </button>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-2 mt-2">
                          <label class="frm-label">Invoice No.</label>
                        </div>
                        <div class="col-md-2 p-0">
                          <input
                            style="height: 3em"
                            type="text"
                            v-model="
                              purchasesettlement.PURCHASE_SETTLEMENT_INVOICE_NO
                            "
                            class="form-control"
                            placeholder="Enter Invoice No."
                          />
                        </div>
                        <div class="col-md-1 mt-2">
                          <label class="frm-label">Date</label>
                        </div>
                        <div class="col-md-2 p-0">
                          <input
                            style="height: 3em"
                            v-model="
                              purchasesettlement.PURCHASE_SETTLEMENT_INVOICE_DATE
                            "
                            type="text"
                            class="form-control"
                            placeholder="Enter Date"
                          />
                        </div>
                        <div class="col-md-2 ">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            @click="pdfdatapurchaseseeelement"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-3 ">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="
                                freezeInvoice('TAX_COMMISSION_INVOICE'),
                                sellerfredge()
                              "
                            >
                              Freeze Invoice
                            </button>
                          </div>
                      </div>
                      <div
                        class="content purchase-settlement-note-cont"
                        style="margin-top: 10px"
                        ref="purchase-settlement"
                      >
                        <div class="content-header mt-4">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div
                                  class="col-md-3"
                                  style="text-align: center"
                                >
                                  <img
                                    src="../../assets/images/icon.png"
                                    class="img-fluid header-img"
                                    alt="#"
                                  />
                                </div>
                                <div class="col-md-9 p-0">
                                  <ul>
                                    <li>
                                      <img
                                        src="../../assets/images/logo-txt.png"
                                      />
                                    </li>
                                    <li class="mt-2">
                                      <p>
                                        1 Sunview Road #01-46 Eco-Tech@Sunview
                                        Singapore 627615
                                      </p>
                                    </li>
                                    <li>
                                      <p>ROC No./ GST Reg No:. 201929524C</p>
                                    </li>
                                    <li><p>Tel: 62395191 Fax: 62395190</p></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-6" style="text-align: end">
                              <h4 class="fw-bold">
                                <span
                                  style="
                                    border: 1.5px solid #212121;
                                    padding: 4px 10px;
                                  "
                                  >PURCHASE SETTLEMENT</span
                                >
                              </h4>
                              <!-- <div class="heading">
                                  <p>PURCHASE SETTLEMENT</p>
                                </div> -->
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3 ml-2">
                          <div class="col-md-8">
                            <p>
                              Serial No&nbsp;
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.PurchaseNoteDetails
                                    .PURCHASE_SETTLEMENT_NO
                                }}</span
                              >
                            </p>
                          </div>
                          <div class="col-md-2" style="text-align: right">
                            Date
                          </div>
                          <div class="col-md-2">
                            <p>
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.PurchaseNoteDetails.INVOICE_DATE
                                }}</span
                              >
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2 ml-2">
                          <div class="col-md-8">
                            <p>
                              I/We("The Seller")&nbsp;
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.PurchaseNoteDetails.CUSTOMER_NAME
                                }}</span
                              >
                            </p>
                          </div>
                          <div class="col-md-2" style="text-align: right">
                            NRIC/ROC
                          </div>
                          <div class="col-md-2">
                            <p>
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.PurchaseNoteDetails.NRIC_ROC
                                }}</span
                              >
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2 ml-2">
                          <div class="col-md-9">
                            <p>
                              Address&nbsp;
                              <span style="font-weight: bold">
                                :&nbsp;{{
                                  this.PurchaseNoteDetails.ADDRESS
                                }}</span
                              >
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2 ml-2">
                          <div class="col-md-12">
                            <p>
                              agree to sell the following vehicle to Lek Auto
                              Pte Ltd ("Buyer")
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2" style="padding: 1em">
                          <div
                            class="col-md-10"
                            style="border: 3px solid #212121"
                          >
                            <p>
                              <span style="font-weight: bold">DESCRIPTION</span>
                            </p>
                          </div>
                          <div
                            class="col-md-2"
                            style="
                              border-right: 3px solid #212121;
                              border-bottom: 3px solid #212121;
                              border-top: 3px solid #212121;
                            "
                          >
                            <p>
                              <span style="font-weight: bold">Amount(S$)</span>
                            </p>
                          </div>
                          <div
                            class="col-md-10"
                            style="
                              border-left: 3px solid #212121;
                              border-right: 3px solid #212121;
                              border-bottom: 3px solid #212121;
                            "
                          >
                            <div class="row mt-2">
                              <div class="col-md-3"><p>Vehicle No:</p></div>
                              <div class="col-md-3">
                                <p>
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.VEHICLE_NUMBER
                                  }}</span>
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3"><p>Make & Model:</p></div>
                              <div class="col-md-9">
                                <p>
                                  <span style="font-weight: bold"
                                    >{{ this.PurchaseNoteDetails.MAKE_NAME }}-{{
                                      this.PurchaseNoteDetails.MODEL_NAME
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3"><p>Chassis No:</p></div>
                              <div class="col-md-3">
                                <p>
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.CHASIS_NO
                                  }}</span>
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3">
                                <p>Engine No./Motor No.:</p>
                              </div>
                              <div class="col-md-3">
                                <p>
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.ENGINE_NO
                                  }}</span>
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3">
                                <p>Vehicle Price:</p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3"><p>Less:</p></div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Deposit(<span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.CUSTOMER_NAME
                                  }}</span
                                  >):
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Finance Company (<span
                                    style="font-weight: bold"
                                    >{{
                                      this.PurchaseNoteDetails.FINANCE_COMPANY
                                    }}</span
                                  >) &nbsp;<span>Full Settlement Amount:</span>
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Full Payment
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.CUSTOMER_NAME
                                  }}</span
                                  >:
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Balance issued to
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.BALANCE_ISSUED_TO
                                  }}</span
                                  >:
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-2"
                            style="
                              border-right: 3px solid #212121;
                              border-bottom: 3px solid #212121;
                            "
                          >
                            <div class="row mt-2">
                              <div class="col-md-12"><p></p></div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12"></div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12"></div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12"></div>
                            </div>
                            <div class="row" style="margin-top: 6em !important">
                              <div class="col-md-12">
                                <p><span style="font-weight: bold"></span></p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails.VEHICLE_PRICE
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="row" style="margin-top: 2.5em">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails.DEPOSIT_AMOUNT
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>

                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails
                                        .FULL_SETTLEMENT_AMOUNT
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails
                                        .FULL_PAYMENT_AMOUNT
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails.BALANCE
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-1" style="padding: 1em">
                          <div
                            class="col-md-12"
                            style="border: 3px solid #212121"
                          >
                            <div class="row">
                              <div class="col-md-12">
                                <u><b>Vehicle Delivery Acknowledgement</b></u>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Handover on (date)&nbsp;<b
                                    style="font-weight: bold"
                                    >{{
                                      this.PurchaseNoteDetails.DELIVERY_IN_DATE
                                    }}</b
                                  >&nbsp;(time) &nbsp;<b
                                    style="font-weight: bold"
                                    >{{
                                      this.PurchaseNoteDetails.DELIVERY_IN_TIME
                                    }}</b
                                  >
                                  .
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  *Seller has agreed to handover the mentioned
                                  vehicle to Buyer at the above mentioned date &
                                  time and free from all encumbrance or
                                  whatever.
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  *Seller shall be responsible for any traffic
                                  offences and illegal acts arising from the use
                                  thereof.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-8">
                                <p>Signed for & behalf of</p>
                              </div>
                              <div class="col-md-4">
                                <p>Agreed & Accepted by Seller</p>
                              </div>
                              <div class="col-md-12 mt-2">
                                <p>Lek Auto Pte Ltd</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" style="margin-top: 6em">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-7">
                                <span
                                  style="
                                    border-top: 2px solid #000;
                                    width: 100px;
                                    font-weight: bold;
                                  "
                                >
                                  Authorised Signature
                                </span>
                                <p>
                                  Salesman:&nbsp;<b style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.INCHARGE_NAME
                                  }}</b>
                                </p>
                                <p>
                                  HP:&nbsp;<b style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.INCHARGE_CONTACT
                                  }}</b>
                                </p>
                              </div>
                              <div class="col-md-5">
                                <span
                                  style="
                                    border-top: 2px solid #000;
                                    width: 100px;
                                    font-weight: bold;
                                  "
                                >
                                  Authorised Signature & Company Stamp
                                </span>
                                <p>Name:</p>
                                <p>Contact No.:</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
                <!-- Seller Details Section End -->

                <!-- Buyer/ETS Transaction Details Section Start-->
                <div class="col-md-12 mt-2" id="buyer_det" ref="buyer-details">
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="line-heading"><span>Buyer Details</span></h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="line-heading">
                        <span>ETS Transaction Details</span>
                      </h5>
                    </div>
                    <!-- Buyer Upper Details Add Form -->
                    <div class="col-md-6 left-side-frm">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Buyer Type
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatebuyerdata.CUSTOMER_TYPE"
                                @change="onChnageBuyerTypeFun"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="Vendor">Vendor</option>
                                <option value="Customer">Customer</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Buyer Details Vendor -->
                      <div
                        v-if="
                          addupdatebuyerdata &&
                          addupdatebuyerdata.CUSTOMER_TYPE == 'Vendor'
                        "
                        class="col-md-12 mt-2 mb-2"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Vendor Info</label
                            >
                          </div>
                          <div class="col-md-8">
                            <b-input-group>
                              <b-form-input
                                id="BUYER_VENDOR_INFO"
                                @change="onChnageBuyerVendorInfo"
                                @keyup="onKeyUpBuyerVendorInfo"
                                v-model="BUYER_VENDOR_INFO"
                                placeholder="Enter vendor"
                                list="buyervendorlistOptions"
                              ></b-form-input>
                              <b-input-group-append>
                                <b-button
                                  variant="info"
                                  data-bs-toggle="modal"
                                  data-bs-target="#vendor_modal"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <datalist id="buyervendorlistOptions">
                              <option
                                v-for="vendor_info in AllBuyerVendorDetailsArray"
                                :value="vendor_info.VENDOR_NAME"
                                :key="vendor_info.VENDOR_SYS_ID"
                                :id="vendor_info.VENDOR_SYS_ID"
                              ></option>
                            </datalist>
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerVendorCode != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Vendor Code</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerVendorCode"
                              id="Buyer VendorCode"
                              name="Buyer VendorCode"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerVendorName != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Vendor Name</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerVendorName"
                              id="VendorName"
                              name="VendorName"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerVendorAddress != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Vendor Address</label
                            >
                          </div>
                          <div class="col-md-8">
                            <textarea
                              v-model="DisplayBuyerVendorAddress"
                              style="height: 6em"
                              id="Seller Vendor Address"
                              name="Seller Vendor Address"
                              class="form-control"
                              disabled
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>

                      <!-- Buyer Details Customer -->
                      <div
                        v-if="
                          addupdatebuyerdata &&
                          addupdatebuyerdata.CUSTOMER_TYPE == 'Customer'
                        "
                        class="col-md-12 mt-2 mb-2"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Info</label
                            >
                          </div>
                          <div class="col-md-8">
                            <b-input-group>
                              <b-form-input
                                id="BUYER_CUSTOMER_INFO"
                                @change="onChnageBuyerCustomerInfo"
                                @keyup="onKeyUpBuyerCustomerInfo"
                                v-model="BUYER_CUSTOMER_INFO"
                                placeholder="Enter customer"
                                list="buyercustomerlistOptions"
                              ></b-form-input>
                              <b-input-group-append>
                                <b-button
                                  variant="info"
                                  data-bs-toggle="modal"
                                  data-bs-target="#employee_modal"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <datalist id="buyercustomerlistOptions">
                              <option
                                v-for="buyer_customer_info in AllBuyerCustomerDetailsArray"
                                :value="buyer_customer_info.CUSTOMER_NAME"
                                :key="buyer_customer_info.ID_NUMBER"
                              ></option>
                            </datalist>
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerCustomerType != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Type</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerCustomerType"
                              id="Seller Customer Type"
                              name="Seller Customer Type"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="
                            DisplayBuyerCustomerType == 'individual' ||
                            DisplayBuyerCustomerType == 'INDIVIDUAL'
                          "
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >NRIC Number</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerCustomerIdNumber"
                              id="Seller NRIC Number"
                              name="Seller NRIC Number"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="
                            DisplayBuyerCustomerType == 'Company' ||
                            DisplayBuyerCustomerType == 'COMPANY'
                          "
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >UEN Number</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerCustomerIdNumber"
                              id="Seller UEN Number"
                              name="Seller UEN Number"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerCustomerName != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Name</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerCustomerName"
                              id="VendorName"
                              name="VendorName"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerCustomerAddress != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Address</label
                            >
                          </div>
                          <div class="col-md-8">
                            <textarea
                              v-model="DisplayBuyerCustomerAddress"
                              style="height: 4em"
                              id="Seller Customer Address"
                              name="Seller Customer Address"
                              class="form-control"
                              disabled
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Delivery Out Date & Time</label
                            >
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-6">
                                <input
                                  v-model="addupdatebuyerdata.DELIVERY_OUT_DATE"
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                              <div class="col-md-6">
                                <input
                                  v-model="addupdatebuyerdata.TIME"
                                  type="time"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Invoice Number</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.INVOICE_NUMBER"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Invoice Date</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.INVOICE_DATE"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div> -->
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >P&L Date</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              disabled
                              v-model="addupdatebuyerdata.P_L_DATE"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Booking Date</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.BOOKING_DATE"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>

                         <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Admin Fee</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.ADMIN_FEE"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >DN/CN Number
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.DN_CN_NUMBER"
                              type="text"
                              class="form-control"
                              placeholder="Enter DN/CN No."
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >DN/CN Amount($)
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.DN_CN_AMOUNT"
                              type="number"
                              class="form-control"
                              placeholder="Enter DN/CN Amount"
                            />
                          </div>
                          <div class="col-md-4"></div>
                          <div class="col-md-8"></div>
                        </div>
                      </div> -->
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Sales Person</label
                            >
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="addupdatebuyerdata.SALES_PERSON_SYS_ID"
                              class="form-control"
                            >
                              <option value="">Select</option>
                              <option
                                v-for="broke in brokerdetails"
                                :value="broke.USER_SYS_ID"
                                :key="broke.USER_SYS_ID"
                              >
                                {{ broke.PERSON_NAME }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Sales Agreement Number</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="
                                addupdatebuyerdata.SALES_AGREEMENT_NUMBER
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Sales Agreement No."
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4"></div>
                          <div class="col-md-8"></div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Price Amount (with gst amount)<span
                                style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.SELL_PRICE"
                              type="number"
                              class="form-control"
                              placeholder="Enter Price Amount"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >GST($)<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="addupdatebuyerdata.TAX_SYS_ID"
                              @change="globalCalculateGST('BUYER_DETAILS')"
                              class="form-control"
                            >
                              <option value="">Select</option>
                              <option
                                v-for="tax_info in tax_info_detai"
                                :value="tax_info.TAX_SYS_ID"
                                :key="tax_info.TAX_SYS_ID"
                              >
                                {{ tax_info.TAX_NAME }}-{{
                                  tax_info.TAX_PERCENTAGE
                                }}
                                %
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >GST Decimal Adjustment($) (+1/-1)</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="
                                addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT
                              "
                              @keyup="globalDecimalAdjusment('BUYER_DETAILS')"
                              type="number"
                              min="-1"
                              max="1"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Sales Commission($)
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.COM_RTN_1"
                              type="number"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Sales Out Side Commission($)<span
                                style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="
                                addupdatebuyerdata.SALES_OUTSIDE_COMMISSION
                              "
                              type="number"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-left"
                              >GST Amount Before Adjustment($)</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="
                                addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT
                              "
                              disabled
                              type="text"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >GST Amount($)<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.GST_AMOUNT"
                              type="text"
                              disabled
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12 mt-2"
                        v-if="GLOBAL_TAX_TYPE == 'NEW'"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Non Taxable Supplies<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.NON_TAXABLE_SUPPLIES"
                              type="text"
                              disabled
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12 mt-2"
                        v-if="GLOBAL_TAX_TYPE == 'NEW'"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Taxable Supplies<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.TAXABLE_SUPPLIES"
                              type="text"
                              disabled
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Selling Price($) (without gst )<span
                                style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              disabled
                              v-model="addupdatebuyerdata.SELLING_PRICE"
                              type="number"
                              class="form-control"
                              placeholder="Enter Selling Price"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Total Selling Price($)<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.TOTAL_SELLING_PRICE"
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Enter Total Selling Price"
                            />
                          </div>
                          <div class="row mt-4">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                @click="addBuyerUpperSectionDetails()"
                                type="button"
                                class="custom-btn custom-success-btn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- ETS Transaction Details Add Form -->
                    <div class="col-md-6">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Buy Over Date</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.BUY_OVER_DATE"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Fight PL</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.FIGHT_PL"
                                type="checkbox"
                                class="me-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >ETS Paper To</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.ETS_PAPER_TO"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Body Price($)<span style="color: red"
                                  >*</span
                                ></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.BODY_PRICE"
                                type="number"
                                class="form-control"
                                placeholder="Enter Body Price"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Dereg Date</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.DEREG_DATE"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >COE Encashment($)</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.COE_ENCASHMENT"
                                type="number"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Parf Encashment</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.PARF_ENCASHMENT"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >ETS Transfer Value</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.ETS_TRANSFER_VALUE"
                                type="input"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >ETS Paper(External)</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.ETS_PAPER_EXTERNAL"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Invoice Number 2</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.INVOICE_NUMBER_2"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Invoice date 2</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.INVOICE_DATE_2"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >ETS Paper Buyer
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdateetsdata.TO_FROM_WHO"
                                @change="onChnageETSPaperBuyer"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="Vendor">Vendor</option>
                                <option value="Customer">Customer</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="addupdateetsdata.TO_FROM_WHO == 'Vendor'"
                          class="col-md-12 mt-2 mb-2"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Vendor Info</label
                              >
                            </div>
                            <div class="col-md-8">
                              <b-input-group>
                                <b-form-input
                                  id="ETS_VENDOR_INFO"
                                  @change="onChnageETSVendorInfo"
                                  @keyup="onKeyUpETSVendorInfo"
                                  v-model="ETS_VENDOR_INFO"
                                  placeholder="Enter vendor"
                                  list="etsvendorlistOptions"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    variant="info"
                                    data-bs-toggle="modal"
                                    data-bs-target="#vendor_modal"
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                              <datalist id="etsvendorlistOptions">
                                <option
                                  v-for="vendor_info in AllEtsVendorDetailsArray"
                                  :value="vendor_info.VENDOR_NAME"
                                  :key="vendor_info.VENDOR_SYS_ID"
                                  :id="vendor_info.VENDOR_SYS_ID"
                                ></option>
                              </datalist>
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsVendorCode != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Vendor Code</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsVendorCode"
                                id="Buyer VendorCode"
                                name="Buyer VendorCode"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsVendorName != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Vendor Name</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsVendorName"
                                id="VendorName"
                                name="VendorName"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsVendorAddress != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Vendor Address</label
                              >
                            </div>
                            <div class="col-md-8">
                              <textarea
                                v-model="DisplayEtsVendorAddress"
                                style="height: 6em"
                                id="Seller Vendor Address"
                                name="Seller Vendor Address"
                                class="form-control"
                                disabled
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="addupdateetsdata.TO_FROM_WHO == 'Customer'"
                          class="col-md-12 mt-2 mb-2"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Customer Info</label
                              >
                            </div>
                            <div class="col-md-8">
                              <b-input-group>
                                <b-form-input
                                  id="ETS_CUSTOMER_INFO"
                                  @change="onChnageETSCustomerInfo"
                                  @keyup="onKeyUpETSCustomerInfo"
                                  v-model="ETS_CUSTOMER_INFO"
                                  placeholder="Enter customer"
                                  list="EtscustomerlistOptions"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button
                                    variant="info"
                                    data-bs-toggle="modal"
                                    data-bs-target="#employee_modal"
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                              <datalist id="EtscustomerlistOptions">
                                <option
                                  v-for="buyer_customer_info in AllEtsCustomerDetailsArray"
                                  :value="buyer_customer_info.CUSTOMER_NAME"
                                  :key="buyer_customer_info.ID_NUMBER"
                                ></option>
                              </datalist>
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsCustomerType != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Customer Type</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsCustomerType"
                                id="Seller Customer Type"
                                name="Seller Customer Type"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="
                              DisplayEtsCustomerType == 'individual' ||
                              DisplayEtsCustomerType == 'INDIVIDUAL'
                            "
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >NRIC Number</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsCustomerIdNumber"
                                id="Seller NRIC Number"
                                name="Seller NRIC Number"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="
                              DisplayEtsCustomerType == 'Company' ||
                              DisplayEtsCustomerType == 'COMPANY'
                            "
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >UEN Number</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsCustomerIdNumber"
                                id="Seller UEN Number"
                                name="Seller UEN Number"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsCustomerName != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Customer Name</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsCustomerName"
                                id="VendorName"
                                name="VendorName"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsCustomerAddress != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Customer Address</label
                              >
                            </div>
                            <div class="col-md-8">
                              <textarea
                                v-model="DisplayEtsCustomerAddress"
                                style="height: 4em"
                                id="Seller Customer Address"
                                name="Seller Customer Address"
                                class="form-control"
                                disabled
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-4">
                          <button
                            style="width: 120px"
                            @click="addUpdateETSTransactionDetails"
                            type="button"
                            class="custom-btn custom-success-btn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="divider-2"></div>
                </div>
                <!-- Buyer AR Payment Section -->
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-3">
                        <button
                          class="custom-btn add-btn"
                          name=""
                          data-bs-toggle="collapse"
                          data-bs-target="#buyer-colapse"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <strong>Add Payment</strong>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-plus-circle"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="16"></line>
                            <line x1="8" y1="12" x2="16" y2="12"></line>
                          </svg>
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          @click="recalculateReceivablePayment('SELL')"
                          style="width: 120px"
                          type="button"
                          class="custom-btn custom-success-btn"
                        >
                          Recalculate
                        </button>
                      </div>
                      <div class="col-md-7">
                        <div class="row">
                          <div class="col-md-12">
                            <p style="font-weight: bold">
                              Note<span style="color: red"> *</span>&nbsp; Click
                              on recalculate button if any changes in these
                              fields
                            </p>
                          </div>
                          <div class="col-md-12" style="font-weight: bold">
                            <p style="margin-left: 3.4em">
                              Selling Price,Transfer Fee,Road Tax,Insurance
                              Premium, Advance Payment,HP Fee,Other Fee,Admin
                              Fee
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <span style="font-weight: 580"
                          >Total Receivable Amount:&nbsp;S$
                          <span style="font-weight: bold">{{
                            VEHICLE_TOTAL_RECEIVABLE_AMOUNT
                          }}</span></span
                        >
                      </div>
                      <div class="col-md-6">
                        <span style="font-weight: 580"
                          >Balance:&nbsp;S$
                          <span style="font-weight: bold">{{
                            VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT
                          }}</span></span
                        >
                      </div>
                    </div>
                    <div
                      id="buyer-colapse"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body p-2 mt-2">
                        <form>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-12 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right">
                                        AR Payment
                                        <span style="color: red">*</span>
                                      </label>
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        v-model="
                                          addupdatebuyerapdata.PAYMENT_DESC
                                        "
                                        class="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option value="Deposit">Deposit</option>
                                        <option value="Partial Payment">
                                          Partial Payment
                                        </option>
                                        <option value="Full Payment">
                                          Full Payment
                                        </option>
                                        <option value="Contra">Contra</option>
                                        <option value="Loan Disbursement">
                                          Loan Disbursement
                                        </option>
                                        <option value="Others">Others</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-if="
                                    addupdatebuyerapdata.PAYMENT_DESC ==
                                    'Others'
                                  "
                                  class="col-md-12 mt-2"
                                >
                                  <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          addupdatebuyerapdata.PAYMENT_DESC_OTHER
                                        "
                                        type="text"
                                        class="form-control mt-2"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Bank</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        v-model="
                                          addupdatebuyerapdata.BANK_SYS_ID
                                        "
                                        @change="getBankNameForBuyer"
                                        class="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option
                                          v-for="item in Banklist"
                                          :value="item.BANK_SYS_ID"
                                          :key="item.BANK_SYS_ID"
                                        >
                                          {{ item.BANK_NAME }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-md-12 mt-2"
                                  v-if="
                                    addupdatebuyerapdata.PAYMENT_MODE ==
                                    'Cheque'
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Cheque Number</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="addupdatebuyerapdata.CHEQUE_NO"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Cheque No."
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >To/From Who
                                        <span style="color: red">*</span></label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          addupdatebuyerapdata.TO_FROM_WHOM
                                        "
                                        type="text"
                                        class="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >AR Balance($)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        disabled
                                        v-model="AR_REDUCED_RECEIVABLE_AMOUNT"
                                        class="form-control"
                                        placeholder="Enter AR Balance"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-12 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Amount($)
                                        <span style="color: red">*</span></label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="addupdatebuyerapdata.AMOUNT"
                                        @keyup="addARAmountCalculation"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Amount"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Payment Mode
                                        <span style="color: red">*</span></label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        v-model="
                                          addupdatebuyerapdata.PAYMENT_MODE
                                        "
                                        class="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option value="GIRO">GIRO</option>
                                        <option value="TT">TT</option>
                                        <option value="Cheque">Cheque</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Payment Date
                                      </label>
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          addupdatebuyerapdata.CHEQUE_DATE
                                        "
                                        type="text"
                                        class="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Add Remarks</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <textarea
                                        v-model="addupdatebuyerapdata.REMARKS"
                                        class="custom-textarea form-control"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="row mt-4 mb-4">
                                <div class="col-md-4">
                                  <button
                                    style="width: 120px"
                                    @click="addBuyerARPaymentDetails()"
                                    type="button"
                                    class="custom-btn custom-success-btn"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="master-table">
                  <b-table
                    striped
                    hover
                    :items="buyerapdetails"
                    :fields="BuyerARFields"
                    class="table table-bordered mt-3"
                  >
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(Action)="row">
                      <div class="text-nowrap table-btn">
                        <button
                          type="button"
                          class="custom-btn p-0"
                          data-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          @click="
                            UpdateModalShow(
                              row.item,
                              '#buyerAPPaymentUpdateModal'
                            )
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-edit"
                          >
                            <path
                              d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                            ></path>
                            <path
                              d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                            ></path>
                          </svg>
                        </button>
                        <button
                          type="button"
                          class="custom-btn p-0"
                          @click="deleteBuyerARPayment(row.item)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-trash-2"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                              d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                            ></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </button>
                      </div>
                    </template>
                  </b-table>
                </div>
                <!-- Buyer Section Below Details Add Form -->
                <div class="row mt-2">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Total Receivable($)</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="addupdatebuyerdata.TOTAL_RECEIVABLE"
                          type="number"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Total Received($)</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="addupdatebuyerdata.TOTAL_RECEIVED"
                          type="number"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >AR balance</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="addupdatebuyerdata.AR_BALANCE"
                          type="number"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">Broker 1</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          disabled
                          v-model="addupdatebuyerdata.BROKER_1_SYS_ID"
                          class="form-control"
                        >
                          <option value="">Select Broker 1</option>
                          <option
                            v-for="broker in brokerdetails"
                            :value="broker.USER_SYS_ID"
                            :key="broker.USER_SYS_ID"
                          >
                            {{ broker.PERSON_NAME }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">Broker 2</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="addupdatebuyerdata.BROKER_2_SYS_ID"
                          class="form-control"
                        >
                          <option value="">Select Broker 1</option>
                          <option
                            v-for="broker in brokerdetails"
                            :value="broker.USER_SYS_ID"
                            :key="broker.USER_SYS_ID"
                          >
                            {{ broker.PERSON_NAME }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Deposit Pay With GST($)</label
                        >
                      </div>

                      <div class="col-md-8">
                        <input
                          @keyup="globalCalculateGST('BUYER_DEPOSIT_DETAILS')"
                          v-model="addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">GST</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          disabled
                          v-model="addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID"
                          @change="globalCalculateGST('BUYER_DEPOSIT_DETAILS')"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="tax_info in tax_info_detai"
                            :value="tax_info.TAX_SYS_ID"
                            :key="tax_info.TAX_SYS_ID"
                          >
                            {{ tax_info.TAX_NAME }}-{{
                              tax_info.TAX_PERCENTAGE
                            }}
                            %
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >GST Amount($)</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          disabled
                          v-model="addupdatebuyerdata.DEPOSIT_TAX_AMOUNT"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Deposit Pay Without GST($)</label
                        >
                      </div>

                      <div class="col-md-8">
                        <input
                          disabled
                          v-model="
                            addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX
                          "
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Total Deposit Pay($)</label
                        >
                      </div>

                      <div class="col-md-8">
                        <input
                          disabled
                          v-model="addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">Remarks</label>
                      </div>
                      <div class="col-md-8">
                        <textarea
                          style="height: 80px !important"
                          v-model="addupdatebuyerdata.REMARKS"
                          class="custom-textarea form-control"
                        ></textarea>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Transaction Type</label
                        >
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="addupdatebuyerdata.TRANSACTION_TYPE"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option value="Pre Order">Pre Order</option>
                          <option value="New Vehicle">New Vehicle</option>
                          <option value="Local">Local</option>
                          <option value="ETS">ETS</option>
                          <option value="Scrap">Scrap</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Transfer Out By2</label
                        >
                      </div>
                      <div class="col-md-8">
                        <!-- <input
                          v-model="addupdatebuyerdata.TRANSFER_OUT_BY"
                          type="text"
                          class="form-control"
                          placeholder=""
                        /> -->
                        <select
                          v-model="addupdatebuyerdata.TRANSFER_OUT_BY"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="InternalUser in InternalUserList"
                            :value="InternalUser.USER_SYS_ID"
                            :key="InternalUser.USER_SYS_ID"
                          >
                            {{ InternalUser.PERSON_NAME }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >E-Transfer Out Date</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="addupdatebuyerdata.E_TRANSFER_OUT_DATE"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Log Card(Transfer Out)</label
                        >
                      </div>
                      <div class="col-md-6">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="customLogCardInFile"
                          accept="application/pdf"
                          ref="logCardOutFile"
                          @change="handleLogCardOutFileObject()"
                        />
                      </div>
                      <div class="col-md-2" v-if="isDisabledLogCardOut == true">
                        <button
                          style="margin-left: 27px"
                          type="button"
                          class="custom-btn me-1 p-0"
                          v-b-modal.log_card_preview_modal
                          @click="
                            previewLogCardPdfFile('BUYER_LOG_CARD_TRANSFER_OUT')
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-eye"
                          >
                            <path
                              d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                            ></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4"></div>
                      <div class="col-md-6">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="customLogCardInFile"
                          accept="application/pdf"
                          ref="logCardOut2File"
                          @change="handleLogCardOut2FileObject()"
                        />
                      </div>
                      <div
                        class="col-md-2"
                        v-if="isDisabledLogCardOut2 == true"
                      >
                        <button
                          style="margin-left: 27px"
                          type="button"
                          class="custom-btn me-1 p-0"
                          v-b-modal.log_card_preview_modal
                          @click="
                            previewLogCardPdfFile(
                              'BUYER_LOG_CARD_TRANSFER_OUT_2'
                            )
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-eye"
                          >
                            <path
                              d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                            ></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">Status</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="addupdatebuyerdata.STATUS"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option value="Pending">Pending</option>
                          <option value="Sold">Sold</option>
                          <option value="Handover">Handover</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-4">
                    <button
                      style="width: 120px"
                      @click="addUpdateBuyerBelowSectionDetails"
                      type="button"
                      class="custom-btn custom-success-btn"
                    >
                      Save
                    </button>
                  </div>
                </div>
                <!-- Buyer/ETS Transaction Details Section Start-->

                <!-- Sales Receipt Section Start -->
                <h5 class="line-heading" ref="sales-receipt">
                  <span>Sales Receipt</span>
                </h5>
                <div class="col-md-12" id="sales-receipt-section">
                  <div>
                    <b-tabs v-model="activeSalesTabIndex">
                      <!--Insurance Info Tab Section -->
                      <b-tab
                        title="Insurance Info"
                        @click="
                          getInsuranceCompanyDetails(GLOBAL_RECORD_SYS_ID)
                        "
                      >
                        <div class="col-md-12" ref="insurance-info">
                          <div class="row mt-1">
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Company Name</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <select
                                    class="form-control roleddl"
                                    v-model="
                                      InsuranceInfoDetails.INSURANCE_COMPANY_SYS_ID
                                    "
                                  >
                                    <option value="">select</option>
                                    <option
                                      v-for="insurance_company_detail in insurance_company_details"
                                      :key="
                                        insurance_company_detail.INSURANCE_COMPANY_SYS_ID
                                      "
                                      :value="
                                        insurance_company_detail.INSURANCE_COMPANY_SYS_ID
                                      "
                                    >
                                      {{
                                        insurance_company_detail.INSURANCE_COMPANY
                                      }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >Premium Amount
                                    <span style="color: red"></span
                                  ></label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="number"
                                    v-model="
                                      InsuranceInfoDetails.PREMIUM_AMOUNT
                                    "
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 mt-2">
                              <div class="row mt-4 mb-4">
                                <div class="col-md-4">
                                  <button
                                    style="width: 120px"
                                    type="button"
                                    class="custom-btn custom-success-btn"
                                    @click="createInsuranceDetails()"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Trade Info Tab Section -->
                      <b-tab
                        title="Trade Info"
                        @click="
                          getSearchAllVehicleDetailsForTradeInfo(),
                            getVechileTradeDetatils()
                        "
                      >
                        <div
                          aria-labelledby="flush-headingTwo"
                          class="mt-3"
                          ref="trade-info"
                        >
                          <div class="row">
                            <div class="col-md-3">
                              <button
                                class="custom-btn add-btn"
                                @click="onAddTradeInfo()"
                              >
                                <strong>Add Trade Info</strong>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-plus-circle"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="12" y1="8" x2="12" y2="16"></line>
                                  <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div
                            class="p-2"
                            v-for="(tradeInfo, tradeIndex) in multiTradeInfo"
                            :key="tradeIndex"
                          >
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Trade In Veh No.</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <b-input-group>
                                        <b-form-input
                                          :id="`SEARCH_VEHICLE_INFO-${tradeIndex}`"
                                          @change="onChnageTradeInVehicleInfo"
                                          @keyup="onKeyUpTradeInVehicleInfo"
                                          v-model="tradeInfo.TRADE_IN_VEH_NO"
                                          placeholder="Enter vehicle no"
                                          list="SearchAllVehiclelistOptions"
                                        ></b-form-input>
                                      </b-input-group>
                                      <datalist
                                        id="SearchAllVehiclelistOptions"
                                      >
                                        <option
                                          v-for="vehicle_info in search_all_vehicle_details"
                                          :value="vehicle_info.VEHICLE_NUMBER"
                                          :key="vehicle_info.RECORD_SYS_ID"
                                          :id="vehicle_info.RECORD_SYS_ID"
                                        ></option>
                                      </datalist>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Trade In Amount (-)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        :id="`TRADE_IN_AMOUNT_${tradeIndex}`"
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="tradeInfo.TRADE_IN_AMOUNT"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Payment Made (+)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        :id="`PAYMENT_MADE_${tradeIndex}`"
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="tradeInfo.PAYMENT_MADE"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label
                                        class="frm-label-2 align-right"
                                        style="text-align: end"
                                        >Full Settlement Reimbursement</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        :id="`FSR_${tradeIndex}`"
                                        type="number"
                                        class="form-control"
                                        v-model="
                                          tradeInfo.FULL_SETTLEMENT_REIMBURSEMENT
                                        "
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <div class="row mt-4 mb-4">
                                    <div class="col-md-4">
                                      <button
                                        style="width: 120px"
                                        type="button"
                                        class="custom-btn custom-success-btn"
                                        @click="AddTradeInfo(tradeInfo)"
                                      >
                                        Save
                                      </button>&nbsp;
                                      <button
                                        style="width: 120px;border: 1px solid #A25B5B"
                                        type="button"
                                        class="custom-btn btn-danger"
                                        @click="removeTradeInfoDetails(tradeInfo,tradeIndex)"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Others Payment Tab Section -->
                      <b-tab title="Other Payment" @click="getOtherPayment">
                        <!--Others Payment Add Form -->
                        <div class="col-md-12" ref="other-payment">
                          <div class="row mt-1">
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Item Description</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <select
                                    v-model="addotherpayment.DESCRIPTION"
                                    class="form-control"
                                  >
                                    <option value="">Select</option>
                                    <option value="Admin Fee">Admin Fee</option>
                                    <option value="HP Facilities Fee">
                                      HP Facilities Fee
                                    </option>
                                    <option value="Transfer Fee">
                                      Transfer Fee
                                    </option>
                                    <option value="Road Tax">Road Tax</option>
                                    <option value="Others Fee">
                                      Others Fee
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Rate With GST($)</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="number"
                                    @keyup="
                                      globalCalculateGST(
                                        'OTHER_PAYMENT_DETAILS'
                                      )
                                    "
                                    v-model="addotherpayment.UNIT_PRICE"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >TAX
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <select
                                    name="tax"
                                    v-model="addotherpayment.TAX_SYS_ID"
                                    class="form-control"
                                    @change="
                                      globalCalculateGST(
                                        'OTHER_PAYMENT_DETAILS'
                                      )
                                    "
                                  >
                                    <option value="">select</option>
                                    <option
                                      v-for="tax_in in tax_info_details"
                                      :value="tax_in.TAX_SYS_ID"
                                      :key="tax_in.TAX_SYS_ID"
                                    >
                                      {{ tax_in.TAX_NAME }}-{{
                                        tax_in.TAX_PERCENTAGE
                                      }}
                                      %
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Tax Amount</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    disabled
                                    v-model="addotherpayment.TAX_VALUE"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label
                                    class="frm-label-2 align-right"
                                    style="text-align: end"
                                    >Gross Amount($)</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    v-model="addotherpayment.TOTAL_PRICE"
                                    disabled
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label
                                    class="frm-label-2 align-right"
                                    style="text-align: end"
                                    >Rate Without GST($)</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    v-model="addotherpayment.RATE_WITHOUT_TAX"
                                    disabled
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-6 mt-2"
                              v-if="addotherpayment.DESCRIPTION == 'Others Fee'"
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <label
                                    class="frm-label-2 align-right"
                                    style="text-align: end"
                                    >Others Description</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <textarea
                                    v-model="addotherpayment.DESCRIPTION_OTHER"
                                    class="custom-textarea form-control"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4 mb-4">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                type="button"
                                class="custom-btn custom-success-btn"
                                @click="AddOtherPayment()"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>

                        <!--Others Payment Table View -->
                        <div class="content-body-wrapper mt-4">
                          <div class="page-content">
                            <div class="page-data">
                              <div class="row">
                                <div
                                  class="col-md-12 animate__animated animate__zoomIn"
                                >
                                  <div class="card-wrap shadow-main">
                                    <div class="card-wrap-body">
                                      <div class="data-table master-table">
                                        <b-table
                                          striped
                                          hover
                                          :items="OTHER_PAYMENT_DETAILS"
                                          :fields="OTHER_PAYMENT_DETAILS_FIELDS"
                                          class="table table-bordered mt-3"
                                        >
                                          <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                          </template>
                                          <template v-slot:cell(Action)="row">
                                            <div class="text-nowrap table-btn">
                                              <button
                                                type="button"
                                                class="custom-btn p-0"
                                                data-toggle="collapse"
                                                data-bs-target="#collapseTwo"
                                                @click="
                                                  UpdateModalShow(
                                                    row.item,
                                                    '#update_other_payment_modal'
                                                  )
                                                "
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  class="feather feather-edit"
                                                >
                                                  <path
                                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                                  ></path>
                                                  <path
                                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                                  ></path>
                                                </svg>
                                              </button>
                                              <button
                                                type="button"
                                                class="custom-btn p-0"
                                                @click="
                                                  deleteOtherPaymentDetails(
                                                    row.item
                                                  )
                                                "
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  class="feather feather-trash-2"
                                                >
                                                  <polyline
                                                    points="3 6 5 6 21 6"
                                                  ></polyline>
                                                  <path
                                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                                  ></path>
                                                  <line
                                                    x1="10"
                                                    y1="11"
                                                    x2="10"
                                                    y2="17"
                                                  ></line>
                                                  <line
                                                    x1="14"
                                                    y1="11"
                                                    x2="14"
                                                    y2="17"
                                                  ></line>
                                                </svg>
                                              </button>
                                            </div>
                                          </template>
                                        </b-table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Sales Receipt Tab Section -->
                      <b-tab
                        title="Sales Receipt"
                        @click="getSalesReceiptNoteDetails"
                      >
                        <div
                          class="col-md-12"
                          style="text-align: right"
                          ref="sales-receipt-tab"
                        >
                          <button
                            v-show="truesales"
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportsales"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-2 mt-2">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                salesinvoicedata.SALES_RECEIPT_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              v-model="
                                salesinvoicedata.SALES_RECEIPT_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                          <div class="col-md-2">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="AddInvoiceNoAndDateForSalesReceipt"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                          v-show="truesales"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div class="col-md-4" style="text-align: left">
                                <h4 class="fw-bold">
                                  <span
                                    style="
                                      border: 1.5px solid #212121;
                                      padding: 4px 10px;
                                    "
                                    >SALES RECEIPT</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <!-- header  -->
                            <div class="col-md-12">
                              <ul>
                                <li>
                                  <div class="row mt-2">
                                    <div class="col-md-8">
                                      To<span style="font-weight: bold"
                                        >&nbsp;:&nbsp;
                                        {{
                                          this.SalesReceiptNoteDetails
                                            .FIRST_NAME
                                        }}</span
                                      >
                                    </div>
                                    <div
                                      class="col-md-1"
                                      style="text-align: right"
                                    >
                                      <p>S-No</p>
                                    </div>
                                    <div class="col-md-3">
                                      <span style="font-weight: bold"
                                        >:&nbsp;
                                        {{
                                          this.SalesReceiptNoteDetails
                                            .INVOICE_NO
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="row mt-2">
                                    <div class="col-md-8">
                                      Address<span style="font-weight: bold"
                                        >&nbsp;:&nbsp;{{
                                          this.SalesReceiptNoteDetails
                                            .ADDRESS_LINE_1
                                        }}<br />
                                        <span
                                          style="
                                            font-weight: bold;
                                            margin-left: 35px;
                                          "
                                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                            this.SalesReceiptNoteDetails
                                              .COUNTRY_NAME
                                          }},
                                          {{
                                            this.SalesReceiptNoteDetails
                                              .PIN_CODE
                                          }}</span
                                        ></span
                                      >
                                    </div>
                                    <div
                                      class="col-md-1"
                                      style="text-align: right"
                                    >
                                      <p>Date</p>
                                    </div>
                                    <div class="col-md-3">
                                      <span style="font-weight: bold"
                                        >:&nbsp;
                                        {{
                                          this.SalesReceiptNoteDetails
                                            .INVOICE_DATE
                                        }}</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="row mt-2">
                                    <div class="col-md-7">
                                      Vehicle Registration No<span
                                        style="font-weight: bold"
                                        >&nbsp;:&nbsp;
                                        <span style="font-weight: bold">
                                          {{
                                            this.SalesReceiptNoteDetails
                                              .VEHICLE_NUMBER
                                          }}</span
                                        ></span
                                      >
                                    </div>
                                    <div
                                      class="col-md-2"
                                      style="text-align: right"
                                    >
                                      <p>Agreement No.</p>
                                    </div>
                                    <div class="col-md-3">
                                      <span style="font-weight: bold"
                                        >:&nbsp;
                                        {{
                                          this.SalesReceiptNoteDetails
                                            .SALES_AGREEMENT_NUMBER
                                        }}</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>

                            <!-- content body  -->
                            <div class="col-md-9" style="margin-top: 1em">
                              <div class="content-body-3">
                                <ul>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Selling Price.</p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .SELLING_PRICE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Transfer Fee</p>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .TRANSFER_FEE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Road Tax (1/2 yr)</p>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .ROAD_TAX
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>
                                          Insurance Premium (Comp/ TPFT/ TPO)-
                                          <span style="font-weight: bold">{{
                                            this.SalesReceiptNoteDetails
                                              .INSURANCE_COMPANY_NAME
                                          }}</span>
                                        </p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .INSURANCE_PREMIUM
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>HP Facilities Fees</p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .HP_FEE_UNIT_PRICE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p style="font-weight: bold">
                                          Loan Advance Payment
                                        </p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .ADVANCE_PAYMENT
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-1"><p>Others</p></div>
                                      <div
                                        class="col-md-8"
                                        style="text-align: left"
                                      >
                                        <u
                                          ><span style="font-weight: bold">
                                            {{
                                              this.SalesReceiptNoteDetails
                                                .OTHER_DESCRIPTION
                                            }}
                                          </span></u
                                        >
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .OTHER_FEE_UNIT_PRICE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Admin Fee</p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .ADMIN_FEE_UNIT_PRICE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p style="font-weight: bold">
                                          TOTAL PAYABLE
                                        </p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .FINAL_PREMIUM
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9"><p>Less</p></div>
                                    </div>
                                  </li>
                                  
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Deposit paid</p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .DEPOSIT_AMOUNT_WITH_TAX
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Full Payment</p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .FULL_PAYMENT_AMOUNT
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>Trade in Vehicle No.</p>
                                          </div>
                                          <div class="col-md-3 data">
                                            <div class="row">
                                              <div class="col-md-12">
                                                <span style="font-weight: bold">
                                                  {{
                                                    this.SalesReceiptNoteDetails
                                                      .TRADE_IN_VEH_NO
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              class="divider-sales-resceipt"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-12 mt-1">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <div
                                              class="row"
                                              style="margin-left: 2px"
                                            >
                                              <div
                                                class="col-md-6 data"
                                                style="width: 118px !important"
                                              >
                                                <div class="row">
                                                  <div class="col-md-2">
                                                    <span
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <span
                                                      style="font-weight: bold"
                                                    >
                                                      {{
                                                        this
                                                          .SalesReceiptNoteDetails
                                                          .TRADE_IN_AMOUNT
                                                      }}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div
                                                  class="divider-sales-resceipt"
                                                ></div>
                                              </div>
                                              <div class="col-md-6 p-0">
                                                <p>(Trade in Price)</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-5 ps-0">
                                            <div class="row">
                                              <div class="col-md-2">
                                                <p>Less</p>
                                                <div class="col-md-3 data">
                                                  <u
                                                    ><span
                                                      style="font-weight: bold"
                                                    ></span
                                                  ></u>
                                                </div>
                                              </div>
                                              <div
                                                class="col-md-4 data"
                                                style="width: 104px !important"
                                              >
                                                <div class="row">
                                                  <div class="col-md-2">
                                                    <span
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <span
                                                      style="font-weight: bold"
                                                    >
                                                      {{
                                                        this.SalesReceiptNoteDetails.PURCHASE_SETTLEMENT_AMOUNT
                                                      }}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div
                                                  class="divider-sales-resceipt"
                                                ></div>
                                              </div>
                                              <div class="col-md-6">
                                                <p>(Finance Due)</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-3 data">
                                            <div class="row">
                                              <div class="col-md-2">
                                                <span style="font-weight: bold">
                                                  $
                                                </span>
                                              </div>
                                              <div class="col-md-6">
                                                <span style="font-weight: bold">
                                                  {{ this.calculation }}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              class="divider-sales-resceipt"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-2">
                                        <p>Loan Amount</p>
                                      </div>
                                      <div class="col-md-7">
                                        <div class="row">
                                          <div class="col-md-1 data">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <span style="font-weight: bold">
                                                  &nbsp;&nbsp;{{
                                                    this.SalesReceiptNoteDetails
                                                      .INTEREST_RATE
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              class="divider-sales-resceipt-2"
                                            ></div>
                                          </div>
                                          <div
                                            class="col-md-1"
                                            style="margin-left: 10px"
                                          >
                                            X
                                          </div>
                                          <div
                                            class="col-md-2 data"
                                            style="width: 55px !important"
                                          >
                                            <div class="row">
                                              <div class="col-md-6">
                                                <span style="font-weight: bold">
                                                  &nbsp;&nbsp;{{
                                                    this.SalesReceiptNoteDetails
                                                      .LOAN_TENURE
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              class="divider-sales-resceipt-2"
                                            ></div>
                                          </div>
                                          <div
                                            class="col-md-2 p-0"
                                            style="margin-left: 10px"
                                          >
                                            <p>Months</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .LOAN_AMOUNT
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-3">
                                        <p>Finance Co.</p>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <span style="font-weight: bold">
                                              &nbsp;&nbsp;{{
                                                this.SalesReceiptNoteDetails
                                                  .FINANCE_COMPANY
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          style="width: 15em"
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                      <div class="col-md-3 data">
                                        <span></span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p style="font-weight: bold">
                                          BALANCE DUE
                                        </p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              &nbsp;&nbsp;{{
                                                this.SalesReceiptNoteDetails
                                                  .BALANCE_DUE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-3"></div>
                                      <div class="col-md-3 data"></div>
                                      <div class="col-md-3">
                                        <span style="float: right"
                                          >Cash/ Cheque No.
                                        </span>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              &nbsp;&nbsp;{{
                                                this.SalesReceiptNoteDetails
                                                  .CHEQUE_NO
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-3 mt-5" style="border: 2px solid #212121;height:fit-content">
                              <p class="text-center"><b><u>Mode Of Payment</u></b></p>
                              <p class="mt-1" style="color:red"><b><u>Cheque</u></b></p>
                              <p>All Chq issued must be favour of "Lek Auto Pte Ltd" </p>
                              <p class="mt-1" style="color:red"><b><u>Bank Transfer</u></b></p>
                              <p>UOB Bank: UOB3303198200</p>
                              <p class="mt-1" style="color:red"><b><u>Paynow</u></b></p>
                              <p>201929524C001</p>
                              <p class="mt-1">or Scan QR Code for Payment</p>
                              <img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code">
                            </div>
                            <!-- content body 2  -->
                            <div class="col-md-12 mt-2">
                              <div
                                class="content-body-2 p-1"
                                style="border: 2px solid #212121"
                              >
                                <p style="color: red; padding-bottom: 10px">
                                  <b>Delivery Acknowledgement</b>
                                </p>

                                I / We confirm that I/we have examined the above
                                said vehicle and that the same is good order and
                                proper condition and suitable for the purpose
                                for which I/We require it and to my/ our
                                satisfaction in every respect and that I/We have
                                to take delivery of vehicle on
                                &nbsp;(date)&nbsp;<u
                                  ><span style="font-weight: bold">{{
                                    this.SalesReceiptNoteDetails
                                      .DELIVERY_IN_DATE
                                  }}</span></u
                                >&nbsp;(time)&nbsp;<span>
                                  <u
                                    ><span style="font-weight: bold">{{
                                      this.SalesReceiptNoteDetails
                                        .DELIVERY_IN_TIME
                                    }}</span></u
                                  >
                                  . The buyer henceforth on accepting delivery
                                  of the vehicle on the time indicated above I/
                                  We will be fully responsible for the vehicle
                                  use including any traffic offence and or
                                  illegal acts
                                </span>
                              </div>
                            </div>
                            <!-- content footer  -->
                            <div class="col-md-12">
                              <div class="content-footer-2">
                                <div class="row mb-5">
                                  <div class="col-md-6">
                                    <p>Signed for & behalf of</p>
                                  </div>
                                  <div class="col-md-6">
                                    <p style="float: right">
                                      Agreed & Aceepted by Buyer
                                    </p>
                                  </div>
                                </div>
                                <div class="row mt-4">
                                  <div class="col-md-7">
                                    <ul>
                                      <li>
                                        <span style="border-top: 2px solid #000"
                                          >Authorised Signature</span
                                        >
                                      </li>
                                      <li>
                                        <div class="row mt-2">
                                          <div class="col-md-1 p-0">
                                            <p>&nbsp;&nbsp;Name</p>
                                          </div>
                                          <div class="col-md-8">
                                            :
                                            <span style="font-weight: bold">{{
                                              this.SalesReceiptNoteDetails
                                                .INCHARGE_NAME
                                            }}</span>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="row">
                                          <div class="col-md-1 p-0">
                                            <p>&nbsp;&nbsp;HP</p>
                                          </div>
                                          <div class="col-md-8">
                                            :
                                            <span style="font-weight: bold">{{
                                              this.SalesReceiptNoteDetails
                                                .INCHARGE_CONTACT
                                            }}</span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col-md-5">
                                    <ul
                                      style="float: right; text-align: center"
                                    >
                                      <li>
                                        <span
                                          style="
                                            border-top: 2px solid #000;
                                            width: 100px;
                                          "
                                          >Authorised Signature of Buyer</span
                                        >
                                      </li>
                                      <li><span>Company Stamp</span></li>
                                      <li>
                                        <div
                                          class="row mt-2"
                                          style="text-align: left"
                                        >
                                          <div class="col-md-4 p-0">
                                            <p>&nbsp;&nbsp;Name</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span>: </span>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div
                                          class="row"
                                          style="text-align: left"
                                        >
                                          <div class="col-md-4 p-0">
                                            <p>&nbsp;&nbsp;NRIC No.</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span>: </span>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div
                                          class="row"
                                          style="text-align: left"
                                        >
                                          <div class="col-md-4 p-0">
                                            <p>&nbsp;&nbsp;HP</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span>: </span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <!-- Sales Receipt Section End -->

                <!-- Loan Info Section Start -->
                <h5 class="line-heading" ref="loan-info">
                  <span>Loan Info</span>
                </h5>
                <div class="col-md-12" id="loan-info-section">
                  <div>
                    <b-tabs v-model="activeLoanTabIndex">
                      <!--Loan Info Tab Section -->
                      <b-tab title="Loan Info" active>
                        <div
                          aria-labelledby="flush-headingOne"
                          class="mt-3"
                          ref="loan-tab-info"
                        >
                          <div class="p-2">
                            <div class="col-md-12">
                              <div
                                class="row"
                                v-if="addupdatebuyerdata.TAX_SYS_ID != ''"
                              >
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Finance Company</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        class="form-control roleddl"
                                        v-model="loan_info.FIN_COMP_SYS_ID"
                                        @change="financeCompanyOnchangeFunc"
                                      >
                                        <option value="">select</option>
                                        <option
                                          v-for="FinanceCompanyDetail in FinanceCompanyDetails"
                                          :key="
                                            FinanceCompanyDetail.FINANCE_COMPANY_SYS_ID
                                          "
                                          :value="
                                            FinanceCompanyDetail.FINANCE_COMPANY_SYS_ID
                                          "
                                        >
                                          {{
                                            FinanceCompanyDetail.FINANCE_COMPANY
                                          }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right">
                                        Bank
                                      </label>
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        v-model="loan_info.BANK_SYS_ID"
                                        class="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option
                                          v-for="item in finance_company_bank_details"
                                          :value="
                                            item.FINANCE_COMPANY_BANK_SYS_ID
                                          "
                                          :key="
                                            item.FINANCE_COMPANY_BANK_SYS_ID
                                          "
                                        >
                                          {{ item.FINANCE_COMPANY_BANK_NAME }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <!-- <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Invoice Number</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter invoice number"
                                        v-model="loan_info.INVOICE_NO"
                                      />
                                    </div>
                                  </div> -->
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Amount(With GST)(S$)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.LOAN_AMOUNT"
                                        @keyup="
                                          loanInfoCalculation(),
                                            loanAmountKeyupFunc()
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Tenure(In Month)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.LOAN_TENURE"
                                        @keyup="loanInfoCalculation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Interest Rate(%)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.INTEREST_RATE"
                                        @keyup="loanInfoCalculation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Amount(Without GST)(S$)</label
                                      >
                                    </div>

                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                        v-model="
                                          loan_info.LOAN_AMOUNT_WITHOUT_TAX
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Advance Payment Decimal Adjustment($)
                                        (+1/-1)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          loan_info.ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT
                                        "
                                        @keyup="
                                          globalDecimalAdjusment(
                                            'LOAN_INFO_ADVANCE_PAYMENT'
                                          )
                                        "
                                        type="number"
                                        min="-1"
                                        max="1"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-left"
                                        >Advance Payment Before
                                        Adjustment(S$)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          loan_info.ADVANCE_PAYMENT_BEFORE_ADJUSTMENT
                                        "
                                        disabled
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Advance Payment(S$)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <b-form-input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.ADVANCE_PAYMENT"
                                      ></b-form-input>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Interest :</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        disabled
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.INTEREST_AMOUNT"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Subtotal :</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        disabled
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.SUBTOTAL"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >GST</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        v-model="loan_info.LOAN_TAX_SYS_ID"
                                        @change="
                                          globalCalculateGST('LOAN_INFO')
                                        "
                                        class="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option
                                          v-for="tax_info in tax_info_detai"
                                          :value="tax_info.TAX_SYS_ID"
                                          :key="tax_info.TAX_SYS_ID"
                                        >
                                          {{ tax_info.TAX_NAME }}-{{
                                            tax_info.TAX_PERCENTAGE
                                          }}
                                          %
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >GST Decimal Adjustment($)
                                        (+1/-1)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          loan_info.LOAN_TAX_DECIMAL_ADJUSTMENT
                                        "
                                        @keyup="
                                          globalDecimalAdjusment('LOAN_INFO')
                                        "
                                        type="number"
                                        min="-1"
                                        max="1"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-left"
                                        >GST Amount Before Adjustment($)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT
                                        "
                                        disabled
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >GST Amount($)<span style="color: red"
                                          >*</span
                                        ></label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="loan_info.LOAN_TAX_AMOUNT"
                                        type="text"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>

                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >HP Facilties Fee</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.HP_FACILITIES_FEE"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Commission(%)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        @keyup="loanCommisionCalculator"
                                        placeholder=""
                                        v-model="
                                          loan_info.LOAN_COMMISSION_PERCENTAGE
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Commission (Loan Amt x Loan
                                        Commission %)</label
                                      >
                                    </div>

                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.LOAN_COMMISSION"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Commission GST</label
                                      >
                                    </div>

                                    <div class="col-md-8">
                                      <select
                                        v-model="loan_info.LOAN_COMMISSION_TAX_SYS_ID"
                                        @change="
                                          globalCalculateGST('LOAN_COMMISSION')
                                        "
                                        class="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option
                                          v-for="tax_info in tax_info_detai"
                                          :value="tax_info.TAX_SYS_ID"
                                          :key="tax_info.TAX_SYS_ID"
                                        >
                                          {{ tax_info.TAX_NAME }}-{{
                                            tax_info.TAX_PERCENTAGE
                                          }}
                                          %
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Commission GST Amount</label
                                      >
                                    </div>

                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.LOAN_COMMISSION_TAX_AMOUNT"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Commission Amount With GST</label
                                      >
                                    </div>

                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.LOAN_COMMISSION_AMOUNT_WITH_TAX"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Salesman Commission (For admin fee &
                                        loan commission)</label
                                      >
                                    </div>
                                    <div class="col-md-8 mt-2">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.SALES_COMMISSION"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6 pt-1 pb-1">
                                  <div class="row mt-4 mb-4">
                                    <div class="col-md-4">
                                      <button
                                        style="width: 120px"
                                        type="button"
                                        class="custom-btn custom-success-btn"
                                        @click="AddLoanInfo"
                                      >
                                        Save
                                      </button>
                                    </div>
                                    <div class="col-md-4">
                                      <button
                                        style="width: 120px"
                                        type="button"
                                        class="custom-btn custom-danger-btn"
                                        @click="DeleteLoanInfo"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="row pb-5"
                                style="text-align: center"
                                v-if="addupdatebuyerdata.TAX_SYS_ID == ''"
                              >
                                <h5 style="color: red">
                                  Can't Identify GST Slabs From Buyer Details.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!-- Finance Company Tax Invoice Tab Section-->
                      <b-tab
                        title="Finance Company Tax Invoice"
                        @click="getFinanceCompanyInvoiceDetailsWithTax"
                      >
                        <div class="row mt-3">
                          <div class="col-md-2 mt-2" style="text-align: right">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                financetaxinvoicedata.FINANCE_TAX_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2" style="text-align: right">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-2">
                            <input
                              style="height: 3em"
                              v-model="
                                financetaxinvoicedata.FINANCE_TAX_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                          <div class="col-md-1 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="
                                AddInvoiceNumberAndDateForFinanceCompanyTaxInvoice
                              "
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-3 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="
                                freezeInvoice('FINANCE_COMPANY_TAX_INVOICE')
                              "
                            >
                              Freeze Invoice
                            </button>
                          </div>
                        </div>
                        <div
                          class="col-md-12 mt-2"
                          style="text-align: right"
                          ref="finance-company-tax-invoice"
                        >
                          <button
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReporttaxinvoicefinancial"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px"
                                    >TAX INVOICE</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <!-- header  -->
                            <div class="col-md-4">
                              <div
                                class="content-header-2"
                                style="margin-left: 2em"
                              >
                                <ul>
                                  <li><p>Bill To,</p></li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.finance_company_tax_invoice
                                          .FINANCE_COMPANY
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.finance_company_tax_invoice
                                            .FINANCE_COMPANY_ADDRESS
                                        }}
                                      </span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.finance_company_tax_invoice
                                            .FINANCE_COMPANY_COUNTRY_NAME
                                        }}
                                        {{
                                          this.finance_company_tax_invoice
                                            .FINANCE_COMPANY_PIN_CODE
                                        }}</span
                                      >
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <ul>
                                <li><p>In Respect Of Hirer :</p></li>
                                <li>
                                  <p>
                                    {{
                                      this.finance_company_tax_invoice
                                        .CUSTOMER_NAME
                                    }}
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    {{
                                      this.finance_company_tax_invoice
                                        .CUSTOMER_ADDRESS
                                    }}
                                  </p>
                                </li>

                                <li>
                                  <p>
                                    {{
                                      this.finance_company_tax_invoice
                                        .CUSTOMER_COUNTRY_NAME
                                    }}
                                    {{
                                      this.finance_company_tax_invoice
                                        .CUSTOMER_PIN_CODE
                                    }}
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Invoice No</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.finance_company_tax_invoice
                                              .INVOICE_NO
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Term</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;Immediate</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Date</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;
                                          {{
                                            this.finance_company_tax_invoice
                                              .TAX_INVOICE_DATE
                                          }}</span
                                        >
                                        <!-- <span style="font-weight:bold">{{this.pdf_deta.TAX_INVOICE_DATE}} </span> -->
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>VSA No.</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold">
                                          :&nbsp;{{
                                            this.finance_company_tax_invoice
                                              .VSA_NUMBER
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- after heading sec  -->
                            <div class="col-md-12 mt-3">
                              <div
                                class="content-body-2 ps-1 pe-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-7 pt-1 pb-1">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Vehicle Registration No.</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .VEHICLE_NUMBER
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Vehicle Make</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .MAKE_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>VehicleModel</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .MODEL_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Original Registration Date</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .ORIGINAL_REGISTRATION_DATE
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-5 pt-1 pb-1"
                                    style="
                                      border-left: 1px solid rgba(0, 0, 0, 0.3);
                                    "
                                  >
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Engine No./Motor No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .ENGINE_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Chassis No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .CHASIS_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Sales Person</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .SALESMAN
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>GST</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold">
                                                :
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .GST_PERCENTAGE
                                                }}
                                                %
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .TAX_TYPE
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12 mt-3">
                              <div
                                class="content-footer"
                                style="border: 1px solid #212121"
                              >
                                <table class="table table-borderless">
                                  <thead
                                    style="border-bottom: 1px solid #212121"
                                  >
                                    <tr></tr>
                                    <tr>
                                      <th scope="col">
                                        <span style="font-weight: bold"
                                          >Description</span
                                        >
                                      </th>
                                      <th scope="col">
                                        <span style="font-weight: bold"></span>
                                      </th>
                                      <th
                                        scope="col"
                                        class="border-td"
                                        style="text-align: center"
                                      >
                                        <span style="font-weight: bold"
                                          >Amount&nbsp;($)</span
                                        >
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td class="border-td-2">
                                        <span style="font-weight: bold">
                                          Loan Amount</span
                                        >
                                      </td>
                                      <td class="border-td border-td-2">
                                        <span style="font-weight: bold"> </span>
                                      </td>
                                      <td class="border-td border-td-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <span class="fw-bold">$</span>
                                          </div>
                                          <div class="col-md-4">
                                            <span class="fw-bold">{{
                                              this.finance_company_tax_invoice
                                                .LOAN_AMOUNT
                                            }}</span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Vehicle Invoice Price</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .SELLING_PRICE
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6"></div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <!-- <td class="border-td"><span class="ms-1">$ 77,500.0 </span></td> -->
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Deposit Paid</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .DEPOSIT_AMOUNT_WITH_TAX
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6"></div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Loan Amount</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .LOAN_AMOUNT
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6"></div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >GST Details</span
                                        >
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span
                                                style="font-weight: bold"
                                              ></span>
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div
                                              class="row"
                                              style="text-align: center"
                                            >
                                              <div class="col-md-4">
                                                <span class="fw-bold"
                                                  >Taxable supplies</span
                                                >
                                              </div>
                                              <div class="col-md-7">
                                                <span class="fw-bold">
                                                  {{
                                                    this
                                                      .finance_company_tax_invoice
                                                      .TAX_TYPE
                                                  }}
                                                  {{
                                                    this
                                                      .finance_company_tax_invoice
                                                      .GST_PERCENTAGE
                                                  }}
                                                  %
                                                </span>
                                              </div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Customer</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .DEPOSIT_AMOUNT_WITHOUT_TAX
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .DEPOSIT_TAX_AMOUNT
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Finance co.</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .LOAN_AMOUNT_WITHOUT_TAX
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .LOAN_TAX_AMOUNT
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Total GST Incl.</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .TOTAL_FINANCE_WITHOUT_GST_AMOUNT
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .TOTAL_FINANCE_GST
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr style="border-top: 1px solid #212529">
                                      <!-- <th scope="row">1</th> -->
                                      <td class="border-td-2">
                                        <span style="font-weight: bold"
                                          >Total Invoice Amount</span
                                        >
                                      </td>
                                      <td class="border-td border-td-2">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td border-td-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <span class="fw-bold">$</span>
                                          </div>
                                          <div class="col-md-4">
                                            <span class="fw-bold">{{
                                              this.finance_company_tax_invoice
                                                .LOAN_AMOUNT
                                            }}</span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- content footer  -->
                            <div class="content-footer">
                              <div class="row mt-3">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                        <li><p class="me-4" style="color: #000; text-align: center;">Scan QR Code for Payment</p></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!-- Finance Company Invoice Tab Section-->
                      <b-tab
                        title="Finance Company Invoice"
                        @click="getFinanceCompanyInvoiceDetailsWithoutTax"
                      >
                        <div class="row mt-3">
                          <div class="col-md-2 mt-2" style="text-align: right">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                financetaxinvoicedata.FINANCE_TAX_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2" style="text-align: right">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-2">
                            <input
                              style="height: 3em"
                              v-model="
                                financetaxinvoicedata.FINANCE_TAX_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                          <div class="col-md-1 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="
                                AddInvoiceNumberAndDateForFinanceCompanyTaxInvoice
                              "
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-3 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="
                                freezeInvoice('FINANCE_COMPANY_INVOICE'),
                                  freezeVechilestatement()
                              "
                            >
                              Freeze Invoice
                            </button>
                          </div>
                        </div>
                        <!-- <div class="row mt-3">
                          <div class="col-md-2 mt-2" style="text-align: right">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                finance_company_invoice_form.FINANCE_COMPANY_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2" style="text-align: right">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-2">
                            <input
                              style="height: 3em"
                              v-model="
                                finance_company_invoice_form.FINANCE_COMPANY_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                          <div class="col-md-1 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="
                                AddInvoiceNumberAndDateForFinanceCompanyInvoice
                              "
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-3 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="
                                freezeInvoice('FINANCE_COMPANY_INVOICE'),
                                  freezeVechilestatement()
                              "
                            >
                              Freeze Invoice
                            </button>
                          </div>
                        </div> -->
                        <div
                          class="col-md-12 mt-2"
                          style="text-align: right"
                          ref="finance-company-invoice"
                        >
                          <button
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportForFCompanyInvoice"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>

                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px">INVOICE</span>
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <!-- header  -->
                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li><p>Bill To,</p></li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.finance_company_invoice
                                          .FINANCE_COMPANY
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.finance_company_invoice
                                            .ADDRESS_LINE_1
                                        }}
                                      </span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.finance_company_invoice
                                            .CUSTOMER_COUNTRY_NAME
                                        }}
                                        {{
                                          this.finance_company_invoice
                                            .FINANCE_COMPANY_PIN_CODE
                                        }}</span
                                      >
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Invoice No</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.finance_company_invoice
                                              .INVOICE_NO
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Date</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;
                                          {{
                                            this.finance_company_invoice
                                              .TAX_INVOICE_DATE
                                          }}</span
                                        >
                                        <!-- <span style="font-weight:bold">{{this.pdf_deta.TAX_INVOICE_DATE}} </span> -->
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>GST Reg No.</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;201929524C</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Co Reg No.</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold">
                                          :&nbsp;201929524C</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- after heading sec  -->
                            <div class="col-md-12 mt-3">
                              <div class="row">
                                <div class="col-md-3">For Account</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold"
                                    >:&nbsp;{{
                                      this.finance_company_invoice.CUSTOMER_NAME
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">Description</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold"
                                    >:&nbsp;1 unit of&nbsp;&nbsp;
                                    {{ this.finance_company_invoice.MAKE_NAME }}
                                    {{
                                      this.finance_company_invoice.MODEL_NAME
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">Chassis No</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold">
                                    :&nbsp;{{
                                      this.finance_company_invoice.CHASIS_NO
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">Engine No./Motor No.</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold">
                                    :&nbsp;{{
                                      this.finance_company_invoice.ENGINE_NO
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">Registration No</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold">
                                    :&nbsp;{{
                                      this.finance_company_invoice
                                        .VEHICLE_NUMBER
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">
                                  Original Registraion Date
                                </div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold">
                                    :&nbsp;{{
                                      this.finance_company_invoice
                                        .ORIGINAL_REGISTRATION_DATE
                                    }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-4">
                              <div
                                class="content-body-2 ps-1 pe-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-12 pt-1 pb-1">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-8">
                                              <p>Selling Price</p>
                                            </div>
                                            <div
                                              class="col-md-1"
                                              style="text-align: right"
                                            >
                                              <span style="font-weight: bold">
                                                $
                                              </span>
                                            </div>
                                            <div class="col-md-3">
                                              <span style="font-weight: bold">
                                                {{
                                                  this.finance_company_invoice
                                                    .SELLING_PRICE
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-8">
                                              <p>Deposit Paid</p>
                                            </div>
                                            <div
                                              class="col-md-1"
                                              style="text-align: right"
                                            >
                                              <span style="font-weight: bold">
                                                $
                                              </span>
                                            </div>
                                            <div class="col-md-3">
                                              <span style="font-weight: bold">
                                                {{
                                                  this.finance_company_invoice
                                                    .DEPOSIT_AMOUNT_WITHOUT_TAX
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-8">
                                              <p>Loan Amount</p>
                                            </div>
                                            <div
                                              class="col-md-1"
                                              style="text-align: right"
                                            >
                                              <span style="font-weight: bold">
                                                $
                                              </span>
                                            </div>
                                            <div class="col-md-3">
                                              <span style="font-weight: bold">
                                                {{
                                                  this.finance_company_invoice
                                                    .LOAN_AMOUNT_WITHOUT_TAX
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row mt-4">
                                            <div class="col-md-8">
                                              <p>
                                                <u
                                                  >Total payable(Finance
                                                  amount)</u
                                                >
                                              </p>
                                            </div>
                                            <div
                                              class="col-md-1"
                                              style="text-align: right"
                                            >
                                              <span style="font-weight: bold">
                                                $
                                              </span>
                                            </div>
                                            <div class="col-md-3">
                                              <span style="font-weight: bold">
                                                {{
                                                  this.finance_company_invoice
                                                    .TOTAL_FINANCE_WITHOUT_GST_AMOUNT
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-4">
                              <div
                                class="content-body-2 ps-1 pe-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-12 pt-1 pb-1">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-12">
                                              <p>
                                                I declare that the above
                                                particulars in the invoice are
                                                true and correct
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-12">
                                              <p>
                                                NO GST IS DUE FROM FINANCE
                                                COMPANY AS TAXABLE VALUE IS
                                                ZERO.
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-12">
                                              <p>
                                                THE FULL GST AMOUNT HAS BEEN
                                                PAID FOR BY THE HIRER.
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- content footer  -->
                            <div class="content-footer">
                              <div class="row mt-3">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                        <li><p class="me-4" style="color: #000; text-align: center;">Scan QR Code for Payment</p></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Official Receipt Tab Section -->
                      <b-tab
                        title="Official Receipt"
                        @click="getOfficialReceiptDetailsList"
                      >
                        <div class="row mt-3">
                          <div class="col-md-2"></div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-2">
                                <label
                                  class="frm-label-2"
                                  style="font-weight: bold"
                                  >To/From</label
                                >
                              </div>
                              <div class="col-md-8">
                                <select
                                  class="form-control roleddl"
                                  v-model="OFFICIAL_RECEIPT_CUSTOMER"
                                  @change="onchangeOfficialReceiptCustomer"
                                >
                                  <option value="">Select</option>
                                  <option
                                    v-for="OFFICIAL_RECEIPT_CUSTOMER_DETAIL in OFFICIAL_RECEIPT_DETAILS_LIST"
                                    :value="
                                      OFFICIAL_RECEIPT_CUSTOMER_DETAIL.CUSTOMER_SYS_ID
                                    "
                                    :key="
                                      OFFICIAL_RECEIPT_CUSTOMER_DETAIL.CUSTOMER_SYS_ID
                                    "
                                  >
                                    {{
                                      OFFICIAL_RECEIPT_CUSTOMER_DETAIL.CUSTOMER_NAME
                                    }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="OFFICIAL_RECEIPT_CUSTOMER != ''"
                            class="col-md-2"
                            style="text-align: right"
                            ref="official-receipt"
                          >
                            <button
                              v-show="officalrecipt"
                              class="downld animate__animated animate__bounceIn"
                              @click="generateOfficeReceipt"
                            >
                              <i
                                class="fa-solid fa-cloud-arrow-down"
                                style="color: blue"
                              ></i>
                              Download
                            </button>
                          </div>
                        </div>

                        <!-- <div class="row mt-5" v-if="OFFICIAL_RECEIPT_CUSTOMER != ''" v-show="officalrecipt">
                              <div class="col-md-2 mt-2"><label class="frm-label">Invoice No.</label></div>
                              <div class="col-md-3">
                                <input
                                  style="height:3em;"
                                  type="text"
                                  v-model="officalreceipt.OFFICIAL_RECEIPT_INVOICE_NO"
                                  class="form-control"
                                  placeholder="Enter Invoice No."
                                />
                              </div>
                              <div class="col-md-1 mt-2"><label class="frm-label">Date</label></div>
                              <div class="col-md-3">
                                <input
                                  style="height:3em;"
                                  v-model="officalreceipt.OFFICIAL_RECEIPT_INVOICE_DATE"
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Date"
                                />
                              </div>
                              <div class="col-md-2">
                                   <button type="button"
                                    class="custom-btn custom-success-btn"
                                    @click="AddInvoiceNumberAndDateForOfficialReceipt"
                                  >Save</button>
                              </div>
                        </div> -->
                        <div
                          v-if="OFFICIAL_RECEIPT_CUSTOMER != ''"
                          class="purchase-settlement-note-cont mt-4"
                          v-show="officalrecipt"
                        >
                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row">
                                <div
                                  class="col-md-3"
                                  style="text-align: center"
                                >
                                  <img
                                    src="../../assets/images/icon.png"
                                    class="img-fluid header-img"
                                    alt="#"
                                  />
                                </div>
                                <div class="col-md-9 p-0">
                                  <ul>
                                    <li>
                                      <img
                                        src="../../assets/images/logo-txt.png"
                                      />
                                    </li>
                                    <li class="mt-2">
                                      <p>
                                        1 Sunview Road #01-46 Eco-Tech@Sunview
                                        Singapore 627615
                                      </p>
                                    </li>
                                    <li>
                                      <p>ROC No./ GST Reg No:. 201929524C</p>
                                    </li>
                                    <li><p>Tel: 62395191 Fax: 62395190</p></li>
                                  </ul>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-8"></div>
                                <div
                                  class="col-md-4"
                                  style="
                                    text-align: center;
                                    border: 1.5px solid #212121;
                                  "
                                >
                                  <h4 class="fw-bold">
                                    <span style="padding: 4px 10px"
                                      >OFFICIAL RECEIPT</span
                                    >
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div class="row mt-3">
                            <div class="col-md-1"><p>Date</p></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;
                              {{ this.OFFICIAL_RECEIPT_PDF_OBJ.RECEIPT_DATE }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-1"><p>To</p></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.CUSTOMER_NAME
                              }}
                              <br />
                            </div>
                          </div>
                          <div class="mt-4 text-center">
                            <h5
                              style="
                                font-family: 'Times New Roman', Times, serif;
                                font-weight: 600;
                              "
                            >
                              TO WHOM IT MAY CONCERN
                            </h5>
                          </div>
                          <p class="mt-3">
                            We hereby confirm that we have sold & received Full
                            Payment
                            <b
                              >${{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.TOTAL_AMOUNT
                              }}</b
                            >
                            from
                            <b>{{
                              this.OFFICIAL_RECEIPT_PDF_OBJ.CUSTOMER_NAME
                            }}</b>
                            for the following vehicle.
                          </p>
                          <div class="row mt-4">
                            <div class="col-md-3">
                              <p>Vehicle Registration No</p>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.VEHICLE_NUMBER
                              }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-3"><p>Make & Model</p></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;
                              {{ this.OFFICIAL_RECEIPT_PDF_OBJ.MAKE_NAME }}
                              {{ this.OFFICIAL_RECEIPT_PDF_OBJ.MODEL_NAME }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-3">
                              <p>Engine No./Motor No.</p>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.ENGINE_NO
                              }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-3"><p>Chassis No</p></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.CHASIS_NO
                              }}
                            </div>
                          </div>
                          <div class="content-footer mt-3">
                            <div class="row">
                              <div class="col-md-12">
                                <span>*&nbsp;This is a computer generated receipt, there is no signature required.</span>
                              </div>
                                                              
                            </div>
                                            
                        </div>
                          <!-- <br />
                          <br />
                          <br />
                          <br />
                          <div class="row mt-5 mb-3">
                            <div
                              style="border-bottom: 1px solid #212121"
                              class="col-md-3"
                            ></div>
                            <p>Director: Pauson Lek</p>
                          </div> -->
                        </div>
                      </b-tab>

                      <!--Loan Commision Invoice Tab Section  -->
                      <b-tab
                        title="Tax Commision Invoice"
                        @click="getLoanCommisionDetails"
                      >
                        <div class="row mt-3">
                          <div class="col-md-2 mt-2" style="text-align: right">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                taxcommisoninvoicedata.TAX_COMMISSION_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2" style="text-align: right">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-2">
                            <input
                              style="height: 3em"
                              v-model="
                                taxcommisoninvoicedata.TAX_COMMISSION_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                          <div class="col-md-1 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="
                                AddInvoiceNumberAndDateForTaxCommissionInvoice
                              "
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-3 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="
                                freezeInvoice('TAX_COMMISSION_INVOICE'),
                                  taxcommisonfredge()
                              "
                            >
                              Freeze Invoice
                            </button>
                          </div>
                        </div>
                        <div
                          class="col-md-12 mt-2"
                          style="text-align: right"
                          ref="loan-commission-invoice"
                        >
                          <button
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportForTaxCommissionInvoice"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div
                                class="col-md-6"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold" style="padding: 5px">
                                  <span style="padding: 5px 10px"
                                    >TAX COMMISSION INVOICE</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Invoice No</b></div>
                            <div class="col-md-9">
                              <p style="font-weight: bold">
                                :&nbsp;{{ LOAN_COMMISSION_DETAILS.INVOICE_NO }}
                              </p>
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Date</b></div>
                            <div class="col-md-9">
                              <p style="font-weight: bold">
                                :&nbsp;{{
                                  LOAN_COMMISSION_DETAILS.INVOICE_DATE
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>Bill To</b>
                            </div>
                            <div class="col-md-9">
                              <p style="font-weight: bold">
                                : &nbsp;{{
                                  LOAN_COMMISSION_DETAILS.FINANCE_COMPANY
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  LOAN_COMMISSION_DETAILS.ADDRESS
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  LOAN_COMMISSION_DETAILS.COUNTRY
                                }}
                                {{ LOAN_COMMISSION_DETAILS.PIN_CODE }}
                              </p>
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>For the account of</b>
                            </div>
                            <div class="col-md-9" style="font-weight: bold">
                              :&nbsp;{{ LOAN_COMMISSION_DETAILS.CUSTOMER_NAME }}
                            </div>
                          </div>
                          <div class="row mt-5" style="margin-left: 2em">
                            <div class="col-md-8">
                              <u
                                ><b><p>Description of Item</p></b></u
                              >
                            </div>
                            <div align="center" class="col-md-4">
                              <u
                                ><b><p>Amount</p></b></u
                              >
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>Being Commission for Hirer</b>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{ LOAN_COMMISSION_DETAILS.CUSTOMER_NAME }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>Vehicle Registration No</b>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                LOAN_COMMISSION_DETAILS.VEHICLE_NUMBER
                              }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Make & Model</b></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                LOAN_COMMISSION_DETAILS.MAKE_NAME
                              }}&nbsp;{{ LOAN_COMMISSION_DETAILS.MODEL_NAME }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>Engine No./Motor No.</b>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{ LOAN_COMMISSION_DETAILS.ENGINE_NO }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Chassis No.</b></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{ LOAN_COMMISSION_DETAILS.CHASIS_NO }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Commission Rate</b></div>
                            <div class="col-md-6" style="font-weight: bold">
                              : $&nbsp;{{
                                LOAN_COMMISSION_DETAILS.LOAN_AMOUNT_WITHOUT_TAX
                              }}&nbsp;&nbsp; x &nbsp;&nbsp;
                              {{
                                LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_PERCENTAGE
                              }}
                              %
                            </div>
                            <div class="col-md-1" style="text-align: right">
                              <b>$</b>
                            </div>
                            <div class="col-md-2" style="text-align: left">
                              <b>{{
                                LOAN_COMMISSION_DETAILS.LOAN_COMMISSION
                              }}</b>
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>Commission Tax Amount</b>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              : &nbsp;{{LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_TAX_NAME}}
                                &nbsp;-
                                &nbsp;{{ LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_TAX_PERCENTAGE}}%
                            </div>
                            <div class="col-md-1" style="text-align: right">
                              <b>$</b>
                            </div>
                            <div class="col-md-2" style="text-align: left">
                              <b>{{ LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_TAX_AMOUNT }}</b>
                            </div>
                          </div>
                          <div class="row mt-4" style="margin-left: 2em">
                            <div class="col-md-9">
                              <b>Nett Amount Payable (Inclusive of GST)</b>
                            </div>
                            <div
                              class="col-md-1"
                              style="
                                text-align: right;
                                border-top: 1px solid black;
                                border-bottom: 1px solid black;
                              "
                            >
                              <b>$</b>
                            </div>
                            <div
                              class="col-md-2"
                              style="
                                text-align: left;
                                border-top: 1px solid black;
                                border-bottom: 1px solid black;
                              "
                            >
                              <b>{{ LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_AMOUNT_WITH_TAX }}</b>
                            </div>
                          </div>
                          <!-- content footer  -->
                            <div class="content-footer">
                              <div class="row mt-3">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                        <li><p class="me-4" style="color: #000; text-align: center;">Scan QR Code for Payment</p></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <!-- Loan Info Section End -->

                <!-- Tax Invoice Section Start -->
                <h5 class="line-heading" ref="tax-invoice">
                  <span>Tax Invoice</span>
                </h5>
                <div class="col-md-12" id="tax-invoice-section">
                  <div>
                    <b-tabs v-model="activeTaxTabIndex">
                      <!--Manage Tax Invoice Tab -->
                      <b-tab title="Manage Tax Invoice" active>
                        <!--Manage Tax Invoice Add Form -->
                        <div class="col-md-12" ref="manage-tax-invoice">
                          <div class="row mt-1">
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Item Description</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <select
                                    name="item"
                                    v-model="
                                      addmanagetaxinvoice.ITEM_LIST_SYS_ID
                                    "
                                    @change="onChangeItemDescriptionForMIT"
                                    class="form-control"
                                  >
                                    <option value="">select</option>
                                    <option
                                      :value="system.ITEM_LIST_SYS_ID"
                                      v-for="system in item_description_details_for_MTI"
                                      :key="system.ITEM_LIST_SYS_ID"
                                    >
                                      {{ system.DESCRIPTION }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Rate</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    @keyup="
                                      globalCalculateGST('MANAGE_TAX_INVOICE')
                                    "
                                    type="number"
                                    v-model="addmanagetaxinvoice.UNIT_PRICE"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Quantity</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    @keyup="
                                      globalCalculateGST('MANAGE_TAX_INVOICE')
                                    "
                                    type="number"
                                    v-model="addmanagetaxinvoice.QTY"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >TAX
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <select
                                    name="tax"
                                    v-model="addmanagetaxinvoice.TAX_SYS_ID"
                                    class="form-control"
                                    @change="
                                      globalCalculateGST('MANAGE_TAX_INVOICE')
                                    "
                                  >
                                    <option value="">select</option>
                                    <option
                                      v-for="tax_in in tax_info_details"
                                      :value="tax_in.TAX_SYS_ID"
                                      :key="tax_in.TAX_SYS_ID"
                                    >
                                      {{ tax_in.TAX_NAME }}-{{
                                        tax_in.TAX_PERCENTAGE
                                      }}
                                      %
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Tax Amount</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    disabled
                                    v-model="addmanagetaxinvoice.TAX_VALUE"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label
                                    class="frm-label-2 align-right"
                                    style="text-align: end"
                                    >Gross Amount($)</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    v-model="addmanagetaxinvoice.TOTAL_PRICE"
                                    disabled
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4 mb-4">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                type="button"
                                class="custom-btn custom-success-btn"
                                @click="AddManageTaxinvoice()"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>

                        <!--Manage Tax Invoice Table View -->
                        <div class="content-body-wrapper mt-4">
                          <div class="page-content">
                            <div class="page-data">
                              <div class="row">
                                <div
                                  class="col-md-12 animate__animated animate__zoomIn"
                                >
                                  <div class="card-wrap shadow-main">
                                    <div class="card-wrap-body">
                                      <div class="data-table master-table">
                                        <b-table
                                          striped
                                          hover
                                          :items="ManageTaxInvoiceDetails"
                                          :fields="
                                            ManageTaxInvoiceDetailsFields
                                          "
                                          class="table table-bordered mt-3"
                                        >
                                          <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                          </template>
                                          <template v-slot:cell(Action)="row">
                                            <div
                                              class="text-nowrap table-btn"
                                              v-if="row.item.EDIT_FLAG != 'N'"
                                            >
                                              <button
                                                type="button"
                                                class="custom-btn p-0"
                                                data-toggle="collapse"
                                                data-bs-target="#collapseTwo"
                                                @click="
                                                  UpdateModalShow(
                                                    row.item,
                                                    '#manage_tax_invoice_update_modal'
                                                  )
                                                "
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  class="feather feather-edit"
                                                >
                                                  <path
                                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                                  ></path>
                                                  <path
                                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                                  ></path>
                                                </svg>
                                              </button>
                                              <button
                                                type="button"
                                                class="custom-btn p-0"
                                                @click="
                                                  deleteManageTaxInvoice(
                                                    row.item
                                                  )
                                                "
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  class="feather feather-trash-2"
                                                >
                                                  <polyline
                                                    points="3 6 5 6 21 6"
                                                  ></polyline>
                                                  <path
                                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                                  ></path>
                                                  <line
                                                    x1="10"
                                                    y1="11"
                                                    x2="10"
                                                    y2="17"
                                                  ></line>
                                                  <line
                                                    x1="14"
                                                    y1="11"
                                                    x2="14"
                                                    y2="17"
                                                  ></line>
                                                </svg>
                                              </button>
                                            </div>
                                          </template>
                                        </b-table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Tax Invoice Tab-->
                      <b-tab
                        title="Tax Invoice"
                        @click="getInvoiceDetailsWithTax()"
                      >
                        <div class="row mt-4">
                          <div class="col-md-2 mt-2" style="text-align: right">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="taxinvoicedata.TAX_INVOICE_NO"
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2" style="text-align: right">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-2">
                            <input
                              style="height: 3em"
                              v-model="taxinvoicedata.TAX_INVOICE_DATE"
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                          <div class="col-md-1 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="AddInvoiceNoAndDateForTaxInvoice"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-3 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="freezeInvoice('TAX_INVOICE'),invoiceFredge()"
                            >
                              Freeze Invoice
                            </button>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2" style="text-align: right">
                          <button
                            v-if="truettax"
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportTax"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>

                        <div
                          class="content purchase-settlement-note-cont"
                          v-if="truettax"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px"
                                    >TAX INVOICE</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <!-- header  -->
                            <div class="col-md-8">
                              <div
                                class="content-header-2"
                                style="margin-left: 2em"
                              >
                                <ul>
                                  <li><p>Bill To,</p></li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.InvoiceDetailsWithTax.CUSTOMER_NAME
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.InvoiceDetailsWithTax
                                            .ADDRESS_LINE_1
                                        }}
                                      </span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.InvoiceDetailsWithTax
                                            .COUNTRY_NAME
                                        }}
                                        {{
                                          this.InvoiceDetailsWithTax.PIN_CODE
                                        }}</span
                                      >
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-4 mt-2">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Invoice No.</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .INVOICE_NO
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Date</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .TAX_INVOICE_DATE
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>VSA No.</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .VSA_NUMBER
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- after heading sec  -->
                            <div class="col-md-12 mt-2">
                              <div
                                class="content-body-2 p-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-7">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Vehicle Registration No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax.VEHICLE_NUMBER
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Vehicle Make</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .MAKE_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Vehicle Model</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .MODEL_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Original Registration Date</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .ORIGINAL_REGISTRATION_DATE
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Engine No./Motor No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithTax
                                                    .ENGINE_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Chassis No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithTax
                                                    .CHASIS_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Sales Person</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .INCHARGE_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>GST</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold">
                                                :&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .GST_PERCENTAGE
                                                }}
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .TAX_TYPE
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12 mt-3">
                              <div
                                class="content-footer"
                                style="border: 1px solid #212121"
                              >
                                <table class="table table-bordered">
                                  <thead
                                    style="border-bottom: 1px solid #212121"
                                  >
                                    <tr></tr>
                                    <tr>
                                      <th scope="col">
                                        <span style="font-weight: bold"
                                          >Vehicle Price</span
                                        >
                                      </th>

                                      <th scope="col" class="border-td">
                                        <span style="font-weight: bold">
                                          GST</span
                                        >
                                      </th>
                                      <th scope="col" class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .TOTAL_VEHICLE_PRICE
                                          }}
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >TOTAL SELLING PRICE</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .SELLING_PRICE_GST_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .SELLING_PRICE_WITH_GST
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >Admin Fee</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .ADMIN_FEE_TAX_VALUE
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .ADMIN_FEE_UNIT_PRICE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >HP Facilities Fee</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                           this.InvoiceDetailsWithTax.HP_FACILITIES_FEE_TAX_VALUE
                                          }}</span
                                        >

                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                           this.InvoiceDetailsWithTax.HP_FACILITIES_FEE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >OTHER(ACCESSORIES/SURCHANGE FOR
                                          LOAN)</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .OTHER_FEE_TAX_VALUE
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .OTHER_FEE_UNIT_PRICE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    
                                    
                                    <tr></tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >LOAN ADVANCE PAYMENT</span
                                        >
                                      </td>
                                      <td class="border-td"></td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .LOAN_ADVANCE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                  
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >INSURANCE PREMIUM</span
                                        >
                                      </td>
                                      <td class="border-td"></td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .INSURANCE_PREMIUM
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    
                                    
                                    
                                    <tr></tr>
                                    <tr></tr>
                                    <tr></tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >TOTAL PAYABLE AMOUNT</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .TOTAL_GST_PAYABLE
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .TOTAL_PAYABLE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td"></td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Payment Details:</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"> </span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Sales Deposit</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .DEPOSIT_TAX_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .DEPOSIT_AMOUNT_WITHOUT_TAX
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Less: Trade In</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .TRADE_IN_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Less: Trade In Financial</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .TRADE_IN_REIMBURSEMENT
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Less: Payment Previously Made</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .PAYMENT_MADE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >Less: Excess Payment</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .EXCESS_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                  
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >HIRE PURCHASE AMOUNT DUE :
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .FINANCE_COMPANY
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .LOAN_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <p></p>
                                          </div>
                                          <div class="col-md-6 p-0">
                                            <span></span>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Finance Compnay:</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-8">
                                            <span
                                              class="ms-1"
                                              style="font-weight: bold"
                                              >:&nbsp;{{
                                                this.InvoiceDetailsWithTax
                                                  .FINANCE_COMPANY
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Loan Amount</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-8">
                                            <span
                                              class="ms-1"
                                              style="font-weight: bold"
                                              >:&nbsp; $
                                              {{
                                                this.InvoiceDetailsWithTax
                                                  .LOAN_AMOUNT
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <p>Loan Tenure</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span
                                              style="font-weight: bold"
                                              class="ms-1"
                                              >:&nbsp;
                                              {{
                                                this.InvoiceDetailsWithTax
                                                  .LOAN_TENURE
                                              }}&nbsp;Months</span
                                            >
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <p>Interest Rate</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span
                                              class="ms-1"
                                              style="font-weight: bold"
                                              >:&nbsp;{{
                                                this.InvoiceDetailsWithTax
                                                  .INTEREST_RATE
                                              }}
                                              %</span
                                            >
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td class="border-td-2">
                                        <span style="font-weight: bold"
                                          >Amount Due From Customer</span
                                        >
                                      </td>
                                      <td class="border-td border-td-2">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td border-td-2">
                                        <span style="font-weight: bold"
                                          >$ &nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .BALANCE_DUE
                                          }}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- content footer  -->
                            <div class="content-footer">
                              <div class="row mt-3">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                        <li><p class="me-4" style="color: #000; text-align: center;">Scan QR Code for Payment</p></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>


                          </div>
                        </div>
                      </b-tab>

                      <!--Invoice Tab-->
                      <b-tab
                        title="Invoice"
                        @click="getInvoiceDetailsWithoutTax()"
                      >
                        <div class="row mt-4">
                          <div class="col-md-2 mt-2" style="text-align: right">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="invoice_obj.TAX_INVOICE_NO"
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2" style="text-align: right">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-2">
                            <input
                              style="height: 3em"
                              v-model="invoice_obj.TAX_INVOICE_DATE"
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                          <div class="col-md-1 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="AddInvoiceNoAndDateForInvoice()"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-3 mt-1">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="freezeInvoice('INVOICE'),invoiceFredge()"
                            >
                              Freeze Invoice
                            </button>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2" style="text-align: right">
                          <button
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportInvoice"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px">INVOICE</span>
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <!-- header  -->
                            <div class="col-md-8">
                              <div
                                class="content-header-2"
                                style="margin-left: 2em"
                              >
                                <ul>
                                  <li><p>Bill To,</p></li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.InvoiceDetailsWithoutTax
                                          .CUSTOMER_NAME
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.InvoiceDetailsWithoutTax
                                          .ADDRESS_LINE_1
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">
                                        {{
                                          this.InvoiceDetailsWithoutTax
                                            .COUNTRY_NAME
                                        }}
                                        {{
                                          this.InvoiceDetailsWithoutTax.PIN_CODE
                                        }}
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Invoice No</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;
                                          {{
                                            this.InvoiceDetailsWithoutTax
                                              .INVOICE_NO
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Term</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;COD</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Date</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithoutTax
                                              .TAX_INVOICE_DATE
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>VSA No.</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithoutTax
                                              .VSA_NUMBER
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- after heading sec  -->
                            <div class="col-md-12">
                              <div
                                class="content-body-2 ps-1 pe-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-7 pt-1 pb-1">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Vehicle Registration No.</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .VEHICLE_NUMBER
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Vehicle Make</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .MAKE_NAME
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>VehicleModel</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this.InvoiceDetailsWithoutTax
                                                    .MODEL_NAME
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Original Registration Date</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .ORIGINAL_REGISTRATION_DATE
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-5 pt-1 pb-1"
                                    style="
                                      border-left: 1px solid rgba(0, 0, 0, 0.3);
                                    "
                                  >
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Engine No./Motor No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .ENGINE_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Chassis No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .CHASIS_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Sales Person</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .INCHARGE_NAME
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>GST</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;Gross Margin</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12 mt-3">
                              <div
                                class="content-footer"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div
                                    class="col-md-8"
                                    style="border-bottom: 1px solid #212121"
                                  >
                                    <span
                                      style="
                                        font-weight: bold;
                                        margin-left: 5px;
                                      "
                                    >
                                      Description
                                    </span>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                      border-bottom: 1px solid #212121;
                                    "
                                  >
                                    <span style="font-weight: bold"> </span>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="border-bottom: 1px solid #212121"
                                  >
                                    <span style="font-weight: bold">
                                      Amount
                                    </span>
                                  </div>
                                </div>
                                <div
                                  class="row"
                                  style="border-bottom: 1px solid #212121"
                                >
                                  <div class="col-md-8">
                                    <p
                                      class="mt-2 mb-2"
                                      style="
                                        font-weight: bold;
                                        margin-left: 5px;
                                      "
                                    >
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .VEHICLE_NUMBER
                                      }}
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p class="mt-2 mb-2">
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .TOTAL_VEHICLE_PRICE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Fees received on behalf of other agency
                                      and company:
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p></p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Loan Advance Payment
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .LOAN_ADVANCE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">HP charges</p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .HP_FEE_UNIT_PRICE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Insurance Premium
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .INSURANCE_PREMIUM
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">Admin Fee</p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .ADMIN_FEE_UNIT_PRICE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">Other</p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .OTHER_FEE_UNIT_PRICE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p class="mt-2" style="margin-left: 5px">
                                      Total Invoice Amount
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-top: 2px solid #212121;
                                      border-bottom: 2px solid #212121;
                                    "
                                  >
                                    <p class="mt-2">
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .TOTAL_PAYABLE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="row"
                                  style="border-top: 1px solid #212121"
                                >
                                  <div class="col-md-8">
                                    <p class="mt-2" style="margin-left: 5px">
                                      Payment Details:
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p class="mt-2"></p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p class="mt-3" style="margin-left: 5px">
                                      Sales Deposit
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p class="mt-3">
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .DEPOSIT_AMOUNT
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Down/Full Payment
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .FULL_PAYMENT
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">Trade In</p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .TRADE_IN_AMOUNT
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Excess Payment
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>$&nbsp;&nbsp;&nbsp; -</p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p class="mt-4" style="margin-left: 5px">
                                      Finance by &nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .FINANCE_COMPANY
                                      }}&nbsp;&nbsp;&nbsp; Hire Purchase
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p class="mt-4"></p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Loan Amount &nbsp;&nbsp;&nbsp;
                                      :&nbsp;&nbsp;$&nbsp;&nbsp;{{
                                        this.InvoiceDetailsWithoutTax
                                          .LOAN_AMOUNT
                                      }}&nbsp;&nbsp;&nbsp;
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .LOAN_AMOUNT
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Loan Tenure &nbsp;&nbsp;&nbsp;
                                      :&nbsp;&nbsp;{{
                                        this.InvoiceDetailsWithoutTax
                                          .LOAN_TENURE
                                      }}
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p></p>
                                  </div>
                                </div>
                                <div class="row" style="mb-3">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Interest Rate &nbsp;&nbsp;&nbsp;
                                      :&nbsp;&nbsp;{{
                                        this.InvoiceDetailsWithoutTax
                                          .INTEREST_RATE
                                      }}%
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p></p>
                                  </div>
                                </div>
                                <div
                                  class="row"
                                  style="border-top: 1px solid #212121"
                                >
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Amount Due From Customer
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .BALANCE_DUE
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- content footer  -->
                            <div class="content-footer">
                              <div class="row mt-3">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                        <li><p class="me-4" style="color: #000; text-align: center;">Scan QR Code for Payment</p></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>

                            </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <!-- Tax Invoice Section Start -->

                <!-- Credit Note Section Start -->
                <h5 class="line-heading" ref="credit-note">
                  <span>Credit Note</span>
                </h5>
                <div class="col-md-12" id="credit-note-section">
                  <div>
                    <b-tabs v-model="activeCreditTabIndex">
                      <!--Manage Credit Note Tab-->
                      <b-tab
                        title="Manage Credit Note"
                        active
                        @click="getManageCreditNoteDetails"
                      >
                        <!--Manage Credit Note Add Form-->
                        <div class="row mt-3" ref="manage-credit-note">
                          <div class="col-md-6">
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Issued to<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <select
                                  v-model="TaxCreditIssuedType"
                                  @change="onChnageTaxCreditIssuedTypeFun"
                                  class="form-control"
                                >
                                  <option value="">Select</option>
                                  <option value="Vendor">Vendor</option>
                                  <option value="Customer">Customer</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <!-- Tax Credit Note Vendor Section Start  -->
                          <div
                            class="col-md-6"
                            v-if="TaxCreditIssuedType == 'Vendor'"
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Info<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <b-input-group>
                                  <b-form-input
                                    id="TAX_CREDIT_VENDOR_INFO"
                                    @change="onChnageTaxCreditVendorInfo"
                                    @keyup="onKeyUpTaxCreditVendorInfo"
                                    v-model="TAX_CREDIT_VENDOR_INFO"
                                    placeholder="Enter vendor"
                                    list="taxcreditvendorlistOptions"
                                  ></b-form-input>
                                  <b-input-group-append>
                                    <b-button
                                      variant="info"
                                      data-bs-toggle="modal"
                                      data-bs-target="#vendor_modal"
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                                <datalist id="taxcreditvendorlistOptions">
                                  <option
                                    v-for="vendor_info in AllVendordetails"
                                    :value="vendor_info.VENDOR_NAME"
                                    :key="vendor_info.VENDOR_SYS_ID"
                                    :id="vendor_info.VENDOR_SYS_ID"
                                  ></option>
                                </datalist>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="DisplayTaxCreditVendorCode != ''"
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Code<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  id="Tax Credit Vendor Code"
                                  name="Tax Credit Vendor Code"
                                  class="form-control"
                                  v-model="DisplayTaxCreditVendorCode"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="DisplayTaxCreditVendorName != ''"
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Name<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  id="Tax Credit Vendor Name"
                                  name="Tax Credit Vendor Name"
                                  class="form-control"
                                  v-model="DisplayTaxCreditVendorName"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="DisplayTaxCreditVendorAddress != ''"
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Address<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <textarea
                                  style="height: 6em"
                                  id="Tax Credit Vendor Address"
                                  name="Tax Credit Vendor Address"
                                  class="form-control"
                                  v-model="DisplayTaxCreditVendorAddress"
                                  disabled
                                >
                                </textarea>
                              </div>
                            </div>
                          </div>
                          <!-- Tax Credit Note Vendor Section End  -->

                          <!-- Tax Credit Note Customer Section Start  -->
                          <div
                            class="col-md-6"
                            v-if="TaxCreditIssuedType == 'Customer'"
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Info<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <b-input-group>
                                  <b-form-input
                                    id="CustomerInfo"
                                    name="CustomerInfo"
                                    v-model="TAX_CREDIT_CUSTOMER_INFO"
                                    class="form-control"
                                    placeholder="Enter customer"
                                    list="datalistOptions"
                                    @keyup="onKeyUpTaxCreditCustomerInfo"
                                    @change="onChnageTaxCreditCustomerInfo"
                                  ></b-form-input>
                                  <b-input-group-append>
                                    <b-button
                                      variant="info"
                                      data-bs-toggle="modal"
                                      data-bs-target="#employee_modal"
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                                <datalist id="datalistOptions">
                                  <option
                                    v-for="customer_info in AllCustomerArray"
                                    :value="customer_info.CUSTOMER_NAME"
                                    :key="customer_info.ID_NUMBER"
                                  ></option>
                                </datalist>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="DisplayTaxCreditCustomerType != ''"
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Type<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  id="CustomerType"
                                  name="CustomerType"
                                  class="form-control"
                                  v-model="DisplayTaxCreditCustomerType"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="
                              DisplayTaxCreditCustomerType == 'individual' ||
                              DisplayTaxCreditCustomerType == 'INDIVIDUAL'
                            "
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >NRIC Number<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  id="NRIC Number"
                                  name="NRIC Number"
                                  class="form-control"
                                  v-model="DisplayTaxCreditCustomerIdNumber"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="
                              DisplayTaxCreditCustomerType == 'Company' ||
                              DisplayTaxCreditCustomerType == 'COMPANY'
                            "
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >UEN Number<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  id="UEN Number"
                                  name="UEN Number"
                                  class="form-control"
                                  v-model="DisplayTaxCreditCustomerIdNumber"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="DisplayTaxCreditCustomerName != ''"
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Name<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  id="Customer Name"
                                  name="Customer Name"
                                  class="form-control"
                                  v-model="DisplayTaxCreditCustomerName"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-6"
                            v-if="DisplayTaxCreditCustomerAddress != ''"
                          >
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Address<span style="color: red"
                                    >*</span
                                  ></label
                                >
                              </div>
                              <div class="col-md-8">
                                <textarea
                                  style="height: 4em"
                                  id="Customer Address"
                                  name="Customer Address"
                                  class="form-control"
                                  v-model="DisplayTaxCreditCustomerAddress"
                                  disabled
                                >
                                </textarea>
                              </div>
                            </div>
                          </div>
                          <!-- Tax Credit Note Customer Section End  -->
                          <div class="col-md-6">
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Payment Mode</label
                                >
                              </div>
                              <div class="col-md-8">
                                <select
                                  v-model="ManageCreditNoteObj.PAYMENT_TERMS"
                                  class="form-control"
                                >
                                  <option value="">Select</option>
                                  <option value="GIRO">GIRO</option>
                                  <option value="TT">TT</option>
                                  <option value="Cheque">Cheque</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right">
                                  Quantity</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  v-model="ManageCreditNoteObj.QTY"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Unit Price</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  v-model="ManageCreditNoteObj.UNIT_PRICE"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-3">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label"
                                  >TAX <span style="color: red">*</span></label
                                >
                              </div>
                              <div class="col-md-8">
                                <select
                                  name="tax"
                                  v-model="ManageCreditNoteObj.TAX_SYS_ID"
                                  class="form-control"
                                  @change="
                                    globalCalculateGST('MANAGE_CREDIT_NOTE')
                                  "
                                >
                                  <option value="">select</option>
                                  <option
                                    v-for="tax_in in tax_info_details"
                                    :value="tax_in.TAX_SYS_ID"
                                    :key="tax_in.TAX_SYS_ID"
                                  >
                                    {{ tax_in.TAX_NAME }}-{{
                                      tax_in.TAX_PERCENTAGE
                                    }}
                                    %
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Tax Amount</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  disabled
                                  type="text"
                                  v-model="ManageCreditNoteObj.TAX_AMOUNT"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Description</label
                                >
                              </div>
                              <div class="col-md-8">
                                <textarea
                                  style="height: 6em"
                                  class="form-control"
                                  placeholder="Enter description"
                                  v-model="ManageCreditNoteObj.DESCRIPTION"
                                >
                                </textarea>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Amount</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="ManageCreditNoteObj.AMOUNT"
                                  disabled
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row mt-4 mb-4">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                type="button"
                                class="custom-btn custom-success-btn"
                                @click="AddManageCreditnote"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>

                        <!--Manage Credit Note Table View-->
                        <div class="content-body-wrapper mt-4">
                          <div class="row">
                            <div
                              class="col-md-12 animate__animated animate__zoomIn"
                            >
                              <div class="card-wrap shadow-main">
                                <div class="card-wrap-body">
                                  <div class="data-table master-table">
                                    <b-table
                                      striped
                                      hover
                                      :items="
                                        ManageCreditNoteDetailsArray.CREDIT_NOTE_DETAILS
                                      "
                                      :fields="CREDIT_NOTE_DETAILS_FIELDS"
                                      class="table table-bordered mt-3"
                                    >
                                      <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                      </template>
                                      <template v-slot:cell(Action)="row">
                                        <div class="text-nowrap table-btn">
                                          <button
                                            type="button"
                                            class="custom-btn p-0"
                                            data-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            @click="
                                              UpdateModalShow(
                                                row.item,
                                                '#manage_credit_note_update_modal'
                                              )
                                            "
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-edit"
                                            >
                                              <path
                                                d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                              ></path>
                                              <path
                                                d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                              ></path>
                                            </svg>
                                          </button>
                                          <button
                                            type="button"
                                            class="custom-btn p-0"
                                            @click="
                                              deleteManageCreditNote(row.item)
                                            "
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-trash-2"
                                            >
                                              <polyline
                                                points="3 6 5 6 21 6"
                                              ></polyline>
                                              <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                              ></path>
                                              <line
                                                x1="10"
                                                y1="11"
                                                x2="10"
                                                y2="17"
                                              ></line>
                                              <line
                                                x1="14"
                                                y1="11"
                                                x2="14"
                                                y2="17"
                                              ></line>
                                            </svg>
                                          </button>
                                        </div>
                                      </template>
                                    </b-table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- </div>
             </div> -->
                        </div>
                      </b-tab>

                      <!--Credit Note Tab-->
                      <b-tab title="Credit Note" @click="getCreditNoteDetails">
                        <div class="col-md-12" style="text-align: right">
                          <button
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportcredit"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>

                        <div class="row mt-4">
                          <div class="col-md-2 mt-2">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                creditnote_doc_obj.CREDIT_NOTE_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              v-model="
                                creditnote_doc_obj.CREDIT_NOTE_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                          <div class="col-md-2">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="AddInvoiceNoAndDateForCreditNote"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px"
                                    >TAX CREDIT NOTE</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4 mb-4">
                            <div class="col-md-8">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-4"><p>To</p></div>
                                      <div class="col-md-8">
                                        :<span>
                                          {{
                                            this.DynamicproductFlagArr
                                              .CUSTOMER_NAME
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row mt-1">
                                      <div class="col-md-4"><p>Address</p></div>
                                      <div class="col-md-8">
                                        :

                                        <span class="ms-1">
                                          {{
                                            this.DynamicproductFlagArr
                                              .CUSTOMER_ADDRESS
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row mt-1">
                                      <div class="col-md-4">
                                        <p>Vehicle Registration No.</p>
                                      </div>
                                      <div class="col-md-8">
                                        :<span>
                                          {{
                                            this.DynamicproductFlagArr
                                              .VEHICLE_NUMBER
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row mt-1">
                                      <div class="col-md-4">
                                        <p>Engine No./Motor No.</p>
                                      </div>
                                      <div class="col-md-8">
                                        :<span>
                                          {{
                                            this.DynamicproductFlagArr.ENGINE_NO
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row mt-1">
                                      <div class="col-md-4">
                                        <p>Chassis Number</p>
                                      </div>
                                      <div class="col-md-8">
                                        :<span>
                                          {{
                                            this.DynamicproductFlagArr.CHASIS_NO
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <p>Credit Note No.</p>
                                      </div>
                                      <div class="col-md-6">
                                        <span
                                          >:&nbsp;{{
                                            this.DynamicproductFlagArr
                                              .CREDIT_NOTE_NO
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-6"><p>Date</p></div>

                                      <div class="col-md-6">
                                        <span
                                          >:&nbsp;{{
                                            this.DynamicproductFlagArr
                                              .CREDIT_NOTE_DATE
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <p>Agreement No:</p>
                                      </div>
                                      <div class="col-md-6">
                                        <span
                                          >:
                                          {{
                                            this.DynamicproductFlagArr
                                              .SALES_AGREEMENT_NUMBER
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="content-body-2">
                                <table class="table table-borderless">
                                  <thead
                                    style="
                                      border-top: 2px solid #212121;
                                      border-bottom: 2px solid #212121;
                                    "
                                  >
                                    <tr>
                                      <th scope="col">Description of Goods</th>
                                      <th scope="col">Qty</th>
                                      <th scope="col">Unit Price</th>
                                      <th scope="col">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    v-for="(
                                      user_deta, index
                                    ) in DynamicproductFlagArr.CREDIT_NOTE_DETAILS"
                                    :key="index"
                                  >
                                    <tr>
                                      <td>{{ user_deta.DESCRIPTION }}</td>
                                      <td>{{ user_deta.QTY }}</td>
                                      <td>$ {{ user_deta.UNIT_PRICE }}</td>
                                      <td>$ {{ user_deta.AMOUNT }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div
                                class="content-footer"
                                style="border: 1px solid #212121"
                              >
                                <table class="table table-borderless mb-0">
                                  <tbody>
                                    <tr>
                                      <!-- <th scope="col">Qty</th> -->
                                      <td></td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        Amount SGD$
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        ${{
                                          this.DynamicproductFlagArr
                                            .V_UNIT_TOTAL_AMOUNT
                                        }}
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        Terms of Payment:
                                        {{
                                          this.DynamicproductFlagArr
                                            .PAYMENT_TERMS
                                        }}
                                        <span class="MS-2"> </span>
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        Tax Amount $ <span class="ms-1"> </span>
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        ${{
                                          this.DynamicproductFlagArr
                                            .V_TAX_TOTAL_AMOUNT
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        All cheques issued must be made in the
                                        favour of Lek Auto Pte Ltd
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        <span class="ms-1">Total SGD $ </span>
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        $
                                        {{
                                          this.DynamicproductFlagArr
                                            .V_GRAND_TOTAL_AMOUNT
                                        }}
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <!-- Credit Note Section Start -->

                <!-- Cost Center Details Section Start -->
                <div
                  class="col-md-12 mt-3"
                  id="cost_center_det"
                  ref="cost-center"
                >
                  <h5 class="line-heading"><span>Cost Center Details</span></h5>
                  <div class="col-md-12">
                    <b-tabs>
                      <!-- Add Vehicle Cost Tab -->
                      <b-tab
                        title="Add Vehicle Cost"
                        active
                        @click="resetVehicleCostCenterForm"
                      >
                        <div aria-labelledby="flush-headingOne" class="mt-3">
                          <div class="p-2">
                            <div class="col-md-12" id="miscellenous_det">
                              <div class="row">
                                <!-- Cost Cenetr Left Side Form-->
                                <div class="col-md-6">
                                  <!-- <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2"
                                        >Cost Type</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        class="form-control roleddl"
                                        v-model="
                                          VehicleCostCenterForm.COST_TYPE
                                        "
                                      >
                                        <option value="">Select</option>
                                        <option
                                          v-for="system_role_detail in CostTypeDetailsList"
                                          :key="
                                            system_role_detail.SYSTEM_ROLE_SYS_ID
                                          "
                                          :value="system_role_detail.COST_TYPE"
                                        >
                                          {{ system_role_detail.COST_TYPE }}
                                        </option>
                                      </select>
                                    </div>
                                  </div> -->
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2"
                                        >Invoice Number</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="
                                          VehicleCostCenterForm.INVOICE_NO
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-2">
                                    <div class="col-md-4">
                                      <label class="frm-label-2"
                                        >Required PV
                                      </label>
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        class="form-control"
                                        v-model="
                                          VehicleCostCenterForm.REQUIRE_PAYMENT_VOUCHER
                                        "
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      VehicleCostCenterForm.REQUIRE_PAYMENT_VOUCHER ==
                                      '1'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2"
                                        >PV Number</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="VehicleCostCenterForm.PV_NO"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      VehicleCostCenterForm.REQUIRE_PAYMENT_VOUCHER ==
                                      '1'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2"
                                        >PV Date</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="VehicleCostCenterForm.PV_DATE"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-2">
                                    <div class="col-md-4">
                                      <label class="frm-label-2"
                                        >Payment Mode
                                      </label>
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        class="form-control"
                                        v-model="
                                          VehicleCostCenterForm.PAYMENT_MODE
                                        "
                                      >
                                        <option value="">Select</option>
                                        <option value="GIRO">GIRO</option>
                                        <option value="TT">TT</option>
                                        <option value="Cheque">Cheque</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="row mt-2">
                                    <div class="col-md-4">
                                      <label class="frm-label align-right"
                                        >GST</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        v-model="
                                          VehicleCostCenterForm.TAX_SYS_ID
                                        "
                                        class="form-control"
                                        @change="
                                          globalCalculateGST('COST_CENTER')
                                        "
                                      >
                                        <option value="">Select</option>
                                        <option
                                          v-for="tax_in in tax_info_details"
                                          :value="tax_in.TAX_SYS_ID"
                                          :key="tax_in.TAX_SYS_ID"
                                        >
                                          {{ tax_in.TAX_NAME }}-{{
                                            tax_in.TAX_PERCENTAGE
                                          }}
                                          %
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <!-- <div class="row mt-2">
                                        <div class="col-md-4">
                                          <label class="frm-label-2"
                                            >Detail Description</label
                                          >
                                        </div>
                                        <div class="col-md-8">
                                          <textarea
                                            style="height: 6.5em"
                                            type="text"
                                            class="form-control"
                                            v-model="
                                              VehicleCostCenterForm.DETAIL_DESCRIPTION
                                            "
                                          >
                                          </textarea>
                                        </div>
                                      </div> -->
                                </div>

                                <!-- Cost Cenetr Right Side Form-->
                                <div class="col-md-6">
                                  <!-- <div class="row mt-2">
                                        <div class="col-md-4">
                                          <label class="frm-label-2"
                                            >Amount($)</label
                                          >
                                        </div>
                                        <div class="col-md-8">
                                          <input
                                            @keyup="
                                              globalCalculateGST('COST_CENTER')
                                            "
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="
                                              VehicleCostCenterForm.AMOUNT_BEF_GST
                                            "
                                          />
                                        </div>
                                      </div> -->

                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2">
                                        To/From</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        @change="onChangeCostCenterToFrom"
                                        v-model="VehicleCostCenterForm.TO_FROM"
                                        class="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option value="Vendor">Vendor</option>
                                        <option value="Customer">
                                          Customer
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Vendor Info</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <b-input-group>
                                        <b-form-input
                                          id="SELLER_VENDOR_INFO"
                                          @change="onChnageCostCenterVendorInfo"
                                          @keyup="onKeyUpCostCenterVendorInfo"
                                          v-model="COST_CENTER_VENDOR_INFO"
                                          placeholder="Enter vendor"
                                          list="costCenterVendorlistOptions"
                                        ></b-form-input>
                                        <b-input-group-append>
                                          <b-button
                                            variant="info"
                                            data-bs-toggle="modal"
                                            data-bs-target="#vendor_modal"
                                          >
                                            <i class="fa-solid fa-plus"></i>
                                          </b-button>
                                        </b-input-group-append>
                                      </b-input-group>
                                      <datalist
                                        id="costCenterVendorlistOptions"
                                      >
                                        <option
                                          v-for="vendor_info in AllCostCenterVendorDetailsArray"
                                          :value="vendor_info.VENDOR_NAME"
                                          :key="vendor_info.VENDOR_SYS_ID"
                                          :id="vendor_info.VENDOR_SYS_ID"
                                        ></option>
                                      </datalist>
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      DisplayCostCenterVendorCode != '' &&
                                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Vendor Code</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        v-model="DisplayCostCenterVendorCode"
                                        id="VendorCode"
                                        name="VendorCode"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      DisplayCostCenterVendorName != '' &&
                                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Vendor Name</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        v-model="DisplayCostCenterVendorName"
                                        id="VendorName"
                                        name="VendorName"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      DisplayCostCenterVendorAddress != '' &&
                                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Vendor Address</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <textarea
                                        v-model="DisplayCostCenterVendorAddress"
                                        style="height: 6em"
                                        id="Seller Vendor Address"
                                        name="Seller Vendor Address"
                                        class="form-control"
                                        disabled
                                      >
                                      </textarea>
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      VehicleCostCenterForm.TO_FROM ==
                                      'Customer'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Customer Info</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <b-input-group>
                                        <b-form-input
                                          id="COST_CENTER_CUSTOMER_INFO"
                                          @change="
                                            onChnageCostCenterCustomerInfo
                                          "
                                          @keyup="onKeyUpCostCenterCustomerInfo"
                                          v-model="COST_CENTER_CUSTOMER_INFO"
                                          placeholder="Enter customer"
                                          list="CostCenterCustomerlistOptions"
                                        ></b-form-input>
                                        <b-input-group-append>
                                          <b-button
                                            variant="info"
                                            data-bs-toggle="modal"
                                            data-bs-target="#employee_modal"
                                          >
                                            <i class="fa-solid fa-plus"></i>
                                          </b-button>
                                        </b-input-group-append>
                                      </b-input-group>
                                      <datalist
                                        id="CostCenterCustomerlistOptions"
                                      >
                                        <option
                                          v-for="customer_info in AllCostCenterCustomerDetailsArray"
                                          :value="customer_info.CUSTOMER_NAME"
                                          :key="customer_info.ID_NUMBER"
                                        ></option>
                                      </datalist>
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      DisplayCostCenterCustomerType != '' &&
                                      VehicleCostCenterForm.TO_FROM ==
                                        'Customer'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Customer Type</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        v-model="DisplayCostCenterCustomerType"
                                        id="Seller Customer Type"
                                        name="Seller Customer Type"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      DisplayCostCenterCustomerType ==
                                        'individual' ||
                                      (DisplayCostCenterCustomerType ==
                                        'INDIVIDUAL' &&
                                        VehicleCostCenterForm.TO_FROM ==
                                          'Customer')
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >NRIC Number</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        v-model="
                                          DisplayCostCenterCustomerIdNumber
                                        "
                                        id="Seller NRIC Number"
                                        name="Seller NRIC Number"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      DisplayCostCenterCustomerType ==
                                        'Company' ||
                                      (DisplayCostCenterCustomerType ==
                                        'COMPANY' &&
                                        VehicleCostCenterForm.TO_FROM ==
                                          'Customer')
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >UEN Number</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        v-model="
                                          DisplayCostCenterCustomerIdNumber
                                        "
                                        id="Seller UEN Number"
                                        name="Seller UEN Number"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      DisplayCostCenterCustomerName != '' &&
                                      VehicleCostCenterForm.TO_FROM ==
                                        'Customer'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Customer Name</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        v-model="DisplayCostCenterCustomerName"
                                        id="VendorName"
                                        name="VendorName"
                                        class="form-control"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    class="row mt-2"
                                    v-if="
                                      DisplayCostCenterCustomerAddress != '' &&
                                      VehicleCostCenterForm.TO_FROM ==
                                        'Customer'
                                    "
                                  >
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Customer Address</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <textarea
                                        v-model="
                                          DisplayCostCenterCustomerAddress
                                        "
                                        style="height: 4em"
                                        id="Seller Customer Address"
                                        name="Seller Customer Address"
                                        class="form-control"
                                        disabled
                                      >
                                      </textarea>
                                    </div>
                                  </div>

                                  <!-- <div class="row mt-2">
                                        <div class="col-md-4">
                                          <label class="frm-label-2"
                                            >GST Amt.($)</label
                                          >
                                        </div>
                                        <div class="col-md-8">
                                          <input
                                            disabled
                                            type="text"
                                            class="form-control"
                                            placeholder=""
                                            v-model="
                                              VehicleCostCenterForm.GST_AMOUNT
                                            "
                                          />
                                        </div>
                                      </div> -->
                                  <!-- <div class="row mt-2">
                                        <div class="col-md-4">
                                          <label class="frm-label-2"
                                            >Total Amount($)</label
                                          >
                                        </div>
                                        <div class="col-md-8">
                                          <input
                                            disabled
                                            type="text"
                                            class="form-control"
                                            placeholder=""
                                            v-model="
                                              VehicleCostCenterForm.TOTAL_AMOUNT
                                            "
                                          />
                                        </div>
                                      </div> -->
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="table-responsive">
                                  <table class="display table">
                                    <thead>
                                      <tr>
                                        <th scope="col-1">Cost Type</th>
                                        <th scope="col-2">
                                          Detail Description
                                        </th>
                                        <th scope="col-1">
                                          Amount Without GST(S$)
                                        </th>
                                        <th scope="col-1">
                                          <button
                                            type="button"
                                            class="custom-btn custom-success-btn"
                                            @click="addMoreAdditionalCostType"
                                          >
                                            <svg
                                              viewBox="0 0 24 24"
                                              width="20"
                                              height="20"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="css-i6dzq1"
                                            >
                                              <rect
                                                x="3"
                                                y="3"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                              ></rect>
                                              <line
                                                x1="12"
                                                y1="8"
                                                x2="12"
                                                y2="16"
                                              ></line>
                                              <line
                                                x1="8"
                                                y1="12"
                                                x2="16"
                                                y2="12"
                                              ></line>
                                            </svg>
                                            Add
                                          </button>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(
                                          act, index
                                        ) in AdditionalCostType"
                                        :key="act.DETAIL_DESCRIPTION + index"
                                      >
                                        <td>
                                          <select
                                            style="height: 3em; width: 15em"
                                            class="form-control roleddl"
                                            :value="
                                              AdditionalCostType[index]
                                                .COST_TYPE
                                            "
                                            @change="
                                              onAddDetailsDescriptionData(
                                                index,
                                                'COST_TYPE',
                                                $event
                                              )
                                            "
                                          >
                                            <option value="">Select</option>
                                            <option
                                              v-for="system_role_detail in CostTypeDetailsList"
                                              :key="
                                                system_role_detail.SYSTEM_ROLE_SYS_ID
                                              "
                                              :value="
                                                system_role_detail.COST_TYPE
                                              "
                                            >
                                              {{ system_role_detail.COST_TYPE }}
                                            </option>
                                          </select>
                                        </td>
                                        <td>
                                          <textarea
                                            type="text"
                                            class="form-control"
                                            style="height: 3em; width: 25em"
                                            :value="
                                              AdditionalCostType[index]
                                                .DETAIL_DESCRIPTION
                                            "
                                            @change="
                                              onAddDetailsDescriptionData(
                                                index,
                                                'DETAIL_DESCRIPTION',
                                                $event
                                              )
                                            "
                                          >
                                          </textarea>
                                        </td>
                                        <td>
                                          <input
                                            @change="
                                              onAddDetailsDescriptionData(
                                                index,
                                                'AMOUNT_BEF_GST',
                                                $event
                                              )
                                            "
                                            style="height: 3em; width: 18em"
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            :value="
                                              AdditionalCostType[index]
                                                .AMOUNT_BEF_GST
                                            "
                                          />
                                        </td>
                                        <td
                                          style="width: 10em"
                                          v-if="AdditionalCostType.length > 1"
                                        >
                                          <button
                                            type="button"
                                            class="custom-btn p-0 mt-2"
                                            @click="
                                              removeAdditionalCostType(index)
                                            "
                                          >
                                            <svg
                                              viewBox="0 0 24 24"
                                              width="18"
                                              height="18"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="css-i6dzq1"
                                            >
                                              <polyline
                                                points="3 6 5 6 21 6"
                                              ></polyline>
                                              <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                              ></path>
                                              <line
                                                x1="10"
                                                y1="11"
                                                x2="10"
                                                y2="17"
                                              ></line>
                                              <line
                                                x1="14"
                                                y1="11"
                                                x2="14"
                                                y2="17"
                                              ></line>
                                            </svg>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2">
                                    GST Amount :&nbsp;<b
                                      >S$
                                      {{ VehicleCostCenterForm.GST_AMOUNT }}</b
                                    >
                                  </label>
                                </div>
                                <div class="col-md-4">
                                  <label class="frm-label-2">
                                    Total Amount Without GST:&nbsp;<b
                                      >S$
                                      {{
                                        VehicleCostCenterForm.AMOUNT_BEF_GST
                                      }}</b
                                    >
                                  </label>
                                </div>
                                <div class="col-md-4">
                                  <label class="frm-label-2">
                                    Total Amount With GST :&nbsp;<b
                                      >S$
                                      {{
                                        VehicleCostCenterForm.TOTAL_AMOUNT
                                      }}</b
                                    >
                                  </label>
                                </div>
                              </div>
                              <div class="row mt-4">
                                <div class="col-md-4">
                                  <button
                                    style="width: 120px"
                                    type="button"
                                    class="custom-btn custom-success-btn"
                                    @click="addVehicleCostCenterDetails"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!-- View Vehicle Cost Tab -->
                      <b-tab
                        title="View Vehicle Cost"
                        @click="
                          getCostCenterDetailsList(
                            VehicleCostCenterForm.VEHICLE_SYS_ID
                          )
                        "
                      >
                        <div aria-labelledby="flush-headingOne" class="mt-3">
                          <div class="master-table">
                            <b-table
                              class="table table-bordered"
                              striped
                              hover
                              :items="CostCenterDetailsList"
                              :fields="ViewCostCenterFields"
                              @row-clicked="clickVehicleCostCenterDetailsRow"
                            >
                              <template v-slot:cell(Action)="row">
                                <div class="text-nowrap table-btn">
                                  <button
                                    type="button"
                                    class="custom-btn p-0"
                                    data-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    @click="
                                      UpdateModalShow(
                                        row.item,
                                        '#pay_invoice_update_modal'
                                      )
                                    "
                                  >Pay Invoice
                                   
                                  </button>


                                  <button
                                    type="button"
                                    class="custom-btn p-0"
                                    data-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    @click="
                                      UpdateModalShow(
                                        row.item,
                                        '#cost_center_update_modal'
                                      )
                                    "
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-edit"
                                    >
                                      <path
                                        d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                      ></path>
                                      <path
                                        d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                      ></path>
                                    </svg>
                                  </button>
                                  <button
                                    type="button"
                                    class="custom-btn p-0"
                                    @click="deleteVehicleCost(row.item)"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-trash-2"
                                    >
                                      <polyline
                                        points="3 6 5 6 21 6"
                                      ></polyline>
                                      <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                      ></path>
                                      <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                      ></line>
                                      <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                      ></line>
                                    </svg>
                                  </button>
                                  <button
                                    v-if="row.item.REQUIRE_PAYMENT_VOUCHER == 1"
                                    title="Download Payment Voucher"
                                    @click="generatePaymentVoucher(row.item)"
                                    type="button"
                                    class="custom-btn p-0"
                                  >
                                    <i
                                      style="font-size: 1.2em; margin-top: 8px"
                                      class="fas fa-file-download"
                                    ></i>
                                  </button>
                                </div>
                              </template>
                            </b-table>
                          </div>
                        </div>
                      </b-tab>

                      <!-- Floor Stock Interest Tab -->
                      <b-tab
                        title="Floor Stock"
                        @click="
                          calculateNoOfDaysInFoorStock(),
                            getFloorStockInterestDetails()
                        "
                      >
                        <div
                          class="col-md-12 mt-4"
                          style="text-align: center"
                          v-if="
                            floorstock.E_TRANSFER_IN_DATE == '' &&
                            floorstock.E_TRANSFER_OUT_DATE != ''
                          "
                        >
                          <h5 style="color: red">
                            Can't Find E-Transfer in Date From Seller Details.
                          </h5>
                        </div>
                        <div
                          class="col-md-12 mt-4"
                          style="text-align: center"
                          v-if="
                            floorstock.E_TRANSFER_OUT_DATE == '' &&
                            floorstock.E_TRANSFER_IN_DATE != ''
                          "
                        >
                          <h5 style="color: red">
                            Can't Find E-Transfer Out Date From Buyer Details.
                          </h5>
                        </div>
                        <div
                          class="col-md-12 mt-4"
                          style="text-align: center"
                          v-if="
                            floorstock.E_TRANSFER_OUT_DATE == '' &&
                            floorstock.E_TRANSFER_IN_DATE == ''
                          "
                        >
                          <h5 style="color: red">
                            Can't Find E-Transfer In & Out Date From Buyer &
                            Seller Details.
                          </h5>
                        </div>
                        <div
                          class="col-md-12"
                          v-if="
                            floorstock.E_TRANSFER_OUT_DATE != '' &&
                            floorstock.E_TRANSFER_IN_DATE != ''
                          "
                        >
                          <div class="row mt-1">
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Floor stock interest</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    @keyup="calculateFloorStock"
                                    type="text"
                                    v-model="floorstock.FLOOR_STOCK_INTEREST"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3"></div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >E-Transfer in Date</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    v-model="floorstock.E_TRANSFER_IN_DATE"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >E-Transfer out Date
                                  </label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    v-model="floorstock.E_TRANSFER_OUT_DATE"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >No. of Days <span style="color: red"></span
                                  ></label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    disabled
                                    type="number"
                                    @keyup="calculatefloorstock()"
                                    v-model="floorstock.NO_OF_DAYS"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-md-6 mt-3">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label"
                                        >Purchase Price
                                        <span style="color: red"></span
                                      ></label>
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                      v-model="floorstock.PURCHASE_PRICE"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                </div> -->
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >Floor Stock amount
                                    <span style="color: red"></span
                                  ></label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="number"
                                    disabled
                                    v-model="this.floorstock.FLOOR_STOCK_AMOUNT"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="row mt-4 mb-4">
                                <div class="col-md-4">
                                  <button
                                    style="width: 120px"
                                    type="button"
                                    class="custom-btn custom-success-btn"
                                    @click="createFloorstock()"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="master-table mt-3">
                        <b-table
                          class="table table-bordered"
                          striped
                          hover
                          :items="CostCreatedPurchaseList"
                          :fields="CostCreatedPurchaseListFields"
                        >
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>
                        </b-table>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="master-table mt-3">
                        <b-table
                          class="table table-bordered"
                          striped
                          hover
                          :items="CostCreatedSalesList"
                          :fields="CostCreatedSalesListFields"
                        >
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>

                  <h5 class="mt-3">Vehicle Cost Created</h5>
                  <div class="master-table mt-3">
                    <b-table
                      class="table table-bordered"
                      striped
                      hover
                      :items="CostCenterDetailsList"
                      :fields="CostCenterDetailsListFields"
                      @row-clicked="clickVehicleCostCenterDetailsRow"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                    </b-table>
                  </div>
                </div>
                <!-- Cost Center Details Section End -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Customer Modal -->
      <div
        class="modal modal-right fade"
        id="employee_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="width: 55% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Customer</h5>
              <button
                type="button"
                class="btn-normal modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6 mt-2">
                  <label class="frm-label">
                    Customer Type <span style="color: red">*</span>
                  </label>
                  <select
                    id="customertype"
                    v-model="create_customer.CUSTOMER_TYPE"
                    class="form-control"
                  >
                    <option value="">Select</option>
                    <option value="individual">Individual</option>
                    <option value="Company">Company</option>
                  </select>
                </div>
                <div
                  class="individual box"
                  v-if="create_customer.CUSTOMER_TYPE == 'individual'"
                >
                  <ValidationObserver ref="cus">
                    <div class="row">
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label">
                            NRIC Number<span style="color: red">*</span>
                          </label>
                          <input
                            type="text"
                            name="NRIC Number"
                            v-model.trim="create_customer.ID_NUMBER"
                            class="form-control"
                            placeholder="Enter NRIC Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.ID_NUMBER != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label">
                            Full Name <span style="color: red">*</span>
                          </label>
                          <input
                            type="name"
                            name="Full Name"
                            v-model.trim="create_customer.CUSTOMER_NAME"
                            class="form-control"
                            placeholder="Enter full Name"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.CUSTOMER_NAME != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <label class="frm-label"> Mobile Number </label>
                        <input
                          type="name"
                          name="Mobile.NO"
                          v-model.trim="create_customer.CUSTOMER_CONTACT"
                          class="form-control"
                          placeholder="Enter Mobile Number"
                        />
                      </div>
                      <div class="col-md-4 mt-3">
                        <label class="frm-label"> Email ID </label>
                        <input
                          type="text"
                          name="Email Id"
                          v-model.trim="create_customer.GMAIL_ID"
                          class="form-control"
                          placeholder="Enter Email ID"
                        />
                      </div>
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label">
                            post Code <span style="color: red">*</span>
                          </label>
                          <input
                            type="number"
                            name="Post code"
                            v-model.trim="create_customer.PIN_CODE"
                            class="form-control"
                            placeholder="Enter Post Code"
                            maxlength="6"
                            minlength="6"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.PIN_CODE != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-8 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label">
                            Address Line 1<span style="color: red">*</span>
                          </label>
                          <textarea
                            type="name"
                            style="height: 5em"
                            name="Address 1"
                            v-model.trim="create_customer.ADDRESS_LINE_1"
                            class="form-control"
                            placeholder="Enter Address"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.ADDRESS_LINE_1 != '',
                            }"
                          >
                          </textarea>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <ValidationProvider v-slot="{ errors }">
                          <label class="frm-label">
                            Country <span style="color: red">*</span>
                          </label>
                          <b-form-select
                            name="Country"
                            v-model="create_customer.COUNTRY_NAME"
                            :class="{
                              'form-control': true,
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.COUNTRY_NAME != '',
                            }"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Select --</b-form-select-option
                              >
                            </template>
                            <option value="Singapore">Singapore</option>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="modal-footer">
                        <div class="row mt-4">
                          <div class="col-md-3">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="Addcustomer"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-2">
                            <button
                              type="button"
                              class="custom-btn custom-danger-btn"
                              data-bs-dismiss="modal"
                              @click="resetAddCustomerForm"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>

                <div
                  class="Company box"
                  v-if="create_customer.CUSTOMER_TYPE == 'Company'"
                >
                  <ValidationObserver ref="bankfo">
                    <div class="row">
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label">
                            UEN Number <span style="color: red">*</span>
                          </label>
                          <input
                            type="text"
                            name="UEN Number"
                            v-model.trim="create_company.ID_NUMBER"
                            class="form-control"
                            placeholder="Enter UEN Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.ID_NUMBER != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label">
                            Company Name<span style="color: red">*</span>
                          </label>
                          <input
                            type="name"
                            name="Company Name"
                            v-model.trim="create_company.CUSTOMER_NAME"
                            class="form-control"
                            placeholder="Enter Company Name"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.CUSTOMER_NAME != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <label class="frm-label">Person Incharge</label>
                        <input
                          type="name"
                          name="person incharge"
                          v-model.trim="create_company.PERSON_INCHARGE"
                          class="form-control"
                          placeholder="Enter Person incharge"
                        />
                      </div>

                      <div class="col-md-4 mt-3">
                        <label class="frm-label"> Mobile Number </label>
                        <input
                          type="name"
                          name="Mobile Number"
                          v-model.trim="create_company.CUSTOMER_CONTACT"
                          class="form-control"
                          placeholder="Enter Mobile Number"
                        />
                      </div>
                      <div class="col-md-4 mt-3">
                        <label class="frm-label">Email ID</label>
                        <input
                          type="text"
                          name="gmail"
                          v-model.trim="create_company.GMAIL_ID"
                          class="form-control"
                          placeholder="Enter Email ID"
                        />
                      </div>
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label">
                            post Code <span style="color: red">*</span>
                          </label>
                          <input
                            type="name"
                            name="Post code"
                            v-model.trim="create_company.PIN_CODE"
                            class="form-control"
                            placeholder="Enter Post Code"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.PIN_CODE != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-8 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label">
                            Address Line 1<span style="color: red">*</span>
                          </label>
                          <textarea
                            type="name"
                            style="height: 5em"
                            name="address line 1"
                            v-model.trim="create_company.ADDRESS_LINE_1"
                            class="form-control"
                            placeholder="Enter Address"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.ADDRESS_LINE_1 != '',
                            }"
                          >
                          </textarea>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider v-slot="{ errors }">
                          <label class="frm-label">
                            Country <span style="color: red">*</span>
                          </label>
                          <b-form-select
                            name="Country"
                            v-model="create_company.COUNTRY_NAME"
                            :class="{
                              'form-control': true,
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.COUNTRY_NAME != '',
                            }"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Select --</b-form-select-option
                              >
                            </template>
                            <option value="Singapore">Singapore</option>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="modal-footer">
                        <div class="row mt-4">
                          <div class="col-md-3">
                            <button
                              @click="Addcompany"
                              type="button"
                              class="custom-btn custom-success-btn"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-2">
                            <button
                              @click="resetAddCustomerForm"
                              type="button"
                              class="custom-btn custom-danger-btn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add Vendor Modal -->
      <div
        class="modal modal-right fade"
        id="vendor_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="width: 55% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Vendor</h5>
              <button
                type="button"
                class="btn-normal modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
            <div class="modal-body">
              <ValidationObserver ref="bankform2">
                <form>
                  <div class="row">
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Vendor Code <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="Vendorcode"
                          v-model.trim="create_vendor.VENDOR_CODE"
                          class="form-control"
                          placeholder="Enter Vendor Code"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_vendor.VENDOR_CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Vendor Name <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="Vendor Name"
                          v-model.trim="create_vendor.VENDOR_NAME"
                          class="form-control"
                          placeholder="Enter Vendor Name"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_vendor.VENDOR_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label">
                          Person Number <span style="color: red">*</span></label
                        >
                        <input
                          type="number"
                          name="Person Number"
                          v-model.trim="create_vendor.CONTACT_PERSON_NUMBER"
                          class="form-control"
                          placeholder="Enter Person Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_vendor.CONTACT_PERSON_NUMBER != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Person Name <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="Person Name"
                          v-model.trim="create_vendor.CONTACT_PERSON_NAME"
                          class="form-control"
                          placeholder="Enter Person Name"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_vendor.CONTACT_PERSON_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-8 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Address Line 1
                          <span style="color: red">*</span></label
                        >
                        <textarea
                          style="height: 5em"
                          type="text"
                          name="Address Line 1"
                          v-model.trim="create_vendor.ADDRESS_LINE_1"
                          class="form-control"
                          placeholder="Enter Address"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_vendor.ADDRESS_LINE_1 != '',
                          }"
                        >
                        </textarea>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Post Code <span style="color: red">*</span></label
                        >
                        <input
                          type="number"
                          name="pin Code"
                          v-model.trim="create_vendor.PIN_CODE"
                          class="form-control"
                          placeholder="Enter Post Code "
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_vendor.PIN_CODE != null,
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider v-slot="{ errors }">
                        <label class="frm-label"
                          >Country <span style="color: red">*</span></label
                        >
                        <b-form-select
                          name="Country"
                          v-model="create_vendor.COUNTRY_SYS_ID"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_vendor.COUNTRY_SYS_ID != '',
                          }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled>
                              -- Select --
                            </b-form-select-option>
                          </template>
                          <option value="Singapore">Singapore</option>
                        </b-form-select>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="modal-footer-2">
                      <div class="row mt-4">
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            data-bs-dismiss="modal"
                            @click="Addvendor"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="custom-btn custom-danger-btn"
                            data-bs-dismiss="modal"
                            @click="resetAddCustomerForm"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>

      <!-- Seller AP Payment Update Modal -->
      <div
        class="modal modal-right fade"
        id="sellerAPPaymentUpdateModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="width: 55% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update AP Payment
              </h5>
              <button
                type="button"
                class="btn-normal modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
            <div class="accordion-body p-2 mt-2">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span style="font-weight: 580"
                          >Total Receivable Amount:&nbsp;S$
                          <span style="font-weight: bold">{{
                            SELLER_AP_PURCHASE_AMOUNT
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span style="font-weight: 580"
                          >Balance:&nbsp;S$
                          <span style="font-weight: bold">{{
                            SELLER_AP_REDUCED_PURCHASE_AMOUNT
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">Bank</label>
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="updat_user.BANK_SYS_ID"
                              @change="getBankNameForUpdateAPPayment()"
                              class="form-control"
                            >
                              <option value="">Select</option>
                              <option
                                v-for="item in Banklist"
                                :value="item.BANK_SYS_ID"
                                :key="item.BANK_SYS_ID"
                              >
                                {{ item.BANK_NAME }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">
                              Cheque Number
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="updat_user.CHEQUE_NO"
                              type="text"
                              class="form-control"
                              placeholder="Enter Cheque No."
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">
                              To/From Who
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="updat_user.TO_FROM_WHOM"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">
                              AP Balance($)<span style="color: red"></span>
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              disabled
                              v-model="SELLER_AP_REDUCED_RECEIVABLE_AMOUNT"
                              class="form-control"
                              placeholder="Enter AP Balance"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">
                              Amount($)<span style="color: red"></span>
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              @keyup="updateAPAmountCalculationForSeller"
                              v-model="updat_user.AMOUNT"
                              type="text"
                              class="form-control"
                              placeholder="Enter Amount"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">
                              Payment Mode
                            </label>
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="updat_user.PAYMENT_MODE"
                              class="form-control"
                            >
                              <option value="">Select</option>
                              <option value="GIRO">GIRO</option>
                              <option value="TT">TT</option>
                              <option value="Cheque">Cheque</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">
                              Payment Date<span style="color: red"></span>
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="updat_user.CHEQUE_DATE"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">
                              Add Remarks
                            </label>
                          </div>
                          <div class="col-md-8">
                            <textarea
                              v-model="updat_user.REMARKS"
                              class="custom-textarea form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="row mt-4">
                      <div class="col-md-2">
                        <button
                          @click="updateSellerAPPaymentDetails"
                          type="button"
                          class="custom-btn custom-success-btn"
                        >
                          Update
                        </button>
                        <br />
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Buyer AR Payment Update Modal -->
      <div
        class="modal modal-right fade"
        id="buyerAPPaymentUpdateModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" style="width: 55% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update AR Payment
              </h5>
              <button
                type="button"
                class="btn-normal modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
            <div class="accordion-body p-2 mt-2">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-3"></div>
                      <div class="col-md-9">
                        <span style="font-weight: 580"
                          >Total Receivable Amount:&nbsp;S$
                          <span style="font-weight: bold">{{
                            VEHICLE_TOTAL_RECEIVABLE_AMOUNT
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="col-md-8">
                        <span style="font-weight: 580"
                          >Balance:&nbsp;S$
                          <span style="font-weight: bold">{{
                            VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">Bank</label>
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="updat_user.BANK_SYS_ID"
                              @change="getBankNameForBuyerARPaymentUpdate()"
                              class="form-control"
                            >
                              <option value="">Select</option>
                              <option
                                v-for="item in Banklist"
                                :value="item.BANK_SYS_ID"
                                :key="item.BANK_SYS_ID"
                              >
                                {{ item.BANK_NAME }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12 mt-2"
                        v-if="updat_user.PAYMENT_MODE == 'Cheque'"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Cheque Number</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="updat_user.CHEQUE_NO"
                              type="text"
                              class="form-control"
                              placeholder="Enter Cheque No."
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >To/From Who</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="updat_user.TO_FROM_WHOM"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">
                              AR Balance($)<span style="color: red"></span>
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              disabled
                              v-model="AR_REDUCED_RECEIVABLE_AMOUNT"
                              class="form-control"
                              placeholder="Enter AP Balance"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Amount($)<span style="color: red"></span
                            ></label>
                          </div>
                          <div class="col-md-8">
                            <input
                              @keyup="updateBuyerARAmountCalculation"
                              v-model="updat_user.AMOUNT"
                              type="text"
                              class="form-control"
                              placeholder="Enter Amount"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Payment Mode</label
                            >
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="updat_user.PAYMENT_MODE"
                              class="form-control"
                            >
                              <option value="">Select</option>
                              <option value="GIRO">GIRO</option>
                              <option value="TT">TT</option>
                              <option value="Cheque">Cheque</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Payment Date<span style="color: red"></span
                            ></label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="updat_user.CHEQUE_DATE"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right">
                              Add Remarks
                            </label>
                          </div>
                          <div class="col-md-8">
                            <textarea
                              v-model="updat_user.REMARKS"
                              class="custom-textarea form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="row mt-4">
                      <div class="col-md-2">
                        <button
                          @click="updateBuyerARPayment"
                          type="button"
                          class="custom-btn custom-success-btn"
                        >
                          Update
                        </button>
                        <br />
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Other Payment Update Modal -->
      <div
        class="modal modal-right fade"
        id="update_other_payment_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="width: 55% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update Other Payment
              </h5>
            </div>
            <div class="col-md-12 accordion-body p-3">
              <div class="row mt-1">
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Item Description</label
                      >
                    </div>
                    <div class="col-md-8">
                      <select
                        v-model="updat_user.DESCRIPTION"
                        class="form-control"
                      >
                        <option value="">Select</option>
                        <option value="Admin Fee">Admin Fee</option>
                        <option value="HP Facilities Fee">
                          HP Facilities Fee
                        </option>
                        <option value="Transfer Fee">Transfer Fee</option>
                        <option value="Road Tax">Road Tax</option>
                        <option value="Others Fee">Others Fee</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Rate With GST($)</label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="number"
                        @keyup="
                          globalCalculateGST('OTHER_PAYMENT_DETAILS_FOR_UPDATE')
                        "
                        v-model="updat_user.UNIT_PRICE"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label"
                        >TAX <span style="color: red">*</span></label
                      >
                    </div>
                    <div class="col-md-8">
                      <select
                        name="tax"
                        v-model="updat_user.TAX_SYS_ID"
                        class="form-control"
                        @change="
                          globalCalculateGST('OTHER_PAYMENT_DETAILS_FOR_UPDATE')
                        "
                      >
                        <option value="">select</option>
                        <option
                          v-for="tax_in in tax_info_details"
                          :value="tax_in.TAX_SYS_ID"
                          :key="tax_in.TAX_SYS_ID"
                        >
                          {{ tax_in.TAX_NAME }}-{{ tax_in.TAX_PERCENTAGE }}
                          %
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Tax Amount</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        v-model="updat_user.TAX_VALUE"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label
                        class="frm-label-2 align-right"
                        style="text-align: end"
                        >Gross Amount($)</label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="updat_user.TOTAL_PRICE"
                        disabled
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label
                        class="frm-label-2 align-right"
                        style="text-align: end"
                        >Rate Without GST($)</label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="updat_user.RATE_WITHOUT_TAX"
                        disabled
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 mt-2"
                  v-if="updat_user.DESCRIPTION == 'Others Fee'"
                >
                  <div class="row">
                    <div class="col-md-4">
                      <label
                        class="frm-label-2 align-right"
                        style="text-align: end"
                        >Others Description</label
                      >
                    </div>
                    <div class="col-md-8">
                      <textarea
                        v-model="updat_user.DESCRIPTION_OTHER"
                        class="custom-textarea form-control"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-4">
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-success-btn"
                    @click="UpdateOtherPayment()"
                  >
                    Update
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-danger-btn"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Manage Tax Invoice Update Modal -->
      <div
        class="modal modal-right fade"
        id="manage_tax_invoice_update_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="width: 55% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update Manage Tax Invoice
              </h5>
            </div>
            <div class="col-md-12 accordion-body p-3">
              <div class="row mt-1">
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Item Description</label
                      >
                    </div>
                    <div class="col-md-8">
                      <select
                        name="tax"
                        v-model="updat_user.DESCRIPTION"
                        class="form-control"
                      >
                        <option :value="null">select</option>
                        <option
                          v-for="system in item_description_details_for_MTI"
                          :value="system.DESCRIPTION"
                          :key="system.ITEM_LIST_SYS_ID"
                        >
                          {{ system.DESCRIPTION }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Rate</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        @keyup="
                          globalCalculateGST('MANAGE_TAX_INVOICE_FOR_UPDATE')
                        "
                        type="number"
                        v-model="updat_user.UNIT_PRICE"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Quantity</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        @keyup="
                          globalCalculateGST('MANAGE_TAX_INVOICE_FOR_UPDATE')
                        "
                        type="number"
                        v-model="updat_user.QTY"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label"
                        >TAX <span style="color: red">*</span></label
                      >
                    </div>
                    <div class="col-md-8">
                      <select
                        name="tax"
                        v-model="updat_user.TAX_SYS_ID"
                        class="form-control"
                        @change="
                          globalCalculateGST('MANAGE_TAX_INVOICE_FOR_UPDATE')
                        "
                      >
                        <option value="">select</option>
                        <option
                          v-for="tax_in in tax_info_details"
                          :value="tax_in.TAX_SYS_ID"
                          :key="tax_in.TAX_SYS_ID"
                        >
                          {{ tax_in.TAX_NAME }}-{{ tax_in.TAX_PERCENTAGE }}
                          %
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Tax Amount</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        v-model="updat_user.TAX_VALUE"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div class="row">
                    <div class="col-md-4">
                      <label
                        class="frm-label-2 align-right"
                        style="text-align: end"
                        >Gross Amount($)</label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        v-model="updat_user.TOTAL_AMOUNT"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-4">
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-success-btn"
                    @click="updateManageTaxInvoice()"
                  >
                    Update
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-danger-btn"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Manage Credit Note Update Modal -->
      <div
        class="modal modal-right fade"
        id="manage_credit_note_update_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="width: 55% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update Manage Credit Note
              </h5>
            </div>
            <div class="col-md-12 accordion-body p-3">
              <div class="row mt-1">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Payment Mode</label
                      >
                    </div>
                    <div class="col-md-8">
                      <select
                        v-model="updat_user.PAYMENT_TERMS"
                        class="form-control"
                      >
                        <option value="">Select</option>
                        <option value="GIRO">GIRO</option>
                        <option value="TT">TT</option>
                        <option value="Cheque">Cheque</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"> Quantity</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        @keyup="
                          globalCalculateGST('MANAGE_CREDIT_NOTE_FOR_UPDATE')
                        "
                        type="number"
                        v-model="updat_user.QTY"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label">TAX</label>
                    </div>
                    <div class="col-md-8">
                      <select
                        name="tax"
                        v-model="updat_user.TAX_SYS_ID"
                        class="form-control"
                        @change="
                          globalCalculateGST('MANAGE_CREDIT_NOTE_FOR_UPDATE')
                        "
                      >
                        <option value="">select</option>
                        <option
                          v-for="tax_in in tax_info_details"
                          :value="tax_in.TAX_SYS_ID"
                          :key="tax_in.TAX_SYS_ID"
                        >
                          {{ tax_in.TAX_NAME }}-{{ tax_in.TAX_PERCENTAGE }}
                          %
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Unit Price</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        @keyup="
                          globalCalculateGST('MANAGE_CREDIT_NOTE_FOR_UPDATE')
                        "
                        type="number"
                        v-model="updat_user.UNIT_PRICE"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Description</label>
                    </div>
                    <div class="col-md-8">
                      <textarea
                        style="height: 5em"
                        class="form-control"
                        v-model="updat_user.DESCRIPTION"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Amount</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="updat_user.AMOUNT"
                        disabled
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-4">
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-success-btn"
                    @click="updateManageCreditNote()"
                  >
                    Update
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-danger-btn"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Cost Center Update Modal -->
      <div
        class="modal modal-right fade"
        id="cost_center_update_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="width: 55% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update Vehicle Cost
              </h5>
            </div>
            <div class="col-md-12 accordion-body p-3">
              <div class="row">
                <!--Update Cost Cenetr Left Side Form-->
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2">Cost Type</label>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control roleddl"
                        v-model="updat_user.COST_TYPE"
                      >
                        <option value="">Select</option>
                        <option
                          v-for="system_role_detail in CostTypeDetailsList"
                          :key="system_role_detail.SYSTEM_ROLE_SYS_ID"
                          :value="system_role_detail.COST_TYPE"
                        >
                          {{ system_role_detail.COST_TYPE }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-4">
                      <label class="frm-label-2">Invoice Number</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="updat_user.INVOICE_NO"
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-4">
                      <label class="frm-label-2">Required PV </label>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        v-model="updat_user.REQUIRE_PAYMENT_VOUCHER"
                      >
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="updat_user.REQUIRE_PAYMENT_VOUCHER == '1'"
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2">PV Number</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="updat_user.PV_NO"
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-4">
                      <label class="frm-label-2"> To/From</label>
                    </div>
                    <div class="col-md-8">
                      <select
                        @change="onChangeCostCenterToFrom"
                        v-model="VehicleCostCenterForm.TO_FROM"
                        class="form-control"
                      >
                        <option value="">Select</option>
                        <option value="Vendor">Vendor</option>
                        <option value="Customer">Customer</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="VehicleCostCenterForm.TO_FROM == 'Vendor'"
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Vendor Info</label>
                    </div>
                    <div class="col-md-8">
                      <b-input-group>
                        <b-form-input
                          id="SELLER_VENDOR_INFO"
                          @change="onChnageCostCenterVendorInfo"
                          @keyup="onKeyUpCostCenterVendorInfo"
                          v-model="COST_CENTER_VENDOR_INFO"
                          placeholder="Enter vendor"
                          list="costCenterVendorlistOptions"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            data-bs-toggle="modal"
                            data-bs-target="#vendor_modal"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <datalist id="costCenterVendorlistOptions">
                        <option
                          v-for="vendor_info in AllCostCenterVendorDetailsArray"
                          :value="vendor_info.VENDOR_NAME"
                          :key="vendor_info.VENDOR_SYS_ID"
                          :id="vendor_info.VENDOR_SYS_ID"
                        ></option>
                      </datalist>
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterVendorCode != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Vendor Code</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterVendorCode"
                        id="VendorCode"
                        name="VendorCode"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterVendorName != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Vendor Name</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterVendorName"
                        id="VendorName"
                        name="VendorName"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterVendorAddress != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Vendor Address</label
                      >
                    </div>
                    <div class="col-md-8">
                      <textarea
                        v-model="DisplayCostCenterVendorAddress"
                        style="height: 6em"
                        id="Seller Vendor Address"
                        name="Seller Vendor Address"
                        class="form-control"
                        disabled
                      >
                      </textarea>
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="VehicleCostCenterForm.TO_FROM == 'Customer'"
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Customer Info</label
                      >
                    </div>
                    <div class="col-md-8">
                      <b-input-group>
                        <b-form-input
                          id="COST_CENTER_CUSTOMER_INFO"
                          @change="onChnageCostCenterCustomerInfo"
                          @keyup="onKeyUpCostCenterCustomerInfo"
                          v-model="COST_CENTER_CUSTOMER_INFO"
                          placeholder="Enter customer"
                          list="CostCenterCustomerlistOptions"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            data-bs-toggle="modal"
                            data-bs-target="#employee_modal"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <datalist id="CostCenterCustomerlistOptions">
                        <option
                          v-for="customer_info in AllCostCenterCustomerDetailsArray"
                          :value="customer_info.CUSTOMER_NAME"
                          :key="customer_info.ID_NUMBER"
                        ></option>
                      </datalist>
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerType != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Customer Type</label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterCustomerType"
                        id="Seller Customer Type"
                        name="Seller Customer Type"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerType == 'individual' &&
                      DisplayCostCenterCustomerIdNumber != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">NRIC Number</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterCustomerIdNumber"
                        id="Seller NRIC Number"
                        name="Seller NRIC Number"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerType == 'Company' &&
                      DisplayCostCenterCustomerIdNumber != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">UEN Number</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterCustomerIdNumber"
                        id="Seller UEN Number"
                        name="Seller UEN Number"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerName != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Customer Name</label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterCustomerName"
                        id="VendorName"
                        name="VendorName"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerAddress != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Customer Address</label
                      >
                    </div>
                    <div class="col-md-8">
                      <textarea
                        v-model="DisplayCostCenterCustomerAddress"
                        style="height: 4em"
                        id="Seller Customer Address"
                        name="Seller Customer Address"
                        class="form-control"
                        disabled
                      >
                      </textarea>
                    </div>
                  </div>

                  <div
                    class="row mt-2"
                    v-if="updat_user.REQUIRE_PAYMENT_VOUCHER == '1'"
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2">Description</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="updat_user.PV_DESCRIPTION"
                      />
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-4">
                      <label class="frm-label-2">Detail Description</label>
                    </div>
                    <div class="col-md-8">
                      <textarea
                        style="height: 6.5em"
                        type="text"
                        class="form-control"
                        v-model="updat_user.DETAIL_DESCRIPTION"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>

                <!--Update Cost Cenetr Right Side Form-->
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2">Payment Mode </label>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        v-model="updat_user.PAYMENT_MODE"
                      >
                        <option value="">Select</option>
                        <option value="GIRO">GIRO</option>
                        <option value="TT">TT</option>
                        <option value="Cheque">Cheque</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-4">
                      <label class="frm-label-2">Amount($)</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        @keyup="globalCalculateGST('COST_CENTER_FOR_UPDATE')"
                        type="number"
                        class="form-control"
                        placeholder=""
                        v-model="updat_user.AMOUNT_BEF_GST"
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-4">
                      <label class="frm-label align-right">GST</label>
                    </div>
                    <div class="col-md-8">
                      <select
                        v-model="updat_user.TAX_SYS_ID"
                        class="form-control"
                        @change="globalCalculateGST('COST_CENTER_FOR_UPDATE')"
                      >
                        <option value="">Select</option>
                        <option
                          v-for="tax_in in tax_info_details"
                          :value="tax_in.TAX_SYS_ID"
                          :key="tax_in.TAX_SYS_ID"
                        >
                          {{ tax_in.TAX_NAME }}-{{ tax_in.TAX_PERCENTAGE }}
                          %
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-4">
                      <label class="frm-label-2">GST Amt.($)</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        disabled
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="updat_user.GST_AMOUNT"
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-4">
                      <label class="frm-label-2">Total Amount($)</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        disabled
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="updat_user.TOTAL_AMOUNT"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-4">
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-success-btn"
                    @click="updateVehicleCostCenterDetails()"
                  >
                    Update
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-danger-btn"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
         <!-- Pay invoice Update Modal -->
         <div
        class="modal modal-right fade"
        id="pay_invoice_update_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="width: 55% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update Pay Invoice
              </h5>
            </div>
            <div class="col-md-12 accordion-body p-3">
              <div class="row">
                <!--Update Cost Cenetr Left Side Form-->
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2">Payment Code</label>
                    </div>
                    <div class="col-md-8">
                  
                    
                          <select
                        class="form-control roleddl"
                         v-model="payinvoice.PAYMENT_CODE"
                      >
                        <option value="">Select</option>
                        <option
                                                v-for="item in paymentcode"
                                                :value="item.PAYMENT_CODE"
                                                :key="item.PAYMENT_CODE_SYS_ID"
                                              >
                                                {{ item.PAYMENT_DESCRIPTION }}
                                              </option>
                      </select>
                    </div>
                  </div>
                 
                  <div class="row mt-2">
                  <div class="col-md-4">
                      <label class="frm-label-2"> Payment To</label>
                    </div>
                    <div class="col-md-8">
                      <!-- <select
                     
                    
                        class="form-control"
                        v-model="payinvoice.PAYMENT_TO"
                      >
                        <option value="">Select</option>
                        <option value="Vendor">Vendor</option>
                        <option value="Customer">Customer</option>
                      </select> -->
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                       v-model="payinvoice.CUSTOMER_NAME"
                      />
                    </div>
                  </div>
                  <!-- <div
                    class="row mt-2"
                    v-if="VehicleCostCenterForm.TO_FROM == 'Vendor'"
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Vendor Info</label>
                    </div>
                    <div class="col-md-8">
                      <b-input-group>
                        <b-form-input
                          id="SELLER_VENDOR_INFO"
                          @change="onChnageCostCenterVendorInfo"
                          @keyup="onKeyUpCostCenterVendorInfo"
                          v-model="COST_CENTER_VENDOR_INFO"
                          placeholder="Enter vendor"
                          list="costCenterVendorlistOptions"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            data-bs-toggle="modal"
                            data-bs-target="#vendor_modal"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <datalist id="costCenterVendorlistOptions">
                        <option
                          v-for="vendor_info in AllCostCenterVendorDetailsArray"
                          :value="vendor_info.VENDOR_NAME"
                          :key="vendor_info.VENDOR_SYS_ID"
                          :id="vendor_info.VENDOR_SYS_ID"
                        ></option>
                      </datalist>
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterVendorCode != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Vendor Code</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterVendorCode"
                        id="VendorCode"
                        name="VendorCode"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterVendorName != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">Vendor Name</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterVendorName"
                        id="VendorName"
                        name="VendorName"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterVendorAddress != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Vendor'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Vendor Address</label
                      >
                    </div>
                    <div class="col-md-8">
                      <textarea
                        v-model="DisplayCostCenterVendorAddress"
                        style="height: 6em"
                        id="Seller Vendor Address"
                        name="Seller Vendor Address"
                        class="form-control"
                        disabled
                      >
                      </textarea>
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="VehicleCostCenterForm.TO_FROM == 'Customer'"
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Customer Info</label
                      >
                    </div>
                    <div class="col-md-8">
                      <b-input-group>
                        <b-form-input
                          id="COST_CENTER_CUSTOMER_INFO"
                          @change="onChnageCostCenterCustomerInfo"
                          @keyup="onKeyUpCostCenterCustomerInfo"
                          v-model="COST_CENTER_CUSTOMER_INFO"
                          placeholder="Enter customer"
                          list="CostCenterCustomerlistOptions"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            data-bs-toggle="modal"
                            data-bs-target="#employee_modal"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <datalist id="CostCenterCustomerlistOptions">
                        <option
                          v-for="customer_info in AllCostCenterCustomerDetailsArray"
                          :value="customer_info.CUSTOMER_NAME"
                          :key="customer_info.ID_NUMBER"
                        ></option>
                      </datalist>
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerType != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Customer Type</label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterCustomerType"
                        id="Seller Customer Type"
                        name="Seller Customer Type"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerType == 'individual' &&
                      DisplayCostCenterCustomerIdNumber != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">NRIC Number</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterCustomerIdNumber"
                        id="Seller NRIC Number"
                        name="Seller NRIC Number"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerType == 'Company' &&
                      DisplayCostCenterCustomerIdNumber != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right">UEN Number</label>
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterCustomerIdNumber"
                        id="Seller UEN Number"
                        name="Seller UEN Number"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerName != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Customer Name</label
                      >
                    </div>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="DisplayCostCenterCustomerName"
                        id="VendorName"
                        name="VendorName"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="row mt-2"
                    v-if="
                      DisplayCostCenterCustomerAddress != '' &&
                      VehicleCostCenterForm.TO_FROM == 'Customer'
                    "
                  >
                    <div class="col-md-4">
                      <label class="frm-label-2 align-right"
                        >Customer Address</label
                      >
                    </div>
                    <div class="col-md-8">
                      <textarea
                        v-model="DisplayCostCenterCustomerAddress"
                        style="height: 4em"
                        id="Seller Customer Address"
                        name="Seller Customer Address"
                        class="form-control"
                        disabled
                      >
                      </textarea>
                    </div>
                  </div> -->
              
                 
               
             

               
                </div>

                <!--Update Cost Cenetr Right Side Form-->
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label class="frm-label-2">Payment Date </label>
                    </div>
                    <div class="col-md-8">
                    
                      <input
                      
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="payinvoice.PAYMENT_DATE"
                      />
                    </div>
                  </div>

                
              
                <div class="row mt-2">
                    <div class="col-md-4">
                      <label class="frm-label-2">Bank Name</label>
                    </div>
                    <div class="col-md-8">
                    
                         <select
                        class="form-control roleddl"
                        v-model="payinvoice.BANK_SYS_ID"
                      >
                        <option value="">Select</option>
                        <option
                                                v-for="item in Banklist"
                                                :value="item.BANK_SYS_ID"
                                                :key="item.BANK_SYS_ID"
                                              >
                                                {{ item.BANK_NAME }}
                                              </option>
                      </select>
                    </div>
                  </div>
                </div>
              
             
                

              </div>
              <div class="row mt-4 mb-4">
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-success-btn"
                    @click="updatePayInvoice()"
                  >
                    Update
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="custom-btn custom-danger-btn"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Preview PDF File -->
      <b-modal id="log_card_preview_modal" size="lg" hide-footer hide-header>
        <div class="container-fluid d-flex">
          <iframe
            id="fred"
            style="border: 1px solid #666ccc"
            title="PDF in an i-Frame"
            :src="preview_pdf_url"
            frameborder="1"
            scrolling="auto"
            width="100%"
            height="550px"
          ></iframe>
        </div>
      </b-modal>

      <!-- Preview Cost Center Details Description-->
      <b-modal
        id="cost_center_details_description_preview_modal"
        size="lg"
        hide-footer
        hide-header
      >
        <div class="container-fluid d-flex">
          <b-table
            striped
            hover
            :items="CostCenterDetailsDescriptionItems"
            :fields="CostCenterDetailsDescriptionFields"
          >
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>
        </div>
      </b-modal>
    </b-modal>
    <!-- Update Admin Master Modal End Here  -->

    <!-- View Admin Master Modal Start Here  -->
    <b-modal
      id="admin_view_modal"
      class="admin_view_modal"
      v-model="admin_view_modal_show"
      size="xl"
      hide-footer
    >
      <template #modal-header>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">View Admin Master</h5>
          <h5 class="modal-title" style="margin-left: 20px">
            {{ vehicletype }}
          </h5>

          <button
            type="button"
            class="btn-normal modal-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </button>
        </div>
      </template>

      <div class="container-fluid d-flex">
        <div v-if="loading">
          <div class="preloder loading_main_background" id="upload_loader">
            <img src="../../assets/images/loader.gif" />
          </div>
        </div>
        <div class="col-md-2 col-sm-12 mt-4" style="position: fixed">
          <aside>
            <div class="side-navbar animate__animated animate__slideInLeft">
              <div class="navbar-links shortcut-nav">
                <ul>
                  <i
                    class="fa-solid fa-sync fa-spin mx-5"
                    v-if="loading"
                    style="color: #a25b5b"
                  ></i>
                  <div class="col-md-2">
                    <button
                      class="custom-btn custom-success-btn"
                      @click="clickVehicleDetailsRow(current_vehicle_detail)"
                    >
                      Refresh
                    </button>
                  </div>
                  <li class="active my-2" @click="scrollTo('stock-entry')">
                    <span class="nav-itm">Stock Record</span>
                  </li>

                  <li class="active my-2" @click="scrollTo('seller-details')">
                    <span class="nav-itm">Seller Details</span>
                  </li>

                  <li class="active my-2" @click="scrollTo('buyer-details')">
                    <span class="nav-itm">Buyer Details / ETS Transaction</span>
                  </li>

                  <li class="dropdown my-1">
                    <a href="#">
                      <li class="dropbtn">
                        <span class="nav-itm">Sales Receipt</span>
                        <i
                          class="fa-solid fa-angle-right icon-2"
                          style="right: 70px"
                        ></i>
                      </li>
                    </a>
                    <div
                      class="dropdown-content mt-2"
                      style="
                        min-width: 200px;
                        max-width: 160px;
                        padding: 0.5rem;
                      "
                    >
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('insurance-info', 'sales-receipt')"
                      >
                        <span class="nav-itm">Insurance Info</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('trade-info', 'sales-receipt')"
                      >
                        <span class="nav-itm">Trade Info</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('other-payment', 'sales-receipt')"
                      >
                        <span class="nav-itm">Others Payable</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('sales-receipt-tab', 'sales-receipt')"
                      >
                        <span class="nav-itm">Sales Receipt</span>
                      </li>
                    </div>
                  </li>

                  <li class="dropdown my-1">
                    <a href="#">
                      <li class="dropbtn">
                        <span class="nav-itm">Loan Info</span>
                        <i
                          class="fa-solid fa-angle-right icon-2"
                          style="right: 70px"
                        ></i>
                      </li>
                    </a>
                    <div
                      class="dropdown-content mt-2"
                      style="
                        min-width: 200px;
                        max-width: 160px;
                        padding: 0.5rem;
                      "
                    >
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('loan-tab-info', 'loan-info')"
                      >
                        <span class="nav-itm">Loan Info</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="
                          scrollTo('finance-company-tax-invoice', 'loan-info')
                        "
                      >
                        <span class="nav-itm">Finance Company Tax Invoice</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="
                          scrollTo('finance-company-invoice', 'loan-info')
                        "
                      >
                        <span class="nav-itm">Finance Company Invoice</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="scrollTo('official-receipt', 'loan-info')"
                      >
                        <span class="nav-itm">Official Receipt</span>
                      </li>
                      <li
                        class="my-2 px-2"
                        @click="
                          scrollTo('loan-commission-invoice', 'loan-info')
                        "
                      >
                        <span class="nav-itm">Loan Commission Invoice</span>
                      </li>
                    </div>
                  </li>

                  <li
                    class="active my-2"
                    @click="scrollTo('manage-tax-invoice', 'tax-invoice')"
                  >
                    <span class="nav-itm">Tax invoice</span>
                  </li>

                  <li
                    class="active my-2"
                    @click="scrollTo('manage-credit-note', 'credit-note')"
                  >
                    <span class="nav-itm">Credit note</span>
                  </li>

                  <li class="active my-2" @click="scrollTo('cost-center')">
                    <span class="nav-itm">Cost Centre</span>
                  </li>
                  <li
                    class="active my-2"
                    @click="getPlStatmentDetails()"
                    style="color: #754115; font-weight: bold"
                  >
                    <span class="nav-itm">
                      PL Statement
                      <i
                        class="fa-solid fa-cloud-arrow-down"
                        style="color: blue"
                      ></i>
                    </span>
                  </li>
                  <li
                    class="active my-2"
                    @click="calculateCommision"
                    style="color: #754115; font-weight: bold"
                  >
                    <span class="nav-itm">Calculate Commission</span>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
        </div>
        <div
          class="col-md-10 col-sm-12 main-cont animate__animated animate__slideInRight"
          style="margin-left: 220px"
        >
          <div class="container-fluid">
            <div class="modal-body admin-edt-frm">
              <div class="row mt-3">
                <!-- Header Section Start-->
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Vehicle Status
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.VEHICLE_STATUS"
                                @change="onchangeVehicleStatus"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="Pre Order">Pre Order</option>
                                <option value="New Vehicle">New Vehicle</option>
                                <option value="Local">Local</option>
                                <option value="ETS">ETS</option>
                                <option value="Scrap">Scrap</option>
                                <!-- <option value="Trade-in">Trade-in</option> -->
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Vehicle No.
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.VEHICLE_NUMBER"
                                type="text"
                                class="form-control"
                                placeholder="Enter Vehicle No."
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Temporary Vehicle No.</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.TEMPORARY_VEHICLE_NUMBER"
                                type="text"
                                class="form-control"
                                placeholder="Enter Temporary Vehicle No."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Entry Date <span style="color: red"></span
                              ></label>
                            </div>
                            <div class="col-md-8">
                              <label>{{ addmasterdata.ENTRY_DATE }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Created By <span style="color: red"></span
                              ></label>
                            </div>
                            <div class="col-md-8">
                              <label>{{ addmasterdata.CREATED_NAME }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="frm-label-2"
                                >Last Modified By:
                                <span
                                  ><label>{{
                                    addmasterdata.MODIFIED_NAME
                                  }}</label></span
                                ></label
                              >
                            </div>
                            <!-- <div class="col-md-6">
                              <label class="frm-label-2"
                                >Not Show To Pricelist:
                                <input
                                  type="checkbox"
                                  v-model="addmasterdata.PRICE_LIST_STATUS"
                              /></label>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Header Section End-->

                <!-- Stock Entry Section Start -->
                <div class="col-md-12 mt-2" id="stock_entry" ref="stock-entry">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="line-heading"><span>Stock Entry</span></h5>
                    </div>
                    <!-- Stock Entry Left Side Form Section -->
                    <div class="col-md-6 left-side-frm">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Make <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                class="form-control"
                                id="make"
                                v-model="addmasterdata.MAKE_SYS_ID"
                                @change="getmakeName"
                              >
                                <option value="">Select Manufracture</option>
                                <option
                                  v-for="makenm in Manufracturename"
                                  :value="makenm.VEHICLE_MAKE_SYS_ID"
                                  :key="makenm.VEHICLE_MAKE_SYS_ID"
                                >
                                  {{ makenm.VEHICLE_MAKE }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Model <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="addmasterdata.MODEL_NAME"
                                name="Model"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Model Short Name</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="addmasterdata.MODEL_SHORT_NAME"
                                name="Model"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Body Type
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                name="Body Type"
                                v-model="addmasterdata.BODY_TYPE_SYS_ID"
                                class="form-control"
                                @change="getbodyName"
                              >
                                <option value="">Select Body Type</option>
                                <option
                                  v-for="body in Bodytype"
                                  :value="body.VEHICLE_BODY_TYPE_SYS_ID"
                                  :key="body.VEHICLE_BODY_TYPE_SYS_ID"
                                >
                                  {{ body.VEHICLE_BODY_TYPE }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Propellant
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                class="form-control"
                                v-model="addmasterdata.PROPELLANT"
                              >
                                <option value="">Select</option>
                                <option value="Petrol">Petrol</option>
                                <option value="Diesel">Diesel</option>
                                <option value="Natural Gas">Natural Gas</option>
                                <option value="Electric">Electric</option>
                                <option value="Petrol-Electric">
                                  Petrol-Electric
                                </option>
                                <option value="Diesel-Electric (Plug-In)">
                                  Diesel-Electric (Plug-In)
                                </option>
                                <option value="Petrol-Electric (Plug-In)">
                                  Petrol-Electric (Plug-In)
                                </option>
                                <option value="Diesel-CNG">
                                  Diesel-CNG (Plug-In)
                                </option>
                                <option value="Diesel- Electric">
                                  Diesel- Electric
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Chasis No. </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.CHASIS_NO"
                                type="text"
                                class="form-control"
                                placeholder="Enter Chasis No."
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Engine No./Motor No.
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.ENGINE_NO"
                                type="text"
                                class="form-control"
                                placeholder="Enter Engine No."
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2"
                                    >Laden
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    @keyup="onKeyUpCalculateTon"
                                    v-model="addmasterdata.LADEN"
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2"
                                    >Unladen
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="addmasterdata.UNLADEN"
                                    @keyup="onKeyUpCalculateTon"
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2"> Ton </label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="addmasterdata.ENGINE_TON"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2">
                                    Engine Cap
                                  </label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="addmasterdata.ENGINE_CAP"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2"
                                    >PAX
                                    <span style="color: red">*</span></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    v-model="addmasterdata.PAX"
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Year Of Manufacture
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.MANUFACTURE_YEAR"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Original Registration Date</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="
                                  addmasterdata.ORIGINAL_REGISTRATION_DATE
                                "
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Colour</label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.COLOR_SYS_ID"
                                class="form-control"
                                @change="getcolorName"
                              >
                                <option value="">Select Colour</option>
                                <option
                                  v-for="colournm in Coloursname"
                                  :value="colournm.VEHICLE_COLOR_SYS_ID"
                                  :key="colournm.VEHICLE_COLOR_SYS_ID"
                                >
                                  {{ colournm.VEHICLE_COLOR }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Number Of TRF</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.TSF_NUMBER"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Location</label>
                            </div>
                            <div class="col-md-8">
                              <select
                                name="Location"
                                v-model="addmasterdata.LOCATION"
                                class="form-control"
                              >
                                <option value="">Select location</option>
                                <option
                                  v-for="Vehiclelocation in Vehiclelocations"
                                  :value="Vehiclelocation.VEHICLE_LOCATION"
                                  :key="Vehiclelocation.VEHICLE_LOCATION_SYS_ID"
                                >
                                  {{ Vehiclelocation.VEHICLE_LOCATION }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Incharge By</label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="
                                  addmasterdata.INCHARGE_BY_BROKER_SYS_ID
                                "
                                class="form-control"
                                name="Incharge By"
                              >
                                <option :value="null">Select Incharge</option>
                                <option
                                  v-for="broker in brokerDetailsArray"
                                  :value="broker.USER_SYS_ID"
                                  :key="broker.USER_SYS_ID"
                                >
                                  {{ broker.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Asking Price($)</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                type="number"
                                v-model="addmasterdata.ASKING_PRICE"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Accessory</label>
                            </div>
                            <div class="col-md-8">
                              <textarea
                                type="text"
                                v-model="addmasterdata.ACCESSORY"
                                class="form-control"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-12" style="margin-top: 25px">
                          <div class="row">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                type="button"
                                @click="updateVehicleStockRecordLeftFormDetails"
                                class="custom-btn custom-success-btn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>

                    <!-- Stock Entry Right Side Form Section -->
                    <div class="col-md-6" id="Record-Section">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Transfer Fee($)</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                type="number"
                                v-model="addmasterdata.TRANSFER_FEE"
                                class="form-control"
                                placeholder="Enter Transfer Fee"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Road Tax Type</label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.ROAD_TAX_TYPE"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="6 Months">6 Months</option>
                                <option value="12 Months">12 Months</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Road Tax($) </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                type="number"
                                v-model="addmasterdata.ROAD_TAX"
                                class="form-control"
                                placeholder="Enter Road Tax"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Road Tax Expiry Date</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.ROAD_TAX_EXPIRY_DATE"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Inspection Expiry Date
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.INSPECTION_EXPIRY_DATE"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Layup Exp Date</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.LAYUP_EXP_DATE"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Layup amount</label>
                            </div>
                            <div class="col-md-8">
                              <input
                                type="number"
                                class="form-control"
                                v-model="addmasterdata.LAYUP_AMOUNT"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >COE (Log Card)($)
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.COE_LOG_GUARD"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >COE(ACC)/ DPQP($)</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.COE_ACC_DPQP"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >COE Expiry Date
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.COE_EXPIRY_DATE"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">COE Number </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.COE_NUMBER"
                                type="text"
                                class="form-control"
                                placeholder="Enter COE Number"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">COE To Pay </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.COE_TO_PAY"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="YES">5 year</option>
                                <option value="NO">10 year</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Open Market Value($)</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.OPEN_MARKET_VALUE"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Parf Amount($) </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.PARF_AMOUNT"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >Registration Fee ($)<span
                                  style="color: red"
                                  v-if="vehicletype == 'New Vehicle'"
                                  >*</span
                                ></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.REGISTRATION_FEE"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">ARF Amount($) </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.ARF_AMOUNT"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2"
                                >CEVS Surcharge($)
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.CEVS_SURCHARGE"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">CEVS Rebate($) </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addmasterdata.CEVS_REBATE"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">ETS Paper From </label>
                            </div>
                            <div class="col-md-8">
                              <b-input-group>
                                <b-form-input
                                  :disabled="etsfromdisable"
                                  id="SEARCH_VEHICLE_INFO"
                                  @change="onChnageETSPaperFrom"
                                  v-model="addmasterdata.ETS_PAPER_FROM"
                                  list="SearchAllVehiclelistOptionsForETSPaperFrom"
                                ></b-form-input>
                              </b-input-group>
                              <datalist
                                id="SearchAllVehiclelistOptionsForETSPaperFrom"
                              >
                                <option
                                  v-for="vehicle_info in search_all_vehicle_details"
                                  :value="vehicle_info.VEHICLE_NUMBER"
                                  :key="vehicle_info.RECORD_SYS_ID"
                                  :id="vehicle_info.RECORD_SYS_ID"
                                ></option>
                              </datalist>
                            </div>
                            <!-- <div class="col-md-8">
                              <select
                                v-model="addmasterdata.ETS_PAPER_FROM"
                                :disabled="etsfromdisable"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="option 1">option 1</option>
                                <option value="option 2">option 2</option>
                              </select>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2">Use TCOE </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addmasterdata.USE_TCOE"
                                class="form-control"
                              >
                                <option>Select</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-12 mt-4">
                          <div class="row">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                type="button"
                                @click="
                                  updateVehicleStockRecordRightFormDetails
                                "
                                class="custom-btn custom-success-btn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Stock Entry Section End -->

                <!-- Seller Details Section Start -->
                <div
                  class="col-md-12 mt-2"
                  id="seller_det"
                  ref="seller-details"
                >
                  <div class="col-md-12">
                    <h5 class="line-heading"><span>Seller Details</span></h5>
                  </div>
                  <b-tabs>
                    <!-- Seller Details Tab -->
                    <b-tab active title="Seller Details">
                      <!-- Seller Upper Section Form -->
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right">
                                  Seller Type <span style="color: red">*</span>
                                </label>
                              </div>
                              <div class="col-md-8">
                                <select
                                  v-model="addupdatesellerdata.CUSTOMER_TYPE"
                                  @change="onChnageSellerTypeFun"
                                  class="form-control"
                                >
                                  <option value="">Select</option>
                                  <option value="Vendor">Vendor</option>
                                  <option value="Customer">Customer</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="addupdatesellerdata.CUSTOMER_TYPE == 'Vendor'"
                            class="col-md-12 mt-2 mb-2"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Info</label
                                >
                              </div>
                              <div class="col-md-8">
                                <b-input-group>
                                  <b-form-input
                                    id="SELLER_VENDOR_INFO"
                                    @change="onChnageSellerVendorInfo"
                                    @keyup="onKeyUpSellerVendorInfo"
                                    v-model="SELLER_VENDOR_INFO"
                                    placeholder="Enter vendor"
                                    list="sellervendorlistOptions"
                                  ></b-form-input>
                                  <!-- <b-input-group-append>
                                    <b-button
                                      variant="info"
                                      data-bs-toggle="modal"
                                      data-bs-target="#vendor_modal"
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </b-button>
                                  </b-input-group-append> -->
                                </b-input-group>
                                <datalist id="sellervendorlistOptions">
                                  <option
                                    v-for="vendor_info in AllSellerVendorDetailsArray"
                                    :value="vendor_info.VENDOR_NAME"
                                    :key="vendor_info.VENDOR_SYS_ID"
                                    :id="vendor_info.VENDOR_SYS_ID"
                                  ></option>
                                </datalist>
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerVendorCode != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Code</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerVendorCode"
                                  id="VendorCode"
                                  name="VendorCode"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerVendorName != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Name</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerVendorName"
                                  id="VendorName"
                                  name="VendorName"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerVendorAddress != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Vendor Address</label
                                >
                              </div>
                              <div class="col-md-8">
                                <textarea
                                  v-model="DisplaySellerVendorAddress"
                                  style="height: 6em"
                                  id="Seller Vendor Address"
                                  name="Seller Vendor Address"
                                  class="form-control"
                                  disabled
                                >
                                </textarea>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="
                              addupdatesellerdata.CUSTOMER_TYPE == 'Customer'
                            "
                            class="col-md-12 mt-2 mb-2"
                          >
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Info</label
                                >
                              </div>
                              <div class="col-md-8">
                                <b-input-group>
                                  <b-form-input
                                    id="SELLER_CUSTOMER_INFO"
                                    @change="onChnageSellerCustomerInfo"
                                    @keyup="onKeyUpSellerCustomerInfo"
                                    v-model="SELLER_CUSTOMER_INFO"
                                    placeholder="Enter customer"
                                    list="sellervendorlistOptions"
                                  ></b-form-input>
                                  <!-- <b-input-group-append>
                                    <b-button
                                      variant="info"
                                      data-bs-toggle="modal"
                                      data-bs-target="#employee_modal"
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </b-button>
                                  </b-input-group-append> -->
                                </b-input-group>
                                <datalist id="sellervendorlistOptions">
                                  <option
                                    v-for="customer_info in AllSellerCustomerDetailsArray"
                                    :value="customer_info.CUSTOMER_NAME"
                                    :key="customer_info.ID_NUMBER"
                                  ></option>
                                </datalist>
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerCustomerType != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Type</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerCustomerType"
                                  id="Seller Customer Type"
                                  name="Seller Customer Type"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="
                                DisplaySellerCustomerType == 'individual' ||
                                DisplaySellerCustomerType == 'INDIVIDUAL'
                              "
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >NRIC Number</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerCustomerIdNumber"
                                  id="Seller NRIC Number"
                                  name="Seller NRIC Number"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="
                                DisplaySellerCustomerType == 'Company' ||
                                DisplaySellerCustomerType == 'COMPANY'
                              "
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >UEN Number</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerCustomerIdNumber"
                                  id="Seller UEN Number"
                                  name="Seller UEN Number"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerCustomerName != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Name</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  v-model="DisplaySellerCustomerName"
                                  id="VendorName"
                                  name="VendorName"
                                  class="form-control"
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              class="row mt-2"
                              v-if="DisplaySellerCustomerAddress != ''"
                            >
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Customer Address</label
                                >
                              </div>
                              <div class="col-md-8">
                                <textarea
                                  v-model="DisplaySellerCustomerAddress"
                                  style="height: 4em"
                                  id="Seller Customer Address"
                                  name="Seller Customer Address"
                                  class="form-control"
                                  disabled
                                >
                                </textarea>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right">
                                  Purchase Date
                                </label>
                              </div>
                              <div class="col-md-8">
                                <input
                                  v-model="addupdatesellerdata.PURCHASE_DATE"
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-2 align-right">
                            <input
                              type="checkbox"
                              @change="clickSellerFormNoDeveryDate"
                              v-model="addupdatesellerdata.NO_DELIVERY_DATE"
                            />
                            <label class="frm-label-2 ms-2"
                              >No Delivery Date</label
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Agreement Number
                                </label>
                              </div>
                              <div class="col-md-8">
                                <input
                                  v-model="addupdatesellerdata.AGREEMENT_NUMBER"
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Agreement No."
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Estimate Delivery In Date
                                </label>
                              </div>
                              <div class="col-md-8">
                                <input
                                  v-model="
                                    addupdatesellerdata.ESTIMATE_DELIVERY_IN_DATE
                                  "
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Admin fees($)
                                </label>
                              </div>
                              <div class="col-md-8">
                                <input
                                  v-model="addupdatesellerdata.ADMIN_FEE"
                                  type="number"
                                  class="form-control"
                                  placeholder="Enter Admin Fees"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Delivery In Date & Time</label
                                >
                              </div>
                              <div class="col-md-4 align-left">
                                <input
                                  :disabled="isDisabled"
                                  v-model="addupdatesellerdata.DELIVERY_IN_DATE"
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                              <div class="col-md-4 align-left">
                                <input
                                  :disabled="isDisabled"
                                  v-model="addupdatesellerdata.DELIVERY_IN_TIME"
                                  type="time"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-4">
                          <div class="row">
                            <div class="col-md-2">
                              <input
                                type="checkbox"
                                v-model="addupdatesellerdata.EXCLUDE_COE"
                                class="align-right"
                                style="margin-top: 2px; margin-left: 5px"
                              />
                              <label class="frm-label-2 align-left">
                                Exclude COE($)
                              </label>
                            </div>
                            <div class="col-md-4">
                              <input
                                type="checkbox"
                                v-model="
                                  addupdatesellerdata.EXCLUDE_REGISTRATION_FEE_AND_ARF
                                "
                                class="align-right"
                                style="margin-top: 2px; margin-left: 5px"
                              />
                              <label class="frm-label-2 align-left">
                                Exclude Registration Fee And ARF($)
                              </label>
                            </div>
                            <div class="col-md-2">
                              <input
                                type="checkbox"
                                v-model="addupdatesellerdata.EXCLUDE_ROAD_TAX"
                                class="align-right"
                                style="margin-top: 2px; margin-left: 5px"
                              />
                              <label class="frm-label-2 align-left">
                                Exclude Road Tax
                              </label>
                            </div>
                            <div class="col-md-4">
                              <input
                                type="checkbox"
                                @change="onchangePurchasePriceIsZero()"
                                v-model="
                                  addupdatesellerdata.PURCHASE_PRICE_IS_ZERO
                                "
                                class="align-right"
                                style="margin-top: 2px; margin-left: 5px"
                              />
                              <label class="frm-label-2 align-left">
                                Tick If The Purchase Price Is Zero
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 mt-4">
                          <div class="row">
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                price amount(with gst amount)<span
                                  style="color: red"
                                  >*</span
                                >
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                type="text"
                                :disabled="isDisablePriceAmount"
                                v-model="addupdatesellerdata.PURCHASE_PRICE"
                                class="form-control"
                                placeholder=""
                                @keyup="onkeyupPriceAmount"
                              />
                            </div>

                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                Purchase Price($)(without gst amount)<span
                                  style="color: red"
                                  >*</span
                                >
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                type="text"
                                disabled
                                v-model="addupdatesellerdata.PURCHASE_PRICE_SD"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                Purchase Outside Commission($)
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                v-model="
                                  addupdatesellerdata.PURCHASE_OUTSIDE_COMMISSION
                                "
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                Total Purchase Price($)<span style="color: red"
                                  >*</span
                                >
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                disabled
                                v-model="
                                  addupdatesellerdata.TOTAL_PURCHASE_PRICE
                                "
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mt-4">
                          <div class="row">
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                GST<span style="color: red">*</span>
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <select
                                id="SELLER_DETAILS"
                                v-model="addupdatesellerdata.TAX_SYS_ID"
                                class="form-control"
                                @change="globalCalculateGST('SELLER_DETAILS')"
                              >
                                <option value="">Select</option>
                                <option
                                  v-for="tax_info in tax_info_detai"
                                  :value="tax_info.TAX_SYS_ID"
                                  :key="tax_info.TAX_SYS_ID"
                                >
                                  {{ tax_info.TAX_NAME }}-{{
                                    tax_info.TAX_PERCENTAGE
                                  }}
                                  %
                                </option>
                              </select>
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                GST Amount Before Adjustment($)
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                v-model="
                                  addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT
                                "
                                disabled
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                GST Decimal Adjustment(+1/-1)
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                v-model="
                                  addupdatesellerdata.GST_DECIMAL_ADJUSTMENT
                                "
                                @keyup="
                                  globalDecimalAdjusment('SELLER_DETAILS')
                                "
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right">
                                GST Amount($)<span style="color: red">*</span>
                              </label>
                            </div>
                            <div class="col-md-8 mt-2">
                              <input
                                disabled
                                v-model="addupdatesellerdata.GST_AMOUNT"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                            <div class="col-md-4 mt-2">
                              <label class="frm-label-2 align-right"
                                >Add Remarks</label
                              >
                            </div>
                            <div class="col-md-8 mt-2">
                              <div class="accordion-item">
                                <textarea
                                  v-model="addupdatesellerdata.NOTE"
                                  class="custom-textarea form-control"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <button
                                style="width: 120px"
                                type="button"
                                @click="addUpdateSellerDetails"
                                class="custom-btn custom-success-btn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div> -->

                        <div class="col-md-12">
                          <div class="divider-2"></div>
                        </div>

                        <!-- Seller AP Payment Add Section -->
                        <div class="col-md-12">
                          <div class="row">
                            <!-- <div class="col-md-3">
                                 <button
                                class="custom-btn add-btn"
                                name=""
                                data-bs-toggle="collapse"
                                data-bs-target="#seller-colapse"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                <strong>Add Payment</strong>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-plus-circle"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="12" y1="8" x2="12" y2="16"></line>
                                  <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                              </button>
                              </div> -->
                            <div class="col-md-2">
                              <button
                                @click="recalculateReceivablePayment('BUY')"
                                style="width: 120px"
                                type="button"
                                class="custom-btn custom-success-btn"
                              >
                                Recalculate
                              </button>
                            </div>
                            <!-- <div class="col-md-7">
                                <div class="row">
                                  <div class="col-md-12">
                                    <p style="font-weight: bold">
                                      Note<span style="color: red"> *</span>&nbsp; Click
                                      on recalculate button if any changes in these
                                      fields
                                    </p>
                                  </div>
                                  <div class="col-md-12" style="font-weight: bold">
                                    <p style="margin-left: 3.4em">
                                      Selling Price,Transfer Fee,Road Tax,Insurance
                                      Premium, Advance Payment,HP Fee,Other Fee,Admin
                                      Fee
                                    </p>
                                  </div>
                                </div>
                              </div> -->
                          </div>
                          <div class="row mt-3">
                            <div class="col-md-6">
                              <span style="font-weight: 580"
                                >Total Payable Amount:&nbsp;S$
                                <span style="font-weight: bold">{{
                                  SELLER_AP_PURCHASE_AMOUNT
                                }}</span></span
                              >
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: 580"
                                >Balance:&nbsp;S$
                                <span style="font-weight: bold">{{
                                  SELLER_AP_REDUCED_PURCHASE_AMOUNT
                                }}</span></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Seller AP Payment Table Section -->
                      <div class="master-table">
                        <b-table
                          striped
                          hover
                          :items="sellerapdetails"
                          :fields="SellerAPFields"
                          class="table table-bordered mt-3"
                        >
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>
                        </b-table>
                      </div>

                      <!-- Seller Below Section Form-->
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Asking Price ($)
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdatesellerdata.ASKING_PRICE"
                                type="text"
                                class="form-control"
                                placeholder="Enter Asking Price"
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Alternate Price($)
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                disabled
                                v-model="addupdatesellerdata.ALTERNATE_PRICE_SD"
                                type="number"
                                class="form-control"
                                placeholder="Alternate Price"
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Salesman Code
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="
                                  addupdatesellerdata.SALES_PERSON_SYS_ID
                                "
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option
                                  v-for="broker in brokerdetails"
                                  :value="broker.USER_SYS_ID"
                                  :key="broker.USER_SYS_ID"
                                >
                                  {{ broker.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <!-- <div class="row mt-2">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right">
                                        Trade In By
                                      </label>
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="addupdatesellerdata.TRADE_IN_BY"
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                </div> -->
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Broker 1
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatesellerdata.BROKER_1"
                                class="form-control"
                              >
                                <option value="">Select Broker 1</option>
                                <option
                                  v-for="broker in brokerdetails"
                                  :value="broker.USER_SYS_ID"
                                  :key="broker.USER_SYS_ID"
                                >
                                  {{ broker.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Broker 2
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatesellerdata.BROKER_2"
                                class="form-control"
                              >
                                <option value="">Select Broker 2</option>
                                <option
                                  v-for="broker in brokerdetails"
                                  :value="broker.USER_SYS_ID"
                                  :key="broker.USER_SYS_ID"
                                >
                                  {{ broker.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Com.Rtn.($)
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdatesellerdata.COM_RTN_1"
                                type="number"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Commission Date
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdatesellerdata.RETURN_DATE_2"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <br />
                          <br />

                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Broker Deal
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                @change="onChangeBrokerDeal"
                                v-model="addupdatesellerdata.BROKER_DEAL"
                                type="checkbox"
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                Transaction Type
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatesellerdata.TRANSACTION_TYPE"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="Local">Local</option>
                                <option value="Ets">Ets</option>
                                <option value="New Vehicle">New Vehicle</option>
                                <option value="Scrap">Scrap</option>
                              </select>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                E-Transfer By
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatesellerdata.E_TRANSFER_BY"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option
                                  v-for="InternalUser in InternalUserList"
                                  :value="InternalUser.USER_SYS_ID"
                                  :key="InternalUser.USER_SYS_ID"
                                >
                                  {{ InternalUser.PERSON_NAME }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right">
                                E-Transfer In Date
                              </label>
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdatesellerdata.E_TRANSFER_IN_DATE"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-5">
                              <label class="frm-label-2 align-right">
                                Log Card (Transfer In)
                              </label>
                            </div>
                            <div
                              class="col-md-2"
                              v-if="isDisabledLogCardIn == true"
                            >
                              <button
                                type="button"
                                class="custom-btn me-1 p-0"
                                v-b-modal.log_card_preview_modal
                                @click="
                                  previewLogCardPdfFile(
                                    'SELLER_LOG_CARD_TRANSFER_IN'
                                  )
                                "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-eye"
                                >
                                  <path
                                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                  ></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-5">
                              <label class="frm-label-2 align-right">
                                Log Card 2 (Transfer In)
                              </label>
                            </div>
                            <div
                              class="col-md-2"
                              v-if="isDisabledLogCardIn2 == true"
                            >
                              <button
                                type="button"
                                class="custom-btn me-1 p-0"
                                v-b-modal.log_card_preview_modal
                                @click="
                                  previewLogCardPdfFile(
                                    'SELLER_LOG_CARD_TRANSFER_IN_2'
                                  )
                                "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-eye"
                                >
                                  <path
                                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                  ></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>

                    <!-- Purchase Settlement Tab -->
                    <b-tab
                      title="Purchase Settlement"
                      @click="getPurchasesNoteDetails"
                    >
                      <div
                        class="col-md-12"
                        style="text-align: right"
                        ref="purchase-settlement"
                      >
                        <button
                          class="downld animate__animated animate__bounceIn"
                          @click="generateReportPurchase"
                        >
                          <i
                            class="fa-solid fa-cloud-arrow-down"
                            style="color: blue"
                          ></i>
                          Download
                        </button>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-2 mt-2">
                          <label class="frm-label">Invoice No.</label>
                        </div>
                        <div class="col-md-3">
                          <input
                            style="height: 3em"
                            type="text"
                            v-model="
                              purchasesettlement.PURCHASE_SETTLEMENT_INVOICE_NO
                            "
                            class="form-control"
                            placeholder="Enter Invoice No."
                          />
                        </div>
                        <div class="col-md-1 mt-2">
                          <label class="frm-label">Date</label>
                        </div>
                        <div class="col-md-3">
                          <input
                            style="height: 3em"
                            v-model="
                              purchasesettlement.PURCHASE_SETTLEMENT_INVOICE_DATE
                            "
                            type="text"
                            class="form-control"
                            placeholder="Enter Date"
                          />
                        </div>
                        <!-- <div class="col-md-2">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            @click="pdfdatapurchaseseeelement"
                          >
                            Save
                          </button>
                        </div> -->
                      </div>
                      <div
                        class="content purchase-settlement-note-cont"
                        style="margin-top: 10px"
                        ref="purchase-settlement"
                      >
                        <div class="content-header mt-4">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div
                                  class="col-md-3"
                                  style="text-align: center"
                                >
                                  <img
                                    src="../../assets/images/icon.png"
                                    class="img-fluid header-img"
                                    alt="#"
                                  />
                                </div>
                                <div class="col-md-9 p-0">
                                  <ul>
                                    <li>
                                      <img
                                        src="../../assets/images/logo-txt.png"
                                      />
                                    </li>
                                    <li class="mt-2">
                                      <p>
                                        1 Sunview Road #01-46 Eco-Tech@Sunview
                                        Singapore 627615
                                      </p>
                                    </li>
                                    <li>
                                      <p>ROC No./ GST Reg No:. 201929524C</p>
                                    </li>
                                    <li><p>Tel: 62395191 Fax: 62395190</p></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-6" style="text-align: end">
                              <h4 class="fw-bold">
                                <span
                                  style="
                                    border: 1.5px solid #212121;
                                    padding: 4px 10px;
                                  "
                                  >PURCHASE SETTLEMENT</span
                                >
                              </h4>
                              <!-- <div class="heading">
                                  <p>PURCHASE SETTLEMENT</p>
                                </div> -->
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3 ml-2">
                          <div class="col-md-8">
                            <p>
                              Serial No&nbsp;
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.PurchaseNoteDetails
                                    .PURCHASE_SETTLEMENT_NO
                                }}</span
                              >
                            </p>
                          </div>
                          <div class="col-md-2" style="text-align: right">
                            Date
                          </div>
                          <div class="col-md-2">
                            <p>
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.PurchaseNoteDetails.INVOICE_DATE
                                }}</span
                              >
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2 ml-2">
                          <div class="col-md-8">
                            <p>
                              I/We("The Seller")&nbsp;
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.PurchaseNoteDetails.CUSTOMER_NAME
                                }}</span
                              >
                            </p>
                          </div>
                          <div class="col-md-2" style="text-align: right">
                            NRIC/ROC
                          </div>
                          <div class="col-md-2">
                            <p>
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.PurchaseNoteDetails.NRIC_ROC
                                }}</span
                              >
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2 ml-2">
                          <div class="col-md-9">
                            <p>
                              Address&nbsp;
                              <span style="font-weight: bold">
                                :&nbsp;{{
                                  this.PurchaseNoteDetails.ADDRESS
                                }}</span
                              >
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2 ml-2">
                          <div class="col-md-12">
                            <p>
                              agree to sell the following vehicle to Lek Auto
                              Pte Ltd ("Buyer")
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2" style="padding: 1em">
                          <div
                            class="col-md-10"
                            style="border: 3px solid #212121"
                          >
                            <p>
                              <span style="font-weight: bold">DESCRIPTION</span>
                            </p>
                          </div>
                          <div
                            class="col-md-2"
                            style="
                              border-right: 3px solid #212121;
                              border-bottom: 3px solid #212121;
                              border-top: 3px solid #212121;
                            "
                          >
                            <p>
                              <span style="font-weight: bold">Amount(S$)</span>
                            </p>
                          </div>
                          <div
                            class="col-md-10"
                            style="
                              border-left: 3px solid #212121;
                              border-right: 3px solid #212121;
                              border-bottom: 3px solid #212121;
                            "
                          >
                            <div class="row mt-2">
                              <div class="col-md-3"><p>Vehicle No:</p></div>
                              <div class="col-md-3">
                                <p>
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.VEHICLE_NUMBER
                                  }}</span>
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3"><p>Make & Model:</p></div>
                              <div class="col-md-9">
                                <p>
                                  <span style="font-weight: bold"
                                    >{{ this.PurchaseNoteDetails.MAKE_NAME }}-{{
                                      this.PurchaseNoteDetails.MODEL_NAME
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3"><p>Chassis No:</p></div>
                              <div class="col-md-3">
                                <p>
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.CHASIS_NO
                                  }}</span>
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3">
                                <p>Engine No./Motor No.:</p>
                              </div>
                              <div class="col-md-3">
                                <p>
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.ENGINE_NO
                                  }}</span>
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3">
                                <p>Vehicle Price:</p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3"><p>Less:</p></div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Deposit(<span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.CUSTOMER_NAME
                                  }}</span
                                  >):
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Finance Company (<span
                                    style="font-weight: bold"
                                    >{{
                                      this.PurchaseNoteDetails.FINANCE_COMPANY
                                    }}</span
                                  >) &nbsp;<span>Full Settlement Amount:</span>
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Full Payment
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.CUSTOMER_NAME
                                  }}</span
                                  >:
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Balance issued to
                                  <span style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.BALANCE_ISSUED_TO
                                  }}</span
                                  >:
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-2"
                            style="
                              border-right: 3px solid #212121;
                              border-bottom: 3px solid #212121;
                            "
                          >
                            <div class="row mt-2">
                              <div class="col-md-12"><p></p></div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12"></div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12"></div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12"></div>
                            </div>
                            <div class="row" style="margin-top: 6em !important">
                              <div class="col-md-12">
                                <p><span style="font-weight: bold"></span></p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails.VEHICLE_PRICE
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="row" style="margin-top: 2.5em">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails.DEPOSIT_AMOUNT
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>

                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails
                                        .FULL_SETTLEMENT_AMOUNT
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails
                                        .FULL_PAYMENT_AMOUNT
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  $
                                  <span
                                    style="font-weight: bold; text-align: right"
                                    >{{
                                      this.PurchaseNoteDetails.BALANCE
                                    }}</span
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-1" style="padding: 1em">
                          <div
                            class="col-md-12"
                            style="border: 3px solid #212121"
                          >
                            <div class="row">
                              <div class="col-md-12">
                                <u><b>Vehicle Delivery Acknowledgement</b></u>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  Handover on (date)&nbsp;<b
                                    style="font-weight: bold"
                                    >{{
                                      this.PurchaseNoteDetails.DELIVERY_IN_DATE
                                    }}</b
                                  >&nbsp;(time) &nbsp;<b
                                    style="font-weight: bold"
                                    >{{
                                      this.PurchaseNoteDetails.DELIVERY_IN_TIME
                                    }}</b
                                  >
                                  .
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  *Seller has agreed to handover the mentioned
                                  vehicle to Buyer at the above mentioned date &
                                  time and free from all encumbrance or
                                  whatever.
                                </p>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-12">
                                <p>
                                  *Seller shall be responsible for any traffic
                                  offences and illegal acts arising from the use
                                  thereof.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-8">
                                <p>Signed for & behalf of</p>
                              </div>
                              <div class="col-md-4">
                                <p>Agreed & Accepted by Seller</p>
                              </div>
                              <div class="col-md-12 mt-2">
                                <p>Lek Auto Pte Ltd</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" style="margin-top: 6em">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-7">
                                <span
                                  style="
                                    border-top: 2px solid #000;
                                    width: 100px;
                                    font-weight: bold;
                                  "
                                >
                                  Authorised Signature
                                </span>
                                <p>
                                  Salesman:&nbsp;<b style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.INCHARGE_NAME
                                  }}</b>
                                </p>
                                <p>
                                  HP:&nbsp;<b style="font-weight: bold">{{
                                    this.PurchaseNoteDetails.INCHARGE_CONTACT
                                  }}</b>
                                </p>
                              </div>
                              <div class="col-md-5">
                                <span
                                  style="
                                    border-top: 2px solid #000;
                                    width: 100px;
                                    font-weight: bold;
                                  "
                                >
                                  Authorised Signature & Company Stamp
                                </span>
                                <p>Name:</p>
                                <p>Contact No.:</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
                <!-- Seller Details Section End -->

                <!-- Buyer/ETS Transaction Details Section Start-->
                <div class="col-md-12 mt-2" id="buyer_det" ref="buyer-details">
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="line-heading"><span>Buyer Details</span></h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="line-heading">
                        <span>ETS Transaction Details</span>
                      </h5>
                    </div>
                    <!-- Buyer Upper Details Add Form -->
                    <div class="col-md-6 left-side-frm">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Buyer Type
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdatebuyerdata.CUSTOMER_TYPE"
                                @change="onChnageBuyerTypeFun"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="Vendor">Vendor</option>
                                <option value="Customer">Customer</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Buyer Details Vendor -->
                      <div
                        v-if="
                          addupdatebuyerdata &&
                          addupdatebuyerdata.CUSTOMER_TYPE == 'Vendor'
                        "
                        class="col-md-12 mt-2 mb-2"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Vendor Info</label
                            >
                          </div>
                          <div class="col-md-8">
                            <b-input-group>
                              <b-form-input
                                id="BUYER_VENDOR_INFO"
                                @change="onChnageBuyerVendorInfo"
                                @keyup="onKeyUpBuyerVendorInfo"
                                v-model="BUYER_VENDOR_INFO"
                                placeholder="Enter vendor"
                                list="buyervendorlistOptions"
                              ></b-form-input>
                              <!-- <b-input-group-append>
                                <b-button
                                  variant="info"
                                  data-bs-toggle="modal"
                                  data-bs-target="#vendor_modal"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </b-button>
                              </b-input-group-append> -->
                            </b-input-group>
                            <datalist id="buyervendorlistOptions">
                              <option
                                v-for="vendor_info in AllBuyerVendorDetailsArray"
                                :value="vendor_info.VENDOR_NAME"
                                :key="vendor_info.VENDOR_SYS_ID"
                                :id="vendor_info.VENDOR_SYS_ID"
                              ></option>
                            </datalist>
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerVendorCode != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Vendor Code</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerVendorCode"
                              id="Buyer VendorCode"
                              name="Buyer VendorCode"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerVendorName != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Vendor Name</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerVendorName"
                              id="VendorName"
                              name="VendorName"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerVendorAddress != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Vendor Address</label
                            >
                          </div>
                          <div class="col-md-8">
                            <textarea
                              v-model="DisplayBuyerVendorAddress"
                              style="height: 6em"
                              id="Seller Vendor Address"
                              name="Seller Vendor Address"
                              class="form-control"
                              disabled
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>

                      <!-- Buyer Details Customer -->
                      <div
                        v-if="
                          addupdatebuyerdata &&
                          addupdatebuyerdata.CUSTOMER_TYPE == 'Customer'
                        "
                        class="col-md-12 mt-2 mb-2"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Info</label
                            >
                          </div>
                          <div class="col-md-8">
                            <b-input-group>
                              <b-form-input
                                id="BUYER_CUSTOMER_INFO"
                                @change="onChnageBuyerCustomerInfo"
                                @keyup="onKeyUpBuyerCustomerInfo"
                                v-model="BUYER_CUSTOMER_INFO"
                                placeholder="Enter customer"
                                list="buyercustomerlistOptions"
                              ></b-form-input>
                              <!-- <b-input-group-append>
                                <b-button
                                  variant="info"
                                  data-bs-toggle="modal"
                                  data-bs-target="#employee_modal"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </b-button>
                              </b-input-group-append> -->
                            </b-input-group>
                            <datalist id="buyercustomerlistOptions">
                              <option
                                v-for="buyer_customer_info in AllBuyerCustomerDetailsArray"
                                :value="buyer_customer_info.CUSTOMER_NAME"
                                :key="buyer_customer_info.ID_NUMBER"
                              ></option>
                            </datalist>
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerCustomerType != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Type</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerCustomerType"
                              id="Seller Customer Type"
                              name="Seller Customer Type"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="
                            DisplayBuyerCustomerType == 'individual' ||
                            DisplayBuyerCustomerType == 'INDIVIDUAL'
                          "
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >NRIC Number</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerCustomerIdNumber"
                              id="Seller NRIC Number"
                              name="Seller NRIC Number"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="
                            DisplayBuyerCustomerType == 'Company' ||
                            DisplayBuyerCustomerType == 'COMPANY'
                          "
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >UEN Number</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerCustomerIdNumber"
                              id="Seller UEN Number"
                              name="Seller UEN Number"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerCustomerName != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Name</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              v-model="DisplayBuyerCustomerName"
                              id="VendorName"
                              name="VendorName"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-2"
                          v-if="DisplayBuyerCustomerAddress != ''"
                        >
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Address</label
                            >
                          </div>
                          <div class="col-md-8">
                            <textarea
                              v-model="DisplayBuyerCustomerAddress"
                              style="height: 4em"
                              id="Seller Customer Address"
                              name="Seller Customer Address"
                              class="form-control"
                              disabled
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Delivery Out Date & Time</label
                            >
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-6">
                                <input
                                  v-model="addupdatebuyerdata.DELIVERY_OUT_DATE"
                                  type="text"
                                  class="form-control"
                                />
                              </div>
                              <div class="col-md-6">
                                <input
                                  v-model="addupdatebuyerdata.TIME"
                                  type="time"
                                  class="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Invoice Number</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.INVOICE_NUMBER"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Invoice Date</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.INVOICE_DATE"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div> -->
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >P&L Date</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              disabled
                              v-model="addupdatebuyerdata.P_L_DATE"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Booking Date</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.BOOKING_DATE"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >DN/CN Number
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.DN_CN_NUMBER"
                              type="text"
                              class="form-control"
                              placeholder="Enter DN/CN No."
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >DN/CN Amount($)
                            </label>
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.DN_CN_AMOUNT"
                              type="number"
                              class="form-control"
                              placeholder="Enter DN/CN Amount"
                            />
                          </div>
                          <div class="col-md-4"></div>
                          <div class="col-md-8"></div>
                        </div>
                      </div> -->
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Sales Person</label
                            >
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="addupdatebuyerdata.SALES_PERSON_SYS_ID"
                              class="form-control"
                            >
                              <option value="">Select</option>
                              <option
                                v-for="broke in brokerdetails"
                                :value="broke.USER_SYS_ID"
                                :key="broke.USER_SYS_ID"
                              >
                                {{ broke.PERSON_NAME }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Sales Agreement Number</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="
                                addupdatebuyerdata.SALES_AGREEMENT_NUMBER
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Sales Agreement No."
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4"></div>
                          <div class="col-md-8"></div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Price Amount (with gst amount)<span
                                style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.SELL_PRICE"
                              type="number"
                              class="form-control"
                              placeholder="Enter Price Amount"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >GST($)<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <select
                              v-model="addupdatebuyerdata.TAX_SYS_ID"
                              @change="globalCalculateGST('BUYER_DETAILS')"
                              class="form-control"
                            >
                              <option value="">Select</option>
                              <option
                                v-for="tax_info in tax_info_detai"
                                :value="tax_info.TAX_SYS_ID"
                                :key="tax_info.TAX_SYS_ID"
                              >
                                {{ tax_info.TAX_NAME }}-{{
                                  tax_info.TAX_PERCENTAGE
                                }}
                                %
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >GST Decimal Adjustment($) (+1/-1)</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="
                                addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT
                              "
                              @keyup="globalDecimalAdjusment('BUYER_DETAILS')"
                              type="number"
                              min="-1"
                              max="1"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Sales Commission($)
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.COM_RTN_1"
                              type="number"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Sales Out Side Commission($)<span
                                style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="
                                addupdatebuyerdata.SALES_OUTSIDE_COMMISSION
                              "
                              type="number"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-left"
                              >GST Amount Before Adjustment($)</label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="
                                addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT
                              "
                              disabled
                              type="text"
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >GST Amount($)<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.GST_AMOUNT"
                              type="text"
                              disabled
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12 mt-2"
                        v-if="GLOBAL_TAX_TYPE == 'NEW'"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Non Taxable Supplies<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.NON_TAXABLE_SUPPLIES"
                              type="text"
                              disabled
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12 mt-2"
                        v-if="GLOBAL_TAX_TYPE == 'NEW'"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Taxable Supplies<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.TAXABLE_SUPPLIES"
                              type="text"
                              disabled
                              class="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Selling Price($) (without gst )<span
                                style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              disabled
                              v-model="addupdatebuyerdata.SELLING_PRICE"
                              type="number"
                              class="form-control"
                              placeholder="Enter Selling Price"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Total Selling Price($)<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              v-model="addupdatebuyerdata.TOTAL_SELLING_PRICE"
                              disabled
                              type="text"
                              class="form-control"
                              placeholder="Enter Total Selling Price"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- ETS Transaction Details Add Form -->
                    <div class="col-md-6">
                      <div class="row mt-2">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Buy Over Date</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.BUY_OVER_DATE"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Fight PL</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.FIGHT_PL"
                                type="checkbox"
                                class="me-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >ETS Paper To</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.ETS_PAPER_TO"
                                type="text"
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Body Price($)<span style="color: red"
                                  >*</span
                                ></label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.BODY_PRICE"
                                type="number"
                                class="form-control"
                                placeholder="Enter Body Price"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Dereg Date</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.DEREG_DATE"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >COE Encashment($)</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.COE_ENCASHMENT"
                                type="number"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Parf Encashment</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.PARF_ENCASHMENT"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >ETS Transfer Value</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.ETS_TRANSFER_VALUE"
                                type="input"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >ETS Paper(External)</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.ETS_PAPER_EXTERNAL"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Invoice Number 2</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.INVOICE_NUMBER_2"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Invoice date 2</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                v-model="addupdateetsdata.INVOICE_DATE_2"
                                type="text"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >ETS Paper Buyer
                                <span style="color: red">*</span></label
                              >
                            </div>
                            <div class="col-md-8">
                              <select
                                v-model="addupdateetsdata.TO_FROM_WHO"
                                @change="onChnageETSPaperBuyer"
                                class="form-control"
                              >
                                <option value="">Select</option>
                                <option value="Vendor">Vendor</option>
                                <option value="Customer">Customer</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="addupdateetsdata.TO_FROM_WHO == 'Vendor'"
                          class="col-md-12 mt-2 mb-2"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Vendor Info</label
                              >
                            </div>
                            <div class="col-md-8">
                              <b-input-group>
                                <b-form-input
                                  id="ETS_VENDOR_INFO"
                                  @change="onChnageETSVendorInfo"
                                  @keyup="onKeyUpETSVendorInfo"
                                  v-model="ETS_VENDOR_INFO"
                                  placeholder="Enter vendor"
                                  list="etsvendorlistOptions"
                                ></b-form-input>
                                <!-- <b-input-group-append>
                                  <b-button
                                    variant="info"
                                    data-bs-toggle="modal"
                                    data-bs-target="#vendor_modal"
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </b-button>
                                </b-input-group-append> -->
                              </b-input-group>
                              <datalist id="etsvendorlistOptions">
                                <option
                                  v-for="vendor_info in AllEtsVendorDetailsArray"
                                  :value="vendor_info.VENDOR_NAME"
                                  :key="vendor_info.VENDOR_SYS_ID"
                                  :id="vendor_info.VENDOR_SYS_ID"
                                ></option>
                              </datalist>
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsVendorCode != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Vendor Code</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsVendorCode"
                                id="Buyer VendorCode"
                                name="Buyer VendorCode"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsVendorName != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Vendor Name</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsVendorName"
                                id="VendorName"
                                name="VendorName"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsVendorAddress != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Vendor Address</label
                              >
                            </div>
                            <div class="col-md-8">
                              <textarea
                                v-model="DisplayEtsVendorAddress"
                                style="height: 6em"
                                id="Seller Vendor Address"
                                name="Seller Vendor Address"
                                class="form-control"
                                disabled
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="addupdateetsdata.TO_FROM_WHO == 'Customer'"
                          class="col-md-12 mt-2 mb-2"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Customer Info</label
                              >
                            </div>
                            <div class="col-md-8">
                              <b-input-group>
                                <b-form-input
                                  id="ETS_CUSTOMER_INFO"
                                  @change="onChnageETSCustomerInfo"
                                  @keyup="onKeyUpETSCustomerInfo"
                                  v-model="ETS_CUSTOMER_INFO"
                                  placeholder="Enter customer"
                                  list="EtscustomerlistOptions"
                                ></b-form-input>
                                <!-- <b-input-group-append>
                                  <b-button
                                    variant="info"
                                    data-bs-toggle="modal"
                                    data-bs-target="#employee_modal"
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </b-button>
                                </b-input-group-append> -->
                              </b-input-group>
                              <datalist id="EtscustomerlistOptions">
                                <option
                                  v-for="buyer_customer_info in AllEtsCustomerDetailsArray"
                                  :value="buyer_customer_info.CUSTOMER_NAME"
                                  :key="buyer_customer_info.ID_NUMBER"
                                ></option>
                              </datalist>
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsCustomerType != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Customer Type</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsCustomerType"
                                id="Seller Customer Type"
                                name="Seller Customer Type"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="
                              DisplayEtsCustomerType == 'individual' ||
                              DisplayEtsCustomerType == 'INDIVIDUAL'
                            "
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >NRIC Number</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsCustomerIdNumber"
                                id="Seller NRIC Number"
                                name="Seller NRIC Number"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="
                              DisplayEtsCustomerType == 'Company' ||
                              DisplayEtsCustomerType == 'COMPANY'
                            "
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >UEN Number</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsCustomerIdNumber"
                                id="Seller UEN Number"
                                name="Seller UEN Number"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsCustomerName != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Customer Name</label
                              >
                            </div>
                            <div class="col-md-8">
                              <input
                                type="text"
                                v-model="DisplayEtsCustomerName"
                                id="VendorName"
                                name="VendorName"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div
                            class="row mt-2"
                            v-if="DisplayEtsCustomerAddress != ''"
                          >
                            <div class="col-md-4">
                              <label class="frm-label-2 align-right"
                                >Customer Address</label
                              >
                            </div>
                            <div class="col-md-8">
                              <textarea
                                v-model="DisplayEtsCustomerAddress"
                                style="height: 4em"
                                id="Seller Customer Address"
                                name="Seller Customer Address"
                                class="form-control"
                                disabled
                              >
                              </textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="divider-2"></div>
                </div>
                <!-- Buyer AR Payment Section -->
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-2">
                        <button
                          @click="recalculateReceivablePayment('SELL')"
                          style="width: 120px"
                          type="button"
                          class="custom-btn custom-success-btn"
                        >
                          Recalculate
                        </button>
                      </div>
                      <div class="col-md-7">
                        <div class="row">
                          <div class="col-md-12">
                            <p style="font-weight: bold">
                              Note<span style="color: red"> *</span>&nbsp; Click
                              on recalculate button if any changes in these
                              fields
                            </p>
                          </div>
                          <div class="col-md-12" style="font-weight: bold">
                            <p style="margin-left: 3.4em">
                              Selling Price,Transfer Fee,Road Tax,Insurance
                              Premium, Advance Payment,HP Fee,Other Fee,Admin
                              Fee
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <span style="font-weight: 580"
                          >Total Receivable Amount:&nbsp;S$
                          <span style="font-weight: bold">{{
                            VEHICLE_TOTAL_RECEIVABLE_AMOUNT
                          }}</span></span
                        >
                      </div>
                      <div class="col-md-6">
                        <span style="font-weight: 580"
                          >Balance:&nbsp;S$
                          <span style="font-weight: bold">{{
                            VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="master-table">
                  <b-table
                    striped
                    hover
                    :items="buyerapdetails"
                    :fields="BuyerARFields"
                    class="table table-bordered mt-3"
                  >
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                  </b-table>
                </div>
                <!-- Buyer Section Below Details Add Form -->
                <div class="row mt-2">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Total Receivable($)</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="addupdatebuyerdata.TOTAL_RECEIVABLE"
                          type="number"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Total Received($)</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="addupdatebuyerdata.TOTAL_RECEIVED"
                          type="number"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >AR balance</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="addupdatebuyerdata.AR_BALANCE"
                          type="number"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">Broker 1</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          disabled
                          v-model="addupdatebuyerdata.BROKER_1_SYS_ID"
                          class="form-control"
                        >
                          <option value="">Select Broker 1</option>
                          <option
                            v-for="broker in brokerdetails"
                            :value="broker.USER_SYS_ID"
                            :key="broker.USER_SYS_ID"
                          >
                            {{ broker.PERSON_NAME }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">Broker 2</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="addupdatebuyerdata.BROKER_2_SYS_ID"
                          class="form-control"
                        >
                          <option value="">Select Broker 1</option>
                          <option
                            v-for="broker in brokerdetails"
                            :value="broker.USER_SYS_ID"
                            :key="broker.USER_SYS_ID"
                          >
                            {{ broker.PERSON_NAME }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Deposit Pay With GST($)</label
                        >
                      </div>

                      <div class="col-md-8">
                        <input
                          @keyup="globalCalculateGST('BUYER_DEPOSIT_DETAILS')"
                          v-model="addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">GST</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          disabled
                          v-model="addupdatebuyerdata.DEPOSIT_TAX_AMOUNT_SYS_ID"
                          @change="globalCalculateGST('BUYER_DEPOSIT_DETAILS')"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="tax_info in tax_info_detai"
                            :value="tax_info.TAX_SYS_ID"
                            :key="tax_info.TAX_SYS_ID"
                          >
                            {{ tax_info.TAX_NAME }}-{{
                              tax_info.TAX_PERCENTAGE
                            }}
                            %
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >GST Amount($)</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          disabled
                          v-model="addupdatebuyerdata.DEPOSIT_TAX_AMOUNT"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Deposit Pay Without GST($)</label
                        >
                      </div>

                      <div class="col-md-8">
                        <input
                          disabled
                          v-model="
                            addupdatebuyerdata.DEPOSIT_AMOUNT_WITHOUT_TAX
                          "
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Total Deposit Pay($)</label
                        >
                      </div>

                      <div class="col-md-8">
                        <input
                          disabled
                          v-model="addupdatebuyerdata.DEPOSIT_AMOUNT_WITH_TAX"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">Remarks</label>
                      </div>
                      <div class="col-md-8">
                        <textarea
                          style="height: 80px !important"
                          v-model="addupdatebuyerdata.REMARKS"
                          class="custom-textarea form-control"
                        ></textarea>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Transaction Type</label
                        >
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="addupdatebuyerdata.TRANSACTION_TYPE"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option value="Pre Order">Pre Order</option>
                          <option value="New Vehicle">New Vehicle</option>
                          <option value="Local">Local</option>
                          <option value="ETS">ETS</option>
                          <option value="Scrap">Scrap</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >Transfer Out By</label
                        >
                      </div>
                      <div class="col-md-8">
                        <!-- <input
                          v-model="addupdatebuyerdata.TRANSFER_OUT_BY"
                          type="text"
                          class="form-control"
                          placeholder=""
                        /> -->
                        <select
                          v-model="addupdatebuyerdata.TRANSFER_OUT_BY"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="InternalUser in InternalUserList"
                            :value="InternalUser.USER_SYS_ID"
                            :key="InternalUser.USER_SYS_ID"
                          >
                            {{ InternalUser.PERSON_NAME }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right"
                          >E-Transfer Out Date</label
                        >
                      </div>
                      <div class="col-md-8">
                        <input
                          v-model="addupdatebuyerdata.E_TRANSFER_OUT_DATE"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-5">
                        <label class="frm-label-2 align-right"
                          >Log Card(Transfer Out)</label
                        >
                      </div>

                      <div class="col-md-2" v-if="isDisabledLogCardOut == true">
                        <button
                          style="margin-left: 27px"
                          type="button"
                          class="custom-btn me-1 p-0"
                          v-b-modal.log_card_preview_modal
                          @click="
                            previewLogCardPdfFile('BUYER_LOG_CARD_TRANSFER_OUT')
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-eye"
                          >
                            <path
                              d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                            ></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-5">
                        <label class="frm-label-2 align-right"
                          >Log Card2(Transfer Out)</label
                        >
                      </div>

                      <div
                        class="col-md-2"
                        v-if="isDisabledLogCardOut2 == true"
                      >
                        <button
                          style="margin-left: 27px"
                          type="button"
                          class="custom-btn me-1 p-0"
                          v-b-modal.log_card_preview_modal
                          @click="
                            previewLogCardPdfFile(
                              'BUYER_LOG_CARD_TRANSFER_OUT_2'
                            )
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-eye"
                          >
                            <path
                              d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                            ></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <label class="frm-label-2 align-right">Status</label>
                      </div>
                      <div class="col-md-8">
                        <select
                          v-model="addupdatebuyerdata.STATUS"
                          class="form-control"
                        >
                          <option value="">Select</option>
                          <option value="Pending">Pending</option>
                          <option value="Sold">Sold</option>
                          <option value="Handover">Handover</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Buyer/ETS Transaction Details Section Start-->

                <!-- Sales Receipt Section Start -->
                <h5 class="line-heading" ref="sales-receipt">
                  <span>Sales Receipt</span>
                </h5>
                <div class="col-md-12" id="sales-receipt-section">
                  <div>
                    <b-tabs v-model="activeSalesTabIndex">
                      <!--Insurance Info Tab Section -->
                      <b-tab
                        title="Insurance Info"
                        @click="
                          getInsuranceCompanyDetails(GLOBAL_RECORD_SYS_ID)
                        "
                      >
                        <div class="col-md-12" ref="insurance-info">
                          <div class="row mt-1">
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Company Name</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <select
                                    class="form-control roleddl"
                                    v-model="
                                      InsuranceInfoDetails.INSURANCE_COMPANY_SYS_ID
                                    "
                                  >
                                    <option value="">select</option>
                                    <option
                                      v-for="insurance_company_detail in insurance_company_details"
                                      :key="
                                        insurance_company_detail.INSURANCE_COMPANY_SYS_ID
                                      "
                                      :value="
                                        insurance_company_detail.INSURANCE_COMPANY_SYS_ID
                                      "
                                    >
                                      {{
                                        insurance_company_detail.INSURANCE_COMPANY
                                      }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >Premium Amount
                                    <span style="color: red"></span
                                  ></label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="number"
                                    v-model="
                                      InsuranceInfoDetails.PREMIUM_AMOUNT
                                    "
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Trade Info Tab Section -->
                      <b-tab
                        title="Trade Info"
                        @click="
                          getSearchAllVehicleDetailsForTradeInfo(),
                            getVechileTradeDetatils()
                        "
                      >
                        <div
                          aria-labelledby="flush-headingTwo"
                          class="mt-3"
                          ref="trade-info"
                        >
                          <div
                            class="p-2"
                            v-for="(tradeInfo, tradeIndex) in multiTradeInfo"
                            :key="tradeIndex"
                          >
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Trade In Veh No.</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <b-input-group>
                                        <b-form-input
                                          id="SEARCH_VEHICLE_INFO"
                                          @change="onChnageTradeInVehicleInfo"
                                          @keyup="onKeyUpTradeInVehicleInfo"
                                          v-model="tradeInfo.TRADE_IN_VEH_NO"
                                          placeholder="Enter vehicle no"
                                          list="SearchAllVehiclelistOptions"
                                        ></b-form-input>
                                      </b-input-group>
                                      <datalist
                                        id="SearchAllVehiclelistOptions"
                                      >
                                        <option
                                          v-for="vehicle_info in search_all_vehicle_details"
                                          :value="vehicle_info.VEHICLE_NUMBER"
                                          :key="vehicle_info.RECORD_SYS_ID"
                                          :id="vehicle_info.RECORD_SYS_ID"
                                        ></option>
                                      </datalist>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Trade In Amount (-)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="tradeInfo.TRADE_IN_AMOUNT"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Payment Made (+)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="tradeInfo.PAYMENT_MADE"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label
                                        class="frm-label-2 align-right"
                                        style="text-align: end"
                                        >Full Settlement Reimbursement</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        v-model="
                                          tradeInfo.FULL_SETTLEMENT_REIMBURSEMENT
                                        "
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <div class="row mt-4 mb-4"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Others Payment Tab Section -->
                      <b-tab title="Other Payment" @click="getOtherPayment">
                        <!--Others Payment Add Form -->
                        <div class="col-md-12" ref="other-payment"></div>

                        <!--Others Payment Table View -->
                        <div class="content-body-wrapper mt-4">
                          <div class="page-content">
                            <div class="page-data">
                              <div class="row">
                                <div
                                  class="col-md-12 animate__animated animate__zoomIn"
                                >
                                  <div class="card-wrap shadow-main">
                                    <div class="card-wrap-body">
                                      <div class="data-table master-table">
                                        <b-table
                                          striped
                                          hover
                                          :items="OTHER_PAYMENT_DETAILS"
                                          :fields="OTHER_PAYMENT_DETAILS_FIELDS"
                                          class="table table-bordered mt-3"
                                        >
                                          <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                          </template>
                                        </b-table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Sales Receipt Tab Section -->
                      <b-tab
                        title="Sales Receipt"
                        @click="getSalesReceiptNoteDetails"
                      >
                        <div
                          class="col-md-12"
                          style="text-align: right"
                          ref="sales-receipt-tab"
                        >
                          <button
                            v-show="truesales"
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportsales"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-2 mt-2">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                salesinvoicedata.SALES_RECEIPT_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              v-model="
                                salesinvoicedata.SALES_RECEIPT_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                          v-show="truesales"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div class="col-md-4" style="text-align: left">
                                <h4 class="fw-bold">
                                  <span
                                    style="
                                      border: 1.5px solid #212121;
                                      padding: 4px 10px;
                                    "
                                    >SALES RECEIPT</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <!-- header  -->
                            <div class="col-md-12">
                              <ul>
                                <li>
                                  <div class="row mt-2">
                                    <div class="col-md-8">
                                      To<span style="font-weight: bold"
                                        >&nbsp;:&nbsp;
                                        {{
                                          this.SalesReceiptNoteDetails
                                            .FIRST_NAME
                                        }}</span
                                      >
                                    </div>
                                    <div
                                      class="col-md-1"
                                      style="text-align: right"
                                    >
                                      <p>S-No</p>
                                    </div>
                                    <div class="col-md-3">
                                      <span style="font-weight: bold"
                                        >:&nbsp;
                                        {{
                                          this.SalesReceiptNoteDetails
                                            .INVOICE_NO
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="row mt-2">
                                    <div class="col-md-8">
                                      Address<span style="font-weight: bold"
                                        >&nbsp;:&nbsp;{{
                                          this.SalesReceiptNoteDetails
                                            .ADDRESS_LINE_1
                                        }}<br />
                                        <span
                                          style="
                                            font-weight: bold;
                                            margin-left: 35px;
                                          "
                                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                            this.SalesReceiptNoteDetails
                                              .COUNTRY_NAME
                                          }},
                                          {{
                                            this.SalesReceiptNoteDetails
                                              .PIN_CODE
                                          }}</span
                                        ></span
                                      >
                                    </div>
                                    <div
                                      class="col-md-1"
                                      style="text-align: right"
                                    >
                                      <p>Date</p>
                                    </div>
                                    <div class="col-md-3">
                                      <span style="font-weight: bold"
                                        >:&nbsp;
                                        {{
                                          this.SalesReceiptNoteDetails
                                            .INVOICE_DATE
                                        }}</span
                                      >
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="row mt-2">
                                    <div class="col-md-7">
                                      Vehicle Registration No<span
                                        style="font-weight: bold"
                                        >&nbsp;:&nbsp;
                                        <span style="font-weight: bold">
                                          {{
                                            this.SalesReceiptNoteDetails
                                              .VEHICLE_NUMBER
                                          }}</span
                                        ></span
                                      >
                                    </div>
                                    <div
                                      class="col-md-2"
                                      style="text-align: right"
                                    >
                                      <p>Agreement No.</p>
                                    </div>
                                    <div class="col-md-3">
                                      <span style="font-weight: bold"
                                        >:&nbsp;
                                        {{
                                          this.SalesReceiptNoteDetails
                                            .SALES_AGREEMENT_NUMBER
                                        }}</span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>

                            <!-- content body  -->
                            <div class="col-md-12" style="padding: 2em">
                              <div class="content-body-3">
                                <ul>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Selling Price.</p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .SELLING_PRICE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Transfer Fee</p>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .TRANSFER_FEE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Road Tax (1/2 yr)</p>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .ROAD_TAX
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>
                                          Insurance Premium (Comp/ TPFT/ TPO)-
                                          <span style="font-weight: bold">{{
                                            this.SalesReceiptNoteDetails
                                              .INSURANCE_COMPANY_NAME
                                          }}</span>
                                        </p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .INSURANCE_PREMIUM
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>HP Facilities Fees</p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .HP_FEE_UNIT_PRICE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p style="font-weight: bold">
                                          Loan Advance Payment
                                        </p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .ADVANCE_PAYMENT
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-1"><p>Others</p></div>
                                      <div
                                        class="col-md-8"
                                        style="text-align: left"
                                      >
                                        <u
                                          ><span style="font-weight: bold">
                                            {{
                                              this.SalesReceiptNoteDetails
                                                .OTHER_DESCRIPTION
                                            }}
                                          </span></u
                                        >
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .OTHER_FEE_UNIT_PRICE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Admin Fee</p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .ADMIN_FEE_UNIT_PRICE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p style="font-weight: bold">
                                          TOTAL PAYABLE
                                        </p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .FINAL_PREMIUM
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9"><p>Less</p></div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p>Deposit paid</p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .DEPOSIT_AMOUNT_WITH_TAX
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>Trade in Vehicle No.</p>
                                          </div>
                                          <div class="col-md-3 data">
                                            <div class="row">
                                              <div class="col-md-12">
                                                <span style="font-weight: bold">
                                                  {{
                                                    this.SalesReceiptNoteDetails
                                                      .TRADE_IN_VEH_NO
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              class="divider-sales-resceipt"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-12 mt-1">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <div
                                              class="row"
                                              style="margin-left: 2px"
                                            >
                                              <div
                                                class="col-md-6 data"
                                                style="width: 118px !important"
                                              >
                                                <div class="row">
                                                  <div class="col-md-2">
                                                    <span
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <span
                                                      style="font-weight: bold"
                                                    >
                                                      {{
                                                        this
                                                          .SalesReceiptNoteDetails
                                                          .TRADE_IN_AMOUNT
                                                      }}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div
                                                  class="divider-sales-resceipt"
                                                ></div>
                                              </div>
                                              <div class="col-md-6 p-0">
                                                <p>(Trade in Price)</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-5 ps-0">
                                            <div class="row">
                                              <div class="col-md-2">
                                                <p>Less</p>
                                                <div class="col-md-3 data">
                                                  <u
                                                    ><span
                                                      style="font-weight: bold"
                                                    ></span
                                                  ></u>
                                                </div>
                                              </div>
                                              <div
                                                class="col-md-4 data"
                                                style="width: 104px !important"
                                              >
                                                <div class="row">
                                                  <div class="col-md-2">
                                                    <span
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <span
                                                      style="font-weight: bold"
                                                    >
                                                      {{
                                                        this
                                                          .SalesReceiptNoteDetails
                                                          .PURCHASE_SETTLEMENT_AMOUNT
                                                      }}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div
                                                  class="divider-sales-resceipt"
                                                ></div>
                                              </div>
                                              <div class="col-md-6">
                                                <p>(Finance Due)</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-3 data">
                                            <div class="row">
                                              <div class="col-md-2">
                                                <span style="font-weight: bold">
                                                  $
                                                </span>
                                              </div>
                                              <div class="col-md-6">
                                                <span style="font-weight: bold">
                                                  {{ this.calculation }}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              class="divider-sales-resceipt"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-2">
                                        <p>Loan Amount</p>
                                      </div>
                                      <div class="col-md-7">
                                        <div class="row">
                                          <div class="col-md-1 data">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <span style="font-weight: bold">
                                                  &nbsp;&nbsp;{{
                                                    this.SalesReceiptNoteDetails
                                                      .INTEREST_RATE
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              class="divider-sales-resceipt-2"
                                            ></div>
                                          </div>
                                          <div
                                            class="col-md-1"
                                            style="margin-left: 10px"
                                          >
                                            X
                                          </div>
                                          <div
                                            class="col-md-2 data"
                                            style="width: 55px !important"
                                          >
                                            <div class="row">
                                              <div class="col-md-6">
                                                <span style="font-weight: bold">
                                                  &nbsp;&nbsp;{{
                                                    this.SalesReceiptNoteDetails
                                                      .LOAN_TENURE
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              class="divider-sales-resceipt-2"
                                            ></div>
                                          </div>
                                          <div
                                            class="col-md-2 p-0"
                                            style="margin-left: 10px"
                                          >
                                            <p>Months</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              {{
                                                this.SalesReceiptNoteDetails
                                                  .LOAN_AMOUNT
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-3">
                                        <p>Finance Co.</p>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <span style="font-weight: bold">
                                              &nbsp;&nbsp;{{
                                                this.SalesReceiptNoteDetails
                                                  .FINANCE_COMPANY
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          style="width: 15em"
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                      <div class="col-md-3 data">
                                        <span></span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-9">
                                        <p style="font-weight: bold">
                                          BALANCE DUE
                                        </p>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-2">
                                            <span style="font-weight: bold">
                                              $
                                            </span>
                                          </div>
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              &nbsp;&nbsp;{{
                                                this.SalesReceiptNoteDetails
                                                  .BALANCE_DUE
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row" style="margin-top: 5px">
                                      <div class="col-md-3"></div>
                                      <div class="col-md-3 data"></div>
                                      <div class="col-md-3">
                                        <span style="float: right"
                                          >Cash/ Cheque No.
                                        </span>
                                      </div>
                                      <div class="col-md-3 data">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <span style="font-weight: bold">
                                              &nbsp;&nbsp;{{
                                                this.SalesReceiptNoteDetails
                                                  .CHEQUE_NO
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="divider-sales-resceipt"
                                        ></div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- content body 2  -->
                            <div class="col-md-12">
                              <div
                                class="content-body-2 p-1"
                                style="border: 1px solid #212121"
                              >
                                <p style="color: red; padding-bottom: 10px">
                                  <b>Delivery Acknowledgement</b>
                                </p>

                                I / We confirm that I/we have examined the above
                                said vehicle and that the same is good order and
                                proper condition and suitable for the purpose
                                for which I/We require it and to my/ our
                                satisfaction in every respect and that I/We have
                                to take delivery of vehicle on
                                &nbsp;(date)&nbsp;<u
                                  ><span style="font-weight: bold">{{
                                    this.SalesReceiptNoteDetails
                                      .DELIVERY_IN_DATE
                                  }}</span></u
                                >&nbsp;(time)&nbsp;<span>
                                  <u
                                    ><span style="font-weight: bold">{{
                                      this.SalesReceiptNoteDetails
                                        .DELIVERY_IN_TIME
                                    }}</span></u
                                  >
                                  . The buyer henceforth on accepting delivery
                                  of the vehicle on the time indicated above I/
                                  We will be fully responsible for the vehicle
                                  use including any traffic offence and or
                                  illegal acts
                                </span>
                              </div>
                            </div>
                            <!-- content footer  -->
                            <div class="col-md-12">
                              <div class="content-footer-2">
                                <div class="row mb-5">
                                  <div class="col-md-6">
                                    <p>Signed for & behalf of</p>
                                  </div>
                                  <div class="col-md-6">
                                    <p style="float: right">
                                      Agreed & Aceepted by Buyer
                                    </p>
                                  </div>
                                </div>
                                <div class="row mt-4">
                                  <div class="col-md-7">
                                    <ul>
                                      <li>
                                        <span style="border-top: 2px solid #000"
                                          >Authorised Signature</span
                                        >
                                      </li>
                                      <li>
                                        <div class="row mt-2">
                                          <div class="col-md-1 p-0">
                                            <p>&nbsp;&nbsp;Name</p>
                                          </div>
                                          <div class="col-md-8">
                                            :
                                            <span style="font-weight: bold">{{
                                              this.SalesReceiptNoteDetails
                                                .INCHARGE_NAME
                                            }}</span>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="row">
                                          <div class="col-md-1 p-0">
                                            <p>&nbsp;&nbsp;HP</p>
                                          </div>
                                          <div class="col-md-8">
                                            :
                                            <span style="font-weight: bold">{{
                                              this.SalesReceiptNoteDetails
                                                .INCHARGE_CONTACT
                                            }}</span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col-md-5">
                                    <ul
                                      style="float: right; text-align: center"
                                    >
                                      <li>
                                        <span
                                          style="
                                            border-top: 2px solid #000;
                                            width: 100px;
                                          "
                                          >Authorised Signature of Buyer</span
                                        >
                                      </li>
                                      <li><span>Company Stamp</span></li>
                                      <li>
                                        <div
                                          class="row mt-2"
                                          style="text-align: left"
                                        >
                                          <div class="col-md-4 p-0">
                                            <p>&nbsp;&nbsp;Name</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span>: </span>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div
                                          class="row"
                                          style="text-align: left"
                                        >
                                          <div class="col-md-4 p-0">
                                            <p>&nbsp;&nbsp;NRIC No.</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span>: </span>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div
                                          class="row"
                                          style="text-align: left"
                                        >
                                          <div class="col-md-4 p-0">
                                            <p>&nbsp;&nbsp;HP</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span>: </span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <!-- Sales Receipt Section End -->

                <!-- Loan Info Section Start -->
                <h5 class="line-heading" ref="loan-info">
                  <span>Loan Info</span>
                </h5>
                <div class="col-md-12" id="loan-info-section">
                  <div>
                    <b-tabs v-model="activeLoanTabIndex">
                      <!--Loan Info Tab Section -->
                      <b-tab title="Loan Info" active>
                        <div
                          aria-labelledby="flush-headingOne"
                          class="mt-3"
                          ref="loan-tab-info"
                        >
                          <div class="p-2">
                            <div class="col-md-12">
                              <div
                                class="row"
                                v-if="addupdatebuyerdata.TAX_SYS_ID != ''"
                              >
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Finance Company</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        class="form-control roleddl"
                                        v-model="loan_info.FIN_COMP_SYS_ID"
                                        @change="financeCompanyOnchangeFunc"
                                      >
                                        <option value="">select</option>
                                        <option
                                          v-for="FinanceCompanyDetail in FinanceCompanyDetails"
                                          :key="
                                            FinanceCompanyDetail.FINANCE_COMPANY_SYS_ID
                                          "
                                          :value="
                                            FinanceCompanyDetail.FINANCE_COMPANY_SYS_ID
                                          "
                                        >
                                          {{
                                            FinanceCompanyDetail.FINANCE_COMPANY
                                          }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right">
                                        Bank
                                      </label>
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        v-model="loan_info.BANK_SYS_ID"
                                        class="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option
                                          v-for="item in finance_company_bank_details"
                                          :value="
                                            item.FINANCE_COMPANY_BANK_SYS_ID
                                          "
                                          :key="
                                            item.FINANCE_COMPANY_BANK_SYS_ID
                                          "
                                        >
                                          {{ item.FINANCE_COMPANY_BANK_NAME }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <!-- <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Invoice Number</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter invoice number"
                                        v-model="loan_info.INVOICE_NO"
                                      />
                                    </div>
                                  </div> -->
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Amount(With GST)(S$)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.LOAN_AMOUNT"
                                        @keyup="
                                          loanInfoCalculation(),
                                            loanAmountKeyupFunc()
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Tenure(In Month)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.LOAN_TENURE"
                                        @keyup="loanInfoCalculation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Interest Rate(%)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.INTEREST_RATE"
                                        @keyup="loanInfoCalculation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Amount(Without GST)(S$)</label
                                      >
                                    </div>

                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                        v-model="
                                          loan_info.LOAN_AMOUNT_WITHOUT_TAX
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Advance Payment Decimal Adjustment($)
                                        (+1/-1)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          loan_info.ADVANCE_PAYMENT_DECIMAL_ADJUSTMENT
                                        "
                                        @keyup="
                                          globalDecimalAdjusment(
                                            'LOAN_INFO_ADVANCE_PAYMENT'
                                          )
                                        "
                                        type="number"
                                        min="-1"
                                        max="1"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-left"
                                        >Advance Payment Before
                                        Adjustment(S$)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          loan_info.ADVANCE_PAYMENT_BEFORE_ADJUSTMENT
                                        "
                                        disabled
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Advance Payment(S$)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <b-form-input
                                        disabled
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.ADVANCE_PAYMENT"
                                      ></b-form-input>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Interest :</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        disabled
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.INTEREST_AMOUNT"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Subtotal :</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        disabled
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.SUBTOTAL"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >GST</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <select
                                        v-model="loan_info.LOAN_TAX_SYS_ID"
                                        @change="
                                          globalCalculateGST('LOAN_INFO')
                                        "
                                        class="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option
                                          v-for="tax_info in tax_info_detai"
                                          :value="tax_info.TAX_SYS_ID"
                                          :key="tax_info.TAX_SYS_ID"
                                        >
                                          {{ tax_info.TAX_NAME }}-{{
                                            tax_info.TAX_PERCENTAGE
                                          }}
                                          %
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >GST Decimal Adjustment($)
                                        (+1/-1)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          loan_info.LOAN_TAX_DECIMAL_ADJUSTMENT
                                        "
                                        @keyup="
                                          globalDecimalAdjusment('LOAN_INFO')
                                        "
                                        type="number"
                                        min="-1"
                                        max="1"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-left"
                                        >GST Amount Before Adjustment($)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="
                                          loan_info.LOAN_TAX_AMOUNT_BEFORE_ADJUSTMENT
                                        "
                                        disabled
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >GST Amount($)<span style="color: red"
                                          >*</span
                                        ></label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        v-model="loan_info.LOAN_TAX_AMOUNT"
                                        type="text"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>

                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >HP Facilties Fee</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.HP_FACILITIES_FEE"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Commission(%)</label
                                      >
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        class="form-control"
                                        @keyup="loanCommisionCalculator"
                                        placeholder=""
                                        v-model="
                                          loan_info.LOAN_COMMISSION_PERCENTAGE
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Loan Commission (Loan Amt x Loan
                                        Commission %)</label
                                      >
                                    </div>

                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.LOAN_COMMISSION"
                                      />
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-md-4">
                                      <label class="frm-label-2 align-right"
                                        >Salesman Commission (For admin fee &
                                        loan commission)</label
                                      >
                                    </div>
                                    <div class="col-md-8 mt-2">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder=""
                                        v-model="loan_info.SALES_COMMISSION"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6 pt-1 pb-1">
                                  <div class="row mt-4 mb-4"></div>
                                </div>
                              </div>
                              <div
                                class="row pb-5"
                                style="text-align: center"
                                v-if="addupdatebuyerdata.TAX_SYS_ID == ''"
                              >
                                <h5 style="color: red">
                                  Can't Identify GST Slabs From Buyer Details.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!-- Finance Company Tax Invoice Tab Section-->
                      <b-tab
                        title="Finance Company Tax Invoice"
                        @click="getFinanceCompanyInvoiceDetailsWithTax"
                      >
                        <button
                          class="downld animate__animated animate__bounceIn"
                          @click="generateReporttaxinvoicefinancial"
                          ref="finance-company-tax-invoice"
                        >
                          <i
                            class="fa-solid fa-cloud-arrow-down"
                            style="color: blue"
                          ></i>
                          Download
                        </button>
                        <div class="row mt-3">
                          <div class="col-md-2 mt-2">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                financetaxinvoicedata.FINANCE_TAX_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              v-model="
                                financetaxinvoicedata.FINANCE_TAX_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px"
                                    >TAX INVOICE</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <!-- header  -->
                            <div class="col-md-4">
                              <div
                                class="content-header-2"
                                style="margin-left: 2em"
                              >
                                <ul>
                                  <li><p>Bill To,</p></li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.finance_company_tax_invoice
                                          .FINANCE_COMPANY
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.finance_company_tax_invoice
                                            .FINANCE_COMPANY_ADDRESS
                                        }}
                                      </span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.finance_company_tax_invoice
                                            .FINANCE_COMPANY_COUNTRY_NAME
                                        }}
                                        {{
                                          this.finance_company_tax_invoice
                                            .FINANCE_COMPANY_PIN_CODE
                                        }}</span
                                      >
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <ul>
                                <li><p>In Respect Of Hirer :</p></li>
                                <li>
                                  <p>
                                    {{
                                      this.finance_company_tax_invoice
                                        .CUSTOMER_NAME
                                    }}
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    {{
                                      this.finance_company_tax_invoice
                                        .CUSTOMER_ADDRESS
                                    }}
                                  </p>
                                </li>

                                <li>
                                  <p>
                                    {{
                                      this.finance_company_tax_invoice
                                        .CUSTOMER_COUNTRY_NAME
                                    }}
                                    {{
                                      this.finance_company_tax_invoice
                                        .CUSTOMER_PIN_CODE
                                    }}
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Invoice No</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.finance_company_tax_invoice
                                              .INVOICE_NO
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Term</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;Immediate</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Date</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;
                                          {{
                                            this.finance_company_tax_invoice
                                              .TAX_INVOICE_DATE
                                          }}</span
                                        >
                                        <!-- <span style="font-weight:bold">{{this.pdf_deta.TAX_INVOICE_DATE}} </span> -->
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>VSA No.</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold">
                                          :&nbsp;{{
                                            this.finance_company_tax_invoice
                                              .VSA_NUMBER
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- after heading sec  -->
                            <div class="col-md-12 mt-3">
                              <div
                                class="content-body-2 ps-1 pe-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-7 pt-1 pb-1">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Vehicle Registration No.</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .VEHICLE_NUMBER
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Vehicle Make</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .MAKE_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>VehicleModel</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .MODEL_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Original Registration Date</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .ORIGINAL_REGISTRATION_DATE
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-5 pt-1 pb-1"
                                    style="
                                      border-left: 1px solid rgba(0, 0, 0, 0.3);
                                    "
                                  >
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Engine No./Motor No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .ENGINE_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Chassis No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .CHASIS_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Sales Person</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .SALESMAN
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>GST</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold">
                                                :
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .GST_PERCENTAGE
                                                }}
                                                %
                                                {{
                                                  this
                                                    .finance_company_tax_invoice
                                                    .TAX_TYPE
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12 mt-3">
                              <div
                                class="content-footer"
                                style="border: 1px solid #212121"
                              >
                                <table class="table table-borderless">
                                  <thead
                                    style="border-bottom: 1px solid #212121"
                                  >
                                    <tr></tr>
                                    <tr>
                                      <th scope="col">
                                        <span style="font-weight: bold"
                                          >Description</span
                                        >
                                      </th>
                                      <th scope="col">
                                        <span style="font-weight: bold"></span>
                                      </th>
                                      <th
                                        scope="col"
                                        class="border-td"
                                        style="text-align: center"
                                      >
                                        <span style="font-weight: bold"
                                          >Amount&nbsp;($)</span
                                        >
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td class="border-td-2">
                                        <span style="font-weight: bold">
                                          Loan Amount</span
                                        >
                                      </td>
                                      <td class="border-td border-td-2">
                                        <span style="font-weight: bold"> </span>
                                      </td>
                                      <td class="border-td border-td-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <span class="fw-bold">$</span>
                                          </div>
                                          <div class="col-md-4">
                                            <span class="fw-bold">{{
                                              this.finance_company_tax_invoice
                                                .LOAN_AMOUNT
                                            }}</span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Vehicle Invoice Price</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .SELLING_PRICE
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6"></div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <!-- <td class="border-td"><span class="ms-1">$ 77,500.0 </span></td> -->
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Deposit Paid</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .DEPOSIT_AMOUNT_WITH_TAX
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6"></div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Loan Amount</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .LOAN_AMOUNT
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6"></div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >GST Details</span
                                        >
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span
                                                style="font-weight: bold"
                                              ></span>
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div
                                              class="row"
                                              style="text-align: center"
                                            >
                                              <div class="col-md-4">
                                                <span class="fw-bold"
                                                  >Taxable supplies</span
                                                >
                                              </div>
                                              <div class="col-md-7">
                                                <span class="fw-bold">
                                                  {{
                                                    this
                                                      .finance_company_tax_invoice
                                                      .TAX_TYPE
                                                  }}
                                                  {{
                                                    this
                                                      .finance_company_tax_invoice
                                                      .GST_PERCENTAGE
                                                  }}
                                                  %
                                                </span>
                                              </div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Customer</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .DEPOSIT_AMOUNT_WITHOUT_TAX
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .DEPOSIT_TAX_AMOUNT
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Finance co.</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .LOAN_AMOUNT_WITHOUT_TAX
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .LOAN_TAX_AMOUNT
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Total GST Incl.</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .TOTAL_FINANCE_WITHOUT_GST_AMOUNT
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                    >
                                                      $
                                                    </span>
                                                  </div>
                                                  <div class="col-md-9">
                                                    <span
                                                      class="ms-1"
                                                      style="font-weight: bold"
                                                      >{{
                                                        this
                                                          .finance_company_tax_invoice
                                                          .TOTAL_FINANCE_GST
                                                      }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr style="border-top: 1px solid #212529">
                                      <!-- <th scope="row">1</th> -->
                                      <td class="border-td-2">
                                        <span style="font-weight: bold"
                                          >Total Invoice Amount</span
                                        >
                                      </td>
                                      <td class="border-td border-td-2">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td border-td-2">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <span class="fw-bold">$</span>
                                          </div>
                                          <div class="col-md-4">
                                            <span class="fw-bold">{{
                                              this.finance_company_tax_invoice
                                                .LOAN_AMOUNT
                                            }}</span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- content footer  -->
                            <div class="content-footer">
                              <p class="mt-2">
                                Please cross and make cheque payable to Lek Auto
                                Pte Ltd (Bank Account OCBC 671885168001)
                              </p>
                              <div class="row mt-3">
                                <div class="col-md-9">
                                  <ul>
                                    <li>
                                      <div class="row">
                                        <h6>
                                          THIS IS A COMPUTER GENERATED INVOICE.
                                          NO SIGNATURE REQUIRED
                                        </h6>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!-- Finance Company Invoice Tab Section-->
                      <b-tab
                        title="Finance Company Invoice"
                        @click="getFinanceCompanyInvoiceDetailsWithoutTax"
                      >
                        <button
                          class="downld animate__animated animate__bounceIn"
                          @click="generateReportForFCompanyInvoice"
                          ref="finance-company-invoice"
                        >
                          <i
                            class="fa-solid fa-cloud-arrow-down"
                            style="color: blue"
                          ></i>
                          Download
                        </button>
                        <div class="row mt-3">
                          <div class="col-md-2 mt-2">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                finance_company_invoice_form.FINANCE_COMPANY_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              v-model="
                                finance_company_invoice_form.FINANCE_COMPANY_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                        </div>

                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px">INVOICE</span>
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <!-- header  -->
                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li><p>Bill To,</p></li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.finance_company_invoice
                                          .FINANCE_COMPANY
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.finance_company_invoice
                                            .ADDRESS_LINE_1
                                        }}
                                      </span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.finance_company_invoice
                                            .CUSTOMER_COUNTRY_NAME
                                        }}
                                        {{
                                          this.finance_company_invoice
                                            .FINANCE_COMPANY_PIN_CODE
                                        }}</span
                                      >
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Invoice No</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.finance_company_invoice
                                              .INVOICE_NO
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Date</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;
                                          {{
                                            this.finance_company_invoice
                                              .TAX_INVOICE_DATE
                                          }}</span
                                        >
                                        <!-- <span style="font-weight:bold">{{this.pdf_deta.TAX_INVOICE_DATE}} </span> -->
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>GST Reg No.</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;201929524C</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Co Reg No.</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold">
                                          :&nbsp;201929524C</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- after heading sec  -->
                            <div class="col-md-12 mt-3">
                              <div class="row">
                                <div class="col-md-3">For Account</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold"
                                    >:&nbsp;{{
                                      this.finance_company_invoice.CUSTOMER_NAME
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">Description</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold"
                                    >:&nbsp;1 unit of&nbsp;&nbsp;
                                    {{ this.finance_company_invoice.MAKE_NAME }}
                                    {{
                                      this.finance_company_invoice.MODEL_NAME
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">Chassis No</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold">
                                    :&nbsp;{{
                                      this.finance_company_invoice.CHASIS_NO
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">Engine No./Motor No.</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold">
                                    :&nbsp;{{
                                      this.finance_company_invoice.ENGINE_NO
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">Registration No</div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold">
                                    :&nbsp;{{
                                      this.finance_company_invoice
                                        .VEHICLE_NUMBER
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">
                                  Original Registraion Date
                                </div>
                                <div class="col-md-9">
                                  <span style="font-weight: bold">
                                    :&nbsp;{{
                                      this.finance_company_invoice
                                        .ORIGINAL_REGISTRATION_DATE
                                    }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-4">
                              <div
                                class="content-body-2 ps-1 pe-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-12 pt-1 pb-1">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-8">
                                              <p>Selling Price</p>
                                            </div>
                                            <div
                                              class="col-md-1"
                                              style="text-align: right"
                                            >
                                              <span style="font-weight: bold">
                                                $
                                              </span>
                                            </div>
                                            <div class="col-md-3">
                                              <span style="font-weight: bold">
                                                {{
                                                  this.finance_company_invoice
                                                    .SELLING_PRICE
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-8">
                                              <p>Deposit Paid</p>
                                            </div>
                                            <div
                                              class="col-md-1"
                                              style="text-align: right"
                                            >
                                              <span style="font-weight: bold">
                                                $
                                              </span>
                                            </div>
                                            <div class="col-md-3">
                                              <span style="font-weight: bold">
                                                {{
                                                  this.finance_company_invoice
                                                    .DEPOSIT_AMOUNT_WITHOUT_TAX
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-8">
                                              <p>Loan Amount</p>
                                            </div>
                                            <div
                                              class="col-md-1"
                                              style="text-align: right"
                                            >
                                              <span style="font-weight: bold">
                                                $
                                              </span>
                                            </div>
                                            <div class="col-md-3">
                                              <span style="font-weight: bold">
                                                {{
                                                  this.finance_company_invoice
                                                    .LOAN_AMOUNT_WITHOUT_TAX
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row mt-4">
                                            <div class="col-md-8">
                                              <p>
                                                <u
                                                  >Total payable(Finance
                                                  amount)</u
                                                >
                                              </p>
                                            </div>
                                            <div
                                              class="col-md-1"
                                              style="text-align: right"
                                            >
                                              <span style="font-weight: bold">
                                                $
                                              </span>
                                            </div>
                                            <div class="col-md-3">
                                              <span style="font-weight: bold">
                                                {{
                                                  this.finance_company_invoice
                                                    .TOTAL_FINANCE_WITHOUT_GST_AMOUNT
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 mt-4">
                              <div
                                class="content-body-2 ps-1 pe-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-12 pt-1 pb-1">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-12">
                                              <p>
                                                I declear that the above
                                                particulars in the invoice are
                                                true and correct
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-12">
                                              <p>
                                                NO GST IS DUE FROM FINANCE
                                                COMPANY AS TAXABLE VAULE IS
                                                ZERO.
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            class="row"
                                            style="margin-top: 5px"
                                          >
                                            <div class="col-md-12">
                                              <p>
                                                THE FULL GST AMOUNT HAS BEEN
                                                PAID FOR BY THE HIRER.
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Official Receipt Tab Section -->
                      <b-tab
                        title="Official Receipt"
                        @click="getOfficialReceiptDetailsList"
                      >
                        <div class="row mt-3">
                          <div class="col-md-2"></div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-2">
                                <label
                                  class="frm-label-2"
                                  style="font-weight: bold"
                                  >To/From</label
                                >
                              </div>
                              <div class="col-md-8">
                                <select
                                  class="form-control roleddl"
                                  v-model="OFFICIAL_RECEIPT_CUSTOMER"
                                  @change="onchangeOfficialReceiptCustomer"
                                >
                                  <option value="">Select</option>
                                  <option
                                    v-for="OFFICIAL_RECEIPT_CUSTOMER_DETAIL in OFFICIAL_RECEIPT_DETAILS_LIST"
                                    :value="
                                      OFFICIAL_RECEIPT_CUSTOMER_DETAIL.CUSTOMER_SYS_ID
                                    "
                                    :key="
                                      OFFICIAL_RECEIPT_CUSTOMER_DETAIL.CUSTOMER_SYS_ID
                                    "
                                  >
                                    {{
                                      OFFICIAL_RECEIPT_CUSTOMER_DETAIL.CUSTOMER_NAME
                                    }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="OFFICIAL_RECEIPT_CUSTOMER != ''"
                            class="col-md-2"
                            style="text-align: right"
                            ref="official-receipt"
                          >
                            <button
                              v-show="officalrecipt"
                              class="downld animate__animated animate__bounceIn"
                              @click="generateOfficeReceipt"
                            >
                              <i
                                class="fa-solid fa-cloud-arrow-down"
                                style="color: blue"
                              ></i>
                              Download
                            </button>
                          </div>
                        </div>

                        <!-- <div class="row mt-5" v-if="OFFICIAL_RECEIPT_CUSTOMER != ''" v-show="officalrecipt">
                              <div class="col-md-2 mt-2"><label class="frm-label">Invoice No.</label></div>
                              <div class="col-md-3">
                                <input
                                  style="height:3em;"
                                  type="text"
                                  v-model="officalreceipt.OFFICIAL_RECEIPT_INVOICE_NO"
                                  class="form-control"
                                  placeholder="Enter Invoice No."
                                />
                              </div>
                              <div class="col-md-1 mt-2"><label class="frm-label">Date</label></div>
                              <div class="col-md-3">
                                <input
                                  style="height:3em;"
                                  v-model="officalreceipt.OFFICIAL_RECEIPT_INVOICE_DATE"
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Date"
                                />
                              </div>
                              <div class="col-md-2">
                                   <button type="button"
                                    class="custom-btn custom-success-btn"
                                    @click="AddInvoiceNumberAndDateForOfficialReceipt"
                                  >Save</button>
                              </div>
                        </div> -->
                        <div
                          v-if="OFFICIAL_RECEIPT_CUSTOMER != ''"
                          class="purchase-settlement-note-cont mt-4"
                          v-show="officalrecipt"
                        >
                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row">
                                <div
                                  class="col-md-3"
                                  style="text-align: center"
                                >
                                  <img
                                    src="../../assets/images/icon.png"
                                    class="img-fluid header-img"
                                    alt="#"
                                  />
                                </div>
                                <div class="col-md-9 p-0">
                                  <ul>
                                    <li>
                                      <img
                                        src="../../assets/images/logo-txt.png"
                                      />
                                    </li>
                                    <li class="mt-2">
                                      <p>
                                        1 Sunview Road #01-46 Eco-Tech@Sunview
                                        Singapore 627615
                                      </p>
                                    </li>
                                    <li>
                                      <p>ROC No./ GST Reg No:. 201929524C</p>
                                    </li>
                                    <li><p>Tel: 62395191 Fax: 62395190</p></li>
                                  </ul>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-8"></div>
                                <div
                                  class="col-md-4"
                                  style="
                                    text-align: center;
                                    border: 1.5px solid #212121;
                                  "
                                >
                                  <h4 class="fw-bold">
                                    <span style="padding: 4px 10px"
                                      >OFFICIAL RECEIPT</span
                                    >
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div class="row mt-3">
                            <div class="col-md-1"><p>Date</p></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;
                              {{ this.OFFICIAL_RECEIPT_PDF_OBJ.RECEIPT_DATE }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-1"><p>To</p></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.CUSTOMER_NAME
                              }}
                              <br />
                            </div>
                          </div>
                          <div class="mt-4 text-center">
                            <h5
                              style="
                                font-family: 'Times New Roman', Times, serif;
                                font-weight: 600;
                              "
                            >
                              TO WHOM IT MAY CONCERN
                            </h5>
                          </div>
                          <p class="mt-3">
                            We hereby confirm that we have sold & received Full
                            Payment
                            <b
                              >${{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.TOTAL_AMOUNT
                              }}</b
                            >
                            from
                            <b>{{
                              this.OFFICIAL_RECEIPT_PDF_OBJ.CUSTOMER_NAME
                            }}</b>
                            for the following vehicle.
                          </p>
                          <div class="row mt-4">
                            <div class="col-md-3">
                              <p>Vehicle Registration No</p>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.VEHICLE_NUMBER
                              }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-3"><p>Make & Model</p></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;
                              {{ this.OFFICIAL_RECEIPT_PDF_OBJ.MAKE_NAME }}
                              {{ this.OFFICIAL_RECEIPT_PDF_OBJ.MODEL_NAME }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-3">
                              <p>Engine No./Motor No.</p>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.ENGINE_NO
                              }}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-3"><p>Chassis No</p></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                this.OFFICIAL_RECEIPT_PDF_OBJ.CHASIS_NO
                              }}
                            </div>
                          </div>
                          <!-- <br />
                          <br />
                          <br />
                          <br />
                          <div class="row mt-5 mb-3">
                            <div
                              style="border-bottom: 1px solid #212121"
                              class="col-md-3"
                            ></div>
                            <p>Director: Pauson Lek</p>
                          </div> -->
                        </div>
                      </b-tab>

                      <!--Loan Commision Invoice Tab Section  -->
                      <b-tab
                        title="Tax Commision Invoice"
                        @click="getLoanCommisionDetails"
                      >
                        <div
                          class="col-md-12"
                          style="text-align: right"
                          ref="loan-commission-invoice"
                        >
                          <button
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportForTaxCommissionInvoice"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-2 mt-2">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                taxcommisoninvoicedata.TAX_COMMISSION_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              v-model="
                                taxcommisoninvoicedata.TAX_COMMISSION_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div
                                class="col-md-6"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold" style="padding: 5px">
                                  <span style="padding: 5px 10px"
                                    >TAX COMMISSION INVOICE</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Invoice No</b></div>
                            <div class="col-md-9">
                              <p style="font-weight: bold">
                                :&nbsp;{{ LOAN_COMMISSION_DETAILS.INVOICE_NO }}
                              </p>
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Date</b></div>
                            <div class="col-md-9">
                              <p style="font-weight: bold">
                                :&nbsp;{{
                                  LOAN_COMMISSION_DETAILS.INVOICE_DATE
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>Bill To</b>
                            </div>
                            <div class="col-md-9">
                              <p style="font-weight: bold">
                                : &nbsp;{{
                                  LOAN_COMMISSION_DETAILS.FINANCE_COMPANY
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  LOAN_COMMISSION_DETAILS.ADDRESS
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  LOAN_COMMISSION_DETAILS.COUNTRY
                                }}
                                {{ LOAN_COMMISSION_DETAILS.PIN_CODE }}
                              </p>
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>For the account of</b>
                            </div>
                            <div class="col-md-9" style="font-weight: bold">
                              :&nbsp;{{ LOAN_COMMISSION_DETAILS.CUSTOMER_NAME }}
                            </div>
                          </div>
                          <div class="row mt-5" style="margin-left: 2em">
                            <div class="col-md-8">
                              <u
                                ><b><p>Description of Item</p></b></u
                              >
                            </div>
                            <div align="center" class="col-md-4">
                              <u
                                ><b><p>Amount</p></b></u
                              >
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>Being Commission for Hirer</b>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{ LOAN_COMMISSION_DETAILS.CUSTOMER_NAME }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>Vehicle Registration No</b>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                LOAN_COMMISSION_DETAILS.VEHICLE_NUMBER
                              }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Make & Model</b></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{
                                LOAN_COMMISSION_DETAILS.MAKE_NAME
                              }}&nbsp;{{ LOAN_COMMISSION_DETAILS.MODEL_NAME }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b>Engine No./Motor No.</b>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{ LOAN_COMMISSION_DETAILS.ENGINE_NO }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Chassis No.</b></div>
                            <div class="col-md-6" style="font-weight: bold">
                              :&nbsp;{{ LOAN_COMMISSION_DETAILS.CHASIS_NO }}
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3"><b>Commission Rate</b></div>
                            <div class="col-md-6" style="font-weight: bold">
                              : $&nbsp;{{
                                LOAN_COMMISSION_DETAILS.LOAN_AMOUNT_WITHOUT_TAX
                              }}&nbsp;&nbsp; x &nbsp;&nbsp;
                              {{
                                LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_PERCENTAGE
                              }}
                              %
                            </div>
                            <div class="col-md-1" style="text-align: right">
                              <b>$</b>
                            </div>
                            <div class="col-md-2" style="text-align: left">
                              <b>{{
                                LOAN_COMMISSION_DETAILS.LOAN_COMMISSION
                              }}</b>
                            </div>
                          </div>
                          <div class="row mt-2" style="margin-left: 2em">
                            <div class="col-md-3">
                              <b
                                >{{ LOAN_COMMISSION_DETAILS.GST_PERCENTAGE }}%
                                {{ LOAN_COMMISSION_DETAILS.TAX_TYPE }}</b
                              >
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              :
                            </div>
                            <div class="col-md-1" style="text-align: right">
                              <b>$</b>
                            </div>
                            <div class="col-md-2" style="text-align: left">
                              <b>{{ LOAN_COMMISSION_DETAILS.GST_AMOUNT }}</b>
                            </div>
                          </div>
                          <div class="row mt-4" style="margin-left: 2em">
                            <div class="col-md-9">
                              <b>Nett Amount Payable (Inclusive of GST)</b>
                            </div>
                            <div
                              class="col-md-1"
                              style="
                                text-align: right;
                                border-top: 1px solid black;
                                border-bottom: 1px solid black;
                              "
                            >
                              <b>$</b>
                            </div>
                            <div
                              class="col-md-2"
                              style="
                                text-align: left;
                                border-top: 1px solid black;
                                border-bottom: 1px solid black;
                              "
                            >
                              <b>{{ LOAN_COMMISSION_DETAILS.TOTAL_AMOUNT }}</b>
                            </div>
                          </div>
                          <br />
                          <br />
                          <div class="row mt-4" style="margin-left: 2em">
                            <div class="col-md-12">
                              <p>
                                THIS IS A COMPUTER GENERATED INVOICE. NO
                                SIGNATURE REQUIRED.
                              </p>
                            </div>
                            <div class="col-md-12">
                              <p>
                                Pls credit the above amount to Lek Auto Pte Ltd
                                OCBC Current account : 671885168001
                              </p>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <!-- Loan Info Section End -->

                <!-- Tax Invoice Section Start -->
                <h5 class="line-heading" ref="tax-invoice">
                  <span>Tax Invoice</span>
                </h5>
                <div class="col-md-12" id="tax-invoice-section">
                  <div>
                    <b-tabs v-model="activeTaxTabIndex">
                      <!--Manage Tax Invoice Tab -->
                      <b-tab title="Manage Tax Invoice" active>
                        <!--Manage Tax Invoice Add Form -->
                        <div class="col-md-12" ref="manage-tax-invoice"></div>
                        <!--Manage Tax Invoice Table View -->
                        <div class="content-body-wrapper mt-4">
                          <div class="page-content">
                            <div class="page-data">
                              <div class="row">
                                <div
                                  class="col-md-12 animate__animated animate__zoomIn"
                                >
                                  <div class="card-wrap shadow-main">
                                    <div class="card-wrap-body">
                                      <div class="data-table master-table">
                                        <b-table
                                          striped
                                          hover
                                          :items="ManageTaxInvoiceDetails"
                                          :fields="
                                            ManageTaxInvoiceDetailsFields
                                          "
                                          class="table table-bordered mt-3"
                                        >
                                          <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                          </template>
                                        </b-table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Tax Invoice Tab-->
                      <b-tab
                        title="Tax Invoice"
                        @click="getInvoiceDetailsWithTax()"
                      >
                        <div class="col-md-12" style="text-align: right">
                          <button
                            v-if="truettax"
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportTax"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>

                        <div class="row mt-4">
                          <div class="col-md-2 mt-2">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="taxinvoicedata.TAX_INVOICE_NO"
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              v-model="taxinvoicedata.TAX_INVOICE_DATE"
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                        </div>

                        <div
                          class="content purchase-settlement-note-cont"
                          v-if="truettax"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px"
                                    >TAX INVOICE</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <!-- header  -->
                            <div class="col-md-8">
                              <div
                                class="content-header-2"
                                style="margin-left: 2em"
                              >
                                <ul>
                                  <li><p>Bill To,</p></li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.InvoiceDetailsWithTax.CUSTOMER_NAME
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.InvoiceDetailsWithTax
                                            .ADDRESS_LINE_1
                                        }}
                                      </span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold"
                                        >{{
                                          this.InvoiceDetailsWithTax
                                            .COUNTRY_NAME
                                        }}
                                        {{
                                          this.InvoiceDetailsWithTax.PIN_CODE
                                        }}</span
                                      >
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-4 mt-2">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Invoice No.</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .INVOICE_NO
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Date</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .TAX_INVOICE_DATE
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>VSA No.</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .VSA_NUMBER
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- after heading sec  -->
                            <div class="col-md-12 mt-2">
                              <div
                                class="content-body-2 p-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-8">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Vehicle Registration No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .VEHICLE_NUMBER
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Vehicle Make</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .MAKE_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Vehicle Model</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .MODEL_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Original Registration Date</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .ORIGINAL_REGISTRATION_DATE
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Engine No./Motor No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithTax
                                                    .ENGINE_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Chassis No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithTax
                                                    .CHASIS_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Sales Person</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .INCHARGE_NAME
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>GST</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold">
                                                :&nbsp;
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .GST_PERCENTAGE
                                                }}
                                                {{
                                                  this.InvoiceDetailsWithTax
                                                    .TAX_TYPE
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12 mt-3">
                              <div
                                class="content-footer"
                                style="border: 1px solid #212121"
                              >
                                <table class="table table-bordered">
                                  <thead
                                    style="border-bottom: 1px solid #212121"
                                  >
                                    <tr></tr>
                                    <tr>
                                      <th scope="col">
                                        <span style="font-weight: bold"
                                          >Vehicle Price</span
                                        >
                                      </th>

                                      <th scope="col" class="border-td">
                                        <span style="font-weight: bold">
                                          GST</span
                                        >
                                      </th>
                                      <th scope="col" class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .TOTAL_VEHICLE_PRICE
                                          }}
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >TOTAL SELLING PRICE</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .SELLING_PRICE_GST_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .SELLING_PRICE_WITH_GST
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >Admin Fee</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .ADMIN_FEE_TAX_VALUE
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .ADMIN_FEE_UNIT_PRICE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >LOAN ADVANCE PAYMENT</span
                                        >
                                      </td>
                                      <td class="border-td"></td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .LOAN_ADVANCE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >INSURANCE PREMIUM</span
                                        >
                                      </td>
                                      <td class="border-td"></td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .INSURANCE_PREMIUM
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >OTHER(ACCESSORIES/SURCHANGE FOR
                                          LOAN)</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .OTHER_FEE_TAX_VALUE
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .OTHER_FEE_UNIT_PRICE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr></tr>
                                    <tr></tr>
                                    <tr></tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >TOTAL PAYABLE AMOUNT</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .TOTAL_GST_PAYABLE
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold">
                                          $&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .TOTAL_PAYABLE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td"></td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Payment Details:</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"> </span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Sales Deposit</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .DEPOSIT_TAX_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .DEPOSIT_AMOUNT_WITHOUT_TAX
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Less: Trade In</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .TRADE_IN_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Less: Trade In Financial</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .TRADE_IN_REIMBURSEMENT
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Less: Payment Previously Made</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .PAYMENT_MADE
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span style="font-weight: bold"
                                          >Less: Excess Payment</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .EXCESS_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >Less: Contra</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .CONTRA_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <span style="font-weight: bold"
                                          >HIRE PURCHASE AMOUNT DUE :
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .FINANCE_COMPANY
                                          }}</span
                                        >
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span style="font-weight: bold"
                                          >$&nbsp;&nbsp;&nbsp;
                                          {{
                                            this.InvoiceDetailsWithTax
                                              .LOAN_AMOUNT
                                          }}</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <p></p>
                                          </div>
                                          <div class="col-md-6 p-0">
                                            <span></span>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td"></td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Finance Compnay:</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-8">
                                            <span
                                              class="ms-1"
                                              style="font-weight: bold"
                                              >:&nbsp;{{
                                                this.InvoiceDetailsWithTax
                                                  .FINANCE_COMPANY
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <p>
                                              <span style="font-weight: bold"
                                                >Loan Amount</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-md-8">
                                            <span
                                              class="ms-1"
                                              style="font-weight: bold"
                                              >:&nbsp; $
                                              {{
                                                this.InvoiceDetailsWithTax
                                                  .LOAN_AMOUNT
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <p>Loan Tenure</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span
                                              style="font-weight: bold"
                                              class="ms-1"
                                              >:&nbsp;
                                              {{
                                                this.InvoiceDetailsWithTax
                                                  .LOAN_TENURE
                                              }}&nbsp;Months</span
                                            >
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <p>Interest Rate</p>
                                          </div>
                                          <div class="col-md-8">
                                            <span
                                              class="ms-1"
                                              style="font-weight: bold"
                                              >:&nbsp;{{
                                                this.InvoiceDetailsWithTax
                                                  .INTEREST_RATE
                                              }}
                                              %</span
                                            >
                                          </div>
                                        </div>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td">
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td class="border-td-2">
                                        <span style="font-weight: bold"
                                          >Amount Due From Customer</span
                                        >
                                      </td>
                                      <td class="border-td border-td-2">
                                        <span class="ms-1"></span>
                                      </td>
                                      <td class="border-td border-td-2">
                                        <span style="font-weight: bold"
                                          >$ &nbsp;{{
                                            this.InvoiceDetailsWithTax
                                              .BALANCE_DUE
                                          }}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- content footer  -->
                            <div class="content-footer">
                              <div class="row">
                                <div class="col-md-12">
                                  <ul>
                                    <li class="">
                                      <p>Memo:</p>
                                      <span
                                        >This is a computer generated
                                        document,no signature is required.</span
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <!--Invoice Tab-->
                      <b-tab
                        title="Invoice"
                        @click="getInvoiceDetailsWithoutTax()"
                      >
                        <div class="col-md-12" style="text-align: right">
                          <button
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportInvoice"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>

                        <div class="row mt-4">
                          <div class="col-md-2 mt-2">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="invoice_obj.INVOICE_NO"
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              v-model="invoice_obj.INVOICE_DATE"
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px">INVOICE</span>
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <!-- header  -->
                            <div class="col-md-8">
                              <div
                                class="content-header-2"
                                style="margin-left: 2em"
                              >
                                <ul>
                                  <li><p>Bill To,</p></li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.InvoiceDetailsWithoutTax
                                          .CUSTOMER_NAME
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">{{
                                        this.InvoiceDetailsWithoutTax
                                          .ADDRESS_LINE_1
                                      }}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span style="font-weight: bold">
                                        {{
                                          this.InvoiceDetailsWithoutTax
                                            .COUNTRY_NAME
                                        }}
                                        {{
                                          this.InvoiceDetailsWithoutTax.PIN_CODE
                                        }}
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5">
                                        <p>Invoice No</p>
                                      </div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;
                                          {{
                                            this.InvoiceDetailsWithoutTax
                                              .INVOICE_NO
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Term</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;COD</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>Date</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithoutTax
                                              .TAX_INVOICE_DATE
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-5"><p>VSA No.</p></div>
                                      <div class="col-md-7">
                                        <span style="font-weight: bold"
                                          >:&nbsp;{{
                                            this.InvoiceDetailsWithoutTax
                                              .VSA_NUMBER
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <!-- after heading sec  -->
                            <div class="col-md-12">
                              <div
                                class="content-body-2 ps-1 pe-1"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div class="col-md-7 pt-1 pb-1">
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Vehicle Registration No.</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .VEHICLE_NUMBER
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Vehicle Make</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .MAKE_NAME
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>VehicleModel</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .MODEL_NAME
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-6">
                                              <p>Original Registration Date</p>
                                            </div>
                                            <div class="col-md-6">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .ORIGINAL_REGISTRATION_DATE
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-5 pt-1 pb-1"
                                    style="
                                      border-left: 1px solid rgba(0, 0, 0, 0.3);
                                    "
                                  >
                                    <div class="content-header-3">
                                      <ul>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Engine No./Motor No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .ENGINE_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Chassis No.</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .CHASIS_NO
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>Sales Person</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;{{
                                                  this.InvoiceDetailsWithoutTax
                                                    .INCHARGE_NAME
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div class="row">
                                            <div class="col-md-5">
                                              <p>GST</p>
                                            </div>
                                            <div class="col-md-7">
                                              <span style="font-weight: bold"
                                                >:&nbsp;Gross Margin</span
                                              >
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12 mt-3">
                              <div
                                class="content-footer"
                                style="border: 1px solid #212121"
                              >
                                <div class="row">
                                  <div
                                    class="col-md-8"
                                    style="border-bottom: 1px solid #212121"
                                  >
                                    <span
                                      style="
                                        font-weight: bold;
                                        margin-left: 5px;
                                      "
                                    >
                                      Description
                                    </span>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                      border-bottom: 1px solid #212121;
                                    "
                                  >
                                    <span style="font-weight: bold"> </span>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="border-bottom: 1px solid #212121"
                                  >
                                    <span style="font-weight: bold">
                                      Amount
                                    </span>
                                  </div>
                                </div>
                                <div
                                  class="row"
                                  style="border-bottom: 1px solid #212121"
                                >
                                  <div class="col-md-8">
                                    <p
                                      class="mt-2 mb-2"
                                      style="
                                        font-weight: bold;
                                        margin-left: 5px;
                                      "
                                    >
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .VEHICLE_NUMBER
                                      }}
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p class="mt-2 mb-2">
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .TOTAL_VEHICLE_PRICE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Fees received on behalf of other agency
                                      and company:
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p></p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Loan Advance Payment
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .LOAN_ADVANCE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">HP charges</p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .HP_FEE_UNIT_PRICE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Insurance Premium
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .INSURANCE_PREMIUM
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">Admin Fee</p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .ADMIN_FEE_UNIT_PRICE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">Other</p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .OTHER_FEE_UNIT_PRICE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p class="mt-2" style="margin-left: 5px">
                                      Total Invoice Amount
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-top: 2px solid #212121;
                                      border-bottom: 2px solid #212121;
                                    "
                                  >
                                    <p class="mt-2">
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .TOTAL_PAYABLE
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="row"
                                  style="border-top: 1px solid #212121"
                                >
                                  <div class="col-md-8">
                                    <p class="mt-2" style="margin-left: 5px">
                                      Payment Details:
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p class="mt-2"></p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p class="mt-3" style="margin-left: 5px">
                                      Sales Deposit
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p class="mt-3">
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .DEPOSIT_AMOUNT
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Down/Full Payment
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>$&nbsp;&nbsp;&nbsp; -</p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">Trade In</p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .TRADE_IN_AMOUNT
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Excess Payment
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>$&nbsp;&nbsp;&nbsp; -</p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p class="mt-4" style="margin-left: 5px">
                                      Finance by &nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .FINANCE_COMPANY
                                      }}&nbsp;&nbsp;&nbsp; Hire Purchase
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p class="mt-4"></p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Loan Amount &nbsp;&nbsp;&nbsp;
                                      :&nbsp;&nbsp;$&nbsp;&nbsp;{{
                                        this.InvoiceDetailsWithoutTax
                                          .LOAN_AMOUNT
                                      }}&nbsp;&nbsp;&nbsp;
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .LOAN_AMOUNT
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Loan Tenure &nbsp;&nbsp;&nbsp;
                                      :&nbsp;&nbsp;{{
                                        this.InvoiceDetailsWithoutTax
                                          .LOAN_TENURE
                                      }}
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p></p>
                                  </div>
                                </div>
                                <div class="row" style="mb-3">
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Interest Rate &nbsp;&nbsp;&nbsp;
                                      :&nbsp;&nbsp;{{
                                        this.InvoiceDetailsWithoutTax
                                          .INTEREST_RATE
                                      }}%
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p></p>
                                  </div>
                                </div>
                                <div
                                  class="row"
                                  style="border-top: 1px solid #212121"
                                >
                                  <div class="col-md-8">
                                    <p style="margin-left: 5px">
                                      Amount Due From Customer
                                    </p>
                                  </div>
                                  <div
                                    class="col-md-2"
                                    style="
                                      border-left: 1px solid #212121;
                                      border-right: 1px solid #212121;
                                    "
                                  >
                                    <p></p>
                                  </div>
                                  <div class="col-md-2">
                                    <p>
                                      $&nbsp;&nbsp;&nbsp;
                                      {{
                                        this.InvoiceDetailsWithoutTax
                                          .BALANCE_DUE
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- content footer  -->
                            <!-- <div class="content-footer">
                              <p class="mt-2">
                                Please cross and make cheque payable to Lek Auto
                                Pte Ltd (Bank Account OCBC 671885168001)
                              </p>
                              <div class="row mt-3">
                                <div class="col-md-12">
                                  <ul>
                                    <li>
                                      <div class="row">
                                        <h6>
                                          THIS IS A COMPUTER GENERATED INVOICE.
                                          NO SIGNATURE REQUIRED
                                        </h6>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="row">
                                    <div class="col-md-12">
                                      <ul>
                                        <li class="">
                                          <p>Memo:</p>
                                          <span
                                            >This is a computer generated
                                            document,no signature is
                                            required.</span
                                          >
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <!-- Tax Invoice Section Start -->

                <!-- Credit Note Section Start -->
                <h5 class="line-heading" ref="credit-note">
                  <span>Credit Note</span>
                </h5>
                <div class="col-md-12" id="credit-note-section">
                  <div>
                    <b-tabs v-model="activeCreditTabIndex">
                      <!--Manage Credit Note Tab-->
                      <b-tab
                        title="Manage Credit Note"
                        active
                        @click="getManageCreditNoteDetails"
                      >
                        <!--Manage Credit Note Add Form-->
                        <div class="row mt-3" ref="manage-credit-note"></div>
                        <!--Manage Credit Note Table View-->
                        <div class="content-body-wrapper mt-4">
                          <div class="row">
                            <div
                              class="col-md-12 animate__animated animate__zoomIn"
                            >
                              <div class="card-wrap shadow-main">
                                <div class="card-wrap-body">
                                  <div class="data-table master-table">
                                    <b-table
                                      striped
                                      hover
                                      :items="
                                        ManageCreditNoteDetailsArray.CREDIT_NOTE_DETAILS
                                      "
                                      :fields="CREDIT_NOTE_DETAILS_FIELDS"
                                      class="table table-bordered mt-3"
                                    >
                                      <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                      </template>
                                    </b-table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- </div>
             </div> -->
                        </div>
                      </b-tab>

                      <!--Credit Note Tab-->
                      <b-tab title="Credit Note" @click="getCreditNoteDetails">
                        <div class="col-md-12" style="text-align: right">
                          <button
                            class="downld animate__animated animate__bounceIn"
                            @click="generateReportcredit"
                          >
                            <i
                              class="fa-solid fa-cloud-arrow-down"
                              style="color: blue"
                            ></i>
                            Download
                          </button>
                        </div>

                        <div class="row mt-4">
                          <div class="col-md-2 mt-2">
                            <label class="frm-label">Invoice No.</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              type="text"
                              v-model="
                                creditnote_doc_obj.CREDIT_NOTE_INVOICE_NO
                              "
                              class="form-control"
                              placeholder="Enter Invoice No."
                            />
                          </div>
                          <div class="col-md-1 mt-2">
                            <label class="frm-label">Date</label>
                          </div>
                          <div class="col-md-3">
                            <input
                              style="height: 3em"
                              v-model="
                                creditnote_doc_obj.CREDIT_NOTE_INVOICE_DATE
                              "
                              type="text"
                              class="form-control"
                              placeholder="Enter Date"
                            />
                          </div>
                        </div>
                        <div
                          class="content purchase-settlement-note-cont"
                          style="margin-top: 10px"
                        >
                          <div class="content-header mt-4">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-md-3"
                                    style="text-align: center"
                                  >
                                    <img
                                      src="../../assets/images/icon.png"
                                      class="img-fluid header-img"
                                      alt="#"
                                    />
                                  </div>
                                  <div class="col-md-9 p-0">
                                    <ul>
                                      <li>
                                        <img
                                          src="../../assets/images/logo-txt.png"
                                        />
                                      </li>
                                      <li class="mt-2">
                                        <p>
                                          1 Sunview Road #01-46 Eco-Tech@Sunview
                                          Singapore 627615
                                        </p>
                                      </li>
                                      <li>
                                        <p>ROC No./ GST Reg No:. 201929524C</p>
                                      </li>
                                      <li>
                                        <p>Tel: 62395191 Fax: 62395190</p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-8"></div>
                              <div
                                class="col-md-4"
                                style="
                                  text-align: center;
                                  border: 1.5px solid #212121;
                                "
                              >
                                <h4 class="fw-bold">
                                  <span style="padding: 4px 10px"
                                    >TAX CREDIT NOTE</span
                                  >
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4 mb-4">
                            <div class="col-md-8">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-4"><p>To</p></div>
                                      <div class="col-md-8">
                                        :<span>
                                          {{
                                            this.DynamicproductFlagArr
                                              .CUSTOMER_NAME
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row mt-1">
                                      <div class="col-md-4"><p>Address</p></div>
                                      <div class="col-md-8">
                                        :

                                        <span class="ms-1">
                                          {{
                                            this.DynamicproductFlagArr
                                              .CUSTOMER_ADDRESS
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row mt-1">
                                      <div class="col-md-4">
                                        <p>Vehicle Registration No.</p>
                                      </div>
                                      <div class="col-md-8">
                                        :<span>
                                          {{
                                            this.DynamicproductFlagArr
                                              .VEHICLE_NUMBER
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row mt-1">
                                      <div class="col-md-4">
                                        <p>Engine No./Motor No.</p>
                                      </div>
                                      <div class="col-md-8">
                                        :<span>
                                          {{
                                            this.DynamicproductFlagArr.ENGINE_NO
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row mt-1">
                                      <div class="col-md-4">
                                        <p>Chassis Number</p>
                                      </div>
                                      <div class="col-md-8">
                                        :<span>
                                          {{
                                            this.DynamicproductFlagArr.CHASIS_NO
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="content-header-2">
                                <ul>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <p>Credit Note No.</p>
                                      </div>
                                      <div class="col-md-6">
                                        <span
                                          >:&nbsp;{{
                                            this.DynamicproductFlagArr
                                              .CREDIT_NOTE_NO
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-6"><p>Date</p></div>

                                      <div class="col-md-6">
                                        <span
                                          >:&nbsp;{{
                                            this.DynamicproductFlagArr
                                              .CREDIT_NOTE_DATE
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <p>Agreement No:</p>
                                      </div>
                                      <div class="col-md-6">
                                        <span
                                          >:
                                          {{
                                            this.DynamicproductFlagArr
                                              .SALES_AGREEMENT_NUMBER
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="content-body-2">
                                <table class="table table-borderless">
                                  <thead
                                    style="
                                      border-top: 2px solid #212121;
                                      border-bottom: 2px solid #212121;
                                    "
                                  >
                                    <tr>
                                      <th scope="col">Description of Goods</th>
                                      <th scope="col">Qty</th>
                                      <th scope="col">Unit Price</th>
                                      <th scope="col">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    v-for="(
                                      user_deta, index
                                    ) in DynamicproductFlagArr.CREDIT_NOTE_DETAILS"
                                    :key="index"
                                  >
                                    <tr>
                                      <td>{{ user_deta.DESCRIPTION }}</td>
                                      <td>{{ user_deta.QTY }}</td>
                                      <td>$ {{ user_deta.UNIT_PRICE }}</td>
                                      <td>$ {{ user_deta.AMOUNT }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div
                                class="content-footer"
                                style="border: 1px solid #212121"
                              >
                                <table class="table table-borderless mb-0">
                                  <tbody>
                                    <tr>
                                      <!-- <th scope="col">Qty</th> -->
                                      <td></td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        Amount SGD$
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        ${{
                                          this.DynamicproductFlagArr
                                            .V_UNIT_TOTAL_AMOUNT
                                        }}
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        Terms of Payment:
                                        {{
                                          this.DynamicproductFlagArr
                                            .PAYMENT_TERMS
                                        }}
                                        <span class="MS-2"> </span>
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        Tax Amount $ <span class="ms-1"> </span>
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        ${{
                                          this.DynamicproductFlagArr
                                            .V_TAX_TOTAL_AMOUNT
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <!-- <th scope="row">1</th> -->
                                      <td>
                                        All cheques issued must be made in the
                                        favour of Lek Auto Pte Ltd
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        <span class="ms-1">Total SGD $ </span>
                                      </td>
                                      <td
                                        style="border-left: 1px solid #212121"
                                      >
                                        $
                                        {{
                                          this.DynamicproductFlagArr
                                            .V_GRAND_TOTAL_AMOUNT
                                        }}
                                        <span class="ms-1"></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <!-- Credit Note Section Start -->

                <!-- Cost Center Details Section Start -->
                <div
                  class="col-md-12 mt-3"
                  id="cost_center_det"
                  ref="cost-center"
                >
                  <h5 class="line-heading"><span>Cost Center Details</span></h5>
                  <div class="col-md-12">
                    <b-tabs>
                      <!-- View Vehicle Cost Tab -->
                      <b-tab title="View Vehicle Cost">
                        <div aria-labelledby="flush-headingOne" class="mt-3">
                          <div class="master-table">
                            <b-table
                              class="table table-bordered"
                              striped
                              hover
                              :items="CostCenterDetailsList"
                              :fields="ViewCostCenterFields"
                            >
                              <template v-slot:cell(Action)="row">
                                <div class="text-nowrap table-btn">
                                  <button
                                    v-if="row.item.REQUIRE_PAYMENT_VOUCHER == 1"
                                    title="Download Payment Voucher"
                                    @click="generatePaymentVoucher(row.item)"
                                    type="button"
                                    class="custom-btn p-0"
                                  >
                                    <i
                                      style="font-size: 1.2em; margin-top: 8px"
                                      class="fas fa-file-download"
                                    ></i>
                                  </button>
                                </div>
                              </template>
                            </b-table>
                          </div>
                        </div>
                      </b-tab>

                      <!-- Floor Stock Interest Tab -->
                      <b-tab
                        title="Floor Stock"
                        @click="
                          calculateNoOfDaysInFoorStock(),
                            getFloorStockInterestDetails()
                        "
                      >
                        <div
                          class="col-md-12 mt-4"
                          style="text-align: center"
                          v-if="
                            floorstock.E_TRANSFER_IN_DATE == '' &&
                            floorstock.E_TRANSFER_OUT_DATE != ''
                          "
                        >
                          <h5 style="color: red">
                            Can't Find E-Transfer in Date From Seller Details.
                          </h5>
                        </div>
                        <div
                          class="col-md-12 mt-4"
                          style="text-align: center"
                          v-if="
                            floorstock.E_TRANSFER_OUT_DATE == '' &&
                            floorstock.E_TRANSFER_IN_DATE != ''
                          "
                        >
                          <h5 style="color: red">
                            Can't Find E-Transfer Out Date From Buyer Details.
                          </h5>
                        </div>
                        <div
                          class="col-md-12 mt-4"
                          style="text-align: center"
                          v-if="
                            floorstock.E_TRANSFER_OUT_DATE == '' &&
                            floorstock.E_TRANSFER_IN_DATE == ''
                          "
                        >
                          <h5 style="color: red">
                            Can't Find E-Transfer In & Out Date From Buyer &
                            Seller Details.
                          </h5>
                        </div>
                        <div
                          class="col-md-12"
                          v-if="
                            floorstock.E_TRANSFER_OUT_DATE != '' &&
                            floorstock.E_TRANSFER_IN_DATE != ''
                          "
                        >
                          <div class="row mt-1">
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Floor stock interest</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    @keyup="calculateFloorStock"
                                    type="text"
                                    v-model="floorstock.FLOOR_STOCK_INTEREST"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3"></div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >E-Transfer in Date</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    v-model="floorstock.E_TRANSFER_IN_DATE"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >E-Transfer out Date
                                  </label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    v-model="floorstock.E_TRANSFER_OUT_DATE"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >No. of Days <span style="color: red"></span
                                  ></label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    disabled
                                    type="number"
                                    @keyup="calculatefloorstock()"
                                    v-model="floorstock.NO_OF_DAYS"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-md-6 mt-3">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label class="frm-label"
                                        >Purchase Price
                                        <span style="color: red"></span
                                      ></label>
                                    </div>
                                    <div class="col-md-8">
                                      <input
                                        type="number"
                                      v-model="floorstock.PURCHASE_PRICE"
                                        class="form-control"
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                </div> -->
                            <div class="col-md-6 mt-3">
                              <div class="row">
                                <div class="col-md-4">
                                  <label class="frm-label"
                                    >Floor Stock amount
                                    <span style="color: red"></span
                                  ></label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="number"
                                    disabled
                                    v-model="this.floorstock.FLOOR_STOCK_AMOUNT"
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="row mt-4 mb-4"></div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="master-table mt-3">
                        <b-table
                          class="table table-bordered"
                          striped
                          hover
                          :items="CostCreatedPurchaseList"
                          :fields="CostCreatedPurchaseListFields"
                        >
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>
                        </b-table>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="master-table mt-3">
                        <b-table
                          class="table table-bordered"
                          striped
                          hover
                          :items="CostCreatedSalesList"
                          :fields="CostCreatedSalesListFields"
                        >
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>

                  <h5 class="mt-3">Vehicle Cost Created</h5>
                  <div class="master-table mt-3">
                    <b-table
                      class="table table-bordered"
                      striped
                      hover
                      :items="CostCenterDetailsList"
                      :fields="CostCenterDetailsListFields"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                    </b-table>
                  </div>
                </div>
                <!-- Cost Center Details Section End -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Preview PDF File -->
      <b-modal id="log_card_preview_modal" size="lg" hide-footer hide-header>
        <div class="container-fluid d-flex">
          <iframe
            id="fred"
            style="border: 1px solid #666ccc"
            title="PDF in an i-Frame"
            :src="preview_pdf_url"
            frameborder="1"
            scrolling="auto"
            width="100%"
            height="550px"
          ></iframe>
        </div>
      </b-modal>
    </b-modal>
    <!-- View Admin Master Modal End Here  -->

    <!-- PDF Design For Purchase Settlement  -->
    <vue-html2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Purchase Settlement"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="PurchaseSettlementHtmlPDF"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7"></div>
              <div class="col-md-5">
                <div class="heading">
                  <p>PURCHASE SETTLEMENT</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 ml-2">
            <div class="col-md-7">
              <p>
                Serial No
                <span style="font-weight: bold"
                  >&nbsp;:&nbsp;{{
                    this.PurchaseNoteDetails.PURCHASE_SETTLEMENT_NO
                  }}</span
                >
              </p>
            </div>
            <div class="col-md-2" style="text-align: right">Date</div>
            <div class="col-md-3" style="text-align: left">
              <p>
                :&nbsp;
                <span style="font-weight: bold">{{
                  this.PurchaseNoteDetails.INVOICE_DATE
                }}</span>
              </p>
            </div>
          </div>
          <div class="row mt-2 ml-2">
            <div class="col-md-7">
              <p>
                I/We("The Seller")
                <span style="font-weight: bold"
                  >&nbsp;:&nbsp;{{
                    this.PurchaseNoteDetails.CUSTOMER_NAME
                  }}</span
                >
              </p>
            </div>
            <div class="col-md-2" tyle="text-align:right">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NRIC/ROC
            </div>
            <div class="col-md-3" style="text-align: left">
              <p>
                :&nbsp;
                <span style="font-weight: bold">{{
                  this.PurchaseNoteDetails.NRIC_ROC
                }}</span>
              </p>
            </div>
          </div>
          <div class="row mt-2 ml-2">
            <div class="col-md-12">
              <p>
                Address
                <span style="font-weight: bold"
                  >&nbsp;:&nbsp;{{ this.PurchaseNoteDetails.ADDRESS }}</span
                >
              </p>
            </div>
          </div>
          <div class="row mt-2 ml-2">
            <div class="col-md-12">
              <p>
                agree to sell the following vehicle to Lek Auto Pte Ltd
                ("Buyer")
              </p>
            </div>
          </div>
          <div class="row mt-2" style="padding: 1em">
            <div class="col-md-10" style="border: 3px solid #212121">
              <p><span style="font-weight: bold">DESCRIPTION</span></p>
            </div>
            <div
              class="col-md-2"
              style="
                border-right: 3px solid #212121;
                border-bottom: 3px solid #212121;
                border-top: 3px solid #212121;
              "
            >
              <p><span style="font-weight: bold">Amount(S$)</span></p>
            </div>
            <div
              class="col-md-10"
              style="
                border-left: 3px solid #212121;
                border-right: 3px solid #212121;
                border-bottom: 3px solid #212121;
              "
            >
              <div class="row mt-2">
                <div class="col-md-3"><p>Vehicle No:</p></div>
                <div class="col-md-3">
                  <p>
                    <span style="font-weight: bold">{{
                      this.PurchaseNoteDetails.VEHICLE_NUMBER
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3"><p>Make & Model:</p></div>
                <div class="col-md-9">
                  <p>
                    <span style="font-weight: bold"
                      >{{ this.PurchaseNoteDetails.MAKE_NAME }}-{{
                        this.PurchaseNoteDetails.MODEL_NAME
                      }}</span
                    >
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3"><p>Chassis No:</p></div>
                <div class="col-md-3">
                  <p>
                    <span style="font-weight: bold">{{
                      this.PurchaseNoteDetails.CHASIS_NO
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3"><p>Engine No./Motor No.:</p></div>
                <div class="col-md-3">
                  <p>
                    <span style="font-weight: bold">{{
                      this.PurchaseNoteDetails.ENGINE_NO
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3"><p>Vehicle Price:</p></div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3"><p>Less:</p></div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <p>
                    Deposit(<span style="font-weight: bold">{{
                      this.PurchaseNoteDetails.CUSTOMER_NAME
                    }}</span
                    >):
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <p>
                    Finance Company (<span style="font-weight: bold"
                      >{{ this.PurchaseNoteDetails.FINANCE_COMPANY }})</span
                    >
                    &nbsp;<span>Full Settlement Amount:</span>
                  </p>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <p>
                    Full Payment
                    <span style="font-weight: bold">{{
                      this.PurchaseNoteDetails.CUSTOMER_NAME
                    }}</span
                    >:
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <p>
                    Balance issued to
                    <span style="font-weight: bold">{{
                      this.PurchaseNoteDetails.BALANCE_ISSUED_TO
                    }}</span
                    >:
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-md-2"
              style="
                border-right: 3px solid #212121;
                border-bottom: 3px solid #212121;
              "
            >
              <div class="row mt-2">
                <div class="col-md-12"><p></p></div>
              </div>
              <div class="row mt-2"><div class="col-md-12"></div></div>
              <div class="row mt-2"><div class="col-md-12"></div></div>
              <div class="row mt-2"><div class="col-md-12"></div></div>
              <div class="row" style="margin-top: 6em !important">
                <div class="col-md-12">
                  <p><span style="font-weight: bold"></span></p>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                  <p>
                    $
                    <span style="font-weight: bold; text-align: right">{{
                      this.PurchaseNoteDetails.VEHICLE_PRICE
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="row" style="margin-top: 2.2em">
                <div class="col-md-12">
                  <p>
                    $
                    <span style="font-weight: bold; text-align: right">{{
                      this.PurchaseNoteDetails.DEPOSIT_AMOUNT
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <p>
                    $
                    <span style="font-weight: bold; text-align: right">{{
                      this.PurchaseNoteDetails.FULL_SETTLEMENT_AMOUNT
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <p>
                    $
                    <span style="font-weight: bold; text-align: right">{{
                      this.PurchaseNoteDetails.FULL_PAYMENT_AMOUNT
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <p>
                    $
                    <span style="font-weight: bold; text-align: right">{{
                      this.PurchaseNoteDetails.BALANCE
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="padding: 1em">
            <div class="col-md-12" style="border: 3px solid #212121">
              <div class="row">
                <div class="col-md-12">
                  <u><b>Vehicle Delivery Acknowledgement</b></u>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <p>
                    Handover on (date)&nbsp;<b style="font-weight: bold">{{
                      this.PurchaseNoteDetails.DELIVERY_IN_DATE
                    }}</b
                    >&nbsp;(time) &nbsp;<b style="font-weight: bold">{{
                      this.PurchaseNoteDetails.DELIVERY_IN_TIME
                    }}</b>
                    .
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <p>
                    *Seller has agreed to handover the mentioned vehicle to
                    Buyer at the above mentioned date & time and free from all
                    encumbrance or whatever.
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <p>
                    *Seller shall be responsible for any traffic offences and
                    illegal acts arising from the use thereof.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-8">
                  <p>Signed for & behalf of</p>
                </div>
                <div class="col-md-4">
                  <p>Agreed & Accepted by Seller</p>
                </div>
                <div class="col-md-12 mt-2">
                  <p>Lek Auto Pte Ltd</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 5em">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <span
                    style="
                      border-top: 2px solid #000;
                      width: 100px;
                      font-weight: bold;
                    "
                  >
                    Authorised Signature
                  </span>
                  <p>
                    Salesman:&nbsp;<b style="font-weight: bold">{{
                      this.PurchaseNoteDetails.INCHARGE_NAME
                    }}</b>
                  </p>
                  <p>
                    HP:&nbsp;<b style="font-weight: bold">{{
                      this.PurchaseNoteDetails.INCHARGE_CONTACT
                    }}</b>
                  </p>
                </div>
                <div class="col-md-6">
                  <span
                    style="
                      border-top: 2px solid #000;
                      width: 100px;
                      font-weight: bold;
                      margin-left: 20px;
                    "
                  >
                    Authorised Signature & Company Stamp
                  </span>
                  <p style="margin-left: 20px">Name:</p>
                  <p style="margin-left: 20px">Contact No.:</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Design For Sales Receipt  -->
    <vue-html2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Sales Receipt(Finance Co)"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdfForSalesReceipt"
    >
      <section slot="pdf-content" class="p-4">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li>
                        <p>ROC No./ GST Reg No:. 201929524C</p>
                      </li>
                      <li>
                        <p>Tel: 62395191 Fax: 62395190</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt2">
                <div class="heading2">
                  <p>SALES RECEIPT</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2" style="font-size: 12px">
            <!-- header  -->
            <div class="col-md-12">
              <ul>
                <li>
                  <div class="row mt-2">
                    <div class="col-md-1">To</div>
                    <div class="col-md-7">
                      <span style="font-weight: bold"
                        >:&nbsp;
                        {{ this.SalesReceiptNoteDetails.FIRST_NAME }}</span
                      >
                    </div>
                    <div class="col-md-1" style="text-align: right">
                      <p>S-No</p>
                    </div>
                    <div class="col-md-3">
                      <span style="font-weight: bold"
                        >:&nbsp;
                        {{ this.SalesReceiptNoteDetails.INVOICE_NO }}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row mt-2">
                    <div class="col-md-1">Address</div>
                    <div class="col-md-7">
                      <span style="font-weight: bold">
                        :&nbsp;{{
                          this.SalesReceiptNoteDetails.ADDRESS_LINE_1
                        }}
                        {{ this.SalesReceiptNoteDetails.COUNTRY_NAME }},
                        {{ this.SalesReceiptNoteDetails.PIN_CODE }}
                      </span>
                    </div>
                    <div class="col-md-1" style="text-align: right">
                      <p>Date</p>
                    </div>
                    <div class="col-md-3">
                      <span style="font-weight: bold"
                        >:&nbsp;
                        {{ this.SalesReceiptNoteDetails.INVOICE_DATE }}</span
                      >
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row mt-2">
                    <div class="col-md-7">
                      Vehicle Registration No<span style="font-weight: bold"
                        >&nbsp;:&nbsp;
                        <span style="font-weight: bold">
                          {{
                            this.SalesReceiptNoteDetails.VEHICLE_NUMBER
                          }}</span
                        ></span
                      >
                    </div>
                    <div class="col-md-2" style="text-align: right">
                      <p>Agreement No.</p>
                    </div>
                    <div class="col-md-3">
                      <span style="font-weight: bold"
                        >:&nbsp;
                        {{
                          this.SalesReceiptNoteDetails.SALES_AGREEMENT_NUMBER
                        }}</span
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <!-- content body  -->
            <div class="col-md-8">
              <div class="content-body-3">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-9">
                        <p>Selling Price.</p>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row" style="">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{ this.SalesReceiptNoteDetails.SELLING_PRICE }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 3px">
                      <div class="col-md-9">
                        <p>Transfer Fee</p>
                      </div>
                      <div class="col-md-3">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{ this.SalesReceiptNoteDetails.TRANSFER_FEE }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9">
                        <p>Road Tax (1/2 yr)</p>
                      </div>
                      <div class="col-md-3">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{ this.SalesReceiptNoteDetails.ROAD_TAX }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9">
                        <p>
                          Insurance Premium (Comp/ TPFT/ TPO)-
                          <span style="font-weight: bold">{{
                            this.SalesReceiptNoteDetails.INSURANCE_COMPANY_NAME
                          }}</span>
                        </p>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{
                                this.SalesReceiptNoteDetails.INSURANCE_PREMIUM
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9">
                        <p>HP Facilities Fees</p>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{
                                this.SalesReceiptNoteDetails.HP_FEE_UNIT_PRICE
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9">
                        <p style="font-weight: bold">Loan Advance Payment</p>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{ this.SalesReceiptNoteDetails.ADVANCE_PAYMENT }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-1"><p>Others</p></div>
                      <div class="col-md-8" style="text-align: left">
                        <u>
                          <span
                            style="
                              font-weight: bold;
                              border-bottom: 2px solid #6e6b7b;
                            "
                          >
                            {{ this.SalesReceiptNoteDetails.OTHER_DESCRIPTION }}
                          </span></u
                        >
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{
                                this.SalesReceiptNoteDetails
                                  .OTHER_FEE_UNIT_PRICE
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9">
                        <p>Admin Fee</p>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{
                                this.SalesReceiptNoteDetails
                                  .ADMIN_FEE_UNIT_PRICE
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9">
                        <p style="font-weight: bold">TOTAL PAYABLE</p>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{ this.SalesReceiptNoteDetails.FINAL_PREMIUM }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9"><p>Less</p></div>
                    </div>
                  </li>
                  
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9">
                        <p>Deposit paid</p>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{
                                this.SalesReceiptNoteDetails
                                  .DEPOSIT_AMOUNT_WITH_TAX
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9">
                        <p>Full Payment</p>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{
                                this.SalesReceiptNoteDetails
                                  .FULL_PAYMENT_AMOUNT
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-3">
                            <p>Trade in Vehicle No.</p>
                          </div>
                          <div class="col-md-3 data">
                            <div class="row">
                              <div class="col-md-12">
                                <span
                                  style="
                                    font-weight: bold;
                                    border-bottom: 2px solid #6e6b7b;
                                  "
                                >
                                  {{
                                    this.SalesReceiptNoteDetails.TRADE_IN_VEH_NO
                                  }}
                                </span>
                              </div>
                            </div>
                            <div class="divider-sales-resceipt"></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-1">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="row">
                              <div class="col-md-7 data">
                                <div class="row">
                                  <div class="col-md-12">
                                    <span
                                      style="
                                        font-weight: bold;
                                        border-bottom: 2px solid #6e6b7b;
                                      "
                                    >
                                      $&nbsp;
                                      {{
                                        this.SalesReceiptNoteDetails
                                          .TRADE_IN_AMOUNT
                                      }}
                                    </span>
                                  </div>
                                </div>
                                <div class="divider-sales-resceipt"></div>
                              </div>
                              <div class="col-md-5 p-0">
                                <p>(Trade in Price)</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5 ps-0">
                            <div class="row">
                              <div class="col-md-2">
                                <p>Less</p>
                                <div class="col-md-3 data">
                                  <u><span style="font-weight: bold"></span></u>
                                </div>
                              </div>
                              <div class="col-md-5 data">
                                <div class="row">
                                  <div class="col-md-12">
                                    <span
                                      style="
                                        font-weight: bold;
                                        border-bottom: 2px solid #6e6b7b;
                                      "
                                    >
                                      $&nbsp;
                                      {{
                                        this.SalesReceiptNoteDetails
                                          .PURCHASE_SETTLEMENT_AMOUNT
                                      }}
                                    </span>
                                  </div>
                                </div>
                                <div class="divider-sales-resceipt"></div>
                              </div>
                              <div class="col-md-5">
                                <p>(Finance Due)</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3 data">
                            <div class="row">
                              <div
                                class="col-md-12"
                                style="border-bottom: 2px solid #6e6b7b"
                              >
                                <span style="font-weight: bold">
                                  $&nbsp;&nbsp;&nbsp;
                                  {{ this.calculation }}
                                </span>
                              </div>
                            </div>
                            <div class="divider-sales-resceipt"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-2">
                        <p>Loan Amount</p>
                      </div>
                      <div class="col-md-7">
                        <div class="row">
                          <div class="col-md-1 data">
                            <div class="row">
                              <div class="col-md-6">
                                <span
                                  style="
                                    font-weight: bold;
                                    border-bottom: 2px solid #6e6b7b;
                                  "
                                >
                                  &nbsp;&nbsp;{{
                                    this.SalesReceiptNoteDetails.INTEREST_RATE
                                  }}
                                </span>
                              </div>
                            </div>
                            <div class="divider-sales-resceipt-2"></div>
                          </div>
                          <div class="col-md-1" style="margin-left: 10px">
                            X
                          </div>
                          <div
                            class="col-md-2 data"
                            style="width: 55px !important"
                          >
                            <div class="row">
                              <div class="col-md-6">
                                <span
                                  style="
                                    font-weight: bold;
                                    border-bottom: 2px solid #6e6b7b;
                                  "
                                >
                                  &nbsp;&nbsp;{{
                                    this.SalesReceiptNoteDetails.LOAN_TENURE
                                  }}
                                </span>
                              </div>
                            </div>
                            <div class="divider-sales-resceipt-2"></div>
                          </div>
                          <div class="col-md-2 p-0" style="margin-left: 10px">
                            <p>Months</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{ this.SalesReceiptNoteDetails.LOAN_AMOUNT }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-3">
                        <p>Finance Co.</p>
                      </div>
                      <div class="col-md-9">
                        <div class="row">
                          <div class="col-md-12" style="text-align: left">
                            <span
                              style="
                                font-weight: bold;
                                border-bottom: 2px solid #6e6b7b;
                              "
                            >
                              &nbsp;&nbsp;{{
                                this.SalesReceiptNoteDetails.FINANCE_COMPANY
                              }}
                            </span>
                          </div>
                        </div>
                        <div
                          style="width: 15em"
                          class="divider-sales-resceipt"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-9">
                        <p style="font-weight: bold">BALANCE DUE</p>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              $&nbsp;&nbsp;&nbsp;
                              {{ this.SalesReceiptNoteDetails.BALANCE_DUE }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row" style="margin-top: 2px">
                      <div class="col-md-3"></div>
                      <div class="col-md-3 data"></div>
                      <div class="col-md-3">
                        <span style="float: right">Cash/ Cheque No. </span>
                      </div>
                      <div class="col-md-3 data">
                        <div class="row">
                          <div
                            class="col-md-12"
                            style="border-bottom: 2px solid #6e6b7b"
                          >
                            <span style="font-weight: bold">
                              &nbsp;&nbsp;{{
                                this.SalesReceiptNoteDetails.CHEQUE_NO
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="divider-sales-resceipt"></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3 mt-3" style="border: 2px solid #212121;height:fit-content">
                              <p class="text-center"><b><u>Mode Of Payment</u></b></p>
                              <p class="mt-1" style="color:red"><b><u>Cheque</u></b></p>
                              <p>All Chq issued must be favour of "Lek Auto Pte Ltd" </p>
                              <p class="mt-1" style="color:red"><b><u>Bank Transfer</u></b></p>
                              <p>UOB Bank: UOB3303198200</p>
                              <p class="mt-1" style="color:red"><b><u>Paynow</u></b></p>
                              <p>201929524C001</p>
                              <p class="mt-1">or Scan QR Code for Payment</p>
                              <img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code">
                            </div>
            <!-- content body 2  -->
            <div class="col-md-12">
              <div
                class="content-body-2 p-1"
                style="border: 1px solid #212121; font-size: 12px !important"
              >
                <p style="color: red; padding-bottom: 10px">
                  <b>Delivery Acknowledgement</b>
                </p>

                I / We confirm that I/we have examined the above said vehicle
                and that the same is good order and proper condition and
                suitable for the purpose for which I/We require it and to my/
                our satisfaction in every respect and that I/We have to take
                delivery of vehicle on &nbsp;(date)&nbsp;<u
                  ><span style="font-weight: bold">{{
                    this.SalesReceiptNoteDetails.DELIVERY_IN_DATE
                  }}</span></u
                >&nbsp;(time)&nbsp;<span>
                  <u
                    ><span style="font-weight: bold">{{
                      this.SalesReceiptNoteDetails.DELIVERY_IN_TIME
                    }}</span></u
                  >
                  . The buyer henceforth on accepting delivery of the vehicle on
                  the time indicated above I/ We will be fully responsible for
                  the vehicle use including any traffic offence and or illegal
                  acts
                </span>
              </div>
            </div>
            <!-- content footer  -->
            <div class="col-md-12">
              <div class="content-footer-2">
                <div class="row">
                  <div class="col-md-6">
                    <p>Signed for & behalf of</p>
                  </div>
                  <div class="col-md-6">
                    <p style="float: right">Agreed & Aceepted by Buyer</p>
                  </div>
                </div>
                <div class="row" style="margin-top: 3.5em">
                  <div class="col-md-7">
                    <ul>
                      <li>
                        <span style="border-top: 2px solid #000"
                          >Authorised Signature</span
                        >
                      </li>
                      <li>
                        <div class="row mt-2">
                          <div class="col-md-1 p-0">
                            <p>&nbsp;&nbsp;Name</p>
                          </div>
                          <div class="col-md-8">
                            :
                            <span style="font-weight: bold">{{
                              this.SalesReceiptNoteDetails.INCHARGE_NAME
                            }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-1 p-0">
                            <p>&nbsp;&nbsp;HP</p>
                          </div>
                          <div class="col-md-8">
                            :
                            <span style="font-weight: bold">{{
                              this.SalesReceiptNoteDetails.INCHARGE_CONTACT
                            }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-5">
                    <ul style="float: right; text-align: center">
                      <li>
                        <span style="border-top: 2px solid #000; width: 100px"
                          >Authorised Signature of Buyer</span
                        >
                      </li>
                      <li><span>Company Stamp</span></li>
                      <li>
                        <div class="row" style="text-align: left">
                          <div class="col-md-4 p-0">
                            <p>&nbsp;&nbsp;Name</p>
                          </div>
                          <div class="col-md-8">
                            <span>: </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row" style="text-align: left">
                          <div class="col-md-4 p-0">
                            <p>&nbsp;&nbsp;NRIC No.</p>
                          </div>
                          <div class="col-md-8">
                            <span>: </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row" style="text-align: left">
                          <div class="col-md-4 p-0">
                            <p>&nbsp;&nbsp;HP</p>
                          </div>
                          <div class="col-md-8">
                            <span>: </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Design For Finance Company Tax Invoice  -->
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Finance Company Tax Invoice"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdfForFinanceCompanyTaxInvoice"
    >
      <section slot="pdf-content" class="p-4">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      style="margin-left: 2em"
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-7 p-0">
                    <ul style="margin-left: 5em">
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2" style="width: 30em">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li>
                        <p>ROC No./ GST Reg No:. 201929524C</p>
                      </li>
                      <li>
                        <p>Tel: 62395191 Fax: 62395190</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 ml-2">
              <div class="col-md-12" style="text-align: end">
                <h4 class="fw-bold">
                  <span
                    style="
                      border: 1px solid rgba(0, 0, 0, 0.3);
                      padding: 4px 10px;
                    "
                    >TAX INVOICE</span
                  >
                </h4>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <!-- header  -->
            <div class="col-md-4">
              <div class="content-header-2" style="margin-left: 2em">
                <ul>
                  <li><p>Bill To,</p></li>
                  <li>
                    <p>
                      <span style="font-weight: bold">{{
                        this.finance_company_tax_invoice.FINANCE_COMPANY
                      }}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span style="font-weight: bold"
                        >{{
                          this.finance_company_tax_invoice
                            .FINANCE_COMPANY_ADDRESS
                        }}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span style="font-weight: bold"
                        >{{
                          this.finance_company_tax_invoice
                            .FINANCE_COMPANY_COUNTRY_NAME
                        }}
                        {{
                          this.finance_company_tax_invoice
                            .FINANCE_COMPANY_PIN_CODE
                        }}</span
                      >
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4">
              <ul>
                <li><p>In Respect Of Hirer :</p></li>
                <li>
                  <p>
                    {{ this.finance_company_tax_invoice.CUSTOMER_NAME }}
                  </p>
                </li>
                <li>
                  <p>
                    {{ this.finance_company_tax_invoice.CUSTOMER_ADDRESS }}
                  </p>
                </li>

                <li>
                  <p>
                    {{ this.finance_company_tax_invoice.CUSTOMER_COUNTRY_NAME }}
                    {{ this.finance_company_tax_invoice.CUSTOMER_PIN_CODE }}
                  </p>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-4">
                        <p>Invoice No</p>
                      </div>
                      <div class="col-md-8">
                        <span style="font-weight: bold"
                          >:&nbsp;{{
                            this.finance_company_tax_invoice.INVOICE_NO
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Term</p></div>
                      <div class="col-md-8">
                        <span style="font-weight: bold">:&nbsp;Immediate</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Date</p></div>
                      <div class="col-md-8">
                        <span style="font-weight: bold"
                          >:&nbsp;
                          {{
                            this.finance_company_tax_invoice.TAX_INVOICE_DATE
                          }}</span
                        >
                        <!-- <span style="font-weight:bold">{{this.pdf_deta.TAX_INVOICE_DATE}} </span> -->
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>VSA No.</p></div>
                      <div class="col-md-8">
                        <span style="font-weight: bold">
                          :&nbsp;{{
                            this.finance_company_tax_invoice.VSA_NUMBER
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- after heading sec  -->
            <div class="col-md-12 mt-2">
              <div
                class="content-body-2 ps-1 pe-1"
                style="border: 1px solid #212121"
              >
                <div class="row">
                  <div class="col-md-7 pt-1 pb-1">
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>Vehicle Registration No.</p>
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: bold"
                                >:
                                {{
                                  this.finance_company_tax_invoice
                                    .VEHICLE_NUMBER
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>Vehicle Make</p>
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: bold"
                                >:
                                {{
                                  this.finance_company_tax_invoice.MAKE_NAME
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>VehicleModel</p>
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: bold"
                                >:
                                {{
                                  this.finance_company_tax_invoice.MODEL_NAME
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>Original Registration Date</p>
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: bold"
                                >:
                                {{
                                  this.finance_company_tax_invoice
                                    .ORIGINAL_REGISTRATION_DATE
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="col-md-5 pt-1 pb-1"
                    style="border-left: 1px solid rgba(0, 0, 0, 0.3)"
                  >
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Engine No./Motor No.</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.finance_company_tax_invoice.ENGINE_NO
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Chassis No.</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.finance_company_tax_invoice.CHASIS_NO
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Sales Person</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:
                                {{
                                  this.finance_company_tax_invoice.SALESMAN
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>GST</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold">
                                :
                                {{
                                  this.finance_company_tax_invoice
                                    .GST_PERCENTAGE
                                }}
                                %
                                {{ this.finance_company_tax_invoice.TAX_TYPE }}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-2">
              <div class="content-footer" style="border: 1px solid #212121">
                <table class="table table-borderless">
                  <thead style="border-bottom: 1px solid #212121">
                    <tr></tr>
                    <tr>
                      <th scope="col">
                        <span style="font-weight: bold">Description</span>
                      </th>
                      <th scope="col">
                        <span style="font-weight: bold"></span>
                      </th>
                      <th
                        scope="col"
                        class="border-td"
                        style="text-align: center"
                      >
                        <span style="font-weight: bold">Amount&nbsp;($)</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td class="border-td-2">
                        <span style="font-weight: bold"> Loan Amount</span>
                      </td>
                      <td class="border-td border-td-2">
                        <span style="font-weight: bold"> </span>
                      </td>
                      <td class="border-td border-td-2">
                        <div class="row">
                          <div class="col-md-4">
                            <span class="fw-bold">$</span>
                          </div>
                          <div class="col-md-4">
                            <span class="fw-bold">{{
                              this.finance_company_tax_invoice.LOAN_AMOUNT
                            }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-4">
                            <p>
                              <span style="font-weight: bold"
                                >Vehicle Invoice Price</span
                              >
                            </p>
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-3">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <div class="col-md-9">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                      >{{
                                        this.finance_company_tax_invoice
                                          .SELLING_PRICE
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6"></div>
                            </div>
                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                          </div>
                        </div>
                      </td>
                      <!-- <td class="border-td"><span class="ms-1">$ 77,500.0 </span></td> -->
                      <td class="border-td">
                        <span class="ms-1"></span>
                      </td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-4">
                            <p>
                              <span style="font-weight: bold"
                                >Deposit Paid</span
                              >
                            </p>
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-3">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <div class="col-md-9">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                      >{{
                                        this.finance_company_tax_invoice
                                          .DEPOSIT_AMOUNT_WITH_TAX
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6"></div>
                            </div>
                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                          </div>
                        </div>
                      </td>
                      <td class="border-td">
                        <span style="font-weight: bold"></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="row">
                          <div class="col-md-4">
                            <p>
                              <span style="font-weight: bold">Loan Amount</span>
                            </p>
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-3">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <div class="col-md-9">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                      >{{
                                        this.finance_company_tax_invoice
                                          .LOAN_AMOUNT
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6"></div>
                            </div>
                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                          </div>
                        </div>
                      </td>
                      <td class="border-td"></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <span style="font-weight: bold">GST Details</span>
                      </td>
                      <td class="border-td"></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-3">
                            <p>
                              <span style="font-weight: bold"></span>
                            </p>
                          </div>
                          <div class="col-md-9">
                            <div class="row" style="text-align: center">
                              <div class="col-md-4">
                                <span class="fw-bold">Taxable supplies</span>
                              </div>
                              <div class="col-md-7">
                                <span class="fw-bold">
                                  {{
                                    this.finance_company_tax_invoice.TAX_TYPE
                                  }}
                                  {{
                                    this.finance_company_tax_invoice
                                      .GST_PERCENTAGE
                                  }}
                                  %
                                </span>
                              </div>
                            </div>
                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                          </div>
                        </div>
                      </td>
                      <td class="border-td"></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-3">
                            <p>
                              <span style="font-weight: bold">Customer</span>
                            </p>
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-3">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <div class="col-md-9">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                      >{{
                                        this.finance_company_tax_invoice
                                          .DEPOSIT_AMOUNT_WITHOUT_TAX
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-3">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <div class="col-md-9">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                      >{{
                                        this.finance_company_tax_invoice
                                          .DEPOSIT_TAX_AMOUNT
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                          </div>
                        </div>
                      </td>
                      <td class="border-td"></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-3">
                            <p>
                              <span style="font-weight: bold">Finance co.</span>
                            </p>
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-3">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <div class="col-md-9">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                      >{{
                                        this.finance_company_tax_invoice
                                          .LOAN_AMOUNT_WITHOUT_TAX
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-3">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <div class="col-md-9">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                      >{{
                                        this.finance_company_tax_invoice
                                          .LOAN_TAX_AMOUNT
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                          </div>
                        </div>
                      </td>
                      <td class="border-td"></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-3">
                            <p>
                              <span style="font-weight: bold"
                                >Total GST Incl.</span
                              >
                            </p>
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-3">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <div class="col-md-9">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                      >{{
                                        this.finance_company_tax_invoice
                                          .TOTAL_FINANCE_WITHOUT_GST_AMOUNT
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-3">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <div class="col-md-9">
                                    <span
                                      class="ms-1"
                                      style="font-weight: bold"
                                      >{{
                                        this.finance_company_tax_invoice
                                          .TOTAL_FINANCE_GST
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                          </div>
                        </div>
                      </td>
                      <td class="border-td"></td>
                    </tr>
                    <tr style="border-top: 1px solid #212529">
                      <!-- <th scope="row">1</th> -->
                      <td class="border-td-2">
                        <span style="font-weight: bold"
                          >Total Invoice Amount</span
                        >
                      </td>
                      <td class="border-td border-td-2">
                        <span class="ms-1"></span>
                      </td>
                      <td class="border-td border-td-2">
                        <div class="row">
                          <div class="col-md-4">
                            <span class="fw-bold">$</span>
                          </div>
                          <div class="col-md-4">
                            <span class="fw-bold">{{
                              this.finance_company_tax_invoice.LOAN_AMOUNT
                            }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- content footer  -->
                            <div class="content-footer">
                              <div class="row">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Design For Finance Company Invoice  -->
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Finance Company Invoice"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdfForFinanceCompanyInvoice"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content purchase-settlement-note-cont">
          <div class="content-header">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3" style="text-align: center">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8"></div>
              <div
                class="col-md-4"
                style="text-align: center; border: 1.5px solid #212121"
              >
                <h4 class="fw-bold">
                  <span style="padding: 4px 10px">INVOICE</span>
                </h4>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <!-- header  -->
            <div class="col-md-6">
              <div class="content-header-2">
                <ul>
                  <li><p>Bill To,</p></li>
                  <li>
                    <p>
                      <span style="font-weight: bold">{{
                        this.finance_company_invoice.FINANCE_COMPANY
                      }}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span style="font-weight: bold"
                        >{{ this.finance_company_invoice.ADDRESS_LINE_1 }}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span style="font-weight: bold"
                        >{{
                          this.finance_company_invoice.CUSTOMER_COUNTRY_NAME
                        }}
                        {{
                          this.finance_company_invoice.FINANCE_COMPANY_PIN_CODE
                        }}</span
                      >
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-6">
                        <p>Invoice No</p>
                      </div>
                      <div class="col-md-6">
                        <span style="font-weight: bold"
                          >:&nbsp;{{
                            this.finance_company_invoice.INVOICE_NO
                          }}</span
                        >
                      </div>
                    </div>
                  </li>

                  <li>
                    <div class="row">
                      <div class="col-md-6"><p>Date</p></div>
                      <div class="col-md-6">
                        <span style="font-weight: bold"
                          >:&nbsp;
                          {{
                            this.finance_company_invoice.TAX_INVOICE_DATE
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-6"><p>GST Reg No.</p></div>
                      <div class="col-md-6">
                        <span style="font-weight: bold">:&nbsp;201929524C</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-6"><p>Co Reg No.</p></div>
                      <div class="col-md-6">
                        <span style="font-weight: bold">
                          :&nbsp;201929524C</span
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- after heading sec  -->
            <div class="col-md-12 mt-3">
              <div class="row">
                <div class="col-md-3">For Account</div>
                <div class="col-md-9">
                  <span style="font-weight: bold"
                    >:&nbsp;{{ this.finance_company_invoice.CUSTOMER_NAME }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">Description</div>
                <div class="col-md-9">
                  <span style="font-weight: bold"
                    >:&nbsp;1 unit of&nbsp;&nbsp;
                    {{ this.finance_company_invoice.MAKE_NAME }}&nbsp;&nbsp;
                    {{ this.finance_company_invoice.MODEL_NAME }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">Chassis No</div>
                <div class="col-md-9">
                  <span style="font-weight: bold">
                    :&nbsp;{{ this.finance_company_invoice.CHASIS_NO }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">Engine No./Motor No.</div>
                <div class="col-md-9">
                  <span style="font-weight: bold">
                    :&nbsp;{{ this.finance_company_invoice.ENGINE_NO }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">Registration No</div>
                <div class="col-md-9">
                  <span style="font-weight: bold">
                    :&nbsp;{{ this.finance_company_invoice.VEHICLE_NUMBER }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">Original Registraion Date</div>
                <div class="col-md-8">
                  <span style="font-weight: bold">
                    :&nbsp;{{
                      this.finance_company_invoice.ORIGINAL_REGISTRATION_DATE
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <div
                class="content-body-2 ps-1 pe-1"
                style="border: 1px solid #212121"
              >
                <div class="row">
                  <div class="col-md-12 pt-1 pb-1">
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row" style="margin-top: 5px">
                            <div class="col-md-8">
                              <p>Selling Price</p>
                            </div>
                            <div class="col-md-1" style="text-align: right">
                              <span style="font-weight: bold"> $ </span>
                            </div>
                            <div class="col-md-3">
                              <span style="font-weight: bold">
                                {{ this.finance_company_invoice.SELLING_PRICE }}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row" style="margin-top: 5px">
                            <div class="col-md-8">
                              <p>Deposit Paid</p>
                            </div>
                            <div class="col-md-1" style="text-align: right">
                              <span style="font-weight: bold"> $ </span>
                            </div>
                            <div class="col-md-3">
                              <span style="font-weight: bold">
                                {{
                                  this.finance_company_invoice
                                    .DEPOSIT_AMOUNT_WITHOUT_TAX
                                }}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row" style="margin-top: 5px">
                            <div class="col-md-8">
                              <p>Loan Amount</p>
                            </div>
                            <div class="col-md-1" style="text-align: right">
                              <span style="font-weight: bold"> $ </span>
                            </div>
                            <div class="col-md-3">
                              <span style="font-weight: bold">
                                {{
                                  this.finance_company_invoice
                                    .LOAN_AMOUNT_WITHOUT_TAX
                                }}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row mt-4">
                            <div class="col-md-8">
                              <p><u>Total payable(Finance amount)</u></p>
                            </div>
                            <div class="col-md-1" style="text-align: right">
                              <span style="font-weight: bold"> $ </span>
                            </div>
                            <div class="col-md-3">
                              <span style="font-weight: bold">
                                {{
                                  this.finance_company_invoice
                                    .TOTAL_FINANCE_WITHOUT_GST_AMOUNT
                                }}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <div
                class="content-body-2 ps-1 pe-1"
                style="border: 1px solid #212121"
              >
                <div class="row">
                  <div class="col-md-12 pt-1 pb-1">
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row" style="margin-top: 5px">
                            <div class="col-md-12">
                              <p>
                                I declear that the above particulars in the
                                invoice are true and correct
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row" style="margin-top: 5px">
                            <div class="col-md-12">
                              <p>
                                NO GST IS DUE FROM FINANCE COMPANY AS TAXABLE
                                VAULE IS ZERO.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row" style="margin-top: 5px">
                            <div class="col-md-12">
                              <p>
                                THE FULL GST AMOUNT HAS BEEN PAID FOR BY THE
                                HIRER.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12" style="margin-top: 8em">
              <div class="row">
                <div class="col-md-4" style="border-top: 1px solid black">
                  <p>Authorised Signature</p>
                </div>
              </div>
            </div>
            <div class="content-footer mt-3">
                              <div class="row">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Design For Official Receipt  -->
    <vue-html2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Official Receipt"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdfForOfficialReceipt"
    >
      <section slot="pdf-content" class="p-5">
        <div class="">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3" style="text-align: center">
                  <img
                    src="../../assets/images/icon.png"
                    class="img-fluid header-img"
                    alt="#"
                  />
                </div>
                <div class="col-md-9 p-0">
                  <ul>
                    <li>
                      <img src="../../assets/images/logo-txt.png" />
                    </li>
                    <li class="mt-2">
                      <p>
                        1 Sunview Road #01-46 Eco-Tech@Sunview Singapore 627615
                      </p>
                    </li>
                    <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                    <li><p>Tel: 62395191 Fax: 62395190</p></li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7"></div>
                <div
                  class="col-md-5"
                  style="text-align: center; border: 1.5px solid #212121"
                >
                  <h4 class="fw-bold">
                    <span style="padding: 4px 10px">OFFICIAL RECEIPT</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row mt-3">
            <div class="col-md-1"><p>Date</p></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp; {{ this.OFFICIAL_RECEIPT_PDF_OBJ.RECEIPT_DATE }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-1"><p>To</p></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp;{{ this.OFFICIAL_RECEIPT_PDF_OBJ.CUSTOMER_NAME }} <br />
            </div>
          </div>
          <div class="mt-4 text-center">
            <h5
              style="
                font-family: 'Times New Roman', Times, serif;
                font-weight: 600;
              "
            >
              TO WHOM IT MAY CONCERN
            </h5>
          </div>
          <p class="mt-3">
            We hereby confirm that we have sold & received Full Payment
            <b>${{ this.OFFICIAL_RECEIPT_PDF_OBJ.TOTAL_AMOUNT }}</b> from
            <b>{{ this.OFFICIAL_RECEIPT_PDF_OBJ.CUSTOMER_NAME }}</b> for the
            following vehicle.
          </p>
          <div class="row mt-4">
            <div class="col-md-3">
              <p>Vehicle Registration No</p>
            </div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp;{{ this.OFFICIAL_RECEIPT_PDF_OBJ.VEHICLE_NUMBER }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><p>Make & Model</p></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp; {{ this.OFFICIAL_RECEIPT_PDF_OBJ.MAKE_NAME }}
              {{ this.OFFICIAL_RECEIPT_PDF_OBJ.MODEL_NAME }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><p>Engine No./Motor No.</p></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp;{{ this.OFFICIAL_RECEIPT_PDF_OBJ.ENGINE_NO }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><p>Chassis No</p></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp;{{ this.OFFICIAL_RECEIPT_PDF_OBJ.CHASIS_NO }}
            </div>
          </div>
          <div class="content-footer mt-3">
              <div class="row">
                <div class="col-md-12">
                  <span>*&nbsp;This is a computer generated receipt, there is no signature required.</span>
                </div>
                                                
              </div>
                              
          </div>
          <!-- <br />
                            <br />
                            <br />
                            <br />
                            <div class="row mt-5 mb-3">
                              <div
                                style="border-bottom: 1px solid #212121"
                                class="col-md-3"
                              ></div>
                              <p>Director: Pauson Lek</p>
                            </div> -->
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Design For Tax Commission Invoice -->
    <vue-html2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Tax Commission Invoice"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdfForTaxCommissionInvoice"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content purchase-settlement-note-cont">
          <div class="content-header">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3" style="text-align: center">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          2 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5"></div>
              <div
                class="col-md-7"
                style="text-align: center; border: 1.5px solid #212121"
              >
                <h4 class="fw-bold" style="padding: 5px">
                  <span style="padding: 5px 10px">TAX COMMISSION INVOICE</span>
                </h4>
              </div>
            </div>
          </div>
          <hr />
          <div class="row mt-2">
            <div class="col-md-3"><b>Invoice No</b></div>
            <div class="col-md-9">
              <p style="font-weight: bold">
                :&nbsp;{{ LOAN_COMMISSION_DETAILS.INVOICE_NO }}
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><b>Date</b></div>
            <div class="col-md-9">
              <p style="font-weight: bold">
                :&nbsp;{{ LOAN_COMMISSION_DETAILS.INVOICE_DATE }}
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3">
              <b>Bill To</b>
            </div>
            <div class="col-md-9">
              <p style="font-weight: bold">
                : &nbsp;{{ LOAN_COMMISSION_DETAILS.FINANCE_COMPANY }}<br />
                &nbsp;&nbsp;&nbsp;{{ LOAN_COMMISSION_DETAILS.ADDRESS }}<br />
                &nbsp;&nbsp;&nbsp;{{ LOAN_COMMISSION_DETAILS.COUNTRY }}
                {{ LOAN_COMMISSION_DETAILS.PIN_CODE }}
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><b>For the account of</b></div>
            <div class="col-md-9" style="font-weight: bold">
              :&nbsp;{{ LOAN_COMMISSION_DETAILS.CUSTOMER_NAME }}
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-8">
              <u
                ><b
                  ><p style="border-bottom: 1px solid black">
                    Description of Item
                  </p></b
                ></u
              >
            </div>
            <div align="center" class="col-md-4">
              <u
                ><b><p style="border-bottom: 1px solid black">Amount</p></b></u
              >
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><b>Being Commission for Hirer</b></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp;{{ LOAN_COMMISSION_DETAILS.CUSTOMER_NAME }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><b>Vehicle Registration No</b></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp;{{ LOAN_COMMISSION_DETAILS.VEHICLE_NUMBER }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><b>Make & Model</b></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp;{{ LOAN_COMMISSION_DETAILS.MAKE_NAME }}&nbsp;{{
                LOAN_COMMISSION_DETAILS.MODEL_NAME
              }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><b>Engine No./Motor No.</b></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp;{{ LOAN_COMMISSION_DETAILS.ENGINE_NO }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><b>Chassis No.</b></div>
            <div class="col-md-6" style="font-weight: bold">
              :&nbsp;{{ LOAN_COMMISSION_DETAILS.CHASIS_NO }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3"><b>Commission Rate</b></div>
            <div class="col-md-6" style="font-weight: bold">
              : $&nbsp;{{
                LOAN_COMMISSION_DETAILS.LOAN_AMOUNT_WITHOUT_TAX
              }}&nbsp;&nbsp; x &nbsp;&nbsp;
              {{ LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_PERCENTAGE }} %
            </div>
            <div class="col-md-1" style="text-align: right"><b>$</b></div>
            <div class="col-md-2" style="text-align: left">
              <b>{{ LOAN_COMMISSION_DETAILS.LOAN_COMMISSION }}</b>
            </div>
          </div>
          <div class="row mt-2">
                            <div class="col-md-3">
                              <b>Commission Tax Amount</b>
                            </div>
                            <div class="col-md-6" style="font-weight: bold">
                              : &nbsp;{{LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_TAX_NAME}}
                                &nbsp;-
                                &nbsp;{{ LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_TAX_PERCENTAGE}}%
                            </div>
                            <div class="col-md-1" style="text-align: right">
                              <b>$</b>
                            </div>
                            <div class="col-md-2" style="text-align: left">
                              <b>{{ LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_TAX_AMOUNT }}</b>
                            </div>
                          </div>
          <div class="row mt-4">
            <div class="col-md-9">
              <b>Nett Amount Payable (Inclusive of GST)</b>
            </div>
            <div
              class="col-md-1"
              style="
                text-align: right;
                border-top: 1px solid black;
                border-bottom: 1px solid black;
              "
            >
              <b>$</b>
            </div>
            <div
              class="col-md-2"
              style="
                text-align: left;
                border-top: 1px solid black;
                border-bottom: 1px solid black;
              "
            >
              <b>{{ LOAN_COMMISSION_DETAILS.LOAN_COMMISSION_AMOUNT_WITH_TAX }}</b>
            </div>
          </div>
          <div class="content-footer mt-3">
                              <div class="row">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Design For Tax Invoice -->
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Tax Invoice"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdf3"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li>
                        <p>ROC No./ GST Reg No:. 201929524C</p>
                      </li>
                      <li>
                        <p>Tel: 62395191 Fax: 62395190</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="heading2">
                  <p>TAX INVOICE</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <!-- header  -->
            <div class="col-md-7">
              <div class="content-header-2">
                <ul>
                  <li><p>Bill To,</p></li>
                  <li>
                    <p>
                      <span style="font-weight: bold">{{
                        this.InvoiceDetailsWithTax.CUSTOMER_NAME
                      }}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span style="font-weight: bold"
                        >{{ this.InvoiceDetailsWithTax.ADDRESS_LINE_1 }}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span style="font-weight: bold"
                        >{{ this.InvoiceDetailsWithTax.COUNTRY_NAME }}
                        {{ this.InvoiceDetailsWithTax.PIN_CODE }}</span
                      >
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-5 mt-2">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-5">
                        <p>Invoice No.</p>
                      </div>
                      <div class="col-md-7">
                        <span style="font-weight: bold"
                          >:&nbsp;{{
                            this.InvoiceDetailsWithTax.INVOICE_NO
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-5">
                        <p>Date</p>
                      </div>
                      <div class="col-md-7">
                        <span style="font-weight: bold"
                          >:&nbsp;{{
                            this.InvoiceDetailsWithTax.TAX_INVOICE_DATE
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-5">
                        <p>VSA No.</p>
                      </div>
                      <div class="col-md-7">
                        <span style="font-weight: bold"
                          >:&nbsp;{{
                            this.InvoiceDetailsWithTax.VSA_NUMBER
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- after heading sec  -->
            <div class="col-md-12 mt-2" style="border: 1px solid #212121">
              <div class="content-body-2">
                <div class="row">
                  <div class="col-md-7">
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Vehicle Registration No.</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;
                                {{
                                  this.InvoiceDetailsWithTax.VEHICLE_NUMBER
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Vehicle Make</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;
                                {{ this.InvoiceDetailsWithTax.MAKE_NAME }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Vehicle Model</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;
                                {{
                                  this.InvoiceDetailsWithTax.MODEL_NAME
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Original Registration Date</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;
                                {{
                                  this.InvoiceDetailsWithTax
                                    .ORIGINAL_REGISTRATION_DATE
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Engine No./Motor No.</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.InvoiceDetailsWithTax.ENGINE_NO
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Chassis No.</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.InvoiceDetailsWithTax.CHASIS_NO
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Sales Person</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;
                                {{
                                  this.InvoiceDetailsWithTax.INCHARGE_NAME
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>GST</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold">
                                :&nbsp;
                                {{ this.InvoiceDetailsWithTax.GST_PERCENTAGE }}
                                %
                                {{ this.InvoiceDetailsWithTax.TAX_TYPE }}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3" style="border: 1px solid #212121">
              <div class="content-footer">
                <div class="row">
                  <div
                    class="col-md-8"
                    style="border-bottom: 1px solid #212121"
                  >
                    <span style="font-weight: bold"> Vehicle Price </span>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                      border-bottom: 1px solid #212121;
                    "
                  >
                    <span style="font-weight: bold"> GST </span>
                  </div>
                  <div
                    class="col-md-2"
                    style="border-bottom: 1px solid #212121"
                  >
                    <span style="font-weight: bold">
                      $&nbsp;&nbsp;&nbsp;{{
                        this.InvoiceDetailsWithTax.TOTAL_VEHICLE_PRICE
                      }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>TOTAL SELLING PRICE</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.SELLING_PRICE_GST_AMOUNT }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.SELLING_PRICE_WITH_GST }}
                    </p>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-md-8">
                    <p>Admin Fee</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.ADMIN_FEE_TAX_VALUE }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.ADMIN_FEE_UNIT_PRICE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>HP Facilities Fee</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.HP_FACILITIES_FEE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>OTHER (ACCESSORIES/SURCHANGE FOR LOAN)</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.OTHER_FEE_TAX_VALUE }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.OTHER_FEE_UNIT_PRICE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>LOAN ADVANCE PAYMENT</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <span style="font-weight: bold"> </span>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.LOAN_ADVANCE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>INSURANCE PREMIUM</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.INSURANCE_PREMIUM }}
                    </p>
                  </div>
                </div>
                
                
                <div class="row">
                  <div class="col-md-8">
                    <p>Total PAYABLE AMOUNT</p>
                  </div>
                  <div class="col-md-2" style="border: 1px solid #212121">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.TOTAL_GST_PAYABLE }}
                    </p>
                  </div>
                  <div class="col-md-2" style="border: 1px solid #212121">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.TOTAL_PAYABLE }}
                    </p>
                  </div>
                </div>
                <div class="row mt-2" style="border-top: 1px solid #212121">
                  <div class="col-md-8">
                    <p>Payment Details:</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>Sales Deposit</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.DEPOSIT_TAX_AMOUNT }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{
                        this.InvoiceDetailsWithTax.DEPOSIT_AMOUNT_WITHOUT_TAX
                      }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>Less: Trade In</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.TRADE_IN_AMOUNT }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>Less: Trade In Financial</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.TRADE_IN_REIMBURSEMENT }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>Less: Payment Previously Made</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.PAYMENT_MADE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>Less: Excess Payment</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.EXCESS_AMOUNT }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p>Less: Contra</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.CONTRA_AMOUNT }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <span style="margin-top: 15px; font-weight: 550"
                      >HIRE PURCHASE AMOUNT DUE</span
                    >
                    &nbsp;
                    <span style="margin-top: 15px">{{
                      this.InvoiceDetailsWithTax.FINANCE_COMPANY
                    }}</span>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p style="margin-top: 15px"></p>
                  </div>
                  <div class="col-md-2">
                    <span style="margin-top: 15px">
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.LOAN_AMOUNT }}
                    </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <span style="margin-top: 15px">Finance Company</span>
                  </div>
                  <div class="col-md-5">
                    <span style="margin-top: 15px">
                      :&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.FINANCE_COMPANY }}
                    </span>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p style="margin-top: 15px"></p>
                  </div>
                  <div class="col-md-2">
                    <p style="margin-top: 15px"></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <span style="margin-top: 15px">Loan Amount</span>
                  </div>
                  <div class="col-md-5">
                    <span style="margin-top: 15px">
                      :&nbsp;&nbsp;$ &nbsp;{{
                        this.InvoiceDetailsWithTax.LOAN_AMOUNT
                      }}
                    </span>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p style="margin-top: 15px"></p>
                  </div>
                  <div class="col-md-2">
                    <p style="margin-top: 15px"></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <span style="margin-top: 15px">Loan Tenure</span>
                  </div>
                  <div class="col-md-5">
                    <span style="margin-top: 15px">
                      :&nbsp;&nbsp; {{ this.InvoiceDetailsWithTax.LOAN_TENURE }}
                    </span>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p style="margin-top: 15px"></p>
                  </div>
                  <div class="col-md-2">
                    <p style="margin-top: 15px"></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <span style="margin-top: 15px">Interest Rate</span>
                  </div>
                  <div class="col-md-5">
                    <span style="margin-top: 15px">
                      :&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.INTEREST_RATE }}%
                    </span>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p style="margin-top: 15px"></p>
                  </div>
                  <div class="col-md-2">
                    <p style="margin-top: 15px"></p>
                  </div>
                </div>
                <div
                  class="row"
                  style="
                    border-top: 1px solid #212121;
                    border-bottom: 1px solid #212121;
                  "
                >
                  <div class="col-md-8">
                    <span style="margin-top: 15px"
                      >Amount Due From Customer</span
                    >
                  </div>

                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <span style="margin-top: 15px"></span>
                  </div>
                  <div class="col-md-2">
                    <span style="margin-top: 15px">
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithTax.BALANCE_DUE }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- content footer  -->
            <div class="content-footer mt-3">
                              <div class="row">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Design For Invoice -->
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Invoice"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdfForInvoice"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li>
                        <p>ROC No./ GST Reg No:. 201929524C</p>
                      </li>
                      <li>
                        <p>Tel: 62395191 Fax: 62395190</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="heading2">
                  <p>INVOICE</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <!-- header  -->
            <div class="col-md-8">
              <div class="content-header-2" style="margin-left: 2em">
                <ul>
                  <li><p>Bill To,</p></li>
                  <li>
                    <p>
                      <span style="font-weight: bold">{{
                        this.InvoiceDetailsWithoutTax.CUSTOMER_NAME
                      }}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span style="font-weight: bold">{{
                        this.InvoiceDetailsWithoutTax.ADDRESS_LINE_1
                      }}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span style="font-weight: bold">
                        {{ this.InvoiceDetailsWithoutTax.COUNTRY_NAME }}
                        {{ this.InvoiceDetailsWithoutTax.PIN_CODE }}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-5">
                        <p>Invoice No</p>
                      </div>
                      <div class="col-md-7">
                        <span style="font-weight: bold"
                          >:&nbsp;
                          {{ this.InvoiceDetailsWithoutTax.INVOICE_NO }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>Term</p></div>
                      <div class="col-md-7">
                        <span style="font-weight: bold">:&nbsp;COD</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>Date</p></div>
                      <div class="col-md-7">
                        <span style="font-weight: bold"
                          >:&nbsp;{{
                            this.InvoiceDetailsWithoutTax.TAX_INVOICE_DATE
                          }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>VSA No.</p></div>
                      <div class="col-md-7">
                        <span style="font-weight: bold"
                          >:&nbsp;{{ this.InvoiceDetailsWithoutTax.VSA_NUMBER }}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- after heading sec  -->
            <div class="col-md-12">
              <div
                class="content-body-2 ps-1 pe-1"
                style="border: 1px solid #212121"
              >
                <div class="row">
                  <div class="col-md-7 pt-1 pb-1">
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>Vehicle Registration No.</p>
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.InvoiceDetailsWithoutTax.VEHICLE_NUMBER
                                }}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>Vehicle Make</p>
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.InvoiceDetailsWithoutTax.MAKE_NAME
                                }}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>VehicleModel</p>
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: bold"
                                >:{{ this.InvoiceDetailsWithoutTax.MODEL_NAME }}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>Original Registration Date</p>
                            </div>
                            <div class="col-md-6">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.InvoiceDetailsWithoutTax
                                    .ORIGINAL_REGISTRATION_DATE
                                }}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="col-md-5 pt-1 pb-1"
                    style="border-left: 1px solid rgba(0, 0, 0, 0.3)"
                  >
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Engine No./Motor No.</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.InvoiceDetailsWithoutTax.ENGINE_NO
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Chassis No.</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.InvoiceDetailsWithoutTax.CHASIS_NO
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Sales Person</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;{{
                                  this.InvoiceDetailsWithoutTax.INCHARGE_NAME
                                }}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>GST</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:&nbsp;Gross Margin</span
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              <div class="content-footer" style="border: 1px solid #212121">
                <div class="row">
                  <div
                    class="col-md-8"
                    style="border-bottom: 1px solid #212121"
                  >
                    <span style="font-weight: bold; margin-left: 5px">
                      Description
                    </span>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                      border-bottom: 1px solid #212121;
                    "
                  >
                    <span style="font-weight: bold"> </span>
                  </div>
                  <div
                    class="col-md-2"
                    style="border-bottom: 1px solid #212121"
                  >
                    <span style="font-weight: bold"> Amount </span>
                  </div>
                </div>
                <div class="row" style="border-bottom: 1px solid #212121">
                  <div class="col-md-8">
                    <p
                      class="mt-2 mb-2"
                      style="font-weight: bold; margin-left: 5px"
                    >
                      {{ this.InvoiceDetailsWithoutTax.VEHICLE_NUMBER }}
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p class="mt-2 mb-2">
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.TOTAL_VEHICLE_PRICE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">
                      Fees received on behalf of other agency and company:
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">Loan Advance Payment</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.LOAN_ADVANCE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">HP charges</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.HP_FEE_UNIT_PRICE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">Insurance Premium</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.INSURANCE_PREMIUM }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">Admin Fee</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.ADMIN_FEE_UNIT_PRICE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">Other</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.OTHER_FEE_UNIT_PRICE }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p class="mt-2" style="margin-left: 5px">
                      Total Invoice Amount
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-top: 2px solid #212121;
                      border-bottom: 2px solid #212121;
                    "
                  >
                    <p class="mt-2">
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.TOTAL_PAYABLE }}
                    </p>
                  </div>
                </div>
                <div class="row" style="border-top: 1px solid #212121">
                  <div class="col-md-8">
                    <p class="mt-2" style="margin-left: 5px">
                      Payment Details:
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p class="mt-2"></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p class="mt-3" style="margin-left: 5px">Sales Deposit</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p class="mt-3">
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.DEPOSIT_AMOUNT }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">Down/Full Payment</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>$&nbsp;&nbsp;&nbsp; -</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">Trade In</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.TRADE_IN_AMOUNT }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">Excess Payment</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>$&nbsp;&nbsp;&nbsp; -</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p class="mt-4" style="margin-left: 5px">
                      Finance by &nbsp;&nbsp;&nbsp;
                      {{
                        this.InvoiceDetailsWithoutTax.FINANCE_COMPANY
                      }}&nbsp;&nbsp;&nbsp; Hire Purchase
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p class="mt-4"></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">
                      Loan Amount &nbsp;&nbsp;&nbsp;
                      :&nbsp;&nbsp;$&nbsp;&nbsp;{{
                        this.InvoiceDetailsWithoutTax.LOAN_AMOUNT
                      }}&nbsp;&nbsp;&nbsp;
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.LOAN_AMOUNT }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">
                      Loan Tenure &nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;{{
                        this.InvoiceDetailsWithoutTax.LOAN_TENURE
                      }}
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p></p>
                  </div>
                </div>
                <div class="row" style="mb-3">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">
                      Interest Rate &nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;{{
                        this.InvoiceDetailsWithoutTax.INTEREST_RATE
                      }}%
                    </p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p></p>
                  </div>
                </div>
                <div class="row" style="border-top: 1px solid #212121">
                  <div class="col-md-8">
                    <p style="margin-left: 5px">Amount Due From Customer</p>
                  </div>
                  <div
                    class="col-md-2"
                    style="
                      border-left: 1px solid #212121;
                      border-right: 1px solid #212121;
                    "
                  >
                    <p></p>
                  </div>
                  <div class="col-md-2">
                    <p>
                      $&nbsp;&nbsp;&nbsp;
                      {{ this.InvoiceDetailsWithoutTax.BALANCE_DUE }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- content footer  -->
            <div class="content-footer mt-3">
                              <div class="row">
                                                <div class="col-md-9">
                                                    <ul>
                                                        <li><h5>Mode of Payment</h5></li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Cheque</p></div>
                                                                <div class="col-md-9 p-0">
                                                                    <span>
                                                                        : All Cheque must be in favour of <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Bank Transfer</p></div>
                                                                <div class="col-md-9 p-0"><span>: UOB3303198200</span></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div class="row">
                                                                <div class="col-md-3"><p>Paynow</p></div>
                                                                <div class="col-md-9 p-0"><span>: 201929524C001</span></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-3">
                                                    <ul>
                                                        <li><img src="../../assets/images/QR.jpg" class="img-fluid mt-2" alt="qr code"></li>
                                                    </ul>
                                                </div>
                                            </div>
                              
                            </div>

            </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Design For Credit Note -->
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="creditnote"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      style="margin-left: 2em"
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-7 p-0">
                    <ul style="margin-left: 5em">
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2" style="width: 30em">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li>
                        <p>ROC No./ GST Reg No:. 201929524C</p>
                      </li>
                      <li>
                        <p>Tel: 62395191 Fax: 62395190</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 ml-2">
              <div class="col-md-12" style="text-align: end">
                <h4 class="fw-bold">
                  <span
                    style="
                      border: 1px solid rgba(0, 0, 0, 0.3);
                      padding: 4px 10px;
                    "
                    >TAX CREDIT NOTE</span
                  >
                </h4>
              </div>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-md-7">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>To</p></div>
                      <div class="col-md-7">
                        :<span>
                          {{ this.DynamicproductFlagArr.CUSTOMER_NAME }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-5">
                        <p>Address</p>
                      </div>
                      <div class="col-md-7">
                        :

                        <span class="ms-1">
                          {{ this.DynamicproductFlagArr.CUSTOMER_ADDRESS }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-5">
                        <p>Vehicle Registration No.</p>
                      </div>
                      <div class="col-md-7">
                        :<span>
                          {{ this.DynamicproductFlagArr.VEHICLE_NUMBER }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-5">
                        <p>Engine No./Motor No.</p>
                      </div>
                      <div class="col-md-7">
                        :<span>
                          {{ this.DynamicproductFlagArr.ENGINE_NO }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-5">
                        <p>Chassis Number</p>
                      </div>
                      <div class="col-md-7">
                        :<span>
                          {{ this.DynamicproductFlagArr.CHASIS_NO }}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-7" style="text-align: right">
                        <p>Credit Note No.</p>
                      </div>
                      <div class="col-md-5">
                        <span
                          >:&nbsp;{{
                            this.DynamicproductFlagArr.CREDIT_NOTE_NO
                          }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-7" style="text-align: right">
                        <p>Date</p>
                      </div>

                      <div class="col-md-5">
                        <span
                          >:&nbsp;{{
                            this.DynamicproductFlagArr.CREDIT_NOTE_DATE
                          }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-7" style="text-align: right">
                        <p>Agreement No.</p>
                      </div>
                      <div class="col-md-5">
                        <span
                          >:
                          {{
                            this.DynamicproductFlagArr.SALES_AGREEMENT_NUMBER
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-12">
              <div class="content-body-2">
                <table class="table table-borderless">
                  <thead
                    style="
                      border-top: 2px solid #212121;
                      border-bottom: 2px solid #212121;
                    "
                  >
                    <tr>
                      <th scope="col">Description of Goods</th>
                      <th scope="col">Qty</th>

                      <th scope="col">Unit Price</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(
                      user_deta, index
                    ) in DynamicproductFlagArr.CREDIT_NOTE_DETAILS"
                    :key="index"
                  >
                    <tr>
                      <td>{{ user_deta.DESCRIPTION }}</td>
                      <td>{{ user_deta.QTY }}</td>

                      <td>$ {{ user_deta.UNIT_PRICE }}</td>
                      <td>$ {{ user_deta.AMOUNT }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <div class="content-footer" style="border: 1px solid #212121">
                <table class="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <!-- <th scope="col">Qty</th> -->
                      <td></td>
                      <td style="border-left: 1px solid #212121">
                        Amount SGD$
                      </td>
                      <td style="border-left: 1px solid #212121">
                        ${{ this.DynamicproductFlagArr.V_UNIT_TOTAL_AMOUNT }}
                        <span class="ms-1"></span>
                      </td>
                    </tr>

                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        Terms of Payment:
                        {{ this.DynamicproductFlagArr.PAYMENT_TERMS }}
                        <span class="MS-2"> </span>
                      </td>
                      <td style="border-left: 1px solid #212121">
                        Tax Amount $
                        <span class="ms-1"> </span>
                      </td>
                      <td style="border-left: 1px solid #212121">
                        ${{ this.DynamicproductFlagArr.V_TAX_TOTAL_AMOUNT }}
                      </td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        All cheques issued must be made in the favour of Lek
                        Auto Pte Ltd
                      </td>
                      <td style="border-left: 1px solid #212121">
                        <span class="ms-1">Total SGD $ </span>
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $
                        {{ this.DynamicproductFlagArr.V_GRAND_TOTAL_AMOUNT }}
                        <span class="ms-1"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- PDF Design For PL Statement  -->
    <vue-html2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Purchase Settlement"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="PLStatementHtmlPDF"
    >
    </vue-html2pdf>

    <!-- PL Statement modal -->
    <b-modal
      id="pl_modal"
      class="pl_modal"
      v-model="admin_pl_modal"
      title="PL STATEMENT"
      size="xl"
      hide-footer
    >
      <div>
        <div class="row">
          <div class="col-md-2">
            <img
              src="../../assets/images/icon.png"
              class="img-fluid header-img"
              alt="#"
            />
          </div>
          <div class="col-md-8 p-0">
            <ul>
              <li><img src="../../assets/images/logo-txt.png" /></li>
              <li class="mt-3">
                <p>1 Sunview Road #01-46 Eco-Tech@Sunview Singapore 627615</p>
              </li>
              <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
              <li><p>Tel: 62395191 Fax: 62395190</p></li>
            </ul>
          </div>
          <div class="col-md-2">
            <button
              type="button"
              class="btn btn-info ripple"
              style="float: right"
              @click="plreportpdfdownload"
            >
              <i class="fa fa-download"></i>
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p><b>Vehicle No</b></p>
                  <p><b>Make & Model</b></p>
                  <p><b>Body Type</b></p>
                  <p><b>Chassis No</b></p>
                  <p><b>Engine No</b></p>
                  <p><b>Propellant</b></p>
                  <p><b>Original Reg.Date</b></p>
                  <p><b>Colour</b></p>
                  <p><b>No. of Owner</b></p>
                </div>
                <div class="col-md-7">
                  <p><span class="colon_space">: </span>{{ this.VEHICLE_NUMBER_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.MAKE_NAME }}&{{ this.MODEL_NAME }}</p>
                  <p><span class="colon_space">: </span>{{ this.BODY_TYPE_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.CHASIS_NO_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.ENGINE_NO_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.PROPELLANT_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.ORIGINAL_REGISTRATION_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.COLOR_NAME_NEW }}</p>
                  <p></p><span class="colon_space">: </span>01</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" >
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p><b>Previous Vehicle No.</b></p>
                  <p><b>Rd Tax Exp. Date</b></p>
                  <p><b>Inspection Exp.Date</b></p>
                  <p><b>LayUp Exp. Date</b></p>
                  <p><b>COE Logcard</b></p>
                  <p><b>COE / PQP</b></p>
                  <p><b>COE No.</b></p>
                  <p><b>COE Exp. Date</b></p>
                  <p><b>OMV</b></p>
                  <p><b>ETS Paper From</b></p>
                  <p><b>Use TCOE</b></p>
                </div>
                <div class="col-md-7">
                  <p><span class="colon_space">: </span>{{ this.TEMPORARY_VEHICLE_NUMBER }}</p>
                  <p><span class="colon_space">: </span>{{ this.ROAD_TAX_EXPIRY_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.INSPECTION_EXPIRY_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.LAYUP_EXP_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.COE_LOG_GUARD }}</p>
                  <p><span class="colon_space">: </span>{{ this.COE_ACC_DPQP }}</p>
                  <p><span class="colon_space">: </span>{{ this.COE_NUMBER }}</p>
                  <p><span class="colon_space">: </span>{{ this.COE_EXPIRY_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.OMV_E_TSF }}</p>
                  <p><span class="colon_space">: </span>{{ this.ETS_PAPER_FROM }}</p>
                  <p><span class="colon_space">: </span>{{ this.USE_TCOE }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pdfheader">
          <p>Seller Information</p>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p class="addressline"><b>ID No</b></p>
              
                  <p><b>Purchase Date</b></p>
                  <p><b>Estimated Delivery In Date</b></p>
                  <p><b>Delivery In Date</b></p>
                  <p><b>Purchase Price</b></p>
                  <p><b>Total Purchase Price</b></p>
               
                
                </div>
                <div class="col-md-5">
                  <p class="id_text_wrap"><span class="colon_space">: </span> {{ this.SELLER_CUSTOMER_NAME }}</p>
           
                  <p><span class="colon_space">: </span> {{ this.PURCHASE_DATE_NEW }}</p>
                  <p><span class="colon_space">: </span> {{ this.ESTIMATE_DELIVERY_IN_DATE_NEW }}</p>
                  <p><span class="colon_space">: </span> {{ this.DELIVERY_IN_DATE }}</p>
                  <p><span class="colon_space">: </span> {{ this.PURCHASE_PRICE_SD }}</p>
                 
                  <p><span class="colon_space">: </span> {{ this.TOTAL_PURCHASE_PRICE }}</p>
                  <!-- <p>{{ this.ADDRESS_LINE_1_SELLER }}</p>
                  <p> {{this.COUNTRY_NAME_SELLER}} {{this.PIN_CODE_SELLER}}</P> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                
                <div class="col-md-5">
                   <p class="address_line"><b>Address</b></p>
                   <br />
                  <!-- <p><b>Country Name  </b></p> -->
                  <p><b>Outside Purchase Comm($)</b></p>
                  <p><b>Agreement No</b></p>
                  <p><b>Note</b></p>
                  <p><b>Transaction Date</b></p>
                  <p><b>E-Transfer In Date</b></p>
                  <p><b>GST Method</b></p>
                  <p><b>GST Amount</b></p>
                </div>
                <div class="col-md-7">
                  <p class="address_wrap"> <span class="colon_space">: </span> {{ this.ADDRESS_LINE_1_SELLER }} <br />
                  {{ this.COUNTRY_NAME_SELLER }} {{this.PIN_CODE_SELLER}}</p>
                  <br />
                
                  <p> <span class="colon_space">: </span> {{ this.PURCHASE_OUTSIDE_COMMISSION }}</p>
                  <p> <span class="colon_space">: </span> {{ this.AGREEMENT_NUMBER }}</p>
                  <p> <span class="colon_space">: </span> {{ this.NOTE }}</p>
                  <p> <span class="colon_space">: </span> {{ this.E_TRANSFER_IN_DATE }}</p>
                  <p> <span class="colon_space">: </span> {{ this.E_TRANSFER_IN_DATE }}</p>
                  <p> <span class="colon_space">: </span>{{this.GST_METHOD_SELLER}} </p>
                  <p> <span class="colon_space">: </span>{{this.GST_AMOUNT_SELLER}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="data-table admin-master-table mt-3 table-responsive">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr style="background: #ebd8c3;">
                                                                        <th scope="col">Purchase Price</th>
                                                                        <th scope="col">55,000.00</th>
                                                                        <th scope="col"></th>
                                                                        <th scope="col">Gst</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>GMS</th>
                                                                        <td>55,000.00</td>
                                                                        <td>25</td>
                                                                        <td>652.57</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>DRS</th>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>NEW</th>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>EXE</th>
                                                                        <td>0.00</td>
                                                                        <td></td>
                                                                        <td>0.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>GST</th>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div> -->

        <div class="pdfheader">
          <p>Buyer Information</p>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-4">
                  <p class="addressline"><b>ID No</b></p>
                  <p><b>Delivery Out Date</b></p>
                  <p><b>Invoice No</b></p>
                  <p><b>P & L Date</b></p>
                  <p><b>Booking Date</b></p>
                  <p><b>Sales Person </b></p>
                  <p><b>Sales Agreement No</b></p>
                  <p><b>Sales Agreement Price</b></p>
                  <p><b>GST Method</b></p>
                  <p><b>GST Amount</b></p>
                  <p><b>Outside Sales Comm($)</b></p>
               
                
                </div>
                <div class="col-md-8">
                  <p  class="id_text_wrap"><span class="colon_space">: </span> {{ this.BUYER_CUSTOMER_NAME }}</p>
                  <p><span class="colon_space">: </span> 
                    {{ this.DELIVERY_OUT_DATE_BUYER }}
                    <span><b>Time:</b>{{ this.TIME }}</span>
                  </p>
                  <p><span class="colon_space">: </span> {{ this.INVOICE_NO_BUYER }}</p>
                  <p><span class="colon_space">: </span> {{ this.P_L_DATE }}</p>
                  <p><span class="colon_space">: </span> {{ this.BOOKING_DATE }}</p>
                  <p><span class="colon_space">: </span> {{ this.SALES_PERSON }}</p>
                  <p><span class="colon_space">: </span> {{ this.SALES_AGGREMENT_NUMBER }}</p>
                  <p><span class="colon_space">: </span> {{ this.TOTAL_SELLLING_PRICE }}</p>
                  <p><span class="colon_space">: </span> {{ this.GST_TYPE }} </p>
              <p><span class="colon_space">: </span> {{this.GST_AMOUNT_NEW}}</p>
                  <p><span class="colon_space">: </span> {{ this.SALES_OUTSIDE_COMMISSION }}</p>
           
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-4">
                  <p class="address_line"><b>Address </b></p>
              
                  <p><b>ETS Transaction</b></p>
                  <p><b>Buy Over Date</b></p>

                  <p><b>Fight P & L</b></p>
                  <p><b>ETS Paper To</b></p>
                  <p><b>Body Price</b></p>
                  <p><b>Dereg Date</b></p>
                  <p><b>Coe Encashment</b></p>
                  <p><b>ETS Transfer Value</b></p>
                  <p><b>Invoice No 2</b></p>
                  <p class="address_line"><b>ETS Paper Buyer</b></p>
                  <p><b>Transcastion Type</b></p>
                  <p><b>E-Transfer Out Date</b></p>
                
                </div>
                <div class="col-md-8">
                  <p class="address_wrap"><span class="colon_space">: </span>
                     {{ this.ADDRESS_LINE_1 }} <br />
                  {{ this.COUNTRY_NAME_BUYER}} {{ this.PIN_CODE_BUYER}}</p>
                  <p><span class="colon_space">: </span> -</p>
                  <p><span class="colon_space">: </span> {{ this.BUY_OVER_DATE }}</p>

                  <p><span class="colon_space">: </span> {{ this.FIGHT_PL }}</p>
                  <p><span class="colon_space">: </span> {{ this.ETS_PAPER_TO }}</p>
                  <p><span class="colon_space">: </span> {{this.BODY_PRICE}}</p>
                  <p><span class="colon_space">: </span> {{ this.DEREG_DATE }}</p>
                  <p><span class="colon_space">: </span> {{ this.COE_ENCASHMENT }}</p>
                  <p><span class="colon_space">: </span> {{ this.ETS_TRANSFER_VALUE }}</p>
                  <p><span class="colon_space">: </span> {{ this.INVOICE_NUMBER }}</p>
                  <p class="address_wrap"><span class="colon_space">: </span> {{ this.ETS_PAPER_BUYER }}</p>
                  <p><span class="colon_space">: </span> {{ this.TRANSACTION_TYPE }}</p>
                  <p><span class="colon_space">: </span> {{ this.E_TRANSFER_OUT_DATE }}</p>
               
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-4">
                  <p><b>Insurance Premium</b></p>
                  <p><b>HP Facilities Fee</b></p>
                  <p><b>ADV Payment</b></p>
                  <p><b>Loan Amount</b></p>
                  <p><b>Finance Comapny</b></p>
                  <p><b>Interest Rate</b></p>
                  <p><b>Trade In</b></p>
                  <p><b>Others</b></p>
                  <p><b>Mark up fee</b></p>
                  <p><b>Total Receivable</b></p>
                  <p><b>Total Received</b></p>
                  <p><b>Loan Disbursement</b></p>
                  
               
                  <p><b>AR Balance</b></p>
                  <p><b>Credit Note</b></p>
                  <p><b>Mark up Price</b></p>
                </div>
                <div class="col-md-8">
                  <p> <span class="colon_space">: </span>{{ this.INSURANCE_PREMIUM }}</p>
                  <p> <span class="colon_space">: </span>{{ this.HP_FACILITIES_FEE_NEW }}</p>
                  <p> <span class="colon_space">: </span>{{ this.ADVANCE_PAYMENT }}</p>
                  <p> <span class="colon_space">: </span>{{  this.LOAN_AMOUNT_NEW }}</p>
                  <p> <span class="colon_space">: </span>{{ this.FINANCE_COMPANY }}</p>
                  <p> <span class="colon_space">: </span>{{ this.INTEREST_RATE }}</p>
                  <p> <span class="colon_space">: </span>0</p>
                  <p> <span class="colon_space">: </span>{{ this.OTHERT_AMOUNT_GST_AMOUNT }}</p>
                  <p> <span class="colon_space">: </span>{{ this.MARK_UP_FEE }}</p>
                  <p> <span class="colon_space">: </span>{{ this.TOTAL_RECEIVABLE_AMOUNT }}</p>
                  <p> <span class="colon_space">: </span>{{ this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT_NEW }}</p>
                  <p> <span class="colon_space">: </span>{{ this.LOAN_DISBURSEMENT_AMOUNT }}</p>

                  <!-- <p>{{ this.RECEIVED_DATE }}</p> -->
                  <p> <span class="colon_space">: </span>{{ this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT_NEW }}</p>
                  <p> <span class="colon_space">: </span>0</p>
                  <p> <span class="colon_space">: </span>{{      this.TOTAL_SELLLING_PRICE }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-4">               
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p><b>Trade in Price</b></p>
                  <p><b>Trade in Deposit</b></p>
                  <p><b>Full Settlement</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p><b>Received Date</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  
                  <p><b>GST Method</b></p>
                  <p><b>GST Amount</b></p>
                </div>
                <div class="col-md-8">
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p><span class="colon_space">: </span>0.00</p>
                  <p><span class="colon_space">: </span>0.00</p>
                  <p><span class="colon_space">: </span>0.00</p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p><span class="colon_space">: </span>{{ this.RECEIVED_DATE }}</p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
              
                  <p><span class="colon_space">: </span>{{ this.GST_TYPE }} </p>
                  <p><span class="colon_space">: </span> {{ this.GST_AMOUNT_NEW}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pdfheader">
          <p>Price Transaction</p>
        </div>
        <h6 class="mt-3"><b>Cost Sheet Report</b></h6>
        <div class="row mt-1">
          <div class="col-md-4">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-4">
                  <p>Broker 1 code</p>
                  <p>Broker 2 code</p>
                </div>
                <div class="col-md-8">
                  <p>{{ this.BROKER_1_NAME }}</p>
                  <p>{{ this.BROKER_2_NAME }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-table admin-master-table mt-3 table-responsive">
          <div class="row">
            <div class="col-md-6">
              <div class="master-table mt-3">
                <b-table
                  class="table table-bordered"
                  striped
                  hover
                  :items="CostCreatedPurchaseList"
                  :fields="CostCreatedPurchaseListFields"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                </b-table>
           
              </div>
            </div>
         
      
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p><b>Selling Price</b></p>
                  <p><b>Outside Sales Commission</b></p>
                  <p><b>Total Cost Price</b></p>
                  <p><b>G.S.T Incured (Input)</b></p>

                  <p><b>G.S.T Incured (Output)</b></p>
                  <p><b>Sales Commision</b></p>
                  <p><b>Gross Profit/Loss</b></p>
                  <p><b>{{this.COMMISON_BROKER_NAME  }}: ({{ this.COMMISON_PERCENTEGE}}%)</b></p>
                  <p><b>{{this.COMMISON_BROKER_NAME_NEW  }}: ({{ this.COMMISON_PERCENTEGE_NEW}}%)</b></p>
                  <p><b>Gross Profit/Loss:</b></p>

               
                </div>
                <div class="col-md-6">
                  <p><span class="colon_space">:{{     this.TOTAL_SELLLING_PRICE }} </span> </p>
              <p><span class="colon_space">: {{ this.OUTSIDE_SALES_COMMSION}}  </span> </p>
                  <p><span class="colon_space">: {{this.TOTAL_COST }}</span> </p>
                

                  <p><span class="colon_space">: {{this.Grand_Total_GST}}  </span> </p>
                  <p><span class="colon_space">: {{this.GST_AMOUNT_NEW}}</span> </p>
              <p><span class="colon_space">:  {{this.COM_RTN_1}}  </span>  </p>
                  <p><span class="colon_space">: </span>  {{   this.costcenteramount }}  </p>
                  <p><span class="colon_space">: </span>{{ this.COMMISSION_AMOUNT }} </p>
                  <p><span class="colon_space">: </span> {{  this.COMMISSION_AMOUNT_NEW }} </p>
                  <p><span class="colon_space">: </span> {{ this.plstaementamount}} </p>
                </div>
              </div>
            </div>
          </div>
       
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <h6><b>Floor Stock</b></h6>
                <div class="col-md-6 mt-1">
                  <p><b>Pur Ets Date </b></p>
                  <p><b>E Out Date</b></p>
                  <p><b>Days</b></p>
                  <p><b>Amount</b></p>
                </div>
                <div class="col-md-6">
                  <p><span class="colon_space">: </span>{{ this.FLOOR_STOCK_E_TRRASFER_IN_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.FLOOR_STOCK_E_TRRASFER_OUT_DATE }}</p>
                  <p><span class="colon_space">: </span>
                    {{ this.NO_OF_DAYS }} 
                   
                  </p>
                  <p><span class="colon_space">: </span>{{ this.FLOOR_STOCK_AMOUNT }}</p>
                </div>
              </div>

            
            </div>
          </div>
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <h6><b>Finance Details</b></h6>
                <div class="col-md-4 mt-1">
                  <p><b>Loan amount</b></p>
                  <p><b>Loan Comm</b></p>
                  <p><b>GST</b></p>
                  <p><b>Total Received</b></p>
                  <p><b>Interest Rate</b></p>
                  <p><b>Loan Tenure</b></p>
                  <p><b>Loan Company</b></p>
                  <p><b>Finance Company</b></p>
                </div>
                <div class="col-md-8 mt-1">
                  <p> <span class="colon_space">: </span>{{   this.LOAN_AMOUNT_NEW  }}</p>
                  <p> <span class="colon_space">: </span>{{ this.LOAN_COMMISSION }}</p>
                  <p><span class="colon_space">: </span>-</p>
                  <p><span class="colon_space">: </span>0</p>
                  <p> <span class="colon_space">: </span>{{ this.INTEREST_RATE }}</p>
                  <p> <span class="colon_space">: </span>{{ this.LOAN_TENURE }}</p>
                  <p> <span class="colon_space">: </span>{{ this.FINANCE_COMPANY }}</p>
                  <p> <span class="colon_space">: </span>{{ this.FINANCE_COMPANY_BANK_NAME_PL_SMT }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pdfheader">
          <p>Vehicle Cost Created</p>
        </div>
        <div class="data-table admin-master-table mt-3 table-responsive">
          <b-table
            class="table table-bordered"
            striped
            hover
            :items="CostCenterDetailsList"
            :fields="CostCenterDetailsListFields"
            @row-clicked="clickVehicleCostCenterDetailsRow"
          >
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>


          <table class="table table-bordered">
              <thead style="background-color:#ebd8c3;">
                <tr>
                <th>Total </th>             
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
             
                <th>$</th>
                <th>$</th>
                <th>$</th>
                </tr>
              </thead>
             </table>
        </div>
      </div>
    </b-modal>
    <!--PL STATEMENT PDF-->
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="PL statement"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a3"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="PLREPORTNEWPDFDONE"
    >
    <section slot="pdf-content" class="p-5">
      <div>
        <div class="row">
          <div class="col-md-2">
            <img
              src="../../assets/images/icon.png"
              class="img-fluid header-img"
              alt="#"
            />
          </div>
          <div class="col-md-8 p-0">
            <ul>
              <li><img src="../../assets/images/logo-txt.png" /></li>
              <li class="mt-3">
                <p>1 Sunview Road #01-46 Eco-Tech@Sunview Singapore 627615</p>
              </li>
              <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
              <li><p>Tel: 62395191 Fax: 62395190</p></li>
            </ul>
          </div>
        
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p><b>Vehicle No</b></p>
                  <p><b>Make & Model</b></p>
                  <p><b>Body Type</b></p>
                  <p><b>Chassis No</b></p>
                  <p><b>Engine No</b></p>
                  <p><b>Propellant</b></p>
                  <p><b>Original Reg.Date</b></p>
                  <p><b>Colour</b></p>
                  <p><b>No. of Owner</b></p>
                </div>
                <div class="col-md-7">
                  <p><span class="colon_space">: </span>{{ this.VEHICLE_NUMBER_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.MAKE_NAME }}&{{ this.MODEL_NAME }}</p>
                  <p><span class="colon_space">: </span>{{ this.BODY_TYPE_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.CHASIS_NO_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.ENGINE_NO_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.PROPELLANT_NEW }}</p>
                  <p><span class="colon_space">: </span>{{ this.ORIGINAL_REGISTRATION_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.COLOR_NAME_NEW }}</p>
                  <p></p><span class="colon_space">: </span>01</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" >
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p><b>Previous Vehicle No.</b></p>
                  <p><b>Rd Tax Exp. Date</b></p>
                  <p><b>Inspection Exp.Date</b></p>
                  <p><b>LayUp Exp. Date</b></p>
                  <p><b>COE Logcard</b></p>
                  <p><b>COE / PQP</b></p>
                  <p><b>COE No.</b></p>
                  <p><b>COE Exp. Date</b></p>
                  <p><b>OMV</b></p>
                  <p><b>ETS Paper From</b></p>
                  <p><b>Use TCOE</b></p>
                </div>
                <div class="col-md-7">
                  <p><span class="colon_space">: </span>{{ this.TEMPORARY_VEHICLE_NUMBER }}</p>
                  <p><span class="colon_space">: </span>{{ this.ROAD_TAX_EXPIRY_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.INSPECTION_EXPIRY_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.LAYUP_EXP_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.COE_LOG_GUARD }}</p>
                  <p><span class="colon_space">: </span>{{ this.COE_ACC_DPQP }}</p>
                  <p><span class="colon_space">: </span>{{ this.COE_NUMBER }}</p>
                  <p><span class="colon_space">: </span>{{ this.COE_EXPIRY_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.OMV_E_TSF }}</p>
                  <p><span class="colon_space">: </span>{{ this.ETS_PAPER_FROM }}</p>
                  <p><span class="colon_space">: </span>{{ this.USE_TCOE }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pdfheader">
          <p>Seller Information</p>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p class="addressline"><b>ID No</b></p>
              
                  <p><b>Purchase Date</b></p>
                  <p><b>Estimated Delivery In Date</b></p>
                  <p><b>Delivery In Date</b></p>
                  <p><b>Purchase Price</b></p>
                  <p><b>Total Purchase Price</b></p>
               
                
                </div>
                <div class="col-md-5">
                  <p class="id_text_wrap"><span class="colon_space">: </span> {{ this.SELLER_CUSTOMER_NAME }}</p>
           
                  <p><span class="colon_space">: </span> {{ this.PURCHASE_DATE_NEW }}</p>
                  <p><span class="colon_space">: </span> {{ this.ESTIMATE_DELIVERY_IN_DATE_NEW }}</p>
                  <p><span class="colon_space">: </span> {{ this.DELIVERY_IN_DATE }}</p>
                  <p><span class="colon_space">: </span> {{ this.PURCHASE_PRICE_SD }}</p>
                 
                  <p><span class="colon_space">: </span> {{ this.TOTAL_PURCHASE_PRICE }}</p>
                  <!-- <p>{{ this.ADDRESS_LINE_1_SELLER }}</p>
                  <p> {{this.COUNTRY_NAME_SELLER}} {{this.PIN_CODE_SELLER}}</P> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                
                <div class="col-md-5">
                   <p class="address_line"><b>Address</b></p>
                   <br />
                  <!-- <p><b>Country Name  </b></p> -->
                  <p><b>Outside Purchase Comm($)</b></p>
                  <p><b>Agreement No</b></p>
                  <p><b>Note</b></p>
                  <p><b>Transaction Date</b></p>
                  <p><b>E-Transfer In Date</b></p>
                  <p><b>GST Method</b></p>
                  <p><b>GST Amount</b></p>
                </div>
                <div class="col-md-7">
                  <p class="address_wrap"> <span class="colon_space">: </span> {{ this.ADDRESS_LINE_1_SELLER }} <br />
                  {{ this.COUNTRY_NAME_SELLER }} {{this.PIN_CODE_SELLER}}</p>
                  <br />
                
                  <p> <span class="colon_space">: </span> {{ this.PURCHASE_OUTSIDE_COMMISSION }}</p>
                  <p> <span class="colon_space">: </span> {{ this.AGREEMENT_NUMBER }}</p>
                  <p> <span class="colon_space">: </span> {{ this.NOTE }}</p>
                  <p> <span class="colon_space">: </span> {{ this.E_TRANSFER_IN_DATE }}</p>
                  <p> <span class="colon_space">: </span> {{ this.E_TRANSFER_IN_DATE }}</p>
                  <p> <span class="colon_space">: </span>{{this.GST_METHOD_SELLER}} </p>
                  <p> <span class="colon_space">: </span>{{this.GST_AMOUNT_SELLER}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="data-table admin-master-table mt-3 table-responsive">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr style="background: #ebd8c3;">
                                                                        <th scope="col">Purchase Price</th>
                                                                        <th scope="col">55,000.00</th>
                                                                        <th scope="col"></th>
                                                                        <th scope="col">Gst</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>GMS</th>
                                                                        <td>55,000.00</td>
                                                                        <td>25</td>
                                                                        <td>652.57</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>DRS</th>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>NEW</th>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>EXE</th>
                                                                        <td>0.00</td>
                                                                        <td></td>
                                                                        <td>0.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>GST</th>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                        <td>0.00</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div> -->

        <div class="pdfheader">
          <p>Buyer Information</p>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p class="addressline"><b>ID No</b></p>
                  <p><b>Delivery Out Date</b></p>
                  <p><b>Invoice No</b></p>
                  <p><b>P & L Date</b></p>
                  <p><b>Booking Date</b></p>
                  <p><b>Sales Person </b></p>
                  <p><b>Sales Agreement No</b></p>
                  <p><b>Sales Agreement Price</b></p>
                  <p><b>GST Method</b></p>
                  <p><b>GST Amount</b></p>
                  <p><b>Outside Sales Comm($)</b></p>
               
                
                </div>
                <div class="col-md-5">
                  <p  class="id_text_wrap"><span class="colon_space">: </span> {{ this.BUYER_CUSTOMER_NAME }}</p>
                  <p><span class="colon_space">: </span> 
                    {{ this.DELIVERY_OUT_DATE_BUYER }}
                    <span><b>Time:</b>{{ this.TIME }}</span>
                  </p>
                  <p><span class="colon_space">: </span> {{ this.INVOICE_NO_BUYER }}</p>
                  <p><span class="colon_space">: </span> {{ this.P_L_DATE }}</p>
                  <p><span class="colon_space">: </span> {{ this.BOOKING_DATE }}</p>
                  <p><span class="colon_space">: </span> {{ this.SALES_PERSON }}</p>
                  <p><span class="colon_space">: </span> {{ this.SALES_AGGREMENT_NUMBER }}</p>
                  <p><span class="colon_space">: </span> {{ this.TOTAL_SELLLING_PRICE }}</p>
                  <p><span class="colon_space">: </span> {{ this.GST_TYPE }} </p>
              <p><span class="colon_space">: </span> {{this.GST_AMOUNT_NEW}}</p>
                  <p><span class="colon_space">: </span> {{ this.SALES_OUTSIDE_COMMISSION }}</p>
           
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p class="address_line"><b>Address </b></p>
              
                  <p><b>ETS Transaction</b></p>
                  <p><b>Buy Over Date</b></p>

                  <p><b>Fight P & L</b></p>
                  <p><b>ETS Paper To</b></p>
                  <p><b>Body Price</b></p>
                  <p><b>Dereg Date</b></p>
                  <p><b>Coe Encashment</b></p>
                  <p><b>ETS Transfer Value</b></p>
                  <p><b>Invoice No 2</b></p>
                  <p class="address_line"><b>ETS Paper Buyer</b></p>
                  <p><b>Transcastion Type</b></p>
                  <p><b>E-Transfer Out Date</b></p>
                
                </div>
                <div class="col-md-7">
                  <p class="address_wrap"><span class="colon_space">: </span>
                     {{ this.ADDRESS_LINE_1 }} <br />
                  {{ this.COUNTRY_NAME_BUYER}} {{ this.PIN_CODE_BUYER}}</p>
                  <p><span class="colon_space">: </span> -</p>
                  <p><span class="colon_space">: </span> {{ this.BUY_OVER_DATE }}</p>

                  <p><span class="colon_space">: </span> {{ this.FIGHT_PL }}</p>
                  <p><span class="colon_space">: </span> {{ this.ETS_PAPER_TO }}</p>
                  <p><span class="colon_space">: </span> {{this.BODY_PRICE}}</p>
                  <p><span class="colon_space">: </span> {{ this.DEREG_DATE }}</p>
                  <p><span class="colon_space">: </span> {{ this.COE_ENCASHMENT }}</p>
                  <p><span class="colon_space">: </span> {{ this.ETS_TRANSFER_VALUE }}</p>
                  <p><span class="colon_space">: </span> {{ this.INVOICE_NUMBER }}</p>
                  <p class="address_wrap"><span class="colon_space">: </span> {{ this.ETS_PAPER_BUYER }}</p>
                  <p><span class="colon_space">: </span> {{ this.TRANSACTION_TYPE }}</p>
                  <p><span class="colon_space">: </span> {{ this.E_TRANSFER_OUT_DATE }}</p>
               
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p><b>Insurance Premium</b></p>
                  <p><b>HP Facilities Fee</b></p>
                  <p><b>ADV Payment</b></p>
                  <p><b>Loan Amount</b></p>
                  <p><b>Finance Comapny</b></p>
                  <p><b>Interest Rate</b></p>
                  <p><b>Trade In</b></p>
                  <p><b>Others</b></p>
                  <p><b>Mark up fee</b></p>
                  <p><b>Total Receivable</b></p>
                  <p><b>Total Received</b></p>
                  <p><b>Loan Disbursement</b></p>
                  
               
                  <p><b>AR Balance</b></p>
                  <p><b>Credit Note</b></p>
                  <p><b>Mark up Price</b></p>
                </div>
                <div class="col-md-5">
                  <p> <span class="colon_space">: </span>{{ this.INSURANCE_PREMIUM }}</p>
                  <p> <span class="colon_space">: </span>{{ this.HP_FACILITIES_FEE_NEW }}</p>
                  <p> <span class="colon_space">: </span>{{ this.ADVANCE_PAYMENT }}</p>
                  <p> <span class="colon_space">: </span>{{  this.LOAN_AMOUNT_NEW }}</p>
                  <p> <span class="colon_space">: </span>{{ this.FINANCE_COMPANY }}</p>
                  <p> <span class="colon_space">: </span>{{ this.INTEREST_RATE }}</p>
                  <p> <span class="colon_space">: </span>0</p>
                  <p> <span class="colon_space">: </span>{{ this.OTHERT_AMOUNT_GST_AMOUNT }}</p>
                  <p> <span class="colon_space">: </span>{{ this.MARK_UP_FEE }}</p>
                  <p> <span class="colon_space">: </span>{{ this.TOTAL_RECEIVABLE_AMOUNT }}</p>
                  <p> <span class="colon_space">: </span>{{ this.VEHICLE_TOTAL_RECEIVABLE_AMOUNT_NEW }}</p>
                  <p> <span class="colon_space">: </span>{{ this.LOAN_DISBURSEMENT_AMOUNT }}</p>

                  <!-- <p>{{ this.RECEIVED_DATE }}</p> -->
                  <p> <span class="colon_space">: </span>{{ this.VEHICLE_TOREDUCED_RECEIVABLE_AMOUNT_NEW }}</p>
                  <p> <span class="colon_space">: </span>0</p>
                  <p> <span class="colon_space">: </span>{{      this.TOTAL_SELLLING_PRICE }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">               
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p><b>Trade in Price</b></p>
                  <p><b>Trade in Deposit</b></p>
                  <p><b>Full Settlement</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p><b>Received Date</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  
                  <p><b>GST Method</b></p>
                  <p><b>GST Amount</b></p>
                </div>
                <div class="col-md-6">
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p><span class="colon_space">: </span>0.00</p>
                  <p><span class="colon_space">: </span>0.00</p>
                  <p><span class="colon_space">: </span>0.00</p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
                  <p><span class="colon_space">: </span>{{ this.RECEIVED_DATE }}</p>
                  <p class="text-white"><b>-</b></p>
                  <p class="text-white"><b>-</b></p>
              
                  <p><span class="colon_space">: </span>{{ this.GST_TYPE }} </p>
                  <p><span class="colon_space">: </span> {{ this.GST_AMOUNT_NEW}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pdfheader">
          <p>Price Transaction</p>
        </div>
        <h6 class="mt-3"><b>Cost Sheet Report</b></h6>
        <div class="row mt-1">
          <div class="col-md-4">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-4">
                  <p>Broker 1 code</p>
                  <p>Broker 2 code</p>
                </div>
                <div class="col-md-8">
                  <p>{{ this.BROKER_1_NAME }}</p>
                  <p>{{ this.BROKER_2_NAME }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-table admin-master-table mt-3 table-responsive">
          <div class="row">
            <div class="col-md-6">
              <div class="master-table mt-3">
                <b-table
                  class="table table-bordered"
                  striped
                  hover
                  :items="CostCreatedPurchaseList"
                  :fields="CostCreatedPurchaseListFields"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                </b-table>
           
              </div>
            </div>
         
      
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <div class="col-md-5">
                  <p><b>Selling Price</b></p>
                  <p><b>Outside Sales Commission</b></p>
                  <p><b>Total Cost Price</b></p>
                  <p><b>G.S.T Incured (Input)</b></p>

                  <p><b>G.S.T Incured (Output)</b></p>
                  <p><b>Sales Commision</b></p>
                  <p><b>Gross Profit/Loss</b></p>
                  <p><b>{{this.COMMISON_BROKER_NAME  }}: ({{ this.COMMISON_PERCENTEGE}}%)</b></p>
                  <p><b>{{this.COMMISON_BROKER_NAME_NEW  }}: ({{ this.COMMISON_PERCENTEGE_NEW}}%)</b></p>
                  <p><b>Gross Profit/Loss:</b></p>

               
                </div>
                <div class="col-md-6">
                  <p><span class="colon_space">:{{     this.TOTAL_SELLLING_PRICE }} </span> </p>
              <p><span class="colon_space">: {{ this.OUTSIDE_SALES_COMMSION}}  </span> </p>
                  <p><span class="colon_space">: {{this.TOTAL_COST }}</span> </p>
                

                  <p><span class="colon_space">: {{this.Grand_Total_GST}}  </span> </p>
                  <p><span class="colon_space">: {{this.GST_AMOUNT_NEW}}</span> </p>
              <p><span class="colon_space">:  {{this.COM_RTN_1}}  </span>  </p>
                  <p><span class="colon_space">: </span>  {{   this.costcenteramount }}  </p>
                  <p><span class="colon_space">: </span>{{ this.COMMISSION_AMOUNT }} </p>
                  <p><span class="colon_space">: </span> {{  this.COMMISSION_AMOUNT_NEW }} </p>
                  <p><span class="colon_space">: </span> {{ this.plstaementamount}} </p>
                </div>
              </div>
            </div>
          </div>
       
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <h6><b>Floor Stock</b></h6>
                <div class="col-md-6 mt-1">
                  <p><b>Pur Ets Date </b></p>
                  <p><b>E Out Date</b></p>
                  <p><b>Days</b></p>
                  <p><b>Amount</b></p>
                </div>
                <div class="col-md-6">
                  <p><span class="colon_space">: </span>{{ this.FLOOR_STOCK_E_TRRASFER_IN_DATE }}</p>
                  <p><span class="colon_space">: </span>{{ this.FLOOR_STOCK_E_TRRASFER_OUT_DATE }}</p>
                  <p><span class="colon_space">: </span>
                    {{ this.NO_OF_DAYS }} 
                   
                  </p>
                  <p><span class="colon_space">: </span>{{ this.FLOOR_STOCK_AMOUNT }}</p>
                </div>
              </div>

           
            </div>
          </div>
          <div class="col-md-6">
            <div class="content-header-2">
              <div class="row">
                <h6><b>Finance Details</b></h6>
                <div class="col-md-4 mt-1">
                  <p><b>Loan amount</b></p>
                  <p><b>Loan Comm</b></p>
                  <p><b>GST</b></p>
                  <p><b>Total Received</b></p>
                  <p><b>Interest Rate</b></p>
                  <p><b>Loan Tenure</b></p>
                  <p><b>Loan Company</b></p>
                  <p><b>Finance Company</b></p>
                </div>
                <div class="col-md-8 mt-1">
                  <p> <span class="colon_space">: </span>{{   this.LOAN_AMOUNT_NEW  }}</p>
                  <p> <span class="colon_space">: </span>{{ this.LOAN_COMMISSION }}</p>
                  <p><span class="colon_space">: </span>-</p>
                  <p><span class="colon_space">: </span>0</p>
                  <p> <span class="colon_space">: </span>{{ this.INTEREST_RATE }}</p>
                  <p> <span class="colon_space">: </span>{{ this.LOAN_TENURE }}</p>
                  <p> <span class="colon_space">: </span>{{ this.FINANCE_COMPANY }}</p>
                  <p> <span class="colon_space">: </span>{{ this.FINANCE_COMPANY }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pdfheader">
          <p>Vehicle Cost Created</p>
        </div>
        <div class="data-table admin-master-table mt-3 table-responsive">
          <b-table
            class="table table-bordered"
            striped
            hover
            :items="CostCenterDetailsList"
            :fields="CostCenterDetailsListFields"
            @row-clicked="clickVehicleCostCenterDetailsRow"
          >
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>


          <table class="table table-bordered">
              <thead style="background-color:#ebd8c3;">
                <tr>
                <th>Total </th>             
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
             
                <th>$</th>
                <th>$</th>
                <th>$</th>
                </tr>
              </thead>
             </table>
        </div>
      </div>
    </section>
    
     

    </vue-html2pdf>
  </div>
</template>

<script>
import { view_vehicledetails } from "../admin-master/index";
export default view_vehicledetails;
</script>
<style scoped>
.loading_main_background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(42, 42, 43, 0.45);
  z-index: 9999;
  /* display: none; */
}
/* .preloder {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgb(0, 155, 254);
    z-index: 9999;
} */

.preloder img {
  width: auto;
  margin: 12% 38%;
  border-radius: 50%;
  opacity: 0.6;
}
.loading_main_background img {
  width: 5% !important;
  margin: 20% 50% !important;
  opacity: 1 !important;
  position: absolute;
}
img {
  vertical-align: middle;
  border-style: none;
}
li.nav-item {
  width: 10em !important;
  text-align: center !important;
}
.downld {
  background: transparent;
  outline: none;
  border: none;
  color: #112b3c;
  font-weight: 600;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  float: right !important;
  font-size: 13px;
  margin-top: 5px;
}
.animate__bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.modal .admin-edt-frm .divider-sales-resceipt {
  margin-top: 5px;
  width: 7em;
  border-bottom: 2px solid #6e6b7b;
}
.modal .admin-edt-frm .divider-sales-resceipt-2 {
  margin-top: 5px;
  width: 3em;
  border-bottom: 2px solid #6e6b7b;
}
.content .content-header .heading {
  border: 1.5px solid #212121;
  padding: 10px 15px;
  font-size: 17px;
  font-weight: 700;
  /* margin-top: 50px; */
  text-align: center;
}
.content .content-header .heading2 {
  border: 1.5px solid #212121;
  padding: 10px 15px;
  font-size: 17px;
  font-weight: 700;
  margin-top: 50px;
  text-align: center;
}
.purchase-settlement-note-cont {
  font-weight: 500;
  background: #fff;
  box-shadow: 0 0 5px 1px #d2d2d2;
  /* margin-top: 10px; */
  padding: 10px 20px;
}
.admin_edit_modal.modal-dialog {
  max-width: 500px;
  margin: 1rem auto;
}
.admin_edit_modal.modal-xl {
  max-width: 1200px;
}
.content-footer ul li {
    margin-top: 7px;
}
.content-footer ul li h5 {
    font-weight: 600;
    color: #151515;
}
.content-footer ul li img {
    width: 140px;
    height: 130px;
}
.content-footer ul li .scan-msg {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #FF5D5D;
    padding: 2px 22px;
    text-align: center;
    color: #fff;
}
.content-footer ul li p {
    color: #FF4949;
    font-weight: 600;
}
</style>
