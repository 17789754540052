<template>
  <div>
    <div>
      <h4><b>Master Data Setup</b></h4>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data">
          <div class="master-selection">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-5">
                    <label class="frm-label mt-3"
                      >Select Master Data<span style="color: red"
                        >*</span
                      ></label
                    >
                  </div>
                  <div class="col-md-7">
                    <select
                      v-model="selectedMasterData"
                      class="form-control roleddl"
                    >
                      <option :value="null">Select</option>
                      <option
                        v-for="master_name_detail in master_name_details"
                        :value="master_name_detail.MASTER_NAME"
                        :key="master_name_detail.MASTER_NAME_SYS_ID"
                      >
                        {{ master_name_detail.MASTER_NAME }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <TaxTable v-if="selectedMasterData === 'Tax Type'" />
            <CostTypeTable v-if="selectedMasterData == 'Cost Type'" />
            <InsuranceProviderTable
              v-if="selectedMasterData === 'Insurance Provider'"
            />
            <InsuranceTypeTable
              v-if="selectedMasterData === 'Insurance Type'"
            />
            <InsuranceCompanyTable
              v-if="selectedMasterData === 'Insurance Company'"
            />
            <BankNameTable v-if="selectedMasterData === 'Bank'" />
            <VechileManufracturerTable
              v-if="selectedMasterData === 'Vehicle Make'"
            />
            <VechileBodyTypeTable
              v-if="selectedMasterData === 'Vehicle Body Type'"
            />
            <VechileModelTable v-if="selectedMasterData === 'Vehicle Model'" />
            <VechileColorTable v-if="selectedMasterData === 'Vehicle Color'" />
            <VechileLocationTable
              v-if="selectedMasterData === 'Vehicle Location'"
            />
            <CountryNameTable v-if="selectedMasterData === 'Country'" />
            <FianceCompanyTable
              v-if="selectedMasterData === 'Finance Company'"
            />
            <FinanceCompanyBankTable
              v-if="selectedMasterData === 'Finance Company Bank'"
            />
            <InvoiceDescriptionTable
              v-if="selectedMasterData === 'Invoice Description'"
            />
            <PaymentCode v-if="selectedMasterData === 'Payment Code '"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";

import TaxTable from "@/components/master-data-components/TaxTable";
import CostTypeTable from "@/components/master-data-components/CostTypeTable";
import InsuranceProviderTable from "@/components/master-data-components/InsuranceProviderTable";
import InsuranceTypeTable from "@/components/master-data-components/InsuranceTypeTable";
import InsuranceCompanyTable from "@/components/master-data-components/InsuranceCompanyTable";
import BankNameTable from "@/components/master-data-components/BankNameTable";
import VechileManufracturerTable from "@/components/master-data-components/VechileManufracturerTable";
import VechileBodyTypeTable from "@/components/master-data-components/VechileBodyTypeTable";
import VechileModelTable from "@/components/master-data-components/VechileModelTable";
import VechileColorTable from "@/components/master-data-components/VechileColorTable";
import VechileLocationTable from "@/components/master-data-components/VechileLocationTable";
import SystemRoleTable from "@/components/master-data-components/SystemRoleTable";
import CountryNameTable from "@/components/master-data-components/CountryNameTable";
import FianceCompanyTable from "@/components/master-data-components/FianceCompanyTable";
import FinanceCompanyBankTable from "@/components/master-data-components/FinanceCompanyBankTable";
import InvoiceDescriptionTable from "@/components/master-data-components/InvoiceDescriptionTable";
import PaymentCode from "@/components/master-data-components/PaymentCode.vue";

export default {
  data() {
    return {
      selectedMasterData: null,
      master_name_details: [],

      selected_row: {},
    };
  },

  components: {
    TaxTable,
    CostTypeTable,
    InsuranceProviderTable,
    InsuranceTypeTable,
    InsuranceCompanyTable,
    BankNameTable,
    VechileManufracturerTable,
    VechileBodyTypeTable,
    VechileModelTable,
    VechileColorTable,
    VechileLocationTable,
    SystemRoleTable,
    CountryNameTable,
    FianceCompanyTable,
    InvoiceDescriptionTable,
    FinanceCompanyBankTable,
    PaymentCode
  },
  mounted() {
    this.getMasterlistDetails();
    // this. getMasterData()
  },

  methods: {
    getMasterlistDetails() {
      this.axios
        .get(apiEndPoints.view_master, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.master_name_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  computed: {
    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
  },
};
</script>
