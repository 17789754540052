export default {
    methods: {
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => v[j]));
        },

        generateXlsx(headers, data, file_name = "download") {
            import ('@/Exports/Excel').then(excel => {
                excel.export_json_to_excel({
                    header: headers,
                    data,
                    filename: file_name,
                });
            });
        },

        formatDate(date) {
            const dateArray = new Date(date).toLocaleString('en-IN').split(',')[0].split("/");
            return (dateArray[0].length > 1 ? dateArray[0] : '0'+ dateArray[0]) + '-' + (dateArray[1].length > 1 ? dateArray[1] : '0'+ dateArray[1]) + '-' + dateArray[2];
        },
      
          

        parseDate(date) {
            if (new Date(date) != 'Invalid Date') {
                const dateArray = new Date(date).toLocaleString('en-US').split(',')[0].split('/');
                return dateArray[2] + '-' + (dateArray[1].length > 1 ? dateArray[1] : '0'+ dateArray[1]) + '-' + (dateArray[0].length > 1 ? dateArray[0] : '0'+ dateArray[0]);
            } else {
                const dateArray = date.split("-"); // required formatter to parse date is yyyy-mm-dd
                return dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0]
            }
        },

         sortBy(array, key) {
            return array.sort((a, b) => a[key].localeCompare(b[key]))
         },
         removeCommaSystem(value){
            value = String(value).replace(/,/g, '');
            return value;
        },

    }
};