<template>
  <div>
    <div class="floating-heading">
      <h4><span>Broker</span></h4>

      <div class="floating-search-btn-container">
        <button
          id="srch_btn"
          data-bs-toggle="modal"
          data-bs-target="#left_modal_sm"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>
      <div class="page-content">
        <div class="page-data">
          <div class="row mb-3">
            <div class="col-md-12 mb-1">
              <label class="frm-label-2" style="color: #a36626"
                ><i class="fa-solid fa-filter"></i> Filter</label
              >
              <div class="row mb-3">
                <div class="col-md-2" v-if="statusvalue">
                  <label class="frm-label-2" style="font-size: 15px"
                    >Broker Name:
                    <span class="filter-lbl">{{ statusvalue }}</span></label
                  >
                </div>
                <div class="col-md-3" v-if="numbervalue">
                  <label class="frm-label-2" style="font-size: 15px">
                    Mobile Number:
                    <span class="filter-lbl">{{ numbervalue }}</span></label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="floating-add-btn-container animate__animated animate__lightSpeedInRight"
    >
      <a
        href="#"
        id="add_btn"
        data-bs-toggle="modal"
        data-bs-target="#add_broker_modal"
        v-if="menuPermissions.FULL_ACCESS || menuPermissions.CREATE_ACCESS"
      >
        <i class="fa-solid fa-plus"></i>
      </a>
    </div>
    <!-- <div class="main-cont animate__animated animate__slideInRight">
            <div class="container-fluid"> -->
    <!-- <div class="row show-hide-panel">
                     <div class="col-md-12 animate__animated animate__zoomIn">
                        <div class="card-wrap shadow-main">
                           <div class="card-wrap-body cus-body-wrap">
                              <div class="filter-panel-wrap"> -->
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data">
          <div class="row">
            <div class="col-md-12 animate__animated animate__zoomIn">
              <div class="card-wrap shadow-main">
                <div class="card-wrap-body">
                  <div class="data-table master-table">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>SL. No</th>
                          <th>Person Name</th>
                          <th>Address</th>
                          <th>Mobile Number</th>
                          <th>Commission Percentage</th>
                          <th>Broker Type</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(user_detail, index) in user_details"
                        :key="user_detail.RECORD_SYS_ID"
                      >
                        <tr>
                          <td>{{ index + 1 }}</td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.PERSON_NAME }}
                          </td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.ADDRESS_LINE_1 }}
                          </td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.MOBILE_NO }}
                          </td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.COMMISSION_PERCENTAGE }}
                          </td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.SYSTEM_ROLE }}
                          </td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.ACCOUNT_STATUS }}
                          </td>
                          <td class="table-btn">
                            <button
                              type="button"
                              class="custom-btn me-1 p-0"
                              data-bs-toggle="modal"
                              data-bs-target="#view_frm"
                              @click="
                                showDetails(user_detail, '#view_broker_modal'),
                                  editfun(user_detail)
                              "
                              v-if="menuPermissions.UPDATE_ACCESS"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-eye"
                              >
                                <path
                                  d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                ></path>
                                <circle cx="12" cy="12" r="3"></circle>
                              </svg>
                            </button>
                            <button
                              type="button"
                              class="custom-btn p-0"
                              @click="
                                showModal(user_detail, '#rokerupdate_modal')
                              "
                            ></button>
                            <button
                              type="button"
                              class="custom-btn p-0"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_frm"
                              @click="Delete(user_detail)"
                              v-if="menuPermissions.DELETE_ACCESS"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trash-2"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path
                                  d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                ></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div>
            </div> -->
    </div>

    <!-- right modal from here for add broker -->
    <div
      class="modal modal-right fade"
      id="add_broker_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Broker</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bankform">
              <div class="row mt-2">
                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Broker Type <span style="color: red">*</span></label
                    >

                    <b-form-select
                      name="Broker Type"
                      @change="getsysid(), showReporting()"
                      v-model="create_user.SYSTEM_ROLE_SYS_ID"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null &&
                          create_user.SYSTEM_ROLE_SYS_ID != '',
                      }"
                    >
                      <option value="">Select</option>
                      <option
                        v-for="system_role_detail in system_role_details"
                        :value="system_role_detail.SYSTEM_ROLE_SYS_ID"
                        :key="system_role_detail.SYSTEM_ROLE_SYS_ID"
                      >
                        {{ system_role_detail.SYSTEM_ROLE }}
                      </option>
                    </b-form-select>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >First Name<span style="color: red">*</span></label
                    >
                    <input
                      type="name"
                      name="First Name"
                      v-model.trim="create_user.FIRST_NAME"
                      class="form-control"
                      placeholder="Enter First Name"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_user.FIRST_NAME != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Last Name<span style="color: red">*</span></label
                    >
                    <input
                      type="name"
                      name="Last Name"
                      v-model.trim="create_user.LAST_NAME"
                      class="form-control"
                      placeholder="Enter Last Name"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_user.LAST_NAME != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider
                    rules="required|mobileNumber"
                    v-slot="{ errors }"
                  >
                    <label class="frm-label"
                      >Mobile Number<span style="color: red">*</span></label
                    >
                    <input
                      type="number"
                      name="Mobile no"
                      v-model.trim="create_user.MOBILE_NO"
                      class="form-control"
                      placeholder="Enter Mobile Number"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_user.MOBILE_NO != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-md-4 mt-3">
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <label class="frm-label"
                      >Email ID<span style="color: red">*</span></label
                    >
                    <input
                      type="name"
                      name="Email"
                      v-model.trim="create_user.EMAIL_ID"
                      class="form-control"
                      placeholder="Enter Email"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_user.EMAIL_ID != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Gender<span style="color: red">*</span></label
                    >
                    <select
                      name="Gender"
                      v-model="create_user.GENDER"
                      class="form-control"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_user.GENDER != '',
                      }"
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                      <!-- <option></option> -->
                    </select>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Address <span style="color: red">*</span></label
                    >
                    <textarea
                      type="name"
                      name="Address "
                      style="height: 5em"
                      v-model.trim="create_user.ADDRESS_LINE_1"
                      class="form-control"
                      placeholder="Enter Address"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_user.ADDRESS_LINE_1 != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Post Code<span style="color: red">*</span></label
                    >
                    <input
                      type="number"
                      name="pin Code"
                      v-model.trim="create_user.PIN_CODE"
                      class="form-control"
                      placeholder="Enter Post Code "
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_user.PIN_CODE != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider v-slot="{ errors }">
                    <label class="frm-label"
                      >Country <span style="color: red">*</span></label
                    >
                    <b-form-select
                      name="Country"
                      v-model="create_user.COUNTRY_SYS_ID"
                      @change="getcountrysysid"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_user.COUNTRY_SYS_ID != '',
                      }"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled
                          >-- Select --</b-form-select-option
                        >
                      </template>
                      <option value="" selected>Select</option>
                      <option
                        v-for="cost_type_info_detail in cost_type_info_details"
                        :value="cost_type_info_detail.COUNTRY_SYS_ID"
                        :key="cost_type_info_detail.COUNTRY_SYS_ID"
                      >
                        {{ cost_type_info_detail.COUNTRY_NAME }}
                      </option>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Password<span style="color: red">*</span></label
                    >
                    <input
                      type="name"
                      name="Password"
                      v-model.trim="create_user.PASSWORD"
                      class="form-control"
                      placeholder="Enter Password"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_user.PASSWORD != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Commission Percentage
                      <span style="color: red">*</span></label
                    >
                    <input
                      type="number"
                      name="Commission Percentage"
                      v-model="create_user.COMMISSION_PERCENTAGE"
                      class="form-control"
                      placeholder="Enter Commission Percentage"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null &&
                          create_user.COMMISSION_PERCENTAGE != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4 mt-3">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label" v-show="show"
                        >Reporting Broker
                        <!-- <span style="color: red;">*</span> -->
                      </label>
                      <select
                        name="Reporting Broker"
                        v-show="show"
                        v-model="USER_SYS_ID"
                        @change="getseniorbrokersysid"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid': errors[0] == null && USER_SYS_ID != '',
                        }"
                      >
                        >

                        <option value="">Select</option>
                        <option
                          v-for="tax_info in tax_info_details"
                          :value="tax_info.USER_SYS_ID"
                          :key="tax_info.USER_SYS_ID"
                        >
                          {{ tax_info.SENIOR_BROKER }}
                        </option>
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label" v-show="show"
                        >Reporting Broker percentage
                        <!-- <span style="color: red;">*</span> -->
                      </label>
                      <input
                        v-show="show"
                        type="name"
                        name="name"
                        v-model.trim="create_user.REPORTING_BROKER_PERCENTAGE"
                        class="form-control"
                        placeholder="Enter reporting broker percentage"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_user.REPORTING_BROKER_PERCENTAGE != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="modal-footer-2">
                  <div class="row mt-4">
                    <div class="col-md-2">
                      <button
                        type="button"
                        class="custom-btn custom-success-btn"
                        data-bs-dismiss="modal"
                        @click="AddBroker"
                      >
                        Save
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button
                        type="button"
                        class="custom-btn custom-danger-btn"
                        data-bs-dismiss="modal"
                        @click="emptyfu"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
          <!-- //ends here -->
        </div>
      </div>
    </div>

    <!-- right modal from here for edit broker -->
    <div
      class="modal modal-right fade"
      id="rokerupdate_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Broker</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="bankform2" v-slot="{ invalid }">
              <form>
                <div class="row mt-2">
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Broker Types <span style="color: red">*</span></label
                      >
                      <b-form-select
                        name="System Role"
                        @change="getsysidedit(), showEditReporting()"
                        v-model="update_user.SYSTEM_ROLE_SYS_ID"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.SYSTEM_ROLE_SYS_ID != '',
                        }"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- Select Role --</b-form-select-option
                          >
                        </template>
                        <option
                          v-for="system_role_detail in system_role_details"
                          :key="system_role_detail.SYSTEM_ROLE_SYS_ID"
                          :value="system_role_detail.SYSTEM_ROLE_SYS_ID"
                        >
                          {{ system_role_detail.SYSTEM_ROLE }}
                        </option>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >First Name<span style="color: red">*</span></label
                      >
                      <input
                        type="name"
                        name="Vendor Name"
                        v-model.trim="update_user.FIRST_NAME"
                        class="form-control"
                        placeholder="Enter Vendor Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.FIRST_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Last Name<span style="color: red">*</span></label
                      >
                      <input
                        type="name"
                        name="Last Name"
                        v-model.trim="update_user.LAST_NAME"
                        class="form-control"
                        placeholder="Enter Last Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.LAST_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Gender<span style="color: red">*</span></label
                      >
                      <select
                        name="Gender"
                        v-model="update_user.GENDER"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.GENDER != '',
                        }"
                      >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                        <!-- <option></option> -->
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider
                      rules="required|mobileNumber"
                      v-slot="{ errors }"
                    >
                      <label class="frm-label"
                        >Mobile Number<span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="Mobile no"
                        v-model.trim="update_user.MOBILE_NO"
                        class="form-control"
                        placeholder="Enter Mobile no"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.MOBILE_NO != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <label class="frm-label"
                        >Email ID<span style="color: red">*</span></label
                      >
                      <input
                        type="name"
                        name="Vendor Name"
                        v-model.trim="update_user.EMAIL_ID"
                        class="form-control"
                        placeholder="Enter Email"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.EMAIL_ID != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Address <span style="color: red">*</span></label
                      >
                      <textarea
                        type="name"
                        name="Address Line 1"
                        style="height: 5em"
                        v-model.trim="update_user.ADDRESS_LINE_1"
                        class="form-control"
                        placeholder="Enter Address"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.ADDRESS_LINE_1 != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Post Code<span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="pin Code"
                        v-model.trim="update_user.PIN_CODE"
                        class="form-control"
                        placeholder="Enter Vendor Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.PIN_CODE != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Country <span style="color: red">*</span></label
                      >
                      <b-form-select
                        name="country"
                        v-model="update_user.COUNTRY_SYS_ID"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.COUNTRY_NAME != null,
                        }"
                      >
                        <template>
                          <b-form-select-option :value="null" disabled
                            >-- Select --</b-form-select-option
                          >
                        </template>

                        <option
                          v-for="cost_type_info_detail in cost_type_info_details"
                          :value="cost_type_info_detail.COUNTRY_SYS_ID"
                          :key="cost_type_info_detail.COUNTRY_SYS_ID"
                        >
                          {{ cost_type_info_detail.COUNTRY_NAME }}
                        </option>

                        <!-- <option></option> -->
                      </b-form-select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Commission Percentage
                        <span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="Commission Percentage"
                        v-model="update_user.COMMISSION_PERCENTAGE"
                        class="form-control"
                        placeholder="Enter Commission Percentage"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.COMMISSION_PERCENTAGE != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3" v-show="showedit">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Reporting Broker
                        <!-- <span style="color: red;">*</span> -->
                      </label>
                      <select
                        name="Reporting Broker"
                        v-model="update_user.SENIOR_BROKER"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.SENIOR_BROKER != '',
                        }"
                      >
                        >
                        <option value="">Select</option>
                        <option
                          v-for="tax_info in tax_info_details"
                          :value="tax_info.USER_SYS_ID"
                          :key="tax_info.USER_SYS_ID"
                        >
                          {{ tax_info.SENIOR_BROKER }}
                        </option>
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3" v-show="showedit">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Reporting Broker Percentage
                        <!-- <span style="color: red;">*</span> -->
                      </label>
                      <input
                    
                        type="name"
                        name="name"
                        v-model.trim="update_user.REPORTING_BROKER_PERCENTAGE"
                        class="form-control"
                        placeholder="Enter reporting broker percentage"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.REPORTING_BROKER_PERCENTAGE != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          :disabled="invalid"
                          @click="updateBroker"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <div v-show="show1" class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          :disabled="invalid"
                          :class="{ active: isActive1 }"
                          @click="toggle1()"
                        >
                          {{ isActive1 ? "Delete" : "Activate" }}
                        </button>
                      </div>

                      <div v-show="show2" class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          :disabled="invalid"
                          :class="{ active: isActive2 }"
                          @click="toggle2(), userBlock(), userUnblock()"
                        >
                          {{ isActive2 ? "Block" : "Unblock" }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <!-- right modal from here for view broker -->
    <div
      class="modal modal-right fade"
      id="view_broker_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">View Broker</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div
                class="col-md-4 mt-3"
                v-if="view_brokers.FIRST_NAME || view_brokers.LAST_NAME"
              >
                <label class="frm-label"> Name </label>
                <label
                  >{{ view_brokers.FIRST_NAME }}
                  {{ view_brokers.LAST_NAME }}</label
                >
              </div>

              <div class="col-md-4 mt-3" v-if="view_brokers.GENDER">
                <label class="frm-label">Gender</label>
                <label>{{ view_brokers.GENDER }}</label>
              </div>

              <div class="col-md-4 mt-3" v-if="view_brokers.MOBILE_NO">
                <label class="frm-label">Mobile Number </label>
                <label>{{ view_brokers.MOBILE_NO }}</label>
              </div>

              <div
                class="col-md-4 mt-3"
                v-if="
                  view_brokers.ADDRESS_LINE_1 ||
                  view_brokers.ADDRESS_LINE_2 ||
                  view_brokers.PIN_CODE
                "
              >
                <label class="frm-label">Address </label>
                <label>
                  <span v-if="view_brokers.ADDRESS_LINE_1"
                    >{{ view_brokers.ADDRESS_LINE_1 }}<br
                  /></span>
                  <span v-if="view_brokers.ADDRESS_LINE_2"
                    >{{ view_brokers.ADDRESS_LINE_2 }}<br
                  /></span>
                  <span v-if="view_brokers.COUNTRY_NAME"
                    >{{ view_brokers.COUNTRY_NAME }} -
                  </span>
                  <span v-if="view_brokers.PIN_CODE">{{
                    view_brokers.PIN_CODE
                  }}</span>
                </label>
              </div>
              <!-- <div class="col-md-4 mt-3" v-if="view_brokers.COUNTRY_NAME">
                                <label class="frm-label">Country </label>
                                <label>{{view_brokers.COUNTRY_NAME}}</label>
                            </div> -->
              <div class="col-md-4 mt-3" v-if="view_brokers.SYSTEM_ROLE">
                <label class="frm-label">Broker Type</label>
                <label>{{ view_brokers.SYSTEM_ROLE }}</label>
              </div>
              <div class="col-md-4 mt-3" v-if="view_brokers.EMAIL_ID">
                <label class="frm-label">Email ID </label>
                <label>{{ view_brokers.EMAIL_ID }}</label>
              </div>
              <div
                class="col-md-4 mt-3"
                v-if="view_brokers.COMMISSION_PERCENTAGE"
              >
                <label class="frm-label">Commission Percentage </label>
                <label>{{ view_brokers.COMMISSION_PERCENTAGE }}%</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- search modal -->
    <div
      class="modal modal-left fade"
      id="left_modal_sm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="left_modal_sm"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search By</h5>
          </div>
          <div class="modal-body search-panel">
            <div class="row">
              <div class="col-md-12">
                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="all"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">All Combinations</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="status"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Broker Name</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="number"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Mobile Number</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="make"
                    class="rad-input"
                    v-model="search_option"
                  />
                </label>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'status'"
              >
                <label class="frm-label">Broker name</label>
                <input
                  type="text"
                  v-model="INSURANCE_TYPE"
                  class="form-control"
                  placeholder="Enter Broker Name"
                />
              </div>
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'number'"
              >
                <label class="frm-label">Mobile Number</label>
                <input
                  type="text"
                  v-model="VEHICLENUMBER"
                  class="form-control"
                  placeholder="Enter Mobile No."
                />
              </div>

              <div class="col-md-12 mt-2" v-if="search_option">
                <div class="btn-conainer">
                  <button
                    type="button"
                    @click="getBrokerDetails()"
                    id="srch-btn"
                    class="custom-btn custom-success-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
export default {
  // props: {
  //     type: {
  //         type: String,
  //         default: "text",
  //     },
  //     size: {
  //         type: String,
  //         default: "8",
  //     },
  //     characters: {
  //         type: String,
  //         default: "a-z,A-Z,0-9,#",
  //     },
  //     placeholder: {
  //         type: String,
  //         default: "Password",
  //     },
  //     auto: [String, Boolean],
  //     value: "",
  // },

  data() {
    return {
      system_role_details: [],
      tax_info_details: [],
      cost_type_info_details: [],
      user_details: [],
      delete_user: {
        ITEM: "DELETE_USER",
        USER_SYS_ID: "",
      },
      activate_user: {
        ITEM: "ACTIVATE_USER",
        USER_SYS_ID: "",
      },
      block_user: {
        ITEM: "BLOCK_USER",
        USER_SYS_ID: "",
      },
      unblock_user: {
        ITEM: "UNBLOCK_USER",
        USER_SYS_ID: "",
      },
      create_user: {
        ITEM: "ADD_USER",
        BROKER_TYPE: "",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        DOB: "",
        EMAIL_ID: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        GENDER: "",
        MOBILE_NO: "",
        SYSTEM_ROLE: "",
        COUNTRY_SYS_ID: "",

        ORGANISATION_SYS_ID: "1",
        SYSTEM_ROLE_SYS_ID: "",
        USER_TYPE: "External",
        PASSWORD: "",
        ADDRESS_LINE_1: "",

        PIN_CODE: "",
        REPORTING_BROKER_PERCENTAGE: "",
        COUNTRY_NAME: "",
        COMMISSION_PERCENTAGE: "",
        SENIOR_BROKER: "",
      },
      search_option: "",
      vehicletype: "",
      INSURANCE_TYPE: "",
      view_brokers: [],
      selected_row: {},
      update_user: {},
      NAME: "",
      CONTACT_NO: "",
      BROKER_TYPE: "",
      statusvalue: false,
      STATUS: "",
      show: false,
      showedit: false,
      vehicledetails_page_no: 1,
      vehicledetails: {},
      isActive1: false,
      isActive2: false,
      show1: true,
      show2: true,

      // Address_details:{},
    };
  },
  mounted() {
    this.getBrokerDetails();
    this.getSystemRoleDetails();
    this.getTaxInfo();
    this.getCountryInfo();
    //this.getBrokerDetail()
  },
  methods: {
    emptyfu() {
      $("#add_broker_modal").modal("hide");
      this.create_user.ADDRESS_LINE_1 = "";
      this.create_user.ADDRESS_LINE_2 = "";
      this.create_user.FIRST_NAME = "";
      this.create_user.LAST_NAME = "";
      this.create_user.MOBILE_NO = "";
      this.create_user.COUNTRY_NAME = "";
      this.create_user.PIN_CODE = "";
      this.create_user.GENDER = "";
      this.create_user.BROKER_TYPE = "";
      this.create_user.EMAIL_ID = "";
      this.create_user.PASSWORD = "";
      this.create_user.SYSTEM_ROLE_SYS_ID = "";
      this.create_user.COMMISSION_PERCENTAGE = "";
      this.create_user.COUNTRY_SYS_ID =
        this.cost_type_info_details[0].COUNTRY_SYS_ID;
      this.getcountrysysid();
    },
    async Delete(data) {
      const result = await Global.showConfirmationAlert(
        `Delete Broker ${data.PERSON_NAME}`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE_USER",
          USER_SYS_ID: data.USER_SYS_ID,
          CREATED_BY: user_details.UserId,
        };

        //console.log(postarrydata);
        this.axios
          .post(apiEndPoints.create_update_broker, postarrydata)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getBrokerDetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            // this.isProductModalLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    filtershowhide() {
      if (this.STATUS != "") {
        this.statusvalue = true;
      } else {
        this.statusvalue = false;
      }

      if (this.NAME != "") {
        this.makevalue = true;
      } else {
        this.makevalue = false;
      }
      if (this.CONTACT_NO != "") {
        this.modelvalue = true;
      } else {
        this.modelvalue = false;
      }
    },

    showReporting() {
      //console.log("func call");
      var ddlBroType = this.create_user.SYSTEM_ROLE;
      console.log(ddlBroType,"67")
      if (
        ddlBroType == "Junior Broker" ||
        ddlBroType == "Junior Assistance Broker"
      ) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    showEditReporting() {
      var ddlEditBroType = this.update_user.SYSTEM_ROLE;
      if (
        ddlEditBroType === "Junior Broker" ||
        ddlEditBroType === "Junior Assistance Broker"
      ) {
        this.showedit = true;
      } else {
        this.showedit = false;
      }
    },
    // generate() {
    //     this.create_user.PASSWORD = "";

    //     let charactersArray = this.characters.split(",");
    //     let CharacterSet = "";
    //     let password = "";

    //     if (charactersArray.indexOf("a-z") >= 0) {
    //         CharacterSet += "abcdefghijklmnopqrstuvwxyz";
    //     }
    //     if (charactersArray.indexOf("A-Z") >= 0) {
    //         CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //     }
    //     if (charactersArray.indexOf("0-9") >= 0) {
    //         CharacterSet += "0123456789";
    //     }
    //     if (charactersArray.indexOf("#") >= 0) {
    //         CharacterSet += "![]{}()%&*$#^<>~@|";
    //     }

    //     for (let i = 0; i < this.size; i++) {
    //         password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
    //     }
    //     this.create_user.PASSWORD = password;
    // },

    getSystemRoleDetails() {
      this.axios
        .get(apiEndPoints.system_role_api, {
          params: {
            ITEM: "EXTERNAL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.system_role_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    AddBroker() {
      //console.log("ty");
      //this.generate();
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.create_user["CREATED_BY"] = user_details.UserId;

      this.create_user["REPORTING_BROKER_SYS_ID"] = this.USER_SYS_ID;

      //console.log(JSON.stringify(this.create_user));
      this.$refs.bankform.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.create_update_broker, this.create_user)
            .then((response) => {
              //console.log(response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getBrokerDetails();
                getSystemRoleDetails();
                this.emptyfu();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },
    //   getBrokerDetail() {

    //             this.axios
    //                 .get(apiEndPoints.view_user, {
    //                     params: {
    //                         ITEM: "SPECIFIC",
    //                         NAME:this.NAME,
    //                         CONTACT_NO:this.CONTACT_NO,
    //                         BROKER_TYPE:this.BROKER_TYPE,
    //                         USER_TYPE:"EXTERNAL",
    //                         PAGE_SIZE: 12,

    //                     },
    //                 })
    //                 .then((response) => {
    //                     //console.log("m",response.data.response);
    //                         if (response.data.status == "true") {

    //                     this.broker_details = response.data.response[0];

    //                 }
    //                  else if (response.data.status == false || response.data.status == "false") {

    //                 }
    //                 })
    //                 .catch((err) => {
    //                     //console.log(err);
    //                 });
    //         },

    getseniorbrokersysid() {
      this.create_user.SENIOR_BROKER = this.tax_info_details.find(
        (o) => o.USER_SYS_ID === this.USER_SYS_ID
      ).SENIOR_BROKER;
      //console.log("getseniorbrokersysid", this.create_user.SENIOR_BROKER);
    },
    getsysid() {
      this.create_user.BROKER_TYPE = this.system_role_details.find(
        (o) => o.SYSTEM_ROLE_SYS_ID === this.create_user.SYSTEM_ROLE_SYS_ID
      ).SYSTEM_ROLE;
      this.create_user.SYSTEM_ROLE = this.system_role_details.find(
        (o) => o.SYSTEM_ROLE_SYS_ID === this.create_user.SYSTEM_ROLE_SYS_ID
      ).SYSTEM_ROLE;
    },
    getcountrysysid() {
      //console.log("riju");
      this.create_user.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.create_user.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
      this.update_user.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.update_user.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
    },

    getsysidedit() {
      this.update_user.SYSTEM_ROLE = this.system_role_details.find(
        (o) => o.SYSTEM_ROLE_SYS_ID === this.update_user.SYSTEM_ROLE_SYS_ID
      ).SYSTEM_ROLE;
    },

    updateBroker() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.update_user["CREATED_BY"] = user_details.UserId;
      this.update_user["ITEM"] = "UPDATE_USER";
      this.update_user["RECORD_SYS_ID"] = this.update_user.USER_SYS_ID;
      this.update_user["SYSTEM_ROLE_SYS_ID"] =
        this.update_user.SYSTEM_ROLE_SYS_ID;
      //console.log(JSON.stringify(this.update_user));
      this.axios
        .post(apiEndPoints.create_update_broker, this.update_user)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getBrokerDetails();
            this.getSystemRoleDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    getBrokerDetails(page_no = 1) {
      this.filtershowhide();
      if (this.search_option == "all") {
        this.statusvalue = this.INSURANCE_TYPE;
        this.numbervalue = this.VEHICLENUMBER;
      } else {
        if (this.search_option == "status")
          this.statusvalue = this.INSURANCE_TYPE;
        if (this.search_option == "number")
          this.numbervalue = this.VEHICLENUMBER;
      }
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            ITEM: "SPECIFIC",
            NAME: this.INSURANCE_TYPE,
            CONTACT_NO: this.VEHICLENUMBER,
            BROKER_TYPE: this.BROKER_TYPE,
            USER_TYPE: "EXTERNAL",
            PAGE_SIZE: 100,
            PAGE_NO: page_no,
          },
        })
        .then((response) => {
          //console.log("b", response.data.response);
          if (response.data.status == "true") {
            if (page_no == 1) {
              this.vehicledetails = [];
            }
            this.vehicledetails = [
              ...this.vehicledetails,
              ...response.data.response,
            ];
            this.vehicledetails_page_no += 1;
            this.user_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            if (page_no == 1) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //for senior broker dropdown---
    getTaxInfo() {
      this.axios
        .get(apiEndPoints.senior_broker, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("ttt", response);
          //this.tax_info_details = response.data.response;
          if (response.data.status == "true") {
            this.tax_info_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == false
          ) {
            this.tax_info_details = [];
            // Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getSeniorBrokerName() {
      this.addmasterdata.MAKE_NAME = this.Manufracturename.find(
        (o) => o.VEHICLE_MAKE_SYS_ID === this.addmasterdata.MAKE_SYS_ID
      ).VEHICLE_MAKE;
    },
    userid() {
      this.addmasterdata.MAKE_NAME = this.Manufracturename.find(
        (o) => o.VEHICLE_MAKE_SYS_ID === this.addmasterdata.MAKE_SYS_ID
      ).VEHICLE_MAKE;
    },
    loadVehicleDetails(isVisible) {
      if (isVisible) {
        this.getBrokerDetails(this.vehicledetails_page_no);
      }
    },
    showDetails(row, id) {
      this.view_brokers = row;
      //console.log(row);
      $(id).modal("show");
      // this.getaddressDetails();
    },
    showModal(row, id) {
      this.update_user = row;
      console.log(  this.update_user)
      this.showEditReporting()
      //console.log(this.update_user);
      $(id).modal("show");
    },

    getCountryInfo() {
      this.axios
        .get(apiEndPoints.view_country, {
          params: { ITEM: "VIEW_ALL", RECORD_SYS_ID: 0 },
        })
        .then((response) => {
          //console.log(response);
          //this.cost_type_info_details = response.data.response;
          if (response.data.status == "true") {
            this.cost_type_info_details = response.data.response;
            this.$nextTick(() => {
              this.create_user.COUNTRY_SYS_ID =
                this.cost_type_info_details[0].COUNTRY_SYS_ID;
              this.getcountrysysid();
            });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.cost_type_info_details = [];
            this.create_user.COUNTRY_SYS_ID = null;
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    toggle1() {
      //this.show2=false;
      if (!this.isActive1) {
        this.isActive1 = true;
        //this.show2=false;
        this.userActivate();
      } else {
        this.isActive1 = false;
        //this.userActivate();

        this.userDelete();
      }
    },
    toggle2() {
      if (!this.isActive2) {
        this.isActive2 = true;
        //this.userBlock();
        this.userUnblock();
      } else {
        this.isActive2 = false;
        //this.userUnblock();
        this.userBlock();
      }
    },

    userDelete() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.delete_user["ITEM"] = "DELETE_USER";
      this.delete_user["USER_SYS_ID"] = this.update_user.USER_SYS_ID;

      this.axios
        .post(apiEndPoints.user_block_delete, this.delete_user)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getBrokerDetails();
            getSystemRoleDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        });
    },

    userActivate() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.activate_user["ITEM"] = "ACTIVATE_USER";
      this.activate_user["USER_SYS_ID"] = this.update_user.USER_SYS_ID;
      this.axios
        .post(apiEndPoints.user_block_delete, this.activate_user)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);

            this.getBrokerDetails();
            getSystemRoleDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        });
    },
    userBlock() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.block_user["ITEM"] = "BLOCK_USER";
      this.block_user["USER_SYS_ID"] = this.update_user.USER_SYS_ID;
      this.axios
        .post(apiEndPoints.user_block_delete, this.block_user)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getBrokerDetails();
            getSystemRoleDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        });
    },
    userUnblock() {
      //console.log("user unblock");
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.unblock_user["ITEM"] = "UNBLOCK_USER";
      this.unblock_user["USER_SYS_ID"] = this.update_user.USER_SYS_ID;
      //console.log(JSON.stringify(this.unblock_user));
      this.axios
        .post(apiEndPoints.user_block_delete, this.unblock_user)
        .then((response) => {
          //console.log(response);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getBrokerDetails();
            getSystemRoleDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        });
    },
  },
  computed: {
    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
  },
};
</script>
