import { render, staticRenderFns } from "./ViewInsurance.vue?vue&type=template&id=797c03af&scoped=true&"
import script from "./ViewInsurance.vue?vue&type=script&lang=js&"
export * from "./ViewInsurance.vue?vue&type=script&lang=js&"
import style0 from "./ViewInsurance.vue?vue&type=style&index=0&id=797c03af&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797c03af",
  null
  
)

export default component.exports